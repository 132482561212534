import React, { useState, useEffect } from "react";
import Modal from "../../modal/Modal";
import { useNavigate } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import TextAreaBox from "../../../layouts/TextAreaBox";
import { useStatusModal } from "../../../App";
const core = require("../../../lib/core");
function CompanyModal({ modalDismiss, data, templateData, tempid }) {
  const nav = useNavigate();
  console.log(data, templateData);

  const [loading, setLoading] = useState(false);

  const [companyData, setCompanyData] = useState({
    ...data,
    benefits: "",
    culture: "",
  });
  const [isEditing, setIsEditing] = useState({
    field: null,
    value: "",
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const { showSuccess, showError } = useStatusModal();

  const truncateText = (text, limit) => {
    const words = text.split(" ");
    if (words.length > limit) {
      return words.slice(0, limit).join(" ") + "...";
    }
    return text;
  };

  useEffect(() => {
    if (templateData.length > 0) {
      const benefits = templateData[0]?.benefits
        ? templateData[0].benefits.join(", ")
        : "";
      const culture = templateData[0]?.culture || "";
      setCompanyData((prevData) => ({
        ...prevData,
        benefits,
        culture,
      }));
      setLoading(false);
    }
  }, [templateData]);

  const handleInputChange = (fieldKey, value) => {
    setCompanyData({ ...companyData, [fieldKey]: value });
  };

  const handleEdit = (fieldKey, currentValue) => {
    setIsEditing({
      field: fieldKey,
      value: currentValue || "", // Ensure a string value, even if undefined
    });
  };

  const handleCancel = () => {
    setIsEditing({ field: null, value: "" });
  };

  const [updatedFields, setUpdatedFields] = useState({});

  const handleUpdate = async (fieldKey) => {
    const updatedValue = isEditing.value;

    // Update local state
    setCompanyData((prevData) => ({
      ...prevData,
      [fieldKey]: updatedValue,
    }));

    // Directly pass the updated value to the API
    const updatedField = { [fieldKey]: updatedValue };

    setIsEditing({ field: null, value: "" });

    // Call the API with the specific field and value
    await updateNewCompanyPost(updatedField);
  };

  const updateNewCompanyPost = async (updatedField) => {
    // setLoading(true);

    try {
      const payload = updatedField; // Use the updatedField passed from handleUpdate
      const json = await core.API(
        core.API_METHODS.PUT,
        core.COMPANY_API.UPDATE_COMPANY_DATA + companyData?._id,
        1,
        payload,
      );

      if (json.data) {
        // setLoading(false);
        console.log("Company data updated successfully!");
        setUpdatedFields({}); // Clear the updated fields after success
      } else {
        // setLoading(false);
      }
    } catch (error) {
      // setLoading(false);
      // showError("Something went wrong. Please try again.");
    }
  };

  // const handleUpdate = (fieldKey) => {
  //   setCompanyData((prevData) => ({
  //     ...prevData,
  //     [fieldKey]: isEditing.value,
  //   }));
  //   setIsEditing({ field: null, value: "" });

  //   // Add this: Scroll to the updated field
  //   setTimeout(() => {
  //     const updatedField = document.getElementById(`field-${fieldKey}`);
  //     if (updatedField) {
  //       updatedField.scrollIntoView({ behavior: 'smooth', block: 'center' });
  //     }
  //   }, 0);
  // };

  const EditableField = ({ label, value, fieldKey, textarea }) => (
    <div className="mb-4 group" id={`field-${fieldKey}`}>
      <label className="block text-sm font-normal not-italic">{label}</label>
      {isEditing.field === fieldKey ? (
        <div>
          {textarea ? (
            <TextAreaBox
              type="text"
              autoFocus
              className="w-full border border-gray-300 rounded p-2 focus:ring-2 focus:ring-blue-500"
              value={isEditing.value}
              size="350px"
              onChange={(e) =>
                setIsEditing((prev) => ({ ...prev, value: e.target.value }))
              }
            />
          ) : (
            <input
              type="text"
              autoFocus
              className="w-full border border-gray-300 rounded p-2 focus:ring-2 focus:ring-blue-500"
              value={isEditing.value}
              onChange={(e) =>
                setIsEditing((prev) => ({ ...prev, value: e.target.value }))
              }
            />
          )}
          <button
            className="m-1 bg-gray-500 text-white py-1 px-4 rounded-full"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            className="m-1 bg-blue-800 text-white py-1 px-4 rounded-full"
            onClick={() => handleUpdate(fieldKey)}
          >
            Update
          </button>
        </div>
      ) : (
        <div className="flex items-center">
          <div className="py-1 px-2 border border-gray-300 rounded-lg text-sm text-gray-800 w-full">
            {fieldKey === "about" && value ? (
              <p>
                {isExpanded ? value : truncateText(value, 120)}
                {value.split(" ").length > 120 && (
                  <button
                    className="text-blue-800 hover:text-blue-800 focus:outline-none ml-1"
                    onClick={() => setIsExpanded(!isExpanded)}
                  >
                    {isExpanded ? "Show less" : "Show more"}
                  </button>
                )}
              </p>
            ) : (
              <p>{value || "N/A"}</p>
            )}
          </div>
          <span
            className="ml-2 cursor-pointer invisible group-hover:visible"
            onClick={() => handleEdit(fieldKey, value)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              strokeWidth={2}
            >
              <path d="M12 20h9" />
              <path d="M16.5 3.5l4 4L7 21H3v-4L16.5 3.5z" />
            </svg>
          </span>
        </div>
      )}
    </div>
  );

  return (
    <Modal
      className={"w-[600px] md:max-w-[60%]"}
      noBtns={true}
      onSave={(e) => {
        const url = `/templates/job/${tempid}`;
        const newTab = window.open(url, "_blank");
        newTab.focus();
      }}
      modalDismiss={modalDismiss}
    >
      {loading ? (
        <div className="flex justify-center items-center h-64">
          <ScaleLoader color="#1369E9" loading={true} />
        </div>
      ) : (
        <div className="p-8">
          <h3 className="mb-2 text-gray-800">{data?.name}</h3>
          <EditableField
            label="Industry"
            value={
              typeof companyData?.industry !== "string"
                ? companyData?.industry?.join(", ")
                : companyData?.industry
            }
            fieldKey="industry"
          />
          <EditableField
            label="Domain URL"
            value={companyData?.links || companyData.domain_url}
            fieldKey="domain_url"
          />
          <EditableField
            label="Year Founded"
            value={companyData?.year_founded}
            fieldKey="year_founded"
          />
          <EditableField
            label="About"
            value={companyData?.about}
            fieldKey="about"
            textarea
          />
          <EditableField
            label="Location"
            value={companyData?.location}
            fieldKey="location"
          />
          <EditableField
            label="Country"
            value={companyData?.country}
            fieldKey="country"
          />
          <EditableField
            label="LinkedIn"
            value={companyData?.linkedin}
            fieldKey="linkedin"
          />
          <EditableField
            label="Benefits"
            value={companyData?.benefits}
            fieldKey="benefits"
          />
          <EditableField
            label="Culture"
            value={companyData?.culture}
            fieldKey="culture"
          />
        </div>
      )}
    </Modal>
  );
}

export default CompanyModal;
