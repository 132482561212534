import React, { useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import Question from "./screening/Question";
import { ScreeningQuestionContext } from "../../../lib/context";
import { ANSWERTYPES } from "../../../lib/constants";
import { IoIosArrowDropdown } from "react-icons/io";
import { RxDragHandleDots2 } from "react-icons/rx";
import { use } from "react";

function ScreeningQuestion({ quArray, setQuArray }) {
  const handleButtonClick = (index) => {
    const updatedArray = quArray.map((item, idx) => {
      if (idx === index) return { ...item, status: !item.status };
      return item;
    });
    setQuArray(updatedArray);
  };

  const addCustomQuestion = () => {
    const updatedArray = [
      ...quArray,
      {
        id: quArray.length,
        name: "Custom question " + (quArray.length + 1),
        status: true,
        question: "",
        required: false,
        answerType: ANSWERTYPES?.TEXT,
      },
    ];
    setQuArray(updatedArray);
  };

  const updateQuestion = (quesId, key, value) => {
    const updatedArray = quArray.map((item) => {
      if (quesId === item?.id) return { ...item, [key]: value };
      return item;
    });
    setQuArray(updatedArray);
  };

  const selectedQuestions = useMemo(() => {
    const filtered = quArray.filter((item) => item?.status === true);
    const hasOrder = filtered.every((item) => item?.order !== undefined);
    return hasOrder ? filtered.sort((a, b) => a.order - b.order) : filtered;
  }, [quArray]);

  const [expandedItems, setExpandedItems] = useState(new Set());

  console.log(expandedItems);

  const toggleExpand = (id) => {
    const newExpandedItems = new Set(expandedItems);
    if (newExpandedItems.has(id)) {
      newExpandedItems.delete(id);
    } else {
      newExpandedItems.add(id);
    }
    setExpandedItems(newExpandedItems);
    console.log(expandedItems);
  };

  // Updated handleDragEnd function to properly reorder questions
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const { source, destination } = result;

    // Create a copy of the full array
    const newQuArray = [...quArray];

    // Find all selected and unselected questions
    const selectedQuestions = newQuArray.filter((q) => q.status);
    const unselectedQuestions = newQuArray.filter((q) => !q.status);

    // Reorder the selected questions
    const [movedQuestion] = selectedQuestions.splice(source.index, 1);
    selectedQuestions.splice(destination.index, 0, movedQuestion);

    // Create the final array by combining unselected and reordered selected questions
    const selectedIndices = newQuArray
      .map((q, index) => (q.status ? index : -1))
      .filter((index) => index !== -1);

    const finalArray = [...newQuArray];
    selectedIndices.forEach((originalIndex, i) => {
      finalArray[originalIndex] = selectedQuestions[i];
    });

    setQuArray(finalArray);
  };

  const [isEnabled, setIsEnabled] = useState(false);
  return (
    <ScreeningQuestionContext.Provider
      value={{ data: quArray, setData: updateQuestion }}
    >
      <div className="w-full input-data-section" id="screening-question">
        {/* Question selection tags */}
        <div className="w-full py-5 flex flex-wrap gap-2">
          {quArray.map((item, index) => (
            <button
              key={index}
              type="button"
              className={`${
                item.status ? "hover:!bg-[#699c8f]" : "hover:!bg-[#00b98e34]"
              } rounded-[68px] flex text-sm border border-gray-500 items-center gap-1.5 p-2 px-2.5`}
              style={{
                background: item?.status
                  ? "#00B98D"
                  : "rgba(51, 52, 142, 0.03)",
                transition: "background-color 0.5s",
              }}
              onClick={() => handleButtonClick(index)}
            >
              <span
                style={{
                  color: item?.status ? "#FFFFFF" : "#252E4B",
                  transition: "color 0.5s",
                }}
              >
                {item?.name}
              </span>
              {!item?.status ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="add">
                    <path
                      id="Vector"
                      d="M4 8H12"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M8 12V4"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="Right icon">
                    <path
                      id="Vector"
                      d="M4.66797 4.66602L11.3346 11.3327"
                      stroke="white"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M4.66732 11.3327L11.334 4.66602"
                      stroke="white"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              )}
            </button>
          ))}
          {/* Add Custom Question button always at the end */}
          <button
            type="button"
            className="hover:!bg-[#00b98e34] rounded-[68px] flex text-sm border border-gray-500 items-center gap-1.5 p-2 px-2.5"
            style={{
              background: "rgba(51, 52, 142, 0.03)",
              transition: "background-color 0.5s",
            }}
            onClick={addCustomQuestion}
          >
            <span
              style={{
                color: "#252E4B",
                transition: "color 0.5s",
              }}
            >
              Add custom question
            </span>
          </button>
        </div>

        {/* Expand and collapse all together */}

        <button
          onClick={() => {
            if (!isEnabled) {
              // expand all
              setExpandedItems(new Set(quArray.map((q) => q.id)));
            } else {
              // collapse all
              setExpandedItems(new Set());
            }

            setIsEnabled(!isEnabled);
          }}
          type="button"
          className={`relative inline-flex mb-2 h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 ${
            isEnabled ? "bg-blue-600" : "bg-gray-200"
          }`}
        >
          <span
            className={`inline-block h-4 w-4 transform rounded-full bg-white transition-transform ${
              isEnabled ? "translate-x-6" : "translate-x-1"
            }`}
          />
        </button>

        {/* Draggable questions container */}
        <div className="rounded-lg">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="screening-questions">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="space-y-4"
                >
                  {selectedQuestions.map((qu, index) => (
                    <Draggable
                      key={qu.id.toString()}
                      draggableId={qu.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`bg-white border border-gray-500 rounded-lg px-4 py-2 shadow-md${
                            snapshot.isDragging ? "shadow-2xl" : ""
                          }`}
                        >
                          {/* Question header with drag handle */}
                          <div
                            className="flex justify-between items-center px-2 pb-1 border-b border-gray-400"
                            {...provided.dragHandleProps}
                          >
                            <div className="flex -ml-2">
                              <RxDragHandleDots2 className="text-gray-600 text-3xl -ml-1.5 " />
                              <span className="font-medium text-gray-700 ml-2 flex items-center">
                                Question {index + 1}
                              </span>
                            </div>
                            <div
                              className="pb-1 hover:cursor-pointer"
                              onClick={() => toggleExpand(qu.id)}
                            >
                              <IoIosArrowDropdown
                                className={`text-gray-700 text-2xl transition-transform duration-300 ${
                                  expandedItems.has(qu.id) ? "rotate-180" : ""
                                }`}
                              />
                            </div>
                          </div>

                          {/* Question content */}
                          <Question
                            data={qu}
                            index={qu?.id}
                            isExpanded={expandedItems.has(qu.id)}
                            toggleExpand={toggleExpand}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </div>
    </ScreeningQuestionContext.Provider>
  );
}

export default ScreeningQuestion;
