import PercentDonut from "../../../analytics/PercentDonut";
import InitialsLogo from "../../../all/InitialsLogo";
import QuestionsModal from "./QuestionsModal";
import { useContext, useEffect, useState, useRef } from "react";
import ScheduleInterview from "../../applicant/ScheduleInterview";
import { useSelector, useDispatch } from "react-redux";
import { fetchQuestionsFroms3 } from "../../../../redux/services";
import { ScaleLoader } from "react-spinners";
import { ClipLoader } from "react-spinners";
import { JobPageContext } from "../../../../lib/context";
import ApplicantAccordionV2 from "./analytics/ApplicantAccordionV2";
import { ImNewTab } from "react-icons/im";
import { HiAcademicCap } from "react-icons/hi";
import { BsEyeFill } from "react-icons/bs";

import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import SelectMatchScore from "../../../candidate/sourceCandidate/SelectMatchScore";
import { toast } from "react-toastify";
import { Dropdown, Tooltip } from "antd";
import { LuThumbsDown, LuThumbsUp } from "react-icons/lu";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import ResumeModal from "./ResumeModal";
// import ApplyForJobModal from "../../../../screens/recruiter/candidate/ApplyForJobModal";
import ProtectedComponent from "../../../../layouts/ProtectedComponent";
import { BiCheck, BiCheckCircle, BiCopy, BiQuestionMark } from "react-icons/bi";

const core = require("../../../../lib/core");

function Applicantlist({
  educationMatchWeight,
  matchScoreThreshold,
  source,
  atsloading,
  Push_candidate_greenhouse,
  convertProspectToCandidate,
  iter,
  idx,
  setQuestions,
  setEmail,
  checkedItems,
  setCheckedItems,
  pipeline,
  updateStatus,
  company,
  setHidechatbar,
  setHideCallBar,
  setChatDetail,
  removeCandidate,
  setShowModal,
  setCanId,
  handPicked,
  setCandidates,
  resources,
  isSourceCandidate,
  setSelectManualMatch,
  setUrl,
  setCurrentIndex,
  checkResource = false,
  setShowWhatsAppModal,
  setBulkActionFlag,
}) {
  const dispatch = useDispatch();
  // const { setShowCandidate } = useContext(JobPageContext);

  const [dataIdx, setDataIdx] = useState(-1);
  const [analyticsData, setAnalyticsData] = useState({});
  const [loading, setLoading] = useState(false);
  const [contactLoading, setContactLoading] = useState("");
  const [WhatsappLoading, setWhatsappLoading] = useState(false);
  const [pastApplied, setPastApplied] = useState(false);
  const [pastAppliedData, setPastAppliedData] = useState([]);
  const divRef = useRef(null);
  const jobId = useSelector((state) => state?.JobExpandSlice?.profile?.id);
  // const [ShowWhatsAppModal, setShowWhatsAppModal] = useState(false)
  // const [applyDropDown, setApplyDropDown] = useState(-1);
  // const [candiadtejobmodal, setCandidatejobmdal] = useState(null);

  const colors = (score) => {
    let colorArr = [
      "#b80000",
      "#b80000",
      "#cc540a",
      "#de862b",
      "#efb353",
      "#ffdf82",
      "#d2cb5c",
      "#a0b83a",
      "#a0b83a",
      "#a0b83a",
      "#a0b83a",
    ];
    if (score >= 90) {
      return "#009107";
    }
    if (score >= matchScoreThreshold) {
      return "#67a51d";
    } else return colorArr[Math.floor(score / 10)];
  };
  async function getSkills() {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_APPLICANT_ANALYTICS + iter?._id + "/" + jobId,
        1,
      );
      const data = json?.data;
      setAnalyticsData({ ...analyticsData, [iter?._id]: data });
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
      // Handle errors and return the error message
    }
  }

  const [assessLoading, setAssessLoading] = useState(false);
  const [assesScores, setAssessScores] = useState([]);
  async function getAssessmentScores() {
    try {
      setAssessLoading(true);
      const scores = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_ASSESSMENT_SCORES + iter?._id,
        1,
      );
      setAssessScores(scores);
      setAssessLoading(false);
    } catch (error) {
      setAssessLoading(false);
      // Handle errors here
    }
  }
  const handleMouseEnter = () => {
    setShowAssessments(true);
    getAssessmentScores();
  };

  const getS3Questions = (iter) => {
    try {
      if (jobId && iter?._id) {
        const payload = `?jobId=${jobId}&candidateId=${iter?._id}`;
        dispatch(fetchQuestionsFroms3(payload));
      }
    } catch (error) {}
  };

  const [showTooltip, setShowTooltip] = useState(false);
  const [showMailTooltip, setShowMailTooltip] = useState(false);
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
  const [text, setText] = useState();

  const [id, setId] = useState("");
  const [menuShow, setMenuShow] = useState(false);

  const [reload, setReload] = useState(0);

  const openResumeLink = (iter) => {
    window.open(iter?.resume_link, "_blank");
  };
  const dropdownRef = useRef(null);
  useEffect(() => {
    // Add a click event listener to the document
    const handleDocumentClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        // Clicked outside of the menu, close the menu
        setMenuShow(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleDocumentClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const [matchId, setMatchId] = useState(null);

  const handleHover = (id) => {
    setMatchId(id);
    // Add your logic for handling hover here
  };

  const [matchIdForPastApplied, setMatchIdForPastApplied] = useState(null);
  const [hashmap, setHashmap] = useState({});

  const callPastAppliedAPI = async () => {
    try {
      if (matchId) {
        return;
      }

      if (iter?._id) {
        if (hashmap[iter?._id]) {
          setPastAppliedData(hashmap[iter?._id]);
          setPastApplied(true);
          return;
        }

        setPastApplied(true);
        setMatchIdForPastApplied(iter?._id);
        const json = await core.API(
          core.API_METHODS.GET,
          core.JOB_API.GET_PAST_APPLIED + iter?._id + "/" + jobId,
          1,
        );
        if (json?.data && json?.data?.length > 0) {
          setHashmap((prev) => {
            return {
              ...prev,
              [iter?._id]: json?.data,
            };
          });

          setPastAppliedData(json?.data);
        } else {
          setPastApplied(false);
        }
      }
    } catch (error) {
      setPastApplied(false);
      console.log(error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (divRef.current && !divRef.current.contains(event.target)) {
        setMatchIdForPastApplied(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setMatchIdForPastApplied]);

  function formatLink(link) {
    // Check if the link starts with "https://" or "http://"
    if (!link.startsWith("https://") && !link.startsWith("http://")) {
      // If it doesn't, add "https://"
      link = "https://" + link;
    }

    return link;
  }

  const [likeIcon, setLikeIcon] = useState(-1);

  const [isHovered, setIsHovered] = useState(false);

  const handleMouseOver = (iter, id) => {
    setMatchId(id);
    setIsHovered(true);
  };
  const textStyles = {
    position: "absolute",
    top: 0,
    left: "30px", // Adjust the left value as needed
    display: isHovered ? "block" : "none",
    color: "white",
  };
  const handleMouseOut = () => {
    setIsHovered(false);
  };

  async function getGreenhouseCandidateUrl(can_id) {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_GREENHOUSE_CANDIDATE_LINK + can_id,
        1,
      );

      if (json?.data) {
        window.open(json?.data, "_blank");
      }
    } catch (error) {}
  }

  async function saveForLater(sts, id) {
    await core
      .API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_CANDIDATE_FEVORITE_STATUS,
        1,
        {
          matchProfileIds: [id],
          isFavorite: sts,
        },
      )
      .then((json) => {
        if (json.data) {
          setCandidates((prevData) =>
            prevData.map((item) => {
              if (item._id === id) {
                // Update the status property using square brackets notation
                return { ...item, favourite: sts };
              }
              return item;
            }),
          );
        } else {
        }
      });
  }

  // Get Email if Not exists
  const handleGetContact = async (type) => {
    try {
      setContactLoading(type);
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.GET_CANDIDATE_CONTACT,
        1,
        {
          matchProfileIds: [iter?._id],
          type,
        },
      );
      if (!json?.data?.length) {
        setContactLoading("");
        return toast.error(json?.message);
      }
      if (type === "email") {
        if (json && json?.data?.length > 0) {
          const newData = {
            ...iter?.applicant, // Copy existing properties from iter?.applicant
            summary: iter?.matchSummary, // Add or update the 'summary' property
            email: json?.data,
          };

          // Assuming 'setChatDetail' and other functions are defined and work correctly
          setChatDetail(newData);
          setId(iter?.applicant?._id);
          setShowModal("send-email-modal");
        }
      }
      if (type === "phone") {
        if (json && json?.data?.length > 0) {
          setHideCallBar(true);
          setChatDetail({ ...iter?.applicant, phone: json?.data });
          // setId(iter?.id);
          // handleCopyText(iter?.phone[0]);
          setId(iter?.applicant?._id);
        }
      }
    } catch (error) {
      toast.error(error?.message || error?.data?.message);
      console.log(error);
    }
    setContactLoading("");
  };

  const [iconId, setIconId] = useState(null);

  const getbgColor = (status) => {
    if (status === "Not started" || status === "Not sent") {
      return "text-red-600";
    }
    if (status === "Completed") {
      return "text-green-600";
    } else {
      return "text-orange-600";
    }
  };

  const assessmentDone = iter?.totalPercentage || -1;
  const [showAssessments, setShowAssessments] = useState(false);

  return (
    <>
      <tr
        className={`candidate-detail-row-1 py-3   relative hover:bg-[#f4f8ff] ${checkedItems?.includes(iter._id) && "bg-[#f4f8ff]"} `}
        onMouseEnter={() => {
          setLikeIcon(idx);
        }}
        onMouseLeave={() => setLikeIcon(-1)}
      >
        <ReactTooltip
          id="my-tooltip-4"
          noArrow={true}
          style={{
            zIndex: 9999,
            background: "#5F6989",
            fontSize: "10px",
            padding: "4px 8px",
            fontWeight: "400",
          }}
          place="top"
        />

        {likeIcon === idx ? (
          <>
            <div className="absolute  bg-white py-1 px-3 z-[9] shadow-jobPostButtonShadow flex items-center right-[20px] top-[-20px]  rounded-[16px]">
              <span
                className="mr-2 cursor-pointer w-[30px] h-[30px]  flex items-center justify-center rounded-[50%] hover:bg-[#F2F4F8] "
                onClick={() => {
                  removeCandidate({
                    name: iter?.notes,
                    id: iter?._id,
                    vote: true,
                  });
                  setShowModal("add-notes-modal");
                }}
              >
                {iter?.thumbs_up === 1 ? (
                  <IoMdThumbsUp
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"like"}
                    className="candidate-like-icon w-6 h-6"
                  />
                ) : (
                  <LuThumbsUp
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"like"}
                    className="candidate-like-icon w-5 h-5"
                  />
                )}
              </span>
              <span
                className="w-[30px] cursor-pointer h-[30px] flex items-center justify-center rounded-[50%] hover:bg-[#F2F4F8] "
                onClick={() => {
                  removeCandidate({
                    name: iter?.notes,
                    id: iter?._id,
                    vote: false,
                  });
                  setShowModal("add-notes-modal");
                }}
              >
                {iter?.thumbs_up === 2 ? (
                  <IoMdThumbsDown
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"Dislike"}
                    className="candidate-unlinke-icon w-6 h-6"
                  />
                ) : (
                  <LuThumbsDown
                    color="#5F6989"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"Dislike"}
                    className="candidate-unlinke-icon w-5 h-5"
                  />
                )}
              </span>
            </div>
          </>
        ) : (
          <>
            {(iter?.thumbs_up === 1 || iter?.thumbs_up === 2) && (
              <div className="absolute bg-white py-1 px-3 z-[9] shadow-jobPostButtonShadow flex items-center right-[20px] top-[-20px]  rounded-[16px]">
                <span className=" cursor-pointer w-[30px] h-[30px]  flex items-center justify-center rounded-[50%] hover:bg-[#F2F4F8] ">
                  {iter?.thumbs_up === 1 ? (
                    <IoMdThumbsUp
                      color="#5F6989"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"like"}
                      className="candidate-like-icon w-6 h-6"
                    />
                  ) : (
                    <IoMdThumbsDown
                      color="#5F6989"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Dislike"}
                      className="candidate-unlinke-icon w-6 h-6"
                    />
                  )}
                </span>
              </div>
            )}
          </>
        )}

        <td className=" px-4 pt-6 pb-12  flex overflow-x-hidden">
          {/* <div className="mr-[14px]">
            <input
              type="checkbox"
              name="candidate-select-checkbox"
              className="w-5 h-5 border border-gray-500 rounded-md bg-white "
              checked={checkedItems?.includes(iter?._id)}
              onChange={(e) => {
                if (checkedItems?.includes(iter?._id)) {
                  setCheckedItems(
                    checkedItems?.filter((item) => item != iter?._id),
                  );
                } else {
                  setCheckedItems([...checkedItems, iter?._id]);
                }
              }}
            />
          </div> */}
          <div
            className="w-[40px] h-[40px] flex justify-center items-center rounded-[6px] hover:bg-[#FFFFFF]"
            onMouseEnter={() => setIconId(idx)}
            onMouseLeave={() => setIconId(null)}
          >
            {iconId === idx || checkedItems?.length > 0 ? (
              <input
                type="checkbox"
                name="candidate-select-checkbox"
                className="w-5 h-5  rounded-md hover:border-[2px]"
                checked={checkedItems?.includes(iter?._id)}
                onChange={(e) => {
                  if (checkedItems?.includes(iter?._id)) {
                    setCheckedItems(
                      checkedItems?.filter((item) => item != iter?._id),
                    );
                  } else {
                    setCheckedItems([...checkedItems, iter?._id]);
                  }
                }}
              />
            ) : (
              <span className="">
                <InitialsLogo
                  str={iter?.applicant?.first_name}
                  bgRounded={"50%"}
                />
              </span>
            )}
          </div>

          <div className="col-span-8 ml-[14px] relative">
            <div className="flex items-center flex-wrap">
              <ProtectedComponent
                showModal={true}
                code={checkResource ? "Candidate_read" : "no_code"}
              >
                <h4
                  onClick={() => {
                    setShowModal("can-details");
                    setCanId(iter?._id);
                    setCurrentIndex(idx);
                  }}
                  className=" text-base not-italic cursor-pointer font-medium text-gray-800 "
                >
                  {iter?.applicant?.first_name}{" "}
                  {iter?.applicant?.last_name?.length > 0
                    ? iter?.applicant?.last_name
                    : ""}
                </h4>
              </ProtectedComponent>

              {iter?.current_source && (
                <span
                  className="cursor-pointer ml-[8px] py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[10px] not-italic font-normal text-blue-700"
                  onClick={() => {
                    if (iter?.current_source === "Greenhouse") {
                      getGreenhouseCandidateUrl(iter?._id);
                    } else if (iter?.current_source === "Lever") {
                      window.open(iter?.atsUrl, "_blank");
                    }
                  }}
                >
                  {iter?.current_source === "Linkedin"
                    ? "Sprouts"
                    : iter?.current_source}
                </span>
              )}
              {iter?.remote_source && (
                <span className="ml-[8px] py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[10px] not-italic font-normal text-blue-700">
                  {iter?.remote_source ?? ""}
                </span>
              )}
            </div>

            <span className="text-sm not-italic font-normal text-gray-700">
              {iter?.applicant?.title}
              <span className="italic">
                {" "}
                {iter?.applicant?.experience?.length > 0 &&
                iter?.applicant?.experience[
                  iter?.applicant?.experience.length - 1
                ]?.company
                  ? ` @ ${iter?.applicant?.experience[iter?.applicant?.experience.length - 1]?.company}`
                  : ""}
              </span>
            </span>

            <div className="flex items-center space-x-2">
              {iter?.applicant?.education?.length > 0 &&
                iter?.applicant?.education[0]?.degree &&
                iter?.applicant?.education[0]?.school && (
                  <div
                    className="text-sm text-gray-700"
                    // className="flex items-center space-x-1 bg-gray-100 rounded-full px-2 py-1 text-sm text-gray-700"
                  >
                    <span>{iter?.applicant?.education[0]?.school}</span>
                  </div>
                )}
            </div>

            <div className=" flex mt-[10px]">
              {/* {iter?.applicant?.resume_link?.length > 0 && (
                <>
                  {(iter?.applicant?.resume_link?.toLowerCase() !=
                    "not specified" ||
                    formatLink(iter?.applicant?.resume_link) !=
                      "https://sproutsai-staging.s3.amazonaws.com/undefined") && (
                    <a
                      className="candidate-resume-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                      href={formatLink(iter?.applicant?.resume_link)}
                      target="_blank"
                      rel="noreferrer"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Resume"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.9987 6.99935C8.60953 6.99935 9.91537 5.69351 9.91537 4.08268C9.91537 2.47185 8.60953 1.16602 6.9987 1.16602C5.38787 1.16602 4.08203 2.47185 4.08203 4.08268C4.08203 5.69351 5.38787 6.99935 6.9987 6.99935Z"
                          fill="#5F6989"
                        />
                        <path
                          d="M7.00172 8.45898C4.07922 8.45898 1.69922 10.419 1.69922 12.834C1.69922 12.9973 1.82755 13.1257 1.99089 13.1257H12.0126C12.1759 13.1257 12.3042 12.9973 12.3042 12.834C12.3042 10.419 9.92422 8.45898 7.00172 8.45898Z"
                          fill="#5F6989"
                        />
                      </svg>
                    </a>
                  )}
                </>
              )} */}

              {iter?.applicant?.resume_link?.length > 0 && (
                <>
                  {(iter?.applicant?.resume_link?.toLowerCase() !=
                    "not specified" ||
                    formatLink(iter?.applicant?.resume_link) !=
                      "https://sproutsai-staging.s3.amazonaws.com/undefined") && (
                    <button
                      onClick={() => {
                        setUrl(iter?.applicant?.resume_link);
                        setShowModal("resume-modal");
                      }}
                      className="candidate-email-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7 px-2 py-1 mr-2 relative"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M6.9987 6.99935C8.60953 6.99935 9.91537 5.69351 9.91537 4.08268C9.91537 2.47185 8.60953 1.16602 6.9987 1.16602C5.38787 1.16602 4.08203 2.47185 4.08203 4.08268C4.08203 5.69351 5.38787 6.99935 6.9987 6.99935Z"
                          fill="#5F6989"
                        />
                        <path
                          d="M7.00172 8.45898C4.07922 8.45898 1.69922 10.419 1.69922 12.834C1.69922 12.9973 1.82755 13.1257 1.99089 13.1257H12.0126C12.1759 13.1257 12.3042 12.9973 12.3042 12.834C12.3042 10.419 9.92422 8.45898 7.00172 8.45898Z"
                          fill="#5F6989"
                        />
                      </svg>
                    </button>
                  )}
                </>
              )}

              {iter?.applicant?.linkedin?.length > 0 && (
                <>
                  {iter?.applicant?.linkedin?.toLowerCase() !=
                    "not specified" && (
                    <a
                      className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                      href={formatLink(iter?.applicant?.linkedin)}
                      target="_blank"
                      rel="noreferrer"
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Linkedin"}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <path
                          d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                          fill="#5F6989"
                        />
                      </svg>
                    </a>
                  )}
                </>
              )}
              <Tooltip
                color="white"
                overlayInnerStyle={{
                  width: "300px",
                  padding: "0px",
                }}
                open={showMailTooltip}
                onOpenChange={(open) => {
                  setShowMailTooltip(open);
                }}
                title={(open) => {
                  return (iter?.applicant?.email?.length > 0 &&
                    iter?.applicant?.email[0]) ||
                    (iter?.applicant?.work_email?.length &&
                      iter?.applicant?.work_email[0]) ? (
                    <div className="w-full text-black p-4">
                      {iter?.applicant?.email?.length > 0 &&
                      iter?.applicant?.email[0] ? (
                        <div className="flex flex-col gap-1">
                          {iter?.applicant?.email?.map((email, index) => (
                            <>
                              <div
                                key={index}
                                className="text-sm font-semibold flex items-center gap-1"
                              >
                                <span className="grow truncate">{email}</span>
                                <BiCopy
                                  onClick={() => {
                                    navigator.clipboard.writeText(email);
                                  }}
                                  className="text-sm cursor-pointer min-w-[15px]"
                                  size={15}
                                />
                              </div>
                              {index === iter?.applicant?.email?.length - 1 ? (
                                <div className="text-sm">Personal</div>
                              ) : null}
                            </>
                          ))}
                        </div>
                      ) : null}
                      {iter?.applicant?.work_email?.length > 0 &&
                      iter?.applicant?.work_email[0] ? (
                        <div className="flex flex-col gap-1 mt-4">
                          {iter?.applicant?.work_email?.map(
                            (work_email, index) => (
                              <>
                                <div
                                  key={index}
                                  className="text-sm font-semibold flex items-center gap-1"
                                >
                                  <span className="grow truncate">
                                    {work_email}
                                  </span>
                                  <BiCopy
                                    onClick={() => {
                                      navigator.clipboard.writeText(work_email);
                                    }}
                                    className="text-sm cursor-pointer min-w-[15px]"
                                    size={15}
                                  />
                                </div>
                                {index ===
                                iter?.applicant?.work_email?.length - 1 ? (
                                  <div className="text-sm">Business</div>
                                ) : null}
                              </>
                            ),
                          )}
                        </div>
                      ) : null}
                      <div className="flex items-center justify-between gap-2 mt-2 border-t border-t-[#EFF0F2] pt-2">
                        <button
                          className="border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                          onClick={() => {
                            if (iter?.applicant?.email?.length > 0) {
                              const newData = {
                                ...iter?.applicant, // Copy existing properties from iter?.applicant
                                summary: iter?.matchSummary, // Add or update the 'summary' property
                                matchId: iter?.id ?? iter?._id,
                              };

                              // Assuming 'setChatDetail' and other functions are defined and work correctly
                              setChatDetail(newData);
                              setId(iter?.applicant?._id);
                              setShowModal("send-email-modal");
                              setShowMailTooltip(false);
                            }
                          }}
                        >
                          Send email
                        </button>
                        <button
                          onClick={() => {
                            setCheckedItems([iter?._id]);
                            setTimeout(() => {
                              setShowModal("bulk-action-modal");
                              setShowMailTooltip(false);
                            }, 300);
                          }}
                          className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9] hover:border-none  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
                        >
                          Add Sequence
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="p-4 text-black">
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <span className="text-center font-semibold">
                          No Email Found. <br />
                          Edit Candidate Details to add email
                        </span>
                        <button
                          onClick={() => {
                            setShowModal("update_can_data");
                            removeCandidate({
                              index: idx,
                              name: iter?.applicant?.first_name,
                              last_name: iter?.applicant?.last_name,
                              id: iter?.applicant?._id,
                              title: iter?.applicant.title,
                              email: iter?.applicant.email,
                              phone: iter?.applicant.phone,
                              work_phone: iter?.applicant.work_phone,
                              linkedln: iter?.applicant?.linkedin,
                              source: iter?.current_source,
                              remote_source: iter?.remote_source,
                              matchProfileId: iter?._id,
                            });
                            setShowMailTooltip(false);
                          }}
                          className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9] hover:border-none  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  );
                }}
              >
                <button className="candidate-email-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7 px-2 py-1 mr-2 relative">
                  {(iter?.applicant?.email?.length > 0 &&
                    iter?.applicant?.email[0]) ||
                  (iter?.applicant?.work_email?.length &&
                    iter?.applicant?.work_email[0]) ? (
                    <div className="rounded-full h-3 w-3 flex items-center justify-center bg-green-900 | absolute -top-1 -right-1">
                      <BiCheck size={9} className="text-white" />
                    </div>
                  ) : (
                    <div className="rounded-full h-3 w-3 flex items-center justify-center bg-gray-600 | absolute -top-1 -right-1">
                      <BiQuestionMark size={9} className="text-white" />
                    </div>
                  )}
                  {contactLoading === "email" ? (
                    <ClipLoader color="blue" loading={true} size={15} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                    >
                      <g clipPath="url(#clip0_2992_136007)">
                        <rect
                          x="1.5"
                          y="1.15332"
                          width="11"
                          height="9.9"
                          rx="2"
                          fill="#5F6989"
                          stroke="white"
                        />
                        <path
                          d="M1.5 3.35352L4.50122 5.75449C5.96209 6.92319 8.03791 6.92319 9.49878 5.75449L12.5 3.35352"
                          stroke="white"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2992_136007">
                          <rect
                            width="12.1"
                            height="11"
                            fill="white"
                            transform="translate(0.949219 0.5)"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </button>
              </Tooltip>

              {iter?.id === id && (
                <div
                  className="tooltip-message rounded-md px-3 flex justify-center w-[200px] absolute z-20 top-[100px] text-white bg-black"
                  style={{
                    visibility: showTooltip ? "visible" : "hidden",
                  }}
                >
                  Copied to clipboard!
                </div>
              )}
              <Tooltip
                color="white"
                overlayInnerStyle={{
                  width: "300px",
                  padding: "0px",
                }}
                open={showPhoneTooltip}
                onOpenChange={(open) => {
                  setShowPhoneTooltip(open);
                }}
                title={(open) => {
                  return (iter?.applicant?.phone?.length > 0 &&
                    iter?.applicant?.phone[0]) ||
                    (iter?.applicant?.work_phone?.length &&
                      iter?.applicant?.work_phone[0]) ? (
                    <div className="w-full text-black p-4">
                      {iter?.applicant?.phone?.length > 0 &&
                      iter?.applicant?.phone[0] ? (
                        <div className="flex flex-col gap-1">
                          {iter?.applicant?.phone?.map((phone, index) => (
                            <>
                              <div
                                key={index}
                                className="text-sm font-semibold flex items-center gap-1"
                              >
                                <span className="grow truncate">{phone}</span>
                                <BiCopy
                                  onClick={() => {
                                    navigator.clipboard.writeText(phone);
                                  }}
                                  className="text-sm cursor-pointer min-w-[15px]"
                                  size={15}
                                />
                              </div>
                              {index === iter?.applicant?.phone?.length - 1 ? (
                                <div className="text-sm">Personal</div>
                              ) : null}
                            </>
                          ))}
                        </div>
                      ) : null}
                      {iter?.applicant?.work_phone?.length > 0 &&
                      iter?.applicant?.work_phone[0] ? (
                        <div className="flex flex-col gap-1 mt-4">
                          {iter?.applicant?.work_phone?.map(
                            (work_phone, index) => (
                              <>
                                <div
                                  key={index}
                                  className="text-sm font-semibold flex items-center gap-1"
                                >
                                  <span className="grow truncate">
                                    {work_phone}
                                  </span>
                                  <BiCopy
                                    onClick={() => {
                                      navigator.clipboard.writeText(work_phone);
                                    }}
                                    className="text-sm cursor-pointer min-w-[15px]"
                                    size={15}
                                  />
                                </div>
                                {index ===
                                iter?.applicant?.work_phone?.length - 1 ? (
                                  <div className="text-sm">Business</div>
                                ) : null}
                              </>
                            ),
                          )}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <div className="p-4 text-black">
                      <div className="flex flex-col gap-2 justify-center items-center">
                        <span className="text-center font-semibold">
                          No Phone Found. <br />
                          Edit Candidate Details to add phone
                        </span>
                        <button
                          onClick={() => {
                            setShowModal("update_can_data");
                            removeCandidate({
                              index: idx,
                              name: iter?.applicant?.first_name,
                              last_name: iter?.applicant?.last_name,
                              id: iter?.applicant?._id,
                              title: iter?.applicant.title,
                              email: iter?.applicant.email,
                              phone: iter?.applicant.phone,
                              work_phone: iter?.applicant.work_phone,
                              linkedln: iter?.applicant?.linkedin,
                              source: iter?.current_source,
                              remote_source: iter?.remote_source,
                              matchProfileId: iter?._id,
                            });
                            setShowMailTooltip(false);
                          }}
                          className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9] hover:border-none  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
                        >
                          Edit
                        </button>
                      </div>
                    </div>
                  );
                }}
              >
                <button className="candidate-phone-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7  px-2 py-1 mr-2 relative">
                  {iter?.applicant?.phone?.length > 0 ||
                  iter?.applicant?.work_phone?.length > 0 ? (
                    <div className="rounded-full h-3 w-3 flex items-center justify-center bg-green-900 | absolute -top-1 -right-1">
                      <BiCheck size={9} className="text-white" />
                    </div>
                  ) : (
                    <div className="rounded-full h-3 w-3 flex items-center justify-center bg-gray-600 | absolute -top-1 -right-1">
                      <BiQuestionMark size={9} className="text-white" />
                    </div>
                  )}
                  {contactLoading === "phone" ? (
                    <ClipLoader color="blue" loading={true} size={15} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M5.73926 2.48338C5.58613 2.04044 5.21536 1.75 4.80305 1.75H3.4162C2.88862 1.75 2.46094 2.24472 2.46094 2.85514C2.46094 8.04371 6.09637 12.25 10.5808 12.25C11.1084 12.25 11.5359 11.7551 11.5359 11.1447L11.5362 9.53981C11.5362 9.06275 11.2852 8.63383 10.9024 8.45666L9.57318 7.84171C9.2293 7.68257 8.83778 7.75419 8.55326 8.02852L8.21021 8.35956C7.80957 8.74585 7.22008 8.71513 6.85132 8.28846L5.88535 7.16978C5.51658 6.74311 5.48933 6.06161 5.8232 5.59807L6.10925 5.20117C6.34635 4.87197 6.40881 4.41884 6.27126 4.02097L5.73926 2.48338Z"
                        fill="#5F6989"
                      />
                    </svg>
                  )}
                </button>
              </Tooltip>
              {iter?.applicant?.phone?.length > 0 && (
                <button
                  className="text-gray-700 border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7  px-2 py-1 mr-2 relative"
                  onClick={() => {
                    setShowWhatsAppModal(true);
                    setBulkActionFlag(false);
                    setChatDetail([
                      {
                        applicant: iter?.applicant,
                        matchId: iter?._id,
                      },
                    ]);
                  }}
                  data-tooltip-id="my-tooltip-4"
                  data-tooltip-content={"Whatsapp"}
                >
                  {WhatsappLoading ? (
                    <ClipLoader color="blue" loading={true} size={15} />
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      className="text-gray-700"
                      viewBox="0 0 24 24"
                    >
                      <g fill="currentColor">
                        <path
                          fillRule="evenodd"
                          d="M12 4a8 8 0 0 0-6.895 12.06l.569.718l-.697 2.359l2.32-.648l.379.243A8 8 0 1 0 12 4M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382l1.426-4.829l-.006-.007l-.033-.055A9.96 9.96 0 0 1 2 12"
                          clipRule="evenodd"
                        />
                        <path d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1 1 0 0 0-.34-.075c-.196 0-.362.098-.49.291c-.146.217-.587.732-.723.886c-.018.02-.042.045-.057.045c-.013 0-.239-.093-.307-.123c-1.564-.68-2.751-2.313-2.914-2.589c-.023-.04-.024-.057-.024-.057c.005-.021.058-.074.085-.101c.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711c-.158-.377-.366-.552-.655-.552c-.027 0 0 0-.112.005c-.137.005-.883.104-1.213.311c-.35.22-.94.924-.94 2.16c0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537c1.412.564 2.081.63 2.461.63c.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276c.192-.534.243-1.117.115-1.329c-.088-.144-.239-.216-.43-.308" />
                      </g>
                    </svg>
                  )}
                </button>
              )}

              {/* {iter?.phone?.length > 0 && (
                <button
                  className="border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                  onClick={(e) => {
                    setHidechatbar(true);
                    setChatDetail(iter);
                    setId(iter?.id);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M3.73307 13.2776L4.74905 12.4648L4.75652 12.4591C4.96833 12.2897 5.07521 12.2042 5.19445 12.1432C5.30144 12.0886 5.41552 12.0488 5.5332 12.0246C5.66585 11.9974 5.80402 11.9974 6.08138 11.9974H11.8687C12.614 11.9974 12.9871 11.9974 13.272 11.8522C13.5229 11.7244 13.727 11.5202 13.8548 11.2693C14 10.9844 14 10.6117 14 9.86645V4.79534C14 4.05006 14 3.67686 13.8548 3.39193C13.727 3.14104 13.5225 2.93722 13.2716 2.80939C12.9864 2.66406 12.6135 2.66406 11.8668 2.66406H4.13346C3.38673 2.66406 3.01308 2.66406 2.72786 2.80939C2.47698 2.93722 2.27316 3.14104 2.14532 3.39193C2 3.67714 2 4.05079 2 4.79753V12.4449C2 13.1553 2 13.5105 2.14564 13.6929C2.2723 13.8516 2.46429 13.9439 2.66732 13.9437C2.90077 13.9435 3.17829 13.7214 3.73307 13.2776Z"
                      fill="#5F6989"
                      stroke="white"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
              )} */}
            </div>
          </div>
        </td>

        <td className="px-4 pt-6 pb-12">
          <div className="flex flex-col gap-1">
            <div className="flex gap-4">
              {isSourceCandidate ? (
                <ProtectedComponent
                  showModal={true}
                  code={checkResource ? "Candidate_update" : "no_code"}
                >
                  <SelectMatchScore
                    matchScore={
                      iter.humanMatch
                        ? iter.humanMatch
                        : iter?.overallMatch * 100 >= 80
                          ? "Strong Match"
                          : iter?.overallMatch * 100 >= matchScoreThreshold
                            ? "Good Match"
                            : iter?.overallMatch * 100 < matchScoreThreshold &&
                                iter?.overallMatch * 100 > 0
                              ? "Weak Match"
                              : ""
                    }
                    onClick={(vote, match) => {
                      removeCandidate({
                        name: iter?.notes,
                        id: iter?._id,
                        vote: vote,
                        humanMatchScore: match,
                      });
                      setShowModal("add-notes-modal");
                    }}
                  />
                </ProtectedComponent>
              ) : (
                <div className="flex items-center gap-6">
                  {iter?.statusOfCompletion === "Failed" &&
                    !iter?.matchPercent && (
                      <div className="flex justify-center align-middle py-1 px-4 rounded bg-rose-50 ml-8">
                        <span
                          id="badge-dismiss-red"
                          className=" text-rose-600 "
                        >
                          Failed
                        </span>
                      </div>
                    )}

                  <>
                    {iter?.overallMatch >= 0 &&
                      (iter?.current_stage == "Application Review" ||
                        iter?.current_stage == "New applicant") && (
                        <>
                          {iter.humanMatch || iter?.overallMatch ? (
                            <span
                              className="mr-2 border-gray-300 border px-2 py-1 rounded-md text-[14px] font-normal not-italic relative"
                              style={{
                                color: colors(
                                  Math.round(iter?.overallMatch * 100),
                                ),
                              }}
                              onMouseEnter={(e) => handleHover(iter.id)}
                              onMouseLeave={() => setMatchId(null)}
                            >
                              {iter.humanMatch
                                ? iter.humanMatch
                                : iter?.overallMatch * 100 >= 80
                                  ? "Strong Match"
                                  : iter?.overallMatch * 100 >=
                                      matchScoreThreshold
                                    ? "Good Match"
                                    : iter?.overallMatch * 100 <
                                          matchScoreThreshold &&
                                        iter?.overallMatch * 100 > 0
                                      ? "Weak Match"
                                      : ""}

                              {iter.id === matchId && (
                                <div className="flex flex-col absolute bg-gray-800 p-3 z-50 rounded-lg w-[190px] left-[90px] top-[-20px]">
                                  <span className="flex items-center ">
                                    <PercentDonut
                                      id={"match_4" + iter?.applicant?._id}
                                      chartSize={20}
                                      radius={7}
                                      blankColor={"rgba(255, 255, 255, 0.15)"}
                                      width={3}
                                      cornerRadius={1}
                                      hidePercent={true}
                                      percentData={Math.round(
                                        iter?.skillsMatch * 100,
                                      )}
                                      fontSize={8}
                                    />

                                    <span className="text-white text-[11px] font-normal not-italic ">
                                      {`${Math.round(iter?.skillsMatch * 100)}% Skills`}
                                    </span>
                                  </span>

                                  <span className="flex items-center ">
                                    <PercentDonut
                                      id={"match_3" + iter?.applicant?._id}
                                      chartSize={20}
                                      radius={7}
                                      width={3}
                                      blankColor={"rgba(255, 255, 255, 0.15)"}
                                      cornerRadius={1}
                                      hidePercent={true}
                                      percentData={Math.round(
                                        iter?.experienceMatch * 100,
                                      )}
                                      fontSize={8}
                                    />
                                    <span className="text-white text-[11px] font-normal not-italic flex">
                                      {`  ${Math.round(
                                        iter.experienceMatch * 100,
                                      )}% Experience`}
                                    </span>
                                  </span>

                                  {educationMatchWeight > 0 && (
                                    <span className="flex items-center ">
                                      <PercentDonut
                                        id={"match_1" + iter?.applicant?._id}
                                        chartSize={20}
                                        radius={7}
                                        width={3}
                                        cornerRadius={1}
                                        blankColor={"rgba(255, 255, 255, 0.15)"}
                                        hidePercent={true}
                                        percentData={Math.round(
                                          iter?.educationMatch * 100,
                                        )}
                                        fontSize={8}
                                      />
                                      <span className="text-white text-[11px] font-normal not-italic ">
                                        {`${Math.round(iter?.educationMatch * 100)}% Education`}
                                      </span>
                                    </span>
                                  )}

                                  <span className="flex items-center ">
                                    <PercentDonut
                                      id={"match_2" + iter?.applicant?._id}
                                      chartSize={20}
                                      radius={7}
                                      width={3}
                                      blankColor={"rgba(255, 255, 255, 0.15)"}
                                      cornerRadius={1}
                                      hidePercent={true}
                                      percentData={Math.round(
                                        iter?.jobPositionMatch * 100,
                                      )}
                                      fontSize={8}
                                    />
                                    <span className="text-white text-[11px] font-normal not-italic ">
                                      {`${Math.round(
                                        iter?.jobPositionMatch * 100,
                                      )}% Industry`}
                                    </span>
                                  </span>
                                </div>
                              )}
                            </span>
                          ) : null}
                        </>
                      )}
                    <div className="relative">
                      {iter?.pastApplied && (
                        <div
                          className="border-gray-300 border px-2 py-1 rounded-md text-[14px] font-normal not-italic relative"
                          style={{
                            color: colors(Math.round(0 * 100)),
                          }}
                          onMouseEnter={async (e) =>
                            await callPastAppliedAPI(iter._id)
                          }
                          onMouseLeave={() => setPastApplied(false)}
                        >
                          Past Applied
                          <div className="absolute">
                            {pastApplied &&
                            iter?._id === matchIdForPastApplied ? (
                              <div
                                ref={divRef}
                                className="flex flex-col absolute bg-gray-800 p-2 z-50 rounded-lg shadow-lg w-auto max-w-[500px] overflow-hidden whitespace-nowrap -top-[25px] left-[90px] px-4"
                              >
                                <div className="flex gap-2">
                                  {" "}
                                  <p className="text-white text-sm font-semibold mb-3">
                                    {pastAppliedData?.length == 0 ? (
                                      <span className="">Loading...</span>
                                    ) : (
                                      <span className="">Past Applied</span>
                                    )}
                                  </p>
                                  <p className="mt-1">
                                    {pastAppliedData?.length == 0 && (
                                      <span className="">
                                        <ClipLoader
                                          color="white"
                                          size={15}
                                          loading={true}
                                        />
                                      </span>
                                    )}
                                  </p>
                                </div>
                                {pastAppliedData?.map((item, index) => (
                                  <div
                                    key={index}
                                    className=" border-gray-600 py-1 last:border-b-0 mb-2"
                                  >
                                    <div className="flex items-center gap-2 mb-1">
                                      <span className=" text-white text-[11px] font-normal not-italic ">
                                        Job:
                                      </span>
                                      <span className="text-white text-[11px] font-normal not-italic capitalize">
                                        {item?.job_name?.trim()}
                                      </span>

                                      <ImNewTab
                                        className="text-white text-[11px] w-[10px] font-normal not-italic cursor-pointer"
                                        size={8}
                                        onClick={() =>
                                          window.open(
                                            `${window.location.origin}/job/${item?.job_status}/${item?.job_id}/description`,
                                            "_blank",
                                          )
                                        }
                                      />
                                    </div>
                                    <div className="flex items-center gap-2">
                                      <span className=" text-white text-[11px] font-normal not-italic ">
                                        Stage:
                                      </span>
                                      <span className="text-white text-[11px] font-normal not-italic capitalize">
                                        {item?.stage?.trim()}
                                      </span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                    {/* <button
                      className="w-27 border-button rounded-[8px] border h-5 border-[#fefefe] bg-[#FFF] py-[8px] px-[10px] flex items-center justify-center ml-38"
                      onClick={() => {
                        setApplyDropDown(idx);
                        setCandidatejobmdal(iter?.applicant?._id);
                      }}
                    >
                      Apply to Job
                    </button>
                    {applyDropDown > -1 && candiadtejobmodal && (
                      <ApplyForJobModal
                        jobId= {jobId}
                        id={applyDropDown}
                        candidate={candiadtejobmodal}
                        modalDismiss={handleModalDismiss}
                      />
                    )} */}

                    {/* {iter?.applicant?.education?.length > 0 &&
                    iter?.applicant?.education[0]?.degree &&
                    iter?.applicant?.education[0]?.school ? (
                      <div className="flex items-center gap-3">
                        <span
                          data-tooltip-id={`my-tooltip-4`}
                          className="text-[14px] truncate border-gray-300 border px-2 py-0.5 rounded-md flex"
                        >
                          {iter?.applicant?.education[0]?.school}
                        </span>
                      </div>
                    ) : null} */}
                  </>
                </div>
              )}
            </div>

            <div className="flex gap-2">
              {(iter?.applicant?.years_experience > 0 ||
                iter?.applicant?.months_experience > 0) && (
                <div className="flex items-center gap-3">
                  <span
                    data-tooltip-id={`my-tooltip-4`}
                    className="text-[14px] truncate border-gray-300 border px-2 py-0.5 rounded-md"
                    data-tooltip-content={
                      iter?.applicant?.years_experience
                        ? iter?.applicant?.years_experience +
                          " Years of Experience"
                        : iter?.applicant?.months_experience
                          ? iter?.applicant?.months_experience +
                            " Months of Experience"
                          : ""
                    }
                  >
                    {iter?.applicant?.years_experience
                      ? iter?.applicant?.years_experience +
                        " Years of Experience"
                      : iter?.applicant?.months_experience
                        ? iter?.applicant?.months_experience +
                          " Months of Experience"
                        : ""}
                  </span>
                </div>
              )}

              {iter?.applicant?.location?.[0]?.city ||
              iter?.applicant?.location?.[0]?.country ? (
                <div className="flex items-center gap-3">
                  <span
                    data-tooltip-id={`my-tooltip-4`}
                    className="text-[14px] truncate border-gray-300 border px-2 py-0.5 rounded-md flex"
                    data-tooltip-content={[
                      iter?.applicant?.location?.[0]?.city,
                      iter?.applicant?.location?.[0]?.country,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  >
                    {[
                      iter?.applicant?.location?.[0]?.city,
                      iter?.applicant?.location?.[0]?.country,
                    ]
                      .filter(Boolean)
                      .join(", ")}
                  </span>
                </div>
              ) : null}
            </div>

            {iter?.unprocessed ? (
              <div className="flex items-center gap-3">
                <span
                  data-tooltip-id={`my-tooltip-4`}
                  className="text-[12px] text-orange-900 truncate border-gray-300 border px-2 py-0.5 rounded-md flex"
                  data-tooltip-content={`${iter?.unprocessedReason}`}
                >
                  {iter?.unprocessedReason}
                </span>
              </div>
            ) : null}

            {iter?.profileHighlights?.length > 0 ? (
              <div className="flex items-center gap-3">
                {iter?.profileHighlights?.slice(0, 3)?.map((skill, index) => (
                  <span
                    className="border-gray-300 border px-2 py-0.5 rounded-md max-w-[150px] truncate text-[12px]"
                    data-tooltip-id={`my-tooltip-4`}
                    data-tooltip-content={skill}
                  >
                    {skill}
                  </span>
                ))}
                {iter?.profileHighlights?.length > 2 && (
                  <Tooltip
                    color="white"
                    overlayInnerStyle={{
                      width: "400px",
                      padding: "0px",
                    }}
                    title={
                      <div className="flex flex-col gap-2 max-h-[400px] overflow-y-auto p-3">
                        <div className="flex flex-wrap items-center gap-1">
                          {iter?.profileHighlights?.map((skill, index) => (
                            <span
                              key={index}
                              className="bg-gray-300 text-black px-2 py-1 rounded-md max-w-[150px] truncate text-[12px]"
                              data-tooltip-id={`my-tooltip-4`}
                              data-tooltip-content={skill}
                            >
                              {skill}
                            </span>
                          ))}
                        </div>
                      </div>
                    }
                  >
                    <span className="bg-gray-300 px-2 py-0.5 rounded-md w-fit text-[12px]">
                      +{iter?.profileHighlights?.length - 2}
                    </span>
                  </Tooltip>
                )}
              </div>
            ) : null}
          </div>
        </td>

        <td ref={dropdownRef} className=" px-4 pb-16 pt-8 relative">
          <div className=" flex items-center justify-end relative">
            {iter?.questions_generated && (
              <button
                className="candidate-status-manage border-button rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 ml-3 flex items-center justify-center"
                onClick={() => {
                  if (company === "ExtraHop") {
                    setQuestions(iter?.descriptiveQuestions);
                  } else {
                    getS3Questions(iter);
                  }
                  setShowModal("Question-modal");
                }}
                title="Questions"
              >
                <span className="text-base font-medium capitalize">Q/A</span>
              </button>
            )}
            {iter?.rejectedReason && (
              <span
                className="mt-1.5 text-xs"
                onMouseOver={() => handleMouseOver(iter)}
                onMouseOut={handleMouseOut}
                style={{ position: "relative" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M21.1303 9.8531C22.2899 11.0732 22.2899 12.9268 21.1303 14.1469C19.1745 16.2047 15.8155 19 12 19C8.18448 19 4.82549 16.2047 2.86971 14.1469C1.7101 12.9268 1.7101 11.0732 2.86971 9.8531C4.82549 7.79533 8.18448 5 12 5C15.8155 5 19.1745 7.79533 21.1303 9.8531Z"
                    stroke="#252E4B"
                  />
                  <path
                    d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
                    stroke="#252E4B"
                  />
                </svg>
                <span
                  className="rounded tracking-wide bg-gray-900 p-4 w-50 relative -mt-10"
                  style={textStyles}
                >
                  {isHovered ? iter?.rejectedReason : null}
                </span>
              </span>
            )}

            {/* <button
            className="border-button rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 ml-3 flex items-center justify-center"
            title="Schedule interview"
            onClick={() => {
              // setEmail(iter?.email ?iter?.email: "djavier@gmail.com")
              setEmail({
                email: iter?.email,
                id: iter?.id,
                name: iter?.name,
              });
              setReload(reload + 1);
              setShowModal("schedule-interview-modal");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M2 6.49967V12.4997C2 13.9724 3.19391 15.1663 4.66667 15.1663H11.3333C12.8061 15.1663 14 13.9724 14 12.4997V6.49967M2 6.49967V5.49967C2 4.02692 3.19391 2.83301 4.66667 2.83301H11.3333C12.8061 2.83301 14 4.02692 14 5.49967V6.49967M2 6.49967H14M10.6667 1.83301V3.83301M5.33333 1.83301V3.83301"
                stroke="#252E4B"
                strokeLinecap="round"
              />
            </svg>
          </button> */}

            {/* {likeIcon === idx &&
              resources?.filter((item) => item === "Candidate_create")?.length >
                0 && (
                <>
                  <button
                    className="border-button rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 ml-3 flex items-center justify-center"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"Edit candidate details"}
                    onClick={() => {
                      setShowModal("update_can_data");
                      removeCandidate({
                        index: idx,
                        name: iter?.applicant?.first_name,
                        last_name: iter?.applicant?.last_name,
                        id: iter?.applicant?._id,
                        title: iter?.applicant.title,
                        email: iter?.applicant.email,
                        phone: iter?.applicant.phone,
                        linkedln: iter?.applicant?.linkedin,
                        source: iter?.current_source,
                        remote_source: iter?.remote_source,
                        matchProfileId: iter?._id,
                      });
                    }}
                    style={{ color: "#FF475D" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z"
                        stroke="#252E4B"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                  <button
                    className="border-button rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 ml-3 mr-3 flex items-center justify-center"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={"Remove candidate "}
                    onClick={() => {
                      removeCandidate({
                        name: iter?.applicant?.first_name,
                        id: iter?._id,
                      });
                      setShowModal("delete-candidate-modal");
                    }}
                    style={{ color: "#FF475D" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        d="M3.33333 5.83398V12.5007C3.33333 13.9734 4.52724 15.1673 6 15.1673H10C11.4728 15.1673 12.6667 13.9734 12.6667 12.5007V5.83398M9.33333 7.83398V11.834M6.66667 7.83398L6.66667 11.834M10.6667 3.83398L9.72916 2.42772C9.48187 2.05679 9.06556 1.83398 8.61975 1.83398H7.38025C6.93444 1.83398 6.51813 2.05679 6.27084 2.42772L5.33333 3.83398M10.6667 3.83398H5.33333M10.6667 3.83398H14M5.33333 3.83398H2"
                        stroke="#FF475D"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </>
              )} */}

            {handPicked != "handPicked" && (
              <>
                {isSourceCandidate ? (
                  <ProtectedComponent
                    showModal={true}
                    code={
                      checkResource ? "Candidate_pipeline_write" : "no_code"
                    }
                  >
                    <button
                      className={` flex items-center rounded-lg border h-8  border-gray-500 ${iter?.pushed_as_prospect ? "bg-bg1 " : "bg-white border-button"} py-2 px-2.5   justify-center min-w-[140px]`}
                      onClick={() => {
                        removeCandidate({
                          id: iter?._id,
                        });
                        setShowModal("select-stage");
                      }}
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Convert prospect to candidate"}
                    >
                      <span className="text-gray-800 text-sm font-normal w-[170px] truncate not-italic hover:text-blue-900 focus:text-blue-900">
                        {iter._id === atsloading ? (
                          <ScaleLoader
                            height={14}
                            color="#1369E9"
                            loading={true}
                          />
                        ) : (
                          "Convert to candidate"
                        )}
                      </span>
                    </button>
                  </ProtectedComponent>
                ) : (
                  <ProtectedComponent
                    showModal={true}
                    code={
                      checkResource ? "Candidate_pipeline_write" : "no_code"
                    }
                  >
                    <button
                      className="border-button flex items-center rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5   justify-center max-w-[160px] min-w-[140px] "
                      onClick={() => {
                        setMenuShow(!menuShow);
                        setId(iter?.applicant?._id);
                        removeCandidate({
                          name: iter?.notes,
                          id: iter?._id,
                          vote: true,
                          stage:
                            iter?.current_stage ??
                            iter?.applicant?.current_stage,
                        });
                      }}
                      data-tooltip-id="my-tooltip-4"
                      data-tooltip-content={"Change stage"}
                    >
                      <span className="text-gray-800 text-sm font-normal not-italic hover:text-blue-900 focus:text-blue-900 max-w-[140px] truncate">
                        {iter?.current_stage ?? iter?.applicant?.current_stage}{" "}
                      </span>
                      <span className="ml-8px">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                        >
                          <path
                            d="M6.71838 8.30751L6.71753 8.30657L5.18845 6.62649C5.17672 6.6136 5.17066 6.60014 5.16811 6.58407C5.1652 6.56574 5.16722 6.54403 5.17472 6.5241C5.17827 6.51464 5.18239 6.50742 5.18595 6.50247C5.18662 6.50154 5.18724 6.50072 5.18782 6.5L8.14833 6.5L10.8114 6.5C10.8151 6.50456 10.8205 6.51266 10.8253 6.52534C10.8331 6.54588 10.8351 6.56776 10.8323 6.58575C10.8299 6.60139 10.8241 6.61416 10.8129 6.62649L8.34541 9.33765C8.14851 9.55399 7.84854 9.55412 7.65152 9.33803C7.6514 9.33791 7.65128 9.33778 7.65116 9.33765L6.71838 8.30751Z"
                            fill="#252E4B"
                            stroke="#252E4B"
                          />
                        </svg>
                      </span>
                    </button>
                  </ProtectedComponent>
                )}

                {menuShow && iter?.applicant._id === id && (
                  <div className="absolute w-[160px] top-[40px] bg-white  right-[70px] rounded-lg p-1 shadow-menuOptionShadow z-10">
                    <ul>
                      {pipeline
                        ?.filter((item) => item != iter?.current_stage)
                        ?.map((item, index) => (
                          <li
                            className="p-2 text-gray-800 text-sm font-normal  not-italic rounded-lg cursor-pointer hover:bg-bg1"
                            onClick={() => {
                              if (item === "Rejected") {
                                setShowModal("reject-reason-status");
                                setChatDetail({
                                  id: iter?._id,
                                  email: iter?.applicant?.email,
                                  name: iter?.applicant?.first_name,
                                });
                                removeCandidate({
                                  name: iter?.applicant?.first_name,
                                  id: iter?._id,
                                  vote: iter?.thumbs_up === 1 ? true : false,
                                });
                              } else {
                                setReload(reload + 1);
                                updateStatus(iter?._id, item, "");
                                setMenuShow(false);
                              }
                            }}
                          >
                            {item?.split("/")?.at(-1)}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {/* {resources?.filter((item) => item === "Candidate_create")
                  ?.length > 0 && (
                  <button
                    className="border-button rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 ml-3 flex items-center justify-center"
                    data-tooltip-id="my-tooltip-4"
                    data-tooltip-content={
                      iter?.favourite ? "Remove from save" : "Save for later"
                    }
                    onClick={() => {
                      saveForLater(!iter?.favourite, iter?._id);
                    }}
                    style={{ color: "#FF475D" }}
                  >
                    {!iter?.favourite ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="23"
                        viewBox="0 0 20 23"
                        fill="none"
                      >
                        <path
                          d="M14.5 1.5H4.86C2.73 1.5 1 3.24 1 5.36V19.45C1 21.25 2.29 22.01 3.87 21.14L8.75 18.43C9.27 18.14 10.11 18.14 10.62 18.43L15.5 21.14C17.08 22.02 18.37 21.26 18.37 19.45V5.36C18.36 3.24 16.63 1.5 14.5 1.5Z"
                          stroke="#5F6989"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                      >
                        <path
                          d="M16.8203 1.91016H7.18031C5.06031 1.91016 3.32031 3.65016 3.32031 5.77016V19.8602C3.32031 21.6602 4.61031 22.4202 6.19031 21.5502L11.0703 18.8402C11.5903 18.5502 12.4303 18.5502 12.9403 18.8402L17.8203 21.5502C19.4003 22.4302 20.6903 21.6702 20.6903 19.8602V5.77016C20.6803 3.65016 18.9503 1.91016 16.8203 1.91016ZM15.6203 9.03016L11.6203 13.0302C11.4703 13.1802 11.2803 13.2502 11.0903 13.2502C10.9003 13.2502 10.7103 13.1802 10.5603 13.0302L9.06031 11.5302C8.77031 11.2402 8.77031 10.7602 9.06031 10.4702C9.35031 10.1802 9.83031 10.1802 10.1203 10.4702L11.0903 11.4402L14.5603 7.97016C14.8503 7.68016 15.3303 7.68016 15.6203 7.97016C15.9103 8.26016 15.9103 8.74016 15.6203 9.03016Z"
                          fill="#5F6989"
                        />
                      </svg>
                    )}
                  </button>
                )} */}
                <Dropdown
                  dropdownRender={() => {
                    return (
                      <div className="bg-white p-1 rounded-2 w-[150px] | flex flex-col | border shadow rounded-md | candidate-other-status-dropdown">
                        <ProtectedComponent
                          showModal={true}
                          code={checkResource ? "Candidate_update" : "no_code"}
                        >
                          <div
                            className=" px-3 w-full py-2 rounded-md | cursor-pointer | hover:bg-gray-200 | flex gap-2 items-center"
                            onClick={() => {
                              setShowModal("update_can_data");
                              removeCandidate({
                                index: idx,
                                name: iter?.applicant?.first_name,
                                last_name: iter?.applicant?.last_name,
                                id: iter?.applicant?._id,
                                title: iter?.applicant.title,
                                email: iter?.applicant.email,
                                work_email: iter?.applicant.work_email,
                                phone: iter?.applicant.phone,
                                work_phone: iter?.applicant.work_phone,
                                linkedln: iter?.applicant?.linkedin,
                                source: iter?.current_source,
                                remote_source: iter?.remote_source,
                                matchProfileId: iter?._id,
                                resume_link: iter?.applicant?.resume_link,
                              });
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z"
                                stroke="#252E4B"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Edit
                          </div>
                        </ProtectedComponent>

                        <ProtectedComponent
                          showModal={true}
                          code={checkResource ? "Candidate_update" : "no_code"}
                        >
                          <div
                            className=" px-3 w-full py-2 rounded-md | cursor-pointer | hover:bg-gray-200 | flex gap-2 items-center"
                            onClick={() => {
                              removeCandidate({
                                name: iter?.applicant?.first_name,
                                id: iter?._id,
                              });
                              setShowModal("delete-candidate-modal");
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M3.33333 5.83398V12.5007C3.33333 13.9734 4.52724 15.1673 6 15.1673H10C11.4728 15.1673 12.6667 13.9734 12.6667 12.5007V5.83398M9.33333 7.83398V11.834M6.66667 7.83398L6.66667 11.834M10.6667 3.83398L9.72916 2.42772C9.48187 2.05679 9.06556 1.83398 8.61975 1.83398H7.38025C6.93444 1.83398 6.51813 2.05679 6.27084 2.42772L5.33333 3.83398M10.6667 3.83398H5.33333M10.6667 3.83398H14M5.33333 3.83398H2"
                                stroke="#FF475D"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                            Delete
                          </div>
                        </ProtectedComponent>
                        {resources?.filter(
                          (item) => item === "Candidate_create",
                        )?.length > 0 && (
                          <div
                            className=" px-3 w-full py-2 rounded-md | cursor-pointer | hover:bg-gray-200 | flex gap-2 items-center"
                            onClick={() => {
                              saveForLater(!iter?.favourite, iter?._id);
                            }}
                          >
                            {!iter?.favourite ? (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="23"
                                viewBox="0 0 20 23"
                                fill="none"
                              >
                                <path
                                  d="M14.5 1.5H4.86C2.73 1.5 1 3.24 1 5.36V19.45C1 21.25 2.29 22.01 3.87 21.14L8.75 18.43C9.27 18.14 10.11 18.14 10.62 18.43L15.5 21.14C17.08 22.02 18.37 21.26 18.37 19.45V5.36C18.36 3.24 16.63 1.5 14.5 1.5Z"
                                  stroke="#5F6989"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <path
                                  d="M16.8203 1.91016H7.18031C5.06031 1.91016 3.32031 3.65016 3.32031 5.77016V19.8602C3.32031 21.6602 4.61031 22.4202 6.19031 21.5502L11.0703 18.8402C11.5903 18.5502 12.4303 18.5502 12.9403 18.8402L17.8203 21.5502C19.4003 22.4302 20.6903 21.6702 20.6903 19.8602V5.77016C20.6803 3.65016 18.9503 1.91016 16.8203 1.91016ZM15.6203 9.03016L11.6203 13.0302C11.4703 13.1802 11.2803 13.2502 11.0903 13.2502C10.9003 13.2502 10.7103 13.1802 10.5603 13.0302L9.06031 11.5302C8.77031 11.2402 8.77031 10.7602 9.06031 10.4702C9.35031 10.1802 9.83031 10.1802 10.1203 10.4702L11.0903 11.4402L14.5603 7.97016C14.8503 7.68016 15.3303 7.68016 15.6203 7.97016C15.9103 8.26016 15.9103 8.74016 15.6203 9.03016Z"
                                  fill="#5F6989"
                                />
                              </svg>
                            )}
                            {iter?.favourite ? "Unsave" : "Save"}
                          </div>
                        )}
                      </div>
                    );
                  }}
                >
                  <button
                    className="candidate-other-status-button border-button rounded-lg border h-8 aspect-square border-gray-500 bg-white py-2 px-2.5 ml-3 flex items-center justify-center"
                    style={{ color: "#FF475D" }}
                  >
                    <svg
                      width="4"
                      height="16"
                      viewBox="0 0 4 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 14C1 14.5523 1.44772 15 2 15C2.55228 15 3 14.5523 3 14C3 13.4477 2.55228 13 2 13C1.44772 13 1 13.4477 1 14Z"
                        stroke="#96969F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8Z"
                        stroke="#96969F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2Z"
                        stroke="#96969F"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </Dropdown>
              </>
            )}

            {handPicked === "handPicked" && (
              <>
                <button className="bg-[rgba(0,172,133,0.10)] text-[#252E4B] flex items-center py-2 px-2.5 ml-3 rounded-lg">
                  <svg
                    className="mr-[10px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="23"
                    height="13"
                    viewBox="0 0 23 13"
                    fill="none"
                  >
                    <path
                      d="M6 7.00049L10.9497 11.9502L21.5572 1.34375M1.0498 7.05078L5.99955 12.0005M16.606 1.39404L11.3027 6.69734"
                      stroke="#252E4B"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Intrested
                </button>
                <button className="bg-[#FFF1F3] text-[#252E4B] flex items-center py-2 px-2.5 ml-3 rounded-lg">
                  <svg
                    className="mr-[10px]"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7.00195 7L17.002 17"
                      stroke="#252E4B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M7 17L17 7"
                      stroke="#252E4B"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  Not intrested
                </button>
              </>
            )}

            <button
              onClick={() => {
                if (idx != dataIdx) {
                  getSkills(iter?.applicant?._id);
                  setDataIdx(idx);
                } else setDataIdx(-1);
              }}
              style={{ border: "none", background: "none" }}
            >
              {idx == dataIdx ? (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M17.5 14.4844L12 8.99995L6.5 14.4844"
                    stroke="#252E4B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.5 9L12 14.4844L17.5 9"
                    stroke="#252E4B"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </button>
          </div>
        </td>

        <div className="absolute bottom-0 left-0 w-full">
          {iter?.availablity_status !== "Not Started" ||
          iter?.interview_status !== "Not Started" ||
          (iter?.sequence_status && iter?.sequence_status !== "Not Started") ||
          iter?.assessment_status !== "Not Started" ||
          (iter?.pastApplied && iter?.pastApplied.length > 0) ? (
            <div className="flex items-center gap-8 px-4 py-1.5 border-t-[0.5px] border-b-[3px] border-y-gray-300 w-full">
              {iter?.availablity_status !== "Not Started" && (
                <div className="flex items-center text-[12px]">
                  <span>Availability Status : </span>{" "}
                  <span
                    className={`${getbgColor(iter?.availablity_status ?? "Not sent")} ml-1`}
                  >
                    {iter?.availablity_status ?? "Not sent"}
                  </span>
                </div>
              )}

              {iter?.interview_status !== "Not Started" && (
                <div className="flex items-center text-[12px]">
                  <span>Interview Status : </span>{" "}
                  <span
                    className={`${getbgColor(iter?.interview_status ?? "Not sent")} ml-1`}
                  >
                    {iter?.interview_status ?? "Not scheduled"}
                  </span>
                </div>
              )}

              {iter?.sequence_status &&
                iter?.sequence_status !== "Not Started" && (
                  <div className="flex items-center text-[12px]">
                    <span>Sequence : </span>{" "}
                    <span
                      className={`${getbgColor(iter?.sequence_status ?? "Not sent")} ml-1`}
                    >
                      {iter?.sequence_status ?? "Not started"}
                    </span>
                  </div>
                )}

              {iter?.assessment_status !== "Not Started" && (
                <div className="flex items-center text-[12px]">
                  <span>Assessment : </span>{" "}
                  {assessmentDone !== -1 ? (
                    <>
                      <div
                        className="flex items-center text-[12px] cursor-pointer"
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={() => setShowAssessments(false)}
                      >
                        <span className="ml-1">{iter?.assessmentName}</span>
                        <span
                          className={`ml-1 ${
                            Math.round(iter?.totalPercentage) < 25
                              ? "text-red-600"
                              : Math.round(iter?.totalPercentage) <= 50
                                ? "text-orange-600"
                                : Math.round(iter?.totalPercentage) <= 75
                                  ? "text-green-600"
                                  : "text-green-800"
                          }`}
                        >
                          ( {Math.round(iter?.totalPercentage)}% )
                        </span>
                        <div className="relative">
                          {showAssessments && (
                            <>
                              {assessLoading ? (
                                <div className="flex absolute bg-gray-800 px-3 py-2 z-50 rounded-lg w-52 text-gray-300">
                                  <span className="mr-3">Loading...</span>
                                  <span className="mr-5">
                                    <ClipLoader
                                      color="white"
                                      loading={true}
                                      size={15}
                                    />
                                  </span>
                                </div>
                              ) : (
                                <div className="flex flex-col absolute bg-gray-800 px-3 py-2 z-50 rounded-lg w-52">
                                  <p className="text-white text-sm font-semibold mb-2">
                                    Assessments
                                  </p>
                                  {assesScores?.data.map((item) => (
                                    <span
                                      key={item?._id}
                                      className="flex items-center justify-between my-1 w-full"
                                    >
                                      <span className="text-white text-[11px] font-normal not-italic mr-1">
                                        {item?.assessment}
                                      </span>
                                      <div className="flex items-center justify-center mr-2">
                                        <PercentDonut
                                          id={`assessment_${item?.id}`}
                                          chartSize={20}
                                          radius={7}
                                          blankColor={
                                            "rgba(255, 255, 255, 0.15)"
                                          }
                                          width={3}
                                          cornerRadius={1}
                                          hidePercent={true}
                                          percentData={Math.round(
                                            item?.total?.totalPercentage,
                                          )}
                                          fontSize={8}
                                        />
                                        <span className="text-white text-[11px] font-normal not-italic">
                                          {`${Math.round(item?.total?.totalPercentage)}% `}
                                        </span>
                                      </div>
                                      <button
                                        className="flex justify-center items-center bg-blue-900 text-white text-[10px] px-2 py-1 rounded-md ml-1"
                                        onClick={() =>
                                          window.open(
                                            "/assessment-report/" + item?._id,
                                            "_blank",
                                          )
                                        }
                                      >
                                        Report
                                      </button>
                                    </span>
                                  ))}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    <span
                      className={`${getbgColor(iter?.assessment_status ?? "Not sent")} ml-1`}
                    >
                      {iter?.assessment_status ?? "Not sent"}
                    </span>
                  )}
                </div>
              )}

              {iter?.pastApplied && iter?.pastApplied?.length > 0 && (
                <div className="flex items-center text-[12px]">
                  <span>Past Applied : </span>{" "}
                  <span className={`text-green-600 ml-1`}>
                    {iter?.pastApplied?.map((x, index) => (
                      <a
                        key={index}
                        href={`${window.location.host}/job/active/${x?.job}/description`} // Assuming each job has a URL for this example
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {x?.job_name?.substring(0, 20)}..
                      </a>
                    ))}
                  </span>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full border-b-[3px] border-y-gray-300"></div>
          )}
        </div>
      </tr>

      <tr className="max-w-full candidate-detail-row-2">
        <td colSpan="4" className="relative w-full">
          {idx == dataIdx && (
            <ApplicantAccordionV2
              allData={iter}
              sequence_status={iter?.sequence_status}
              sequence_response={iter?.sequence_response}
              educationMatchWeight={educationMatchWeight}
              loading={loading}
              data={analyticsData[iter?._id]}
            />
          )}
        </td>
      </tr>
    </>
  );
}

export default Applicantlist;
