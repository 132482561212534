import { Switch } from "antd";
import React, { memo, useEffect, useState } from "react";
import { Range, getTrackBackground } from "react-range";
import errorIcon from "../../assets/error.svg";
import { IoInformationCircle } from "react-icons/io5";
import { Tooltip } from "react-tooltip";
import InputBox from "../../../layouts/InputBox";

const typeOptions = [
  { value: 0, label: "Not Required" },
  { value: 1, label: "Nice to Have" },
  { value: 2, label: "Must Have" },
];

const stability_attribut = {
  stability: {
    description:
      "Stability is evaluated based on job tenure. Longer tenure indicates better stability, especially for more experienced individuals.",
    criteria: {
      good: "Stays in company for 3+ years or 2+ years if under 5-6 years of experience.",
      bad: "Changes jobs every 1-1.5 years.",
      scoring: {
        good: "7-10",
        average: "5-6",
        bad: "0-4",
      },
    },
  },
  career_growth: {
    description:
      "Career growth is assessed by job progress and promotions. Stagnation, especially in junior roles, reflects poorly.",
    criteria: {
      good: "Progress in role or promotions, job switches to higher roles.",
      bad: "Long tenure in same role without promotions.",
      scoring: {
        good: "7-10",
        average: "5-6",
        bad: "0-4",
      },
    },
  },
  startup_experience: {
    description:
      "Startup experience is valued based on the duration and impact of roles in startups throughout the career.",
    criteria: {
      good: "Significant recent startup experience or 50% of career in startups.",
      average:
        "Startup experience mainly in early career with major contribution.",
      bad: "Minimal or no startup experience.",
      scoring: {
        good: "7-10",
        average: "4-6",
        bad: "0-3",
      },
    },
  },
  job_change_likelihood: {
    description:
      "This metric predicts the probability of a job change based on tenure, frequency of job changes, and recent promotions.",
    criteria: {
      good: "Duration close to average tenure, frequent changes, no recent promotions.",
      average: "Moderate duration, occasional changes, no recent promotions.",
      bad: "Longer duration than average, recent promotions, or newly joined.",
      scoring: {
        good: "8-10",
        average: "5-7",
        bad: "0-4",
      },
    },
  },
  seniority_level: {
    description:
      "Seniority level match assesses the alignment of an individual’s previous roles with the requirements of a new role.",
    criteria: {
      good: "Previous roles align well with new role's responsibilities and requirements with relevant years of experience.",
      average: "Some overlap in responsibilities, but some gaps in experience.",
      bad: "Little to no alignment with significant experience gaps.",
      scoring: {
        good: "7-10",
        average: "5-6",
        bad: "0-4",
      },
    },
  },
};

const OtherAttributes = ({
  formJobdetails,
  setFormJobdetails,
  errorList,
  setErrorList,
  OtherAttributes,
  setOtherAttributes,
}) => {
  console.log("uploaded data fine");
  console.log(formJobdetails);
  const [stability, setStability] = useState({
    value: [0],
    type: 0,
  });
  const [career_growth, setcareer_growth] = useState({
    value: [0],
    type: 0,
  });
  const [startup_experience, setstartup_experience] = useState({
    value: [0],
    type: 0,
  });
  const [open_for_new_role, setopen_for_new_role] = useState(false);
  const [companyMatchAttributes, setCompanyMatchAatributes] = useState([
    "Overview",
    "Culture",
    "Industry",
    "Products & Services",
    "Careers",
  ]);
  const [inital, setInitial] = useState(true);

  useEffect(() => {
    if (!inital) return;
    setTimeout(() => {
      if (
        formJobdetails?.company_match_attributes &&
        formJobdetails?.company_match_attributes.length > 0
      ) {
        let arr = [];
        for (const i of formJobdetails?.company_match_attributes) {
          if (!companyMatchAttributes.includes(i)) {
            arr.push(i);
          }
        }
        setCompanyMatchAatributes([...companyMatchAttributes, ...arr]);
      }
      setInitial(false);
    }, [300]);
  }, [formJobdetails?.company_match_attributes]);

  const [newInput, setNewInput] = useState("");
  // const [OtherAttributes, setOtherAttributes] = useState([]);

  console.log(OtherAttributes);

  const CareerGrowthInfo = ({ data, status }) => {
    return (
      <div className="relative group w-[200px]">
        <div className="">
          <p>
            <strong>Description:</strong> {data?.description}
          </p>
          <div className="mt-2">
            <p>
              <strong>Criteria:</strong>
            </p>
            <p>
              <strong>Good:</strong> {data?.criteria.good}
            </p>
            <p>
              <strong>Bad:</strong> {data?.criteria.bad}
            </p>
            <div className="mt-2">
              <p>
                <strong>Scoring:</strong>
              </p>
              <p>
                <strong>Good:</strong> {data?.criteria?.scoring?.good}
              </p>
              <p>
                <strong>Average:</strong> {data?.criteria?.scoring?.average}
              </p>
              <p>
                <strong>Bad:</strong> {data?.criteria?.scoring?.bad}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleDeleteAttribute = (indexToDelete) => {
    setOtherAttributes((prev) =>
      prev.filter((_, index) => index !== indexToDelete),
    );
  };

  return (
    <div className="px-4 flex flex-col gap-10">
      <div className="grid grid-cols-12 gap-2 items-center mt-8">
        <div className="col-span-4 flex items-center">
          Stability
          <span
            data-tooltip-id="my-tooltip-Stability"
            className="text-gray-500 text-sm italic ml-2"
          >
            <IoInformationCircle />
          </span>
        </div>
        <div>
          <Tooltip
            id="my-tooltip-Stability"
            opacity={1}
            data-tooltip-place="top"
            style={{
              backgroundColor: "#FFF",
              color: "#222",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              zIndex: 50,
            }}
            render={({ content, activeAnchor }) => (
              <CareerGrowthInfo data={stability_attribut["stability"]} />
            )}
          />
        </div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.stability?.threshold || 0]}
            onChange={(newValues) =>
              setFormJobdetails((prev) => ({
                ...prev,
                stability: {
                  ...prev?.stability,
                  threshold: newValues[0],
                },
              }))
            }
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [[formJobdetails?.stability?.threshold || 0]],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.stability?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                stability: {
                  ...prev?.stability,
                  type: parseInt(e.target.value),
                },
              }))
            }
            value={formJobdetails?.stability?.type || 0}
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4 flex items-center">
          Career growth
          <span
            data-tooltip-id="my-tooltip-carrerGrowth"
            className="text-gray-500 text-sm italic ml-2"
          >
            <IoInformationCircle />
          </span>
        </div>
        <div>
          <div>
            <Tooltip
              id="my-tooltip-carrerGrowth"
              opacity={1}
              data-tooltip-place="top"
              style={{
                background: "#FFF",
                color: "#222",
                boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                zIndex: 50,
              }}
              render={({ content, activeAnchor }) => (
                <CareerGrowthInfo data={stability_attribut["career_growth"]} />
              )}
            />
          </div>
        </div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.career_growth?.threshold || 0]}
            onChange={(newValues) =>
              setFormJobdetails((prev) => ({
                ...prev,
                career_growth: {
                  ...prev?.career_growth,
                  threshold: newValues[0],
                },
              }))
            }
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [[formJobdetails?.career_growth?.threshold || 0]],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.career_growth?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                career_growth: {
                  ...prev?.career_growth,
                  type: parseInt(e.target.value),
                },
              }))
            }
            value={formJobdetails?.career_growth?.type || 0}
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4 flex items-center">
          Startup experience
          <span
            data-tooltip-id="my-tooltip-Startup"
            className="text-gray-500 text-sm italic ml-2"
          >
            <IoInformationCircle />
          </span>
        </div>
        <div>
          <Tooltip
            id="my-tooltip-Startup"
            opacity={1}
            data-tooltip-place="top"
            style={{
              backgroundColor: "#FFF",
              color: "#222",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              zIndex: 50,
            }}
            render={({ content, activeAnchor }) => (
              <CareerGrowthInfo
                data={stability_attribut["startup_experience"]}
              />
            )}
          />
        </div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.startup_experience?.threshold || 0]}
            onChange={(newValues) => {
              setFormJobdetails((prev) => ({
                ...prev,
                startup_experience: {
                  ...prev?.startup_experience,
                  threshold: newValues[0],
                },
              }));
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [
                      [formJobdetails?.startup_experience?.threshold || 0],
                    ],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.startup_experience?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            value={formJobdetails?.startup_experience?.type || 0}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                startup_experience: {
                  ...prev?.startup_experience,
                  type: parseInt(e.target.value),
                },
              }))
            }
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4 flex items-center">
          Open to a new role
          <span
            data-tooltip-id="my-tooltip-Open"
            className="text-gray-500 text-sm italic ml-2"
          >
            <IoInformationCircle />
          </span>
        </div>
        <div>
          <Tooltip
            id="my-tooltip-Open"
            opacity={1}
            data-tooltip-place="top"
            style={{
              backgroundColor: "#FFF",
              color: "#222",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              zIndex: 50,
            }}
            render={({ content, activeAnchor }) => (
              <CareerGrowthInfo
                data={stability_attribut["job_change_likelihood"]}
              />
            )}
          />
        </div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.open_for_new_role?.threshold || 0]}
            onChange={(newValues) => {
              setFormJobdetails((prev) => ({
                ...prev,
                open_for_new_role: {
                  ...prev?.open_for_new_role,
                  threshold: newValues[0],
                },
              }));
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [
                      [formJobdetails?.open_for_new_role?.threshold || 0],
                    ],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.open_for_new_role?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            value={formJobdetails?.open_for_new_role?.type || 0}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                open_for_new_role: {
                  ...prev?.open_for_new_role,
                  type: parseInt(e.target.value),
                },
              }))
            }
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4 flex items-center">
          Seniority filter
          <span
            data-tooltip-id="my-tooltip-Company"
            className="text-gray-500 text-sm italic ml-2"
          >
            <IoInformationCircle />
          </span>
        </div>
        <div>
          <Tooltip
            id="my-tooltip-Company"
            zIndex={50}
            opacity={1}
            data-tooltip-place="top"
            style={{
              backgroundColor: "#FFF",
              color: "#222",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
              zIndex: 50,
            }}
            render={({ content, activeAnchor }) => (
              <CareerGrowthInfo data={stability_attribut["seniority_level"]} />
            )}
          />
        </div>
        <div className="col-span-4 flex items-center gap-2">
          <Range
            step={1}
            min={0}
            max={10}
            values={[formJobdetails?.company_match?.threshold || 0]}
            onChange={(newValues) => {
              setFormJobdetails((prev) => ({
                ...prev,
                company_match: {
                  ...prev?.company_match,
                  threshold: newValues[0],
                },
              }));
            }}
            renderTrack={({ props, children }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "8px",
                  width: "calc(100% - 30px)",
                  borderRadius: "4px",
                  right: 0,
                  position: "relative",
                  background: getTrackBackground({
                    values: [[formJobdetails?.company_match?.threshold || 0]],
                    colors: ["teal", "#f2f2f2"],
                    min: 0,
                    max: 10,
                  }),
                }}
              >
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div
                {...props}
                style={{
                  ...props.style,
                  height: "19px",
                  width: "19px",
                  borderRadius: "33.043px",
                  background: "#FFF",
                  boxShadow:
                    "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                }}
              />
            )}
          />
          <span className="inline-block w-4">
            {formJobdetails?.company_match?.threshold || 0}
          </span>
        </div>
        <div className="col-span-3">
          <select
            name="stability-type"
            id=""
            className="w-full border border-gray-300 rounded-md p-2"
            value={formJobdetails?.company_match?.type || 0}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                company_match: {
                  ...prev?.company_match,
                  type: parseInt(e.target.value),
                },
              }))
            }
          >
            {typeOptions.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>

      {OtherAttributes?.map((Iterable, index) => (
        <div>
          <div className="grid grid-cols-12 gap-2 items-center" key={index}>
            <div className="col-span-4 flex items-center">
              <InputBox
                value={Iterable?.name}
                placeholder={"Attribute name"}
                onChange={(e) => {
                  setOtherAttributes((prev) =>
                    prev?.map((attr, i) =>
                      i === index ? { ...attr, name: e.target.value } : attr,
                    ),
                  );
                }}
              />
            </div>
            <div>
              <Tooltip
                id="my-tooltip-Company"
                zIndex={50}
                opacity={1}
                data-tooltip-place="top"
                style={{
                  backgroundColor: "#FFF",
                  color: "#222",
                  boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
                  zIndex: 50,
                }}
                render={({ content, activeAnchor }) => (
                  <CareerGrowthInfo
                    data={stability_attribut["seniority_level"]}
                  />
                )}
              />
            </div>
            <div className="col-span-4 flex items-center gap-2">
              <Range
                step={1}
                min={0}
                max={10}
                values={[Iterable.threshold ?? 0]}
                onChange={(newValues) => {
                  setOtherAttributes((prev) =>
                    prev.map((attr, i) =>
                      i === index ? { ...attr, threshold: newValues[0] } : attr,
                    ),
                  );
                }}
                renderTrack={({ props, children }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "8px",
                      width: "calc(100% - 30px)",
                      borderRadius: "4px",
                      right: 0,
                      position: "relative",
                      background: getTrackBackground({
                        values: [[Iterable?.threshold || 0]],
                        colors: ["teal", "#f2f2f2"],
                        min: 0,
                        max: 10,
                      }),
                    }}
                  >
                    {children}
                  </div>
                )}
                renderThumb={({ props }) => (
                  <div
                    {...props}
                    style={{
                      ...props.style,
                      height: "19px",
                      width: "19px",
                      borderRadius: "33.043px",
                      background: "#FFF",
                      boxShadow:
                        "0px 1.6521738767623901px 4.956521511077881px 0px rgba(62, 72, 84, 0.20)",
                    }}
                  />
                )}
              />
              <span className="inline-block w-4">
                {Iterable.threshold || 0}
              </span>
            </div>
            <div className="col-span-3 flex items-center gap-2">
              <select
                name="stability-type"
                className="w-full border border-gray-300 rounded-md p-2"
                value={Iterable.type || 0}
                onChange={(e) => {
                  setOtherAttributes((prev) =>
                    prev.map((attr, i) =>
                      i === index
                        ? { ...attr, type: parseInt(e.target.value) }
                        : attr,
                    ),
                  );
                }}
              >
                {typeOptions?.map((option) => (
                  <option value={option.value} key={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <button onClick={() => handleDeleteAttribute(index)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <g id="left icon">
                    <path
                      id="Vector"
                      d="M11.0833 5.24935L10.6657 10.6783C10.5722 11.894 9.55851 12.8327 8.33926 12.8327H5.66074C4.44149 12.8327 3.42779 11.894 3.33428 10.6783L2.91667 5.24935M12.25 4.08268C10.7345 3.34417 8.93296 2.91602 7 2.91602C5.06704 2.91602 3.26545 3.34417 1.75 4.08268M5.83333 2.91602V2.33268C5.83333 1.68835 6.35567 1.16602 7 1.16602C7.64433 1.16602 8.16667 1.68835 8.16667 2.33268V2.91602M5.83333 6.41602V9.91602M8.16667 6.41602V9.91602"
                      stroke="#FF475D"
                      strokeLinecap="round"
                    ></path>
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div className="grid col-span-12 mt-2">
            <textarea
              className=""
              placeholder={"Describe attribute ..."}
              onChange={(e) => {
                setOtherAttributes((prev) =>
                  prev?.map((attr, i) =>
                    i === index
                      ? { ...attr, description: e.target.value }
                      : attr,
                  ),
                );
              }}
            ></textarea>
          </div>
        </div>
      ))}

      <div>
        <button
          type="button"
          className="text-[#1369E9] text-[16px] font-normal not-italic leading-[26px]"
          onClick={(e) =>
            setOtherAttributes((prev) => [
              ...prev,
              { name: "", type: "", threshold: "" },
            ])
          }
        >
          + Add more
        </button>
      </div>

      {/* <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-4"> Number of prospects</div>
        <div></div>
        <div className="col-span-7">
          <input
            type="number"
            value={formJobdetails?.number_of_prospects}
            onChange={(e) =>
              setFormJobdetails((prev) => ({
                ...prev,
                number_of_prospects: e.target.value,
              }))
            }
            className="w-full border border-gray-300 rounded-md p-2"
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-6">Blue-collar job </div>
        <div className="col-span-6 flex justify-end">
          <Switch
            className="bg-gray-300 aria-checked:bg-teal-700"
            checked={formJobdetails?.blueColor}
            onChange={(checked) => {
              setFormJobdetails((prev) => ({
                ...prev,
                blueColor: checked,
              }));
            }}
          />
        </div>
      </div>
      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-6">Seniority filter</div>
        <div className="col-span-6 flex justify-end">
          <Switch
            className="bg-gray-300 aria-checked:bg-teal-700"
            checked={formJobdetails?.seniority_check}
            onChange={(checked) => {
              setFormJobdetails((prev) => ({
                ...prev,
                seniority_check: checked,
              }));
            }}
          />
        </div>
      </div> */}

      <div className="grid grid-cols-12 gap-2 items-center">
        <div className="col-span-12">
          Company match attributes
          {Object.keys(errorList).includes("company_match_attr") ? (
            <>
              <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
              <span className="text-red-800 text-sm">
                {errorList?.company_match_attr}
              </span>
            </>
          ) : null}
        </div>
        <div className="col-span-12">
          <input
            value={newInput}
            className={`w-full px-2 mb-4 ${
              Object.keys(errorList).includes("company_match_attr")
                ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                : ""
            }`}
            type="text"
            placeholder="Press enter to add criteria"
            onChange={(event) => {
              // If input is cleared, remove error
              if (
                event.target.value == "" &&
                Object.keys(errorList).includes("company_match_attr")
              ) {
                setErrorList((current) => {
                  const { company_match_attr, ...rest } = current;
                  return rest;
                });
              }
              setNewInput(event.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                try {
                  if (e.target.value == "") return;
                  if (
                    companyMatchAttributes
                      .map((d) => String(d).toLowerCase())
                      .includes(String(e.target.value).toLowerCase())
                  )
                    throw "already added";
                  setCompanyMatchAatributes((prev) => [
                    ...prev,
                    e.target.value,
                  ]);
                  console.log(e.target.value);
                  setFormJobdetails((prev) => ({
                    ...prev,
                    company_match_attributes: [
                      ...prev?.company_match_attributes,
                      e.target.value,
                    ],
                  }));
                  setNewInput("");
                  // If validated value, remove previous error
                  setErrorList((current) => {
                    const { company_match_attr, ...rest } = current;
                    return rest;
                  });
                } catch (error) {
                  console.error(error);
                  setErrorList({ ...errorList, company_match_attr: error });
                }
              }
            }}
          />
        </div>
        <div className="col-span-12">
          {companyMatchAttributes.map((attr) => (
            <button
              key={attr.trim().split(" ").join("_")}
              className={`mr-2 mb-2 h-[34px] px-3 rounded-2xl whitespace-nowrap min-w-fit ${
                formJobdetails?.company_match_attributes?.includes(attr)
                  ? "btn-primary !bg-[#00B98D] !border-[#00B98D]"
                  : "btn-sec hover:text-[#00B98D] hover:border-[#00B98D]"
              }`}
              onClick={(e) => {
                e.preventDefault();
                if (formJobdetails?.company_match_attributes?.includes(attr)) {
                  setFormJobdetails((prev) => ({
                    ...prev,
                    company_match_attributes:
                      prev?.company_match_attributes?.filter(
                        (item) => item !== attr,
                      ),
                  }));
                } else {
                  setFormJobdetails((prev) => ({
                    ...prev,
                    company_match_attributes: [
                      ...prev?.company_match_attributes,
                      attr,
                    ],
                  }));
                }
              }}
            >
              {attr}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(OtherAttributes);
