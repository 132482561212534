import React, { useState, useEffect, useMemo } from "react";
import Modal from "../../modal/Modal";
import { ScaleLoader } from "react-spinners";
import moment from "moment";
import Dropdown from "../../../layouts/Dropdown";
import { filterIcon } from "../candidate/icons/Icons";
import {
  activity,
  calendar,
  mail,
  notes,
  report,
} from "../candidate/icons/Icons";
const core = require("../../../lib/core");

const ACTIVITY_TYPE = {
  ACTIVITY: "activity",
  EMAIL: "email",
  SEQUENCE: "sequence",
  NOTE: "note",
  SCHEDULED: "scheduled",
  REPORT: "report",
};

const MOCK_ACTIVITIES = [
  {
    id: 4,
    activity_type: ACTIVITY_TYPE.ACTIVITY,
    heading: "Assessment Created",
    user: { name: "Sarah Wilson" },
    createdAt: new Date(Date.now() - 30 * 60 * 1000), // 30 minutes ago
    source: "Manual",
  },
  {
    id: 3,
    activity_type: ACTIVITY_TYPE.EMAIL,
    heading: "New Candidate Added",
    user: { name: "Mike Johnson" },
    createdAt: new Date(Date.now() - 2 * 60 * 60 * 1000), // 2 hours ago
    source: "ATS",
  },
  {
    id: 2,
    activity_type: ACTIVITY_TYPE.NOTE,
    heading: "Job Details Updated",
    user: { name: "Jane Smith" },
    createdAt: new Date(Date.now() - 12 * 60 * 60 * 1000), // 12 hours ago
    source: "Manual",
  },
  {
    id: 1,
    activity_type: ACTIVITY_TYPE.ACTIVITY,
    heading: "Job Created",
    user: { name: "John Doe" },
    createdAt: new Date(Date.now() - 24 * 60 * 60 * 1000), // 1 day ago
    source: "Manual",
  },
];

// Updated icons to match reference
const Icons = {
  [ACTIVITY_TYPE.ACTIVITY]: activity,
  [ACTIVITY_TYPE.EMAIL]: mail,
  [ACTIVITY_TYPE.NOTE]: notes,
  [ACTIVITY_TYPE.SCHEDULED]: calendar,
  [ACTIVITY_TYPE.REPORT]: report,
};

const ActivityItem = ({ activity }) => {
  const getActivityIcon = (type) => {
    return Icons[type] || Icons[ACTIVITY_TYPE.ACTIVITY];
  };

  const capitalize = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };

  return (
    <div className="flex gap-3 items-start justify-start mb-5">
      <div
        data-tooltip-id="tooltip"
        data-tooltip-content={capitalize(activity.activity_type)}
        // Fixed icon centering by adjusting negative margin and added proper outline
        className="w-9 h-9 bg-white border border-gray-400 rounded-full -ml-[18px] p-2 text-gray-900 outline-[6px] outline-white outline"
      >
        <div className="h-5 w-5">{getActivityIcon(activity.activity_type)}</div>
      </div>
      <div className="w-full flex flex-col gap-1.5 mt-1">
        {activity.heading && (
          <h1 className="flex items-start text-gray-900 text-base font-medium grow not-italic">
            {activity.heading}
          </h1>
        )}
        <div className="w-full flex justify-between gap-2 mb-2">
          <div className="grow gap-2 flex justify-start">
            {activity.user?.name && (
              <span className="px-2 pt-1 rounded-lg bg-blue-100 text-gray-800 text-xs font-medium">
                {activity.user.name}
              </span>
            )}
            {activity.job && (
              <span className="px-2 pt-1 rounded-lg bg-emerald-100 text-gray-800 text-xs font-medium">
                {activity.job.name}
              </span>
            )}
            <span className="px-2 text-xs text-emerald-800">
              {activity.source}
            </span>
          </div>
          {activity.createdAt && (
            <h2
              data-tooltip-id="tooltip"
              data-tooltip-content={moment(activity.createdAt).format("llll")}
              className="text-gray-600 text-sm not-italic font-normal w-auto cursor-default"
            >
              {moment(activity.createdAt).startOf("seconds").fromNow()}
            </h2>
          )}
        </div>
      </div>
    </div>
  );
};

const JobActivityModal = ({ modalDismiss, jobId }) => {
  const [filter, setFilter] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const [jobActivity, setJobActivity] = useState([]);

  // Filter activities based on type using useMemo for performance
  const filteredActivities = useMemo(() => {
    if (filter === "all") return MOCK_ACTIVITIES;
    return MOCK_ACTIVITIES.filter((act) => act.activity_type === filter);
  }, [filter]);

  const getJobActivity = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_JOB_ACTIVITY_DATA + jobId,
        1,
        {},
      );
      if (json?.data) {
        setJobActivity(json?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getJobActivity();
  }, []);

  const filterActivity = (type) => () => {
    setFilter(type);
  };

  return (
    <Modal
      heading="Job Activity"
      subheading="View all activities related to this job"
      modalDismiss={modalDismiss}
      noBtns={true}
      className="min-h-[60vh] min-w-[60vw]"
    >
      <div className="max-h-[80vh]  overflow-y-auto">
        {isLoading ? (
          <div className="w-full flex items-center justify-center py-10">
            <ScaleLoader height={20} color="#1369E9" loading={true} />
          </div>
        ) : (
          <>
            {/* Added filter dropdown */}
            <div className="w-full flex justify-end px-4 mb-4 mt-0.5">
              <Dropdown
                listAlign="right"
                buttonLabel={
                  <span className="flex gap-2 items-center">
                    {filterIcon}
                    {filter !== "all" && (
                      <span className="text-gray-800 text-sm font-semibold capitalize">
                        {filter}
                      </span>
                    )}
                  </span>
                }
              />
            </div>
            {/* Updated border color to match reference */}
            <div className="px-4">
              <div className="w-full border-l-2 border-gray-600  -mt-5">
                {filteredActivities.length > 0 ? (
                  filteredActivities.map((activity) => (
                    <ActivityItem key={activity.id} activity={activity} />
                  ))
                ) : (
                  <div className="w-full flex items-center justify-center">
                    No activity found
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default JobActivityModal;
