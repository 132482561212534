import React, { useState } from "react";
import classNames from "classnames";
import { useInView } from "react-cool-inview";
import {
  RiBold,
  RiItalic,
  RiStrikethrough,
  RiCodeSSlashLine,
  RiEmotionLine,
  RiH1,
  RiH2,
  RiH3,
  RiH4,
  RiH5,
  RiH6,
  RiParagraph,
  RiListOrdered,
  RiListUnordered,
  RiCodeBoxLine,
  RiLink,
  RiLinkUnlink,
  RiDoubleQuotesL,
  RiSeparator,
  RiTextWrap,
  RiFormatClear,
  RiArrowGoBackLine,
  RiArrowGoForwardLine,
  RiUnderline,
  RiAlignLeft,
  RiAlignCenter,
  RiAlignRight,
  RiImageLine,
} from "react-icons/ri";
// import sample from 'lodash-es/sample.js'

import "./style/Toolbar.scss";
import UploadImageModal from "./UploadImageModal";
import AddLinkModal from "./AddLinkModal";

// sample(['teste'])

function Toolbar({ editor, setLastUploadedImageSize }) {
  const [showImageUploadModal, setShowImageUploadModal] = useState(false);
  const [showAddLinkModal, setShowAddLinkModal] = useState(false);

  const isCursorOverLink = editor.getAttributes("link").href;

  // Helper functions to upload Image
  const addImage = ({ url, width, height, link = "" }) => {
    if (url) {
      setLastUploadedImageSize({
        width,
        height,
      });
      setTimeout(() => {
        setShowImageUploadModal(false);
        editor
          .chain()
          .focus()
          .setImage({ src: url, width, height, link, style: "margin: 1em 0;" })
          .run();
      }, 100);
    }
  };

  // Helper functions to upload Image
  const openImageUpladModal = () => {
    setShowImageUploadModal(true);
  };

  // Helper functions to add link
  const setLink = (link) => {
    if (link === null) {
      setShowAddLinkModal(false);
      return;
    }

    // empty
    if (link === "") {
      editor.chain().focus().extendMarkRange("link").unsetLink().run();
      setShowAddLinkModal(false);
      return;
    }

    // update link
    editor
      .chain()
      .focus()
      .extendMarkRange("link")
      .setLink({ href: link })
      .run();
    setShowAddLinkModal(false);
  };

  const { observe, inView } = useInView({
    rootMargin: "-1px 0px 0px 0px",
    threshold: [1],
  });

  console.log(editor.getAttributes("textStyle").fontSize);
  return (
    <div
      className={classNames(
        "ToolbarContainer",
        "border-b border-gray-500 p-2 rounded-t-md bg-gray-300",
        { sticky: !inView },
      )}
      ref={observe}
    >
      {showAddLinkModal && (
        <AddLinkModal
          onSave={setLink}
          editor={editor}
          modalDismiss={() => setShowAddLinkModal(false)}
        />
      )}
      {showImageUploadModal && (
        <UploadImageModal
          handleDismiss={() => setShowImageUploadModal(false)}
          handleSubmit={addImage}
        />
      )}
      <div className="Toolbar flex flex-wrap justify-start">
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Bold"}
          className={classNames(
            "icon",
            editor.isActive("bold") ? "is-active" : "",
          )}
          onClick={() => editor.chain().focus().toggleBold().run()}
        >
          <RiBold />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Italic"}
          className={classNames(
            "icon",
            editor.isActive("italic") ? "is-active" : "",
          )}
          onClick={() => editor.chain().focus().toggleItalic().run()}
        >
          <RiItalic />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Underline"}
          className={classNames(
            "icon",
            editor.isActive("underline") ? "is-active" : "",
          )}
          onClick={() => editor.chain().focus().toggleUnderline().run()}
        >
          <RiUnderline />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Strikethrough"}
          className={classNames(
            "icon",
            editor.isActive("strike") ? "is-active" : "",
          )}
          onClick={() => editor.chain().focus().toggleStrike().run()}
        >
          <RiStrikethrough />
        </div>
        {/* <div className={classNames("icon", editor.isActive('code') ? 'is-active' : '')} onClick={() => editor.chain().focus().toggleCode().run()}>
                    <RiCodeSSlashLine />
                </div> */}
        {/* <div className="divider"></div>
                <div
                    className="icon"
                    onClick={() =>
                        editor
                            .chain()
                            .focus()
                            .insertEmoji(sample(['😀', '😁', '😂', '😎', '😍', '😱']))
                            .run()
                    }>
                    <RiEmotionLine />
                </div> */}
        <div className="divider"></div>
        {/* <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Heading 1"}
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 1 }).run()
          }
        >
          <RiH1 />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Heading 2"}
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 2 }).run()
          }
        >
          <RiH2 />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Heading 3"}
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 3 }).run()
          }
        >
          <RiH3 />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Heading 4"}
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 4 }).run()
          }
        >
          <RiH4 />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Heading 5"}
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 5 }).run()
          }
        >
          <RiH5 />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Heading 6"}
          className="icon"
          onClick={() =>
            editor.chain().focus().toggleHeading({ level: 6 }).run()
          }
        >
          <RiH6 />
        </div> */}
        <div>
          <select
            className="font-family-select px-2 py-1 rounded border border-gray-700 text-xs w-16 !max-h-[30px]"
            onChange={(e) =>
              editor.chain().focus().setFontSize(e.target.value).run()
            }
            value={editor.getAttributes("textStyle").fontSize || "16px"}
          >
            <option value="8px">8</option>
            <option value="10px">10</option>
            <option value="12px">12</option>
            <option value="14px">14</option>
            <option value="16px">16</option>
            <option value="18px">18</option>
            <option value="20px">20</option>
            <option value="24px">24</option>
            <option value="28px">28</option>
            <option value="32px">32</option>
            <option value="36px">36</option>
          </select>
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Paragraph"}
          className="icon"
          onClick={() => editor.chain().focus().setParagraph().run()}
        >
          <RiParagraph />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Ordered List"}
          className="icon"
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
        >
          <RiListOrdered />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Unordered List"}
          className="icon"
          onClick={() => editor.chain().focus().toggleBulletList().run()}
        >
          <RiListUnordered />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Image"}
          className={classNames("icon", { disabled: !editor.can().setImage() })}
          onClick={() => openImageUpladModal()}
        >
          <RiImageLine />
        </div>
        <div className="divider"></div>
        {/* Text Align */}
        <select
          className="font-family-select px-2 py-1 rounded border border-gray-700 text-xs !max-h-[30px] pr-8"
          onChange={(e) =>
            editor.chain().focus().setFontFamily(e.target.value).run()
          }
          value={editor.getAttributes("textStyle").fontFamily || ""}
        >
          <option value="">Default</option>
          <option value="Arial">Arial</option>
          <option value="Georgia">Georgia</option>
          <option value="Impact">Impact</option>
          <option value="Tahoma">Tahoma</option>
          <option value="Times New Roman">Times New Roman</option>
          <option value="Verdana">Verdana</option>
        </select>
        <div className="divider"></div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Align Left"}
          className={classNames(
            "icon",
            editor.isActive("textAlign", "left") ? "is-active" : "",
          )}
          onClick={() => editor.chain().focus().setTextAlign("left").run()}
        >
          <RiAlignLeft />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Align Center"}
          className={classNames(
            "icon",
            editor.isActive("textAlign", "center") ? "is-active" : "",
          )}
          onClick={() => editor.chain().focus().setTextAlign("center").run()}
        >
          <RiAlignCenter />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Align Right"}
          className={classNames(
            "icon",
            editor.isActive("textAlign", "right") ? "is-active" : "",
          )}
          onClick={() => editor.chain().focus().setTextAlign("right").run()}
        >
          <RiAlignRight />
        </div>
        <div className="divider"></div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Link"}
          className="icon"
          onClick={() => setShowAddLinkModal(true)}
        >
          <RiLink />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Unlink"}
          className={classNames("icon", { disabled: !isCursorOverLink })}
          onClick={() =>
            editor.chain().focus().extendMarkRange("link").unsetLink().run()
          }
        >
          <RiLinkUnlink />
        </div>
        <div className="divider"></div>
        {/* <div
                    className="icon"
                    onClick={() => editor.chain().focus().toggleBlockquote().run()}>
                    <RiDoubleQuotesL />
                </div> */}
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Horizontal Rule"}
          className="icon"
          onClick={() => editor.chain().focus().setHorizontalRule().run()}
        >
          <RiSeparator />
        </div>
        {/* <div className="divider"></div> */}
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Hard Break"}
          className="icon"
          onClick={() => editor.chain().focus().setHardBreak().run()}
        >
          <RiTextWrap />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Clear Formatting"}
          className="icon"
          onClick={() =>
            editor.chain().focus().unsetAllMarks().clearNodes().run()
          }
        >
          <RiFormatClear />
        </div>
        <div className="divider"></div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Undo"}
          className="icon"
          onClick={() => editor.chain().focus().undo().run()}
        >
          <RiArrowGoBackLine />
        </div>
        <div
          data-tooltip-id="tooltip"
          data-tooltip-content={"Redo"}
          className="icon"
          onClick={() => editor.chain().focus().redo().run()}
        >
          <RiArrowGoForwardLine />
        </div>
      </div>
    </div>
  );
}

export { Toolbar };
