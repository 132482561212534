import React, { useEffect, useState } from "react";
const core = require("../../../../../lib/core");
import { useParams } from "react-router-dom";
import InitialsLogo from "../../../../all/InitialsLogo";
import FeedbackIndividual from "../FeedbackIndividual";
import ProgressBar from "../../../../analytics/ProgressBar";
import moment from "moment";
import parse from "html-react-parser";
import LogoTxt from "../../../../assets/recruiter-text.png";
import LogoImg from "../../../../assets/recruiter-logo.png";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { ScaleLoader } from "react-spinners";

function parseHtml(html) {
  const parsedHtml = parse(html.split("&lt;").join("<"));
  return parsedHtml;
}
function ExpandableText({ children, maxChar = 150, classes }) {
  const [expand, setExpand] = useState(false);

  useEffect(() => {}, [expand]);
  return (
    children && (
      <p className={`${classes} w-11/12 break-words `}>
        {children?.length <= maxChar ? (
          children
        ) : expand ? (
          <>
            <span>{children}</span>
            <button
              className="ml-2 p-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(false)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M5 14H10V19M19 10H14V5"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        ) : (
          <>
            <span>{String(children).substring(0, maxChar)} ...</span>
            <button
              className="ml-2 px-1 rounded hover:bg-gray-300"
              onClick={() => setExpand(true)}
            >
              <svg width="16" height="16" viewBox="0 0 24 24" fill="none">
                <path
                  d="M10 19H5V14M14 5H19V10"
                  stroke="#252E4B"
                  strokeWidth="3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </>
        )}
      </p>
    )
  );
}

const TruncatedDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 200; // Adjust this value to get roughly 3-4 lines

  if (description.length <= maxLength) {
    return <p className="mt-2 text-gray-700 text-justify">{description}</p>;
  }

  return (
    <div className="mt-2">
      <p className="text-gray-700">
        {isExpanded ? description : `${description.slice(0, maxLength)}...`}
      </p>
      <button
        className="text-blue-600 hover:text-blue-800 text-justify text-sm mt-1 focus:outline-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? "Read less" : "Read more"}
      </button>
    </div>
  );
};
const InvitedFeedback = () => {
  const { token } = useParams(); // Extract token from the URL
  console.log("Token is: ", token);

  const [jobId, setJobId] = useState("");
  const [appId, setAppId] = useState("");
  const [candidate, setCandidateData] = useState({});
  const [activeTabName, setAvtiveTabName] = useState("Feedback");
  const btnList = [{ ButtonName: "Resume" }, { ButtonName: "Feedback" }];
  const [summary, setSummary] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [isloadingForDetail, setIsLoadingForDetail] = useState(false);

  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  useEffect(() => {
    // Function to verify token
    const verifyToken = async () => {
      setIsLoading(true);
      try {
        const json = await core.API(
          core.API_METHODS.GET,
          `${core.RECRUITER_API.GET_INVITE_FOR_FEEDBACK}${token}`,
          1,
        );
        console.log("Token Verification Response: ", json);
        if (json?.data) {
          // Assuming the API response contains jobId and appId
          setJobId(json?.data?.jobId);
          setAppId(json?.data?.matchProfileId);
        }
      } catch (error) {
        console.error("Error verifying token: ", error);
      } finally {
        setIsLoading(false);
      }
    };

    // Call verifyToken if token exists
    if (token) {
      verifyToken();
    }
  }, [token]);

  useEffect(() => {
    const GetDetails = async () => {
      setIsLoadingForDetail(true);
      try {
        const json = await core.API(
          core.API_METHODS.GET,
          core.RECRUITER_API.GET_JOB_MATCHPROFILE_DETAILS + appId,
          1,
        );

        if (json) {
          setCandidateData(json.data);
          setSummary(parseHtml(json.data?.matchProfile?.summary));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setIsLoadingForDetail(false);
      }
    };
    if (jobId && appId) {
      GetDetails();
    }
  }, [jobId, appId]);

  console.log("Candidate", candidate);
  const criterias = candidate?.matchProfile?.criteriaLevel;
  const pipelineStages = candidate?.matchProfile?.pipeline;
  return (
    <div className="min-h-screen bg-gray-50">
      <header className="bg-white shadow-sm px-6 py-4">
        <div className="flex items-center space-x-4">
          <img
            width={48}
            height={48}
            className="w-12 cursor-pointer"
            src={LogoImg}
            alt="Logo"
            onClick={() => nav("/")}
          />
          <img
            width={119}
            height={42}
            className="w-24 cursor-pointer"
            src={LogoTxt}
            alt="Logo Text"
            onClick={() => nav("/")}
          />
        </div>
      </header>
      {isloading || isloadingForDetail ? (
        <div className="flex justify-center items-center min-h-screen">
          <ScaleLoader height={36} color="#1563E9" loading={true} />
        </div>
      ) : (
        <div className="container mx-auto px-4 py-3">
          <div className="flex flex-col lg:flex-row gap-3">
            <div className="flex flex-col items-start p-4  shadow-md bg-white  mr-0.5  w-[50%] h-screen overflow-y-auto">
              <div className="flex items-center">
                <InitialsLogo
                  str={candidate?.matchProfile?.first_name}
                  bgRounded={"50%"}
                />
                <div className="mx-2 p-2 flex flex-col items-start">
                  <div className="flex items-center gap-2">
                    <h2 className="text-xl font-semibold">
                      {candidate?.matchProfile?.first_name}{" "}
                      {candidate?.matchProfile?.last_name}{" "}
                    </h2>
                    <div className="flex items-center gap-2 ">
                      <span className="inline cursor-pointer  py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[12px] not-italic font-normal text-blue-700">
                        {candidate?.matchProfile?.current_source
                          ? candidate?.matchProfile?.current_source
                          : null}
                      </span>
                      <span className="inline cursor-pointer py-[2px] px-[6px] bg-gray-300 rounded-[4px]  text-[12px] not-italic font-normal text-gray-700">
                        {candidate?.matchProfile?.current_stage
                          ? candidate?.matchProfile?.current_stage
                          : null}
                      </span>
                      <div className="flex ">
                        {candidate?.matchProfile?.linkedin?.length > 0 && (
                          <>
                            {candidate?.matchProfile?.linkedin?.toLowerCase() !=
                              "not specified" && (
                              <a
                                className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                                href={candidate?.matchProfile?.linkedin}
                                target="_blank"
                                rel="noreferrer"
                                data-tooltip-id="my-tooltip-4"
                                data-tooltip-content={"Linkedin"}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                                    fill="#5F6989"
                                  />
                                </svg>
                              </a>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <p className="text-gray-600">
                    {candidate?.matchProfile?.title || "No title"}
                  </p>
                </div>
              </div>
              <div className="flex flex-col justify-between items-start mb-4  bg-white rounded-md pb-4 my-1 mt-4">
                {candidate?.matchProfile?.summary?.length > 0 && (
                  <div className="w-full pr-6">
                    <h2 className="text-base my-2 font-medium text-[#16182f]">
                      Summary
                    </h2>
                    <p className="text-sm text-justify prose !min-w-full">
                      {summary}
                    </p>
                  </div>
                )}
                <div className="">
                  {candidate?.matchProfile?.education?.length > 0 ? (
                    <>
                      <div className="flex justify-start items-stretch flex-col mt-6">
                        <h2 className="text-base my-2 font-medium text-[#16182f]">
                          Education
                        </h2>

                        {candidate?.matchProfile?.education?.map(
                          (education, i) => (
                            <div
                              className="grid grid-cols-[50px_1fr]  gap-x-2 gap-y-1 mt-3"
                              key={"education" + i}
                            >
                              <InitialsLogo
                                width="50px"
                                height="50px"
                                fontSize="24px"
                                styleClass="rounded-xl border border-[#e1e1ee] row-span-4"
                                str={education?.school}
                              />
                              <p className="col-start-2 text-base font-semibold text-[#252e4b]">
                                {education?.degree}
                              </p>
                              <p className="col-start-2 text-sm text-[#1369e9]">
                                {education?.school}
                              </p>
                            </div>
                          ),
                        )}
                      </div>
                    </>
                  ) : null}

                  {candidate?.matchProfile?.experience?.length > 0 ? (
                    <div className="flex justify-start items-stretch flex-col mb-6 gap-5 mt-6 pr-4">
                      <h2 className="text-base my-2 font-medium text-[#16182f]">
                        Work Experience
                      </h2>
                      {/* Loop starts */}
                      {candidate?.matchProfile?.experience?.map(
                        (exp, index) => (
                          <div key={index} className="mb-8 flex">
                            <div className="flex flex-col items-center mr-6">
                              <div className="w-4 h-4 bg-gray-600 rounded-full shadow-sm"></div>
                              {index !==
                                (candidate?.matchProfile?.experience).length -
                                  1 && (
                                <div className="w-0.5 h-full bg-gray-300 transform translate-x-1/2"></div>
                              )}
                            </div>
                            <div className="bg-gray-50 p-5 rounded-lg shadow-md flex-grow transition-all duration-300 hover:shadow-lg">
                              <div className="flex justify-between items-start mb-3">
                                <div>
                                  <h4 className="font-semibold text-base ">
                                    {exp?.position}
                                  </h4>
                                  <p className="text-gray-700 font-medium">
                                    {exp?.company}
                                  </p>
                                </div>
                                <p className="text-sm text-gray-600 bg-gray-200 px-3 py-1 rounded-full">
                                  {moment(exp?.start).format("MMM YYYY")} -
                                  {exp?.ongoing
                                    ? "Present"
                                    : moment(exp.end).format("MMM YYYY")}
                                </p>
                              </div>
                              {exp?.description && (
                                <TruncatedDescription
                                  description={exp?.description}
                                />
                              )}
                              {exp?.skills && exp?.skills.length > 0 && (
                                <div className="mt-4">
                                  <p className="font-semibold text-gray-700 mb-2 ">
                                    Skills:
                                  </p>
                                  <div className="flex flex-wrap gap-2">
                                    {exp.skills.map((skill, skillIndex) => (
                                      <span
                                        key={skillIndex}
                                        className="bg-gray-300 text-gray-800 text-sm px-3 py-1 rounded-lg"
                                      >
                                        {skill}
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        ),
                      )}
                    </div>
                  ) : null}
                </div>

                <div className="h-full w-full overflow-y-auto pb-4 mb-6">
                  {candidate?.matchProfile?.skills?.length > 0 && (
                    <div
                      className="w-full  mb-4 relative  rounded-md"
                      id="candidateAnalytics"
                    >
                      <h4 className="text-[#16182F] my-2 text-[16px] not-italic font-medium">
                        Skills
                      </h4>
                      <div className="w-full">
                        {candidate?.matchProfile?.skills?.length > 0 &&
                          candidate?.matchProfile?.skills?.map((data, it) => (
                            <div
                              className="w-full mb-2.5 bg-white p-4 rounded-md"
                              key={it}
                            >
                              <h4 className="text-gray-700 text-sm not-italic font-medium mb-1">
                                {checkLabel(data?.criteria, data?.label)}
                              </h4>
                              <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                                {checkLabel(data?.skills, data?.keySkills)?.map(
                                  (skill, i) => (
                                    <div
                                      className=" flex last-of-type:pb-0 pb-2 items-center ml-0"
                                      key={i}
                                    >
                                      <span
                                        className="w-[45%] text-[#252E4B] text-[14px] not-italic leading-7 truncate overflow-hidden  mr-[10px]"
                                        onMouseOver={(e) => {
                                          var overflowed =
                                            e.target.scrollWidth >
                                            e.target.clientWidth;
                                          e.target.title = overflowed
                                            ? e.target.textContent
                                            : "";
                                        }}
                                        onMouseOut={(e) =>
                                          (e.target.title = "")
                                        }
                                      >
                                        {skill?.skills}
                                      </span>
                                      <span className="w-[45%]  mr-[10px]">
                                        <ProgressBar
                                          id={"skills-bar" + it + i}
                                          style_classes="w-full"
                                          data={Math.abs(skill?.score)}
                                          height={8}
                                          barColor={"teal"}
                                          bgColor="#c3c5c7"
                                          width={100 * 0.45 - 10}
                                          fromRight={skill?.score < 0}
                                        />
                                      </span>
                                      <span style={{ width: 20 }}>
                                        {Math.floor(skill?.score)}
                                      </span>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="flex-1 flex flex-col shadow-md bg-white py-4 px-2  overflow-y-auto">
              <div className="flex gap-6 ml-2">
                {btnList?.map((btn) => (
                  <button
                    onClick={() => {
                      setAvtiveTabName(btn.ButtonName);
                    }}
                    className={`text-md font-medium pt-2 pb-2.5 border-t-0 border-b-2 border-x-0 ${
                      btn.ButtonName == activeTabName
                        ? "border-[#1369e9] text-[#16182f]"
                        : "border-transparent text-[#252e4b]"
                    } border-solid hover:border-blue-600`}
                  >
                    {btn?.ButtonName}
                  </button>
                ))}
              </div>
              <div className=" overflow-y-auto mb-2  scrollbar-hide">
                {activeTabName === "Resume" && (
                  <div
                    key={candidate?.applicant?._id}
                    className="w-full  relative p-2"
                    style={{ height: "calc(100vh - 160px)" }}
                  >
                    <DocViewer
                      className="scrollbar-candidate"
                      style={{
                        borderColor: "lightgray",
                        overflowY: "scroll",
                        borderRadius: 10,
                        borderWidth: 1,
                        // minHeight: 500,
                      }}
                      config={{
                        header: {
                          disableHeader: true,
                          disableFileName: true,
                          retainURLParams: true,
                        },
                        noRenderer: {
                          overrideComponent: () => (
                            <div className="w-full h-full flex justify-center items-center">
                              Failed to load
                            </div>
                          ),
                        },
                        pdfZoom: {
                          defaultZoom: 1, // 1 as default,
                        },
                      }}
                      documents={[
                        {
                          uri: candidate?.matchProfile?.resume_link,
                        },
                      ]}
                      pluginRenderers={DocViewerRenderers}
                    />
                  </div>
                )}
                {activeTabName == "Feedback" && (
                  <div
                    className="w-full  relative "
                    style={{ height: "calc(100vh - 170px)" }}
                  >
                    <FeedbackIndividual
                      jobId={jobId}
                      appId={appId}
                      criterias={criterias}
                      pipelineStages={pipelineStages}
                      invited={true}
                      fromInvite={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default InvitedFeedback;
