import React, { useState, useContext, useRef, useEffect, useMemo } from "react";
import { UserContext } from "../lib/core";
import { Tooltip as ReactTooltip } from "react-tooltip";
import Modal from "../components/modal/Modal";
import { getPermissionDescription } from "../lib/permissionConstants";

const ProtectedComponent = ({
  code = "no_code",
  children = null,
  fallback = null,
  message = "You don't have permission to perform this action",
  customMessage = "",
  tooltipProps = {},
  wrapperProps = {},
  showModal = false,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const tooltipId = `locked-tooltip-${Math.random().toString(36).substr(2, 9)}`;
  const id = "protected-component-modal";
  const { user } = useContext(UserContext);

  const trimmedCode = code.trim();

  // Always create the memoized Set of resources
  const resourceSet = useMemo(
    () => new Set(user?.resources),
    [user?.resources],
  );

  // Always check permission using the Set
  const isLocked = useMemo(
    () => trimmedCode !== "no_code" && !resourceSet.has(trimmedCode),
    [resourceSet, trimmedCode],
  );

  const modalProps = useMemo(
    () => ({
      description: getPermissionDescription(trimmedCode),
      requiredPermission: trimmedCode,
    }),
    [trimmedCode],
  );

  if (!isLocked || trimmedCode === "no_code") {
    return <>{children}</>;
  }

  const defaultMessage = "You don't have permission to perform this action";
  const tooltipMessage = message || defaultMessage;

  const handleClick = () => {
    console.log("checkResource in protected  showmodal, ");

    if (showModal) {
      console.log("checkResource in protected  showmodal if block, ");

      setIsModalOpen(true);
    }
  };

  const lockedContent = (
    <div
      data-tip
      data-tooltip-id={tooltipId}
      className={`group relative ${wrapperProps?.className || ""}`}
      {...wrapperProps}
      onClick={handleClick}
    >
      <ReactTooltip
        id={tooltipId}
        style={{
          zIndex: 9999,
          background: "#5F6989",
          fontSize: "10px",
          padding: "4px 8px",
          fontWeight: "400",
          ...tooltipProps?.style,
        }}
        place={tooltipProps?.place || "bottom"}
        variant="info"
        content={tooltipMessage}
        {...tooltipProps}
      />
      <div
        className={`pointer-events-none ${showModal ? "cursor-pointer" : ""}`}
      >
        <div className={showModal ? "" : "opacity-70"}>
          {fallback || children}
        </div>
      </div>
    </div>
  );

  const handleModalDismiss = (e) => {
    console.log(e); // Check if the event is properly passed
    setIsModalOpen(!isModalOpen);
    e?.stopPropagation();
  };

  return (
    <>
      {lockedContent}
      {isModalOpen && (
        <Modal
          heading={modalProps.title || "Action Restricted"}
          noBtns={true}
          message={
            modalProps.description ||
            "You don't have permission to perform this action"
          }
          subheading="You don't have permission to perform this action"
          modalDismiss={(e) => handleModalDismiss(e)}
          className="protected-component-modal !max-w-md"
          id={id}
        >
          <div className="space-y-2 overflow-y-auto flex-grow pb-4">
            <div className="flex justify-between w-full">
              <span className="font-semibold w-1/3">Description</span>
              <span className="w-2/3">
                {customMessage.length !== 0
                  ? customMessage
                  : modalProps.description ||
                    "You don't have permission to perform this action"}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="font-semibold w-1/3">Required Permission</span>
              <span className="w-2/3">
                {modalProps.requiredPermission || "Required permission"}
              </span>
            </div>
            <div className="flex justify-between items-center">
              <span className="font-semibold w-1/3">Status</span>
              <span className="text-red-500 flex items-center w-2/3">
                <svg
                  className="w-4 h-4 mr-1"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                    clipRule="evenodd"
                  />
                </svg>
                {modalProps.status || "Restricted"}
              </span>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};

export default ProtectedComponent;
