import React from "react";
import Modal from "../../modal/Modal";
import InputBox from "../../../layouts/InputBox";

const AddLinkModal = ({ onSave, modalDismiss, editor }) => {
  const [link, setLink] = React.useState(editor.getAttributes("link").href);

  const handleSave = () => {
    onSave(link);
  };

  return (
    <Modal
      heading={"Add Link"}
      subheading={"Add a link to the text"}
      onSave={handleSave}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryDisabled: !link,
        primaryLabel: "Save",
      }}
    >
      <div className="flex flex-col">
        <label>Link</label>
        <InputBox
          type="text"
          value={link}
          onChange={(e) => setLink(e.target.value)}
          className="border p-2 rounded"
        />
      </div>
    </Modal>
  );
};

export default AddLinkModal;
