import Skeleton from "react-loading-skeleton";
import { ScaleLoader } from "react-spinners";
import { useState, useEffect, useCallback, useRef, useContext } from "react";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { fetchJobPostList } from "../../../../redux/services";
import { toast } from "react-toastify";
import { Tooltip } from "react-tooltip";
import ReactDOMServer from "react-dom/server";
import { FaSortAmountDown } from "react-icons/fa";
import { BsSortAlphaDown } from "react-icons/bs";
import { BsSortAlphaDownAlt } from "react-icons/bs";
import { RiSortNumberAsc } from "react-icons/ri";
import { BsSortNumericDown } from "react-icons/bs";
import { BsSortNumericDownAlt } from "react-icons/bs";
import { IoRadio } from "react-icons/io5";
import { TbLayoutNavbarInactive } from "react-icons/tb";
import { TbFilterCog } from "react-icons/tb";
import { RiDraftLine } from "react-icons/ri";
import { HiOutlineEyeOff } from "react-icons/hi";
import { FaRegFileArchive } from "react-icons/fa";
import { HiOutlineArchiveBoxArrowDown } from "react-icons/hi2";
import { BsPinAngle } from "react-icons/bs";
import { BsFillPinAngleFill } from "react-icons/bs";
import { IoTimeOutline } from "react-icons/io5";

import SelectAndSearch from "../../../../layouts/SelectAndSearch";
import { CiStar } from "react-icons/ci";
import Modal from "../../../modal/Modal";
import TextAreaBox from "../../../../layouts/TextAreaBox";
import { useLocation } from "react-router-dom";
import { User, Check } from "lucide-react";
const core = require("../../../../lib/core");

function JobListSidebar({
  selectJob,
  params,
  setParams,
  isInitialLoad,
  ...props
}) {
  const dispatch = useDispatch();

  const {
    jobList,
    activeCount,
    unprocessedCount,
    draftCount,
    archivedCount,
    closedCount,
    pendingCount,
    organizationAccounts,
    sortField,
    sortOrder,
  } = useSelector((state) => {
    console.log("Redux State:", state.JobExpandSlice.jobList);

    return {
      jobList: state?.JobExpandSlice?.jobList?.content,
      activeCount: state?.JobExpandSlice?.jobList?.active,
      unprocessedCount: state?.JobExpandSlice?.jobList?.unprocessed,
      draftCount: state?.JobExpandSlice?.jobList?.draft,
      archivedCount: state?.JobExpandSlice?.jobList?.archived,
      closedCount: state?.JobExpandSlice?.jobList?.closed,
      pendingCount: state?.JobExpandSlice?.jobList?.pending,
      organizationAccounts:
        state?.JobExpandSlice?.jobList?.organizationAccounts,
      sortField: state?.JobExpandSlice?.jobList?.sortField,
      sortOrder: state?.JobExpandSlice?.jobList?.sortOrder,
    };
  });

  const [jobType, setJobType] = useState("active");
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [openMoveMenu, setOpenMoveMenu] = useState(false);
  const [accountOptions, setAccountOptions] = useState([]);
  const [showCandidates, setShowCandidates] = useState({});
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const menuRef = useRef(null);
  const buttonRef = useRef(null);
  const dropdownRef = useRef(null);
  // List of jobs
  const [jobs, setJobs] = useState([]);
  const [unprocessedJobs, setUnprocessedJobs] = useState([]);
  const [draftJobs, setDraftJobs] = useState([]);
  const [archivedJobs, setArchivedJobs] = useState([]);
  const [closedJobs, setClosedJobs] = useState([]);
  const [pendingJobs, setPendingJobs] = useState([]);
  // Has more jobs
  const [activeHasMore, setActiveHasMore] = useState(true);
  const [unprocessedHasMore, setUnprocessedHasMore] = useState(true);
  const [draftHasMore, setDraftHasMore] = useState(true);
  const [archivedHasMore, setArchivedHasMore] = useState(true);
  const [closedHasMore, setClosedHasMore] = useState(true);
  const [pendingHasMore, setPendingHasMore] = useState(true);
  // Loading state while pagination
  const [loading, setLoading] = useState("");
  const [reload, setReload] = useState(0);
  // Action - append or replace
  const [replace, setReplace] = useState(props?.replace ?? false);
  // Current sort state
  const [currentSortField, setCurrentSortField] = useState("");
  const [currentSortOrder, setCurrentSortOrder] = useState("");
  const [isSorting, setIsSorting] = useState(false);
  const [currentSort, setCurrentSort] = useState({ field: "", order: "" });
  const { user } = useContext(core.UserContext);
  const [displayValue, setDisplayValue] = useState("");
  const bottomNavItems = [
    { icon: IoRadio, name: "Active", status: "active" },
    { icon: TbLayoutNavbarInactive, name: "Closed", status: "closed" },
    { icon: TbFilterCog, name: "Unprocessed", status: "unprocessed" },
    { icon: HiOutlineEyeOff, name: "Draft", status: "draft" },
    {
      icon: HiOutlineArchiveBoxArrowDown,
      name: "Archived",
      status: "archived",
    },
    ...(user?.isJobRequisitionEnabled
      ? [{ icon: IoTimeOutline, name: "Pending", status: "pending" }]
      : []),
  ];

  const iconStyles = {
    Archived: "text-3xl font-extrabold py-[0.5px]",
    Closed: "text-3xl py-[0.5px] mt-[1px]",
    Draft: " py-[0.3px] mt-[1px] ",
  };

  const barBelowItems = [
    {
      name: "Active",
      list: jobs,
      count: activeCount ?? 0,
      hasMore: activeHasMore,
      loading: loading == "active",
    },
    {
      name: "Closed",
      list: closedJobs,
      count: closedCount ?? 0,
      hasMore: closedHasMore,
      loading: loading == "closed",
    },
    {
      name: "Unprocessed",
      list: unprocessedJobs,
      count: unprocessedCount ?? 0,
      hasMore: unprocessedHasMore,
      loading: loading == "unprocessed",
    },
    {
      name: "Draft",
      list: draftJobs,
      count: draftCount ?? 0,
      hasMore: draftHasMore,
      loading: loading == "draft",
    },
    {
      name: "Archived",
      list: archivedJobs,
      count: archivedCount ?? 0,
      hasMore: archivedHasMore,
      loading: loading == "archived",
    },
    {
      name: "Pending",
      list: pendingJobs,
      count: pendingCount ?? 0,
      hasMore: pendingHasMore,
      loading: loading == "pending",
    },
  ];

  const jobActions =
    // actions for draft
    jobType == "private" || jobType == "draft"
      ? [
          { name: "Publish job", tag: "active" },
          { name: "Close job", tag: "closed" },
          { name: "Archive job", tag: "archived" },
          { name: "Delete job", tag: "deleted" },
        ]
      : // actions for archived
        jobType == "closed"
        ? [
            { name: "Publish job", tag: "active" },
            { name: "Unpublish job", tag: "draft" },
            { name: "Archive job", tag: "archived" },
            { name: "Delete job", tag: "deleted" },
          ]
        : // actions for unprocessed
          jobType == "archived"
          ? [
              { name: "Publish job", tag: "active" },
              { name: "Unpublish job", tag: "draft" },
              { name: "Close job", tag: "closed" },
              { name: "Delete job", tag: "deleted" },
            ]
          : // actions for unprocessed
            jobType == "unprocessed"
            ? [
                { name: "Activate job", tag: "active" },
                { name: "Unpublish job", tag: "draft" },
                { name: "Close job", tag: "closed" },
                { name: "Delete job", tag: "deleted" },
              ]
            : // actions for active
              [
                { name: "Unpublish job", tag: "draft" },
                { name: "Archive job", tag: "archived" },
                { name: "Close job", tag: "closed" },
                { name: "Delete job", tag: "deleted" },
              ];

  const appendJobList = (newJobs) => {
    if (!replace) {
      const updateJobList = (prevJobs) => {
        // Create a Set of existing job IDs for quick lookup
        const existingIds = new Set(prevJobs.map((job) => job.id));

        // Filter out any new jobs that already exist in the list
        const uniqueNewJobs = newJobs.filter((job) => !existingIds.has(job.id));

        const updatedJobs = [...prevJobs, ...uniqueNewJobs];

        // Sort the combined list if a sort option is active
        if (currentSortField) {
          updatedJobs.sort((a, b) => {
            if (currentSortField === "name") {
              return currentSortOrder === "asc"
                ? a.position.localeCompare(b.position)
                : b.position.localeCompare(a.position);
            } else if (currentSortField === "createdAt") {
              return currentSortOrder === "desc"
                ? new Date(a.createdAt) - new Date(b.createdAt)
                : new Date(b.createdAt) - new Date(a.createdAt);
            }
            return 0;
          });
        }
        return updatedJobs;
      };

      if (newJobs?.length > 0) {
        switch (newJobs[0]?.status) {
          case "active":
            setJobs(updateJobList);
            break;
          case "unprocessed":
            setUnprocessedJobs(updateJobList);
            break;
          case "draft":
            setDraftJobs(updateJobList);
            break;
          case "archived":
            setArchivedJobs(updateJobList);
            break;
          case "closed":
            setClosedJobs(updateJobList);
            break;
          case "pending":
            setPendingJobs(updateJobList);
            break;
          case "approved":
            setPendingJobs(updateJobList);
            break;
          case "rejected":
            setPendingJobs(updateJobList);
            break;
        }
      }
    }
  };

  const handleSort = (sortField, sortOrder) => {
    setCurrentSortField(sortField);
    setCurrentSortOrder(sortOrder);
    setReplace(true);
    setIsSorting(true);
    setCurrentSort({ field: sortField, order: sortOrder });

    const payload = {
      status: jobType,
      page: 1,
      sortField,
      sortOrder,
    };

    if (selectedTemplate) {
      payload.account = selectedTemplate; // This will be the ID
    }

    dispatch(fetchJobPostList(payload))
      .then(() => {
        setIsSorting(false);
      })
      .catch((error) => {
        console.error("Error fetching job list:", error);
        setIsSorting(false);
      });

    setSortDropdownIndex(null);
  };

  const handleSelect = (selectedId) => {
    const selectedAccount = accountOptions.find(
      (account) => account.id === selectedId,
    );

    if (selectedAccount) {
      setSelectedTemplate(selectedId); // Store ID for API calls
      setDisplayValue(selectedAccount.name);
      setReplace(true);
      setIsSorting(true);
      setJobs([]);
      setUnprocessedJobs([]);
      setDraftJobs([]);
      setArchivedJobs([]);
      setClosedJobs([]);

      const payload = {
        status: jobType,
        page: 1,
        sortField: currentSortField,
        sortOrder: currentSortOrder,
        account: selectedId,
      };

      dispatch(fetchJobPostList(payload))
        .then(() => {
          setIsSorting(false);
        })
        .catch((error) => {
          console.error("Error fetching job list:", error);
          setIsSorting(false);
        });
    }
  };

  const replaceJobList = (newJobs) => {
    if (newJobs.length > 0) {
      // Ensure uniqueness when replacing
      const uniqueJobs = Array.from(
        new Map(newJobs.map((job) => [job.id, job])).values(),
      );

      switch (uniqueJobs[0]?.status) {
        case "active":
          setJobs(uniqueJobs);
          break;
        case "unprocessed":
          setUnprocessedJobs(uniqueJobs);
          break;
        case "draft":
          setDraftJobs(uniqueJobs);
          break;
        case "archived":
          setArchivedJobs(uniqueJobs);
          break;
        case "closed":
          setClosedJobs(uniqueJobs);
          break;
        case "pending":
          setPendingJobs(uniqueJobs);
          break;
        case "approved":
          setPendingJobs(uniqueJobs);
          break;
        case "rejected":
          setPendingJobs(uniqueJobs);
          break;
      }
    }
  };

  useEffect(() => {
    if (jobList) {
      if (jobList?.length > 0) {
        if (replace) {
          replaceJobList(jobList);
        } else {
          appendJobList(jobList);
        }
        setReplace(false);
        setIsSorting(false); // Reset sorting state
      } else if (jobList?.length === 0) {
        setHasMore(false);
        setIsSorting(false); // Reset sorting state
      }
    }

    console.log(pendingJobs);

    setLoading("");

    if (!params.jobId && jobList != null) {
      setParams({
        jobType: jobList[0]?.status ?? "active",
        jobId: jobList[0]?.id,
        tab: "description",
      });
      selectJob(jobList[0]?.id, jobList[0]?.status);
      $("#job_details_page .list-item")[0]?.classList?.add("active");
    }
  }, [jobList]);

  useEffect(() => {
    setJobType(params?.jobType);
    getActiveJobsbyPage(params?.jobType ?? "active");
  }, [reload]);

  const getActiveJobsbyPage = async (status) => {
    setLoading(status);
    try {
      let jobsLength = getJobsLength(status);
      if (jobsLength % 20 != 0) {
        setLoading("");
        setHasMore(false);
        return;
      }
      const payload = {
        status: status,
        page: Math.round(jobsLength / 20) + 1,
        sortField: currentSortField,
        sortOrder: currentSortOrder,
      };

      if (selectedTemplate) {
        payload.account = selectedTemplate;
      }

      dispatch(fetchJobPostList(payload)).then(() => {
        setIsSorting(false); // Reset sorting state when data is received
      });
    } catch (error) {
      console.error(error);
      setLoading("");
    }
  };

  const getJobsLength = (status) => {
    switch (status) {
      case "active":
        return jobs.length;
      case "closed":
        return closedJobs.length;
      case "unprocessed":
        return unprocessedJobs.length;
      case "draft":
        return draftJobs.length;
      case "archived":
        return archivedJobs.length;
      case "pending":
        return pendingJobs.length;
      default:
        return 0;
    }
  };

  const setHasMore = (value) => {
    switch (jobType) {
      case "active":
        setActiveHasMore(value);
        break;
      case "closed":
        setClosedHasMore(value);
        break;
      case "unprocessed":
        setUnprocessedHasMore(value);
        break;
      case "draft":
        setDraftHasMore(value);
        break;
      case "archived":
        setArchivedHasMore(value);
        break;
      case "pending":
        setPendingHasMore(value);
        break;
      case "approved":
        setPendingHasMore(value);
        break;
      case "rejected":
        setPendingHasMore(value);
        break;
    }
  };

  const jobSelection = (id) => {
    setSelectedJobs((prev) =>
      prev.includes(id) ? prev.filter((jobId) => jobId != id) : [...prev, id],
    );
  };

  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [reason, setReason] = useState("");
  const [statusToChange, setStatusToChange] = useState(null);

  const handleJobAction = (action) => {
    if (action.tag === "closed" || action.tag === "deleted") {
      setStatusToChange(action.tag);
      setOpenCloseModal(true);
    } else {
      changeStatus(action.tag);
    }
  };

  const changeStatus = async (status, reason = "") => {
    setJobs([]);
    setUnprocessedJobs([]);
    setDraftJobs([]);
    setClosedJobs([]);
    setArchivedJobs([]);
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_JOB_STATUS,
        1,
        {
          jobIds: selectedJobs,
          status: status,
          reason: reason,
        },
      );
      if (json.data) {
        setReplace(true);
        setSelectedJobs([]);
        setReason("");
        setOpenMoveMenu(false);
        dispatch(
          fetchJobPostList({
            status: jobType,
            page: 1,
            sortField: currentSortField,
            sortOrder: currentSortOrder,
          }),
        );

        setReload((prev) => prev + 1);
        toast.success(getSuccessMessage(status));
        selectJob(jobList[0]?.id, status);
      }
    } catch (error) {
      setOpenMoveMenu(false);
      setReload((prev) => prev + 1);
      console.error(error);
    }
  };
  const handleReset = () => {
    // Reset all state values
    setSelectedTemplate(null);
    setDisplayValue("");
    setCurrentSort({ field: "", order: "" });
    setCurrentSortField("");
    setCurrentSortOrder("");
    setSortDropdownIndex(null);

    // Make API call with reset parameters
    const payload = {
      status: jobType,
      page: 1,
    };

    dispatch(fetchJobPostList(payload))
      .then(() => {
        setIsSorting(false);
      })
      .catch((error) => {
        console.error("Error fetching job list:", error);
        setIsSorting(false);
      });
  };

  const getSuccessMessage = (status) => {
    switch (status) {
      case "deleted":
        return "Job(s) is successfully deleted";
      case "archived":
        return "Job(s) now successfully moved to archive";
      case "closed":
        return "Job(s) now successfully closed";
      case "active":
        return "Job(s) now successfully actively published";
      default:
        return "Job(s) now made private and moved to drafts";
    }
  };

  const fetchJobs = useCallback(() => {
    dispatch(
      fetchJobPostList({
        status: jobType,
        page: 1,
        sortField: currentSortField,
        sortOrder: currentSortOrder,
        ...(selectedTemplate && { account: selectedTemplate }),
      }),
    );
  }, [dispatch, jobType, currentSortField, currentSortOrder]);

  // useEffect(() => {
  //   fetchJobs();
  // }, [jobType, currentSortField, currentSortOrder]);

  const toggleFavorite = async (jobId, currentSavedStatus) => {
    try {
      const newSavedStatus = currentSavedStatus === 1 ? 0 : 1;

      // Optimistic update
      const updateJobLists = (updateFn) => {
        setJobs(updateFn);
        setUnprocessedJobs(updateFn);
        setDraftJobs(updateFn);
        setArchivedJobs(updateFn);
        setClosedJobs(updateFn);
        setPendingJobs(updateFn);
      };

      updateJobLists((jobList) =>
        jobList?.map((job) =>
          job.id === jobId ? { ...job, isSaved: newSavedStatus } : job,
        ),
      );

      const response = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_TOGGLE_FAVORITE + jobId,
        1,
        { jobId, isSaved: newSavedStatus },
      );

      if (response.data) {
        toast.success("Favorite status updated successfully");
        // fetchJobs(); // Fetch updated data after successful toggle
      } else {
        throw new Error("Failed to update favorite status");
      }
    } catch (error) {
      console.error("Error toggling favorite:", error);
      toast.error("Failed to update favorite status");

      // Revert the optimistic update
      updateJobLists((jobList) =>
        jobList.map((job) =>
          job.id === jobId ? { ...job, isSaved: currentSavedStatus } : job,
        ),
      );
    }
  };

  const [sortDropdownIndex, setSortDropdownIndex] = useState(null);

  const location = useLocation();

  const activebar = location?.pathname?.split("/")?.[2] ?? "active";

  useEffect(() => {
    // find the index of the activebar
    setSelectedJobs([]);
    if (jobType === String(activebar).toLowerCase()) {
      setJobType("active");
    } else {
      setJobType(String(activebar).toLowerCase());
      getActiveJobsbyPage(String(activebar).toLowerCase());
    }
  }, [activebar]);

  console.log(jobType, activebar);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if the click is outside the dropdown
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setSortDropdownIndex(null);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => document.removeEventListener("click", handleClickOutside);
  }, []);

  const handleClickOutside = (event) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target)
    ) {
      setOpenMoveMenu(false);
    }
  };

  useEffect(() => {
    if (openMoveMenu) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [openMoveMenu]);
  // console.log(jobs);

  const getEmails = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER_LIST_ORG,
        1,
      );
      if (json?.data) {
        const options = json.data.map((account) => ({
          name: account.email,
          id: account._id,
        }));

        const dropdownOptions = [
          // { name: "All Jobs", id: "all-jobs" },
          { name: "My jobs", id: user.id },
          ...options,
        ];
        setAccountOptions(dropdownOptions);
      }
    } catch (error) {
      console.error("Error fetching emails:", error);
    }
  };

  // const accountOptions = organizationAccounts?.map(account => ({
  //   name: account.email,
  //   id: account._id,
  // })) || [];

  return (
    <div className="mt-2 h-[calc(100vh - 104px)] grow flex flex-col">
      <ul
        id="menu-list"
        className={`relative block w-full left-0 divide-y grow`}
      >
        {barBelowItems
          ?.filter((item) => String(item.name).toLowerCase() === jobType) // Only show the selected job type
          ?.map((item, i) => (
            <>
              <li
                key={`item-${i}`}
                style={{ bottom: 42 * (4 - i) - 21 }}
                className={`block sticky border-gray-400 bg-gray-100`}
              >
                <button
                  className={`py-[4px] relative pl-[8px] min-h-[42px] w-full flex items-center text-left justify-between border-b border-gray-200`}
                >
                  <span className="flex items-center gap-2 w-full justify-between">
                    <div className="flex gap-2">
                      <span
                        className={`inline-block text-[14px] not-italic font-normal text-gray-800`}
                      >
                        {item?.name}
                      </span>
                      <span
                        className={`inline-block text-[14px] not-italic font-normal text-gray-800`}
                      >
                        {"(" + item.count + ")"}
                      </span>
                    </div>
                    <div
                      id="sorticon"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (user?.isFilterJobs) getEmails();
                        if (jobType === String(item?.name).toLowerCase()) {
                          setSortDropdownIndex(
                            sortDropdownIndex === i ? null : i,
                          );
                        }
                      }}
                      className="flex ml-auto mr-1 hover:scale-125 transition-all duration-300 hover:bg-gray-300 rounded-md"
                    >
                      <FaSortAmountDown className="text-gray-700 text-base m-1.5 pb-[2px]" />
                    </div>
                  </span>

                  {sortDropdownIndex === i && (
                    <div className="absolute right-0.5 top-[40px]  bg-white shadow-lg border border-gray-200 rounded-md w-[225px]">
                      <ul className="py-1 space-y-1">
                        <li className="flex items-center px-2 py-2 hover:bg-gray-300 cursor-pointer border-b-2">
                          <span className="flex-grow">Sort by Name</span>
                          <div className="flex items-center space-x-4">
                            <div
                              className={`flex items-center justify-center w-6 h-6 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                                currentSort.field === "name" &&
                                currentSort.order === "asc"
                                  ? "bg-blue-100"
                                  : ""
                              }`}
                              onClick={() => handleSort("name", "asc")}
                            >
                              <BsSortAlphaDown className="text-gray-700 text-lg" />
                            </div>
                            <div
                              className={`flex items-center justify-center w-6 h-6 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                                currentSort.field === "name" &&
                                currentSort.order === "desc"
                                  ? "bg-blue-100"
                                  : ""
                              }`}
                              onClick={() => handleSort("name", "desc")}
                            >
                              <BsSortAlphaDownAlt className="text-gray-700 text-lg" />
                            </div>
                          </div>
                        </li>
                        <li className="flex items-center px-2 py-2 hover:bg-gray-300 cursor-pointer border-b-2">
                          <span className="flex-grow">Sort by Date</span>
                          <div className="flex items-center space-x-4">
                            <div
                              className={`flex items-center justify-center w-6 h-6 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                                currentSort.field === "createdAt" &&
                                currentSort.order === "asc"
                                  ? "bg-blue-100"
                                  : ""
                              }`}
                              onClick={() => handleSort("createdAt", "asc")}
                            >
                              <BsSortNumericDown className="text-gray-700 text-lg" />
                            </div>
                            <div
                              className={`flex items-center justify-center w-6 h-6 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                                currentSort.field === "createdAt" &&
                                currentSort.order === "desc"
                                  ? "bg-blue-100"
                                  : ""
                              }`}
                              onClick={() => handleSort("createdAt", "desc")}
                            >
                              <BsSortNumericDownAlt className="text-gray-700 text-lg" />
                            </div>
                          </div>
                        </li>

                        <li className="flex items-center px-2 py-2 hover:bg-gray-300 cursor-pointer ">
                          <span className="flex-grow">Sort by Location</span>
                          <div className="flex items-center space-x-4">
                            <div
                              className={`flex items-center justify-center w-6 h-6 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                                currentSort.field === "location" &&
                                currentSort.order === "asc"
                                  ? "bg-blue-100"
                                  : ""
                              }`}
                              onClick={() => handleSort("location", "asc")}
                            >
                              <BsSortAlphaDown className="text-gray-700 text-lg" />
                            </div>
                            <div
                              className={`flex items-center justify-center w-6 h-6 border border-gray-400 hover:bg-white hover:scale-125 rounded-md hover:shadow-md transition-all duration-300 ${
                                currentSort.field === "location" &&
                                currentSort.order === "desc"
                                  ? "bg-blue-100"
                                  : ""
                              }`}
                              onClick={() => handleSort("location", "desc")}
                            >
                              <BsSortAlphaDownAlt className="text-gray-700 text-lg" />
                            </div>
                          </div>
                        </li>
                        {user?.isFilterJobs && (
                          <li className="flex items-center px-2 py-2 hover:bg-gray-300 cursor-pointer">
                            {/* <span className="flex-grow">Sort by</span> */}
                            <div className="relative" ref={dropdownRef}>
                              <SelectAndSearch
                                placeholder="Select account"
                                defalultValue={displayValue}
                                data={accountOptions}
                                setSelectedTemplate={(id) => {
                                  handleSelect(id); // Handle selection
                                }}
                              />
                            </div>
                          </li>
                        )}

                        {/* <button
                          onClick={handleReset}
                          className="px-3 py-2 bg-blue-800 hover:bg-blue-800 text-white rounded-md flex items-center space-x-1 transition-all duration-300 border border-gray-300"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 2a1 1 0 011 1v2.101a7.002 7.002 0 0111.601 2.566 1 1 0 11-1.885.666A5.002 5.002 0 005.999 7H9a1 1 0 010 2H4a1 1 0 01-1-1V3a1 1 0 011-1zm.008 9.057a1 1 0 011.276.61A5.002 5.002 0 0014.001 13H11a1 1 0 110-2h5a1 1 0 011 1v5a1 1 0 11-2 0v-2.101a7.002 7.002 0 01-11.601-2.566 1 1 0 01.61-1.276z"
                              clipRule="evenodd"
                            />
                          </svg>
                          <span>Reset Filters</span>
                        </button> */}
                      </ul>
                    </div>
                  )}
                </button>
              </li>
              <div
                className={`transition-all duration-300 ${
                  jobType === String(item?.name).toLowerCase()
                    ? selectedJobs?.length > 0
                      ? "h-auto max-h-[calc(100vh-195px)]"
                      : "h-auto max-h-[calc(100vh-195px)]"
                    : "h-0"
                } scrollbar-hide overflow-y-scroll divide-y`}
              >
                {(jobType === String(item?.name).toLowerCase() &&
                  item?.list?.length < 10 &&
                  loading == String(item?.name).toLowerCase()) ||
                (jobType === String(item?.name).toLowerCase() && isSorting) ? (
                  [...Array(isSorting ? 5 : 2)].map((_, index) => (
                    <li
                      key={`skeleton-${index}`}
                      className="grid grid-rows-2 grid-cols-[40px_1fr] py-[6px] mb-2"
                    >
                      <span className="row-span-2 w-8 h-8">
                        <Skeleton width={"32px"} height={"32px"} />
                      </span>
                      <span className="w-full h-3">
                        <Skeleton width={"200px"} height={10} />
                      </span>
                      <span className="w-full h-3">
                        <Skeleton width={"100px"} height={10} />
                      </span>
                    </li>
                  ))
                ) : item?.list?.length == 0 ? (
                  <h2 className="text-base text-center mt-2">No jobs found</h2>
                ) : (
                  <>
                    {item?.list?.map((job, i) => (
                      <li
                        key={`job-${i}`}
                        className={`flex  items-start cursor-pointer py-[6px] hover:bg-[#F2F4F8] px-2 border-gray-300 group gap-2 min-h-[72px] ${
                          params?.jobId === job?.id ? "bg-[#F2F4F8]" : ""
                        }`}
                        onClick={() =>
                          selectJob(job?.id, String(item?.name).toLowerCase())
                        }
                      >
                        <input
                          id={`moveSelect-${job.id}`}
                          name="moveSelect"
                          type="checkbox"
                          value={job?.id}
                          checked={selectedJobs.includes(job?.id)}
                          onChange={(e) => {
                            e.preventDefault();
                            jobSelection(job?.id);
                          }}
                          className={`w-4 h-4 group-hover:!block ${
                            selectedJobs.includes(job?.id) ? "!block" : "hidden"
                          }`}
                        />
                        <div className="flex justify-center flex-col w-[200px] grow">
                          <span
                            className={`inline-block text-sm not-italic text-gray-800 truncate ${
                              params?.jobId === job?.id
                                ? "font-semibold"
                                : "font-medium"
                            }`}
                            title={job?.position}
                          >
                            {job?.position}
                          </span>
                          <span className="items-center gap-2 justify-start">
                            <a
                              onClick={() =>
                                selectJob(
                                  job?.id,
                                  String(item?.name).toLowerCase(),
                                  "pipeline",
                                )
                              }
                              className="inline-block text-xs text-[#5F6989] truncate"
                              data-tooltip-id={
                                job?.stages?.reduce(
                                  (total, stage) => total + stage.value,
                                  0,
                                ) > 0
                                  ? "candidateListTooltip"
                                  : ""
                              }
                              data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                <div>
                                  {job?.stages?.map(
                                    (stage, ix) =>
                                      stage.value != 0 && (
                                        <span
                                          className="w-full block"
                                          key={`${i}_${ix}`}
                                        >
                                          {stage.name} - <b>{stage.value}</b>
                                        </span>
                                      ),
                                  )}
                                </div>,
                              )}
                            >
                              {`Prospects : ${job?.totalProspects ?? 0} | Candidates : ${job?.candidates}`}
                            </a>
                          </span>
                          {/* {job?.location?.[0]?.name && ( */}
                          <span className="text-xs text-[#5F6989] truncate -mt-[1px]">
                            {`Location: ${job?.location?.[0]?.name || "Not Available"}`}
                          </span>
                          {/* )} */}
                        </div>

                        <Tooltip
                          id="candidateListTooltip"
                          noArrow={false}
                          style={{
                            zIndex: 9999,
                            background: "#5F6989",
                            fontSize: "12px",
                            padding: "4px 8px",
                            fontWeight: "400",
                            color: "white",
                          }}
                          place="bottom"
                        />

                        <button
                          onClick={(e) => {
                            toggleFavorite(job.id, job.isSaved);
                          }}
                          className="ml-auto"
                          aria-label={
                            job.isSaved === 1
                              ? "Remove from favorites"
                              : "Add to favorites"
                          }
                        >
                          {job.isSaved === 1 ? (
                            <BsFillPinAngleFill
                              className="text-[#5F6989]"
                              size={15}
                              data-tooltip-id="job-pin"
                              data-tooltip-content={"Unpin the job"}
                            />
                          ) : (
                            <BsPinAngle
                              className="text-slate-900 opacity-0 group-hover:opacity-100 transition-opacity duration-00"
                              size={13}
                              data-tooltip-id="job-pin"
                              data-tooltip-content={"Pin the job"}
                            />
                          )}
                        </button>
                        <Tooltip
                          id="job-pin"
                          style={{
                            color: "white",
                            zIndex: 9999,
                            background: "#5F6989",
                            fontSize: "12px",
                          }}
                          place="left"
                        />
                      </li>
                    ))}

                    {item?.hasMore &&
                      item?.list?.length % 10 == 0 &&
                      (item?.loading ? (
                        <div className="flex items-center justify-center">
                          <ScaleLoader color="#1369e9" loading={true} />
                        </div>
                      ) : (
                        <button
                          className="px-[16px] mt-[20px] mb-[20px] flex items-center"
                          onClick={() => {
                            setReplace(false);
                            getActiveJobsbyPage(
                              String(item?.name).toLowerCase(),
                            );
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.41797 7.5L10.0013 12.0704L14.5846 7.5"
                              stroke="#252E4B"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          <span className="text-gray-800 ml-[8px] leading-[28px] text-[14px] not-italic font-normal">
                            See more
                          </span>
                        </button>
                      ))}
                  </>
                )}
              </div>
            </>
          ))}
      </ul>

      {/* Bottom Navigation Bar */}
      <div className="sticky bottom-1 bg-white border-t border-gray-200 pt-1 pb-1 ">
        <div className="flex justify-between items-center">
          {bottomNavItems.map((item, index) => {
            const Icon = item.icon;
            return (
              <button
                data-tooltip-id="tooltip"
                data-tooltip-content={`${item.name}`}
                key={index}
                onClick={() => {
                  setJobType(item.status);
                  setSelectedJobs([]);
                  getActiveJobsbyPage(item.status);
                }}
                className={`flex flex-col items-center w-12 p-1 py-2  rounded-lg transition-all  hover:text-blue-700  ${
                  jobType === item.status
                    ? "text-blue-700 bg-defaultBg"
                    : "text-gray-700 hover:bg-gray-100 "
                }   
                                
                `}
                // title={item.name}
              >
                {/* <Icon
                  className={`text-base ${item.icon == ImDrawer ? "mb-[2px]" : " "}  ${item.icon == FaRegFileArchive ? "mb-[1px] text-base" : " "} ${item.icon == IoRadio ? "-mb-[1px] text-md" : " "} ${item.icon == TbLayoutNavbarInactive ? "-mb-[1px] text-md" : " "}`}
                /> */}
                <Icon className={`text-2xl ${iconStyles[item.name] || ""}`} />
                {/* <span
                  className={`text-[10px] mt-0.5 truncate w-10 ${item.icon == FaRegFileArchive ? "mt-[1px]" : " "}`}
                >
                  {item.name}
                </span> */}
              </button>
            );
          })}
        </div>
      </div>
      {selectedJobs?.length > 0 && (
        <>
          <button
            ref={buttonRef}
            onClick={() => setOpenMoveMenu((prev) => !prev)}
            className="btn-primary btn-sm h-10 relative w-full z-20 right-0 "
          >
            Move to
          </button>

          {openMoveMenu && (
            <ul
              ref={menuRef}
              className="absolute right-4 rounded-lg w-[240px] h-auto bg-white shadow-md bottom-[66px] border border-gray-300 z-20 list-none"
            >
              {jobActions?.map((action, idx) => (
                <li
                  key={`action-${idx}`}
                  className={`px-[8px] py-[7px] text-gray-800 text-[14px] font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded-[4px] ${
                    action?.name === "Delete job"
                      ? "text-[#FF475D] border-t border-t-[#EFF0F2]"
                      : ""
                  }`}
                  onClick={() => handleJobAction(action)}
                >
                  {action.name}
                </li>
              ))}
            </ul>
          )}

          {openCloseModal && (
            <Modal
              heading={`Are you sure you want to ${statusToChange} this job?`}
              modalDismiss={() => {
                setOpenCloseModal(false);
                setReason("");
              }}
              subheading="Please provide a reason for closing this job."
              dismissOnSave={false}
              onSave={() => {
                setOpenCloseModal(false);
                changeStatus(statusToChange, reason);
              }}
              defaultButtons={{ primaryLabel: "Confirm" }}
            >
              <div>
                <TextAreaBox
                  type="text"
                  value={reason}
                  onChange={(e) => setReason(e.target.value)}
                  placeholder="Enter reason here..."
                />
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
}

export default JobListSidebar;
