import { useEffect } from "react";

function usePreventIframeEmbedding() {
  useEffect(() => {
    // Check if the window is in an iframe
    if (window.self !== window.top) {
      // Optional: Redirect to your main site
      window.top.location.href = window.self.location.href;
      // Or just break out of the frame
      // window.top.location = window.self.location;
    }
  }, []);
}

export default usePreventIframeEmbedding;
