import React, { useEffect, useState } from "react";
import { convertToHTML } from "draft-convert";
import {
  convertFromHTML,
  ContentState,
  EditorState,
  convertToRaw,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Modal from "../../modal/Modal";
import { useSelector } from "react-redux";
import SelectAndSearch from "../../../layouts/SelectAndSearch";
import { useContext } from "react";
import SearchUser from "../user/SearchUser";
import Attachments from "../../../layouts/Attachments";
import InputBox from "../../../layouts/InputBox";
import EmailScheduler from "./EmailScheduler";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import { RichUtils } from "draft-js";
import { handleNewLine, insertNewUnstyledBlock } from "draftjs-utils";
import moment from "moment-timezone";
import { Wand2 } from "lucide-react";
import { useStatusModal } from "../../../App";
import RichEditor from "../../all/RichEditor/RichEditor";

const core = require("../../../lib/core");

function SendEmail({ chatDetail, companydata, modalDismiss }) {
  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState([]);
  const [subject, setSubject] = useState([]);
  const { user } = useContext(core.UserContext);
  const [scheduleOption, setScheduleOption] = useState("send_now");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [scheduledTime, setScheduledTime] = useState(null);
  const scheduleOptions = [
    { value: "send_now", label: "Send now" },
    { value: "tomorrow", label: "Tomorrow" },
    { value: "2_days", label: "2 days" },
    { value: "4_days", label: "4 days" },
    { value: "next_weekday", label: "Next weekday" },
    { value: "1_week", label: "1 week" },
    { value: "2_weeks", label: "2 weeks" },
    { value: "1_month", label: "1 month" },
    { value: "custom", label: "Custom time..." },
  ];

  const { position, jobId } = useSelector((state) => {
    return {
      position: state?.JobExpandSlice?.profile?.position,
      jobId: state?.JobExpandSlice?.profile?.id,
    };
  });

  useEffect(() => {
    if (chatDetail?.email?.length > 0) {
      setTo(chatDetail?.email[0]);
    }
  }, [chatDetail]);

  const [emailSuccess, setEmailSuccess] = useState(false);

  // Array of sample options (you can fetch these dynamically)
  const sampleOptions = [];
  const handleEditorStateChange = (newEditorState) => {
    setEditorStateDesc(newEditorState);
  };

  const handleReturn = (event) => {
    event.preventDefault();
    let newEditorState = editorStateDesc;

    if (event.keyCode === 13) {
      if (event.shiftKey) {
        // Shift + Enter: Create a new block
        newEditorState = insertNewUnstyledBlock(editorStateDesc);
      } else {
        // Enter without shift: Insert a soft new line
        newEditorState = RichUtils.insertSoftNewline(editorStateDesc);
      }

      if (newEditorState !== editorStateDesc) {
        setEditorStateDesc(newEditorState);
        return true;
      }

      return false;
    }
  };
  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setInputValue(""); // Clear input after selecting an option
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };
  console.log("chatDetail", chatDetail);
  // useEffect(() => {
  //   setEditorValue(
  //     draftToHtml(convertToRaw(editorStateDesc?.getCurrentContent())),
  //   );
  //   console.log(
  //     draftToHtml(convertToRaw(editorStateDesc?.getCurrentContent())),
  //   );
  // }, [editorStateDesc]);

  const [sendText, setSendingText] = useState("Send email");
  const [attachments, setAttachments] = useState([]);
  const [attachmentsUrl, setAttachmentsUrl] = useState([]);
  const [selectedEmail, setSelectedEmail] = useState(
    user?.userIntegrationsList?.find((item) => item?.is_primary)?.email ||
      user.email ||
      "",
  );

  console.log(user);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({});
  const { showSuccess, showError } = useStatusModal();

  async function sendEmail(attachmentlinks = [], executeOn) {
    try {
      await core
        .API(
          core.API_METHODS.POST,
          core.RECRUITER_API.POST_EMAIL_CANDIDATE,
          1,
          {
            to: to,
            cc: selectedOptions?.map((option) => option?.email),
            body: editorValue,
            subject: subject,
            matchprofile: chatDetail?.matchId,
            applicant: chatDetail?._id,
            job: jobId,
            name: chatDetail?.first_name + " " + chatDetail?.last_name ?? "",
            from: selectedEmail ?? "",
            s3AttachmentUrls: [
              ...(attachmentlinks || []),
              ...(attachmentsUrl || []),
            ],
            execute_on: executeOn,
          },
        )
        .then((json) => {
          // setEmailSuccess(true);
          modalDismiss();
          showSuccess(`Email successfully sent to ${chatDetail?.first_name}`);
          setSubject();
          setSendingText("Send email");
        });
    } catch (error) {
      console.error("Error sending email:", error);
      setLoading(false);
      // setMessage({
      //   type: "error",
      //   message: "Error sending email",
      // });

      modalDismiss();
      showError("Error sending email");
    }
  }

  const handleSubmit = async (executeOn) => {
    setLoading(true);
    if (
      !(
        selectedEmail.length > 0 &&
        selectedEmail.trim()?.length > 0 &&
        subject?.trim()?.length > 0 &&
        editorValue?.length > 0 &&
        !(
          !user?.userIntegrations?.refresh_token && user?.email == selectedEmail
        )
      )
    ) {
      setLoading(false);
      console.log("Please enter all required data");
      setMessage({
        type: "error",
        message: "Please enter all required data",
      });
      return;
    }

    const formData = new FormData();
    const token = await core.getData(core.data.ACCESS_TOKEN);

    // Append each file to the FormData object
    for (let i = 0; i < attachments.length; i++) {
      formData.append("files", attachments[i]);
    }

    try {
      if (attachments.length > 0) {
        await fetch(core.S3_API.POST_UPLOAD_FILE, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + token,
            uid: user?.id,
          },
          body: formData,
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data?.data, data);
            sendEmail(data?.data, executeOn);
          });
      } else {
        sendEmail([], executeOn);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
      setLoading(false);
    }
  };

  const getTemplate = async () => {
    setSubject("");
    setEditorValue("Generating message...");
    // setEditorStateDesc(
    //   EditorState?.createWithContent(
    //     ContentState?.createFromBlockArray(
    //       convertFromHTML(new String("Generating message...").toString()),
    //     ),
    //   ),
    // );
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_EMAIL_TEMPLATE,
        1,
        {
          company_name: companydata,
          job_position: position,
          candidate_name: chatDetail?.first_name,
          candidate_matching_comment:
            chatDetail?.summary || chatDetail?.matchSummary,
          matchId: chatDetail?.matchId || chatDetail?._id,
        },
      );

      if (json?.data) {
        setSubject(json?.data?.data?.subject);

        if (json?.data?.data?.attachments) {
          setAttachmentsUrl(json?.data?.data?.attachments);
        }
        setEditorValue(json?.data?.data?.body);
        // if (typeof json?.data?.data?.body == "string") {
        //   setEditorStateDesc(
        //     EditorState?.createWithContent(
        //       ContentState?.createFromBlockArray(
        //         convertFromHTML(
        //           new String(
        //             json?.data?.data?.body
        //               ?.split("&lt;")
        //               .join("<")
        //               ?.replaceAll("[Your Name]", user?.name)
        //               ?.replaceAll("[Your Title]", "Recruiter"),
        //           ).toString(),
        //         ),
        //       ),
        //     ),
        //   );
        // } else {
        //   setEditorStateDesc(
        //     EditorState?.createWithContent(
        //       ContentState?.createFromBlockArray(
        //         convertFromHTML(new String("Failed to generate").toString()),
        //       ),
        //     ),
        //   );
        // }
      }

      if (json?.error) {
        setEditorValue("Failed to generate");
        // setEditorStateDesc(
        //   EditorState?.createWithContent(
        //     ContentState?.createFromBlockArray(
        //       convertFromHTML(new String("Failed to generate").toString()),
        //     ),
        //   ),
        // );
      }
    } catch (error) {
      setEditorValue("Failed to generate");
      // setEditorStateDesc(
      //   EditorState?.createWithContent(
      //     ContentState?.createFromBlockArray(
      //       convertFromHTML(new String("Failed to generate").toString()),
      //     ),
      //   ),
      // );
    }
  };

  useEffect(() => {
    // getTemplate();
    setEmailSuccess(false);
  }, [chatDetail]);

  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  const getAllTemplates = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.TEMPLATE_API.GET_ALL_TEMPLATE + user?.id,
        1,
      );
      if (json?.data) {
        setTemplates(json?.data);
      }
      // if (json?.data?.length > 0) {
      //   setSelectedTemplate(json.data[0]._id);
      // }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getAllTemplates();
  }, []);

  const replaceVariables = (text) => {
    const regex = /{{(.*?)}}/g;
    return text.replace(regex, (match, variable) => {
      switch (variable) {
        case "first_name":
          return chatDetail?.first_name || "";
        case "last_name":
          return chatDetail?.last_name || "";
        case "org_name":
          return user?.organization?.name || "";
        case "job_name":
          return position || "";
        default:
          return match;
      }
    });
  };

  useEffect(() => {
    if (selectedTemplate) {
      setSubject(
        replaceVariables(
          templates?.find((template) => template._id === selectedTemplate)
            ?.subject,
        ),
      );

      if (
        templates?.find((template) => template._id === selectedTemplate)
          ?.attachments
      ) {
        setAttachmentsUrl(
          templates?.find((template) => template._id === selectedTemplate)
            ?.attachments,
        );
      }

      // setEditorStateDesc(
      //   EditorState?.createWithContent(
      //     ContentState?.createFromBlockArray(
      //       convertFromHTML(
      //         new String(
      //           replaceVariables(
      //             templates
      //               ?.find((template) => template._id === selectedTemplate)
      //               ?.body?.split("&lt;")
      //               .join("<"),
      //           ),
      //         ).toString(),
      //       ),
      //     ),
      //   ),
      // );

      setEditorValue(
        replaceVariables(
          templates
            ?.find((template) => template._id === selectedTemplate)
            ?.body?.split("&lt;")
            .join("<"),
        ),
      );
    }
  }, [selectedTemplate]);

  const handleAttachment = (event) => {
    const files = Array.from(event.target.files);
    setAttachments([...attachments, ...files]);
  };

  const removeAttachment = (index) => {
    setAttachments(attachments.filter((_, i) => i !== index));
  };

  const removeAttachmentUrl = (index) => {
    const newAttachmentsUrl = [...attachmentsUrl];
    newAttachmentsUrl.splice(index, 1);
    setAttachmentsUrl(newAttachmentsUrl);
  };

  const onSubmit = () => {
    let scheduledDateTime;
    const now = moment();
    const userTimezone = moment.tz.guess();

    if (scheduleOption === "custom" && selectedDate && selectedTime) {
      scheduledDateTime = moment(selectedDate)
        .hours(selectedTime.getHours())
        .minutes(selectedTime.getMinutes());
    } else {
      switch (scheduleOption) {
        case "send_now":
          scheduledDateTime = now;
          break;
        case "tomorrow":
          scheduledDateTime = now
            .clone()
            .add(1, "days")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "2_days":
          scheduledDateTime = now
            .clone()
            .add(2, "days")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "4_days":
          scheduledDateTime = now
            .clone()
            .add(4, "days")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "next_weekday":
          scheduledDateTime = now
            .clone()
            .add(1, "weeks")
            .startOf("isoWeek")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "1_week":
          scheduledDateTime = now
            .clone()
            .add(1, "weeks")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "2_weeks":
          scheduledDateTime = now
            .clone()
            .add(2, "weeks")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        case "1_month":
          scheduledDateTime = now
            .clone()
            .add(1, "months")
            .startOf("day")
            .add(now.hours(), "hours")
            .add(now.minutes(), "minutes");
          break;
        default:
          scheduledDateTime = now;
          break;
      }
    }

    // Convert to the user's timezone and format
    const localDate = scheduledDateTime.tz(userTimezone);
    const formattedDate = localDate.format();
    console.log(formattedDate, "date");
    setScheduledTime(formattedDate);

    handleSubmit(formattedDate);
  };

  return (
    <Modal
      modalDismiss={modalDismiss}
      message={message}
      heading={"Send email to " + to}
      subheading={
        "Verify the email's content and then proceed to send it to the candidate."
      }
      noBtns={emailSuccess ? true : false}
      className={"md:max-w-[60%] "}
      onSave={(e) => {
        onSubmit(); // Call the original onSubmit function
      }}
      defaultButtons={{
        primaryClass: `bg-[#1369E9] px-[20px] h-[40px] rounded-[8px] shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] font-normal`,
        primaryLabel: (
          <span className="flex items-center gap-4">
            {scheduleOption === "send_now" ? sendText : "Schedule Email"}
          </span>
        ),
        primaryDisabled:
          sendText === "Sending..." ||
          editorValue === "Generating message..." ||
          subject?.length === 0,
      }}
      isLoading={loading}
    >
      <>
        <div className="w-full ">
          <div className=" mt-[20px] w-full">
            <label
              className=" text-right text-[14px] font-normal not-italic"
              htmlFor=""
            >
              Template
            </label>
            <SelectAndSearch
              placeholder="Select template"
              // defalultValue={
              //   templates?.length > 0 ? templates[0].template_name : null
              // }
              data={templates?.map((template) => ({
                name: template.template_name,
                id: template._id,
              }))}
              setSelectedTemplate={setSelectedTemplate}
            />
          </div>
          <div className=" mt-[20px] w-full">
            <label
              className=" text-right text-[14px] font-normal not-italic required"
              htmlFor=""
            >
              From
              {!user?.userIntegrations?.refresh_token &&
                user?.userIntegrationsList.some(
                  (s) => s.email == selectedEmail,
                ) && (
                  <span className="text-red-400 text-xs font-normal ml-2">
                    {`( Your email is not verified. Please verify your email before
                    sending the email.)`}
                  </span>
                )}
            </label>
            <SelectAndSearch
              enableInput={false}
              placeholder="Select template"
              defalultValue={selectedEmail}
              data={[
                ...user?.userIntegrationsList?.map((integration) => ({
                  name: integration?.email,
                  id: integration?.email,
                })),
                {
                  name: "no-reply@sproutsai.com",
                  id: "no-reply@sproutsai.com",
                },
              ]}
              setSelectedTemplate={setSelectedEmail}
            />
          </div>
          <div className=" mt-[20px]">
            <SearchUser
              label={"Cc"}
              emails={selectedOptions}
              removerEmail={(e) =>
                setSelectedOptions(selectedOptions.filter((_, i) => i !== e))
              }
              setEmails={(e) => {
                setSelectedOptions([...selectedOptions, e]);
              }}
            />
          </div>

          <div className="  mt-[20px]  w-full">
            <label
              className="  text-[14px] font-normal not-italic required"
              htmlFor=""
            >
              Subject
            </label>
            <InputBox
              type="text"
              value={subject}
              size={"medium"}
              onChange={(e) => setSubject(e?.target?.value)}
            />
          </div>
          <div className="mt-[20px] relative w-full" id="description">
            <div className="space-y-2">
              <div className="flex items-center justify-between">
                <label htmlFor="body" className="text-[14px] font-normal">
                  Body
                </label>
                <button
                  data-tooltip-id="tooltip"
                  data-tooltip-content={
                    "Click to generate an AI-powered email message"
                  }
                  onClick={getTemplate}
                  disabled={editorValue === "Generating message..."}
                  className="flex items-center gap-2 text-sm text-transparent bg-clip-text bg-gradient-to-r from-purple-500 to-pink-500 hover:from-purple-600 hover:to-pink-600"
                >
                  <Wand2 className="h-4 w-4 text-purple-500" />
                  {editorValue === "Generating message..."
                    ? "Generating..."
                    : "Generate with AI"}
                </button>
              </div>
              <div className="overflow-x-hidden">
                {/* <Editor
                  editorState={editorStateDesc}
                  onEditorStateChange={handleEditorStateChange}
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class prose max-w-full"
                  toolbarClassName="toolbar-class !max-w-full"
                  handleReturn={handleReturn}
                /> */}
                <RichEditor
                  withToolbar={true}
                  content={editorValue}
                  onEditorChange={(e) => setEditorValue(e)}
                />
              </div>
            </div>
          </div>
          <div className="mt-[20px]">
            <Attachments
              attachments={attachments}
              attachmentsUrl={attachmentsUrl}
              removeAttachment={removeAttachment}
              removeAttachmentUrl={removeAttachmentUrl}
              handleAttachment={handleAttachment}
            />
          </div>
        </div>
        <div>
          {/* email schedule component */}
          <EmailScheduler
            sendText={sendText}
            scheduleOption={scheduleOption}
            setScheduleOption={setScheduleOption}
            selectedDate={selectedDate}
            setSelectedDate={setSelectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
            scheduleOptions={scheduleOptions}
            onSubmit={onSubmit}
            scheduledTime={scheduledTime}
            setScheduledTime={setScheduledTime}
          />
        </div>
      </>
    </Modal>
  );
}

export default SendEmail;
