import React, { useState, useEffect, useContext } from "react";
import InitialsLogo from "../../../all/InitialsLogo";
import ProgressBar from "../../../analytics/ProgressBar";
import { ScaleLoader, ClipLoader } from "react-spinners";
import PercentDonut from "../../../analytics/PercentDonut";
import RejectedReasonModal from "../../applicant/RejectedReasonModal";
import SendEmail from "../../applicant/SendEmail";
import DraggableActions from "./DraggableActions";
import { Dropdown } from "antd";
import moment from "moment";
import FeedbackIndividual from "../../candidate/interview/FeedbackIndividual";
import ChangeCandidatesDataModal from "./ChangeCandidatesDataModal";
import Modal from "../../../modal/Modal";
import { JobPageContext } from "../../../../lib/context";
import Notes from "./Notes";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import "@cyntler/react-doc-viewer/dist/index.css";
import ReactPaginate from "react-paginate";
import SelectMatchScore from "../../../candidate/sourceCandidate/SelectMatchScore";
import ProtectedComponent from "../../../../layouts/ProtectedComponent";
import { VscGitStashApply } from "react-icons/vsc";
import ApplyForJobModal from "../../../../screens/recruiter/candidate/ApplyForJobModal";
import { NotFoundDefaultIcon } from "../../candidate/icons/Icons";
import { BiCheck, BiQuestionMark } from "react-icons/bi";
const core = require("../../../../lib/core");

const MatchIndicator = ({ label, percentage }) => {
  const getColor = (value) => {
    if (value >= 70) return "#4CAF50";
    if (value >= 30) return "#FFA500";
    return "#FF0000";
  };

  const radius = 40;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percentage / 100) * circumference;

  return (
    <div className="flex flex-col items-center mb-4">
      <div className="relative w-24 h-24">
        <svg className="w-24 h-24 transform -rotate-90" viewBox="0 0 100 100">
          <circle
            className="text-gray-200"
            strokeWidth="8"
            stroke="currentColor"
            fill="transparent"
            r={radius}
            cx="50"
            cy="50"
          />
          <circle
            className="text-blue-600"
            strokeWidth="8"
            strokeLinecap="round"
            stroke={getColor(percentage)}
            fill="transparent"
            r={radius}
            cx="50"
            cy="50"
            style={{
              strokeDasharray: circumference,
              strokeDashoffset: strokeDashoffset,
              transition: "stroke-dashoffset 0.5s ease 0s",
            }}
          />
        </svg>
        <div className="absolute top-0 left-0 w-full h-full flex items-center justify-center">
          <span className="text-xl font-bold">{percentage}%</span>
        </div>
      </div>
      <span className="text-sm font-medium mt-2">{label}</span>
    </div>
  );
};
const TruncatedDescription = ({ description }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 200; // Adjust this value to get roughly 3-4 lines

  if (description.length <= maxLength) {
    return <p className="mt-2 text-gray-700 text-justify">{description}</p>;
  }

  return (
    <div className="mt-2">
      <p className="text-gray-700">
        {isExpanded ? description : `${description.slice(0, maxLength)}...`}
      </p>
      <button
        className="text-blue-600 hover:text-blue-800 text-justify text-sm mt-1 focus:outline-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {isExpanded ? "Read less" : "Read more"}
      </button>
    </div>
  );
};

const ReviewCandidates = ({
  candidateData,
  candidates,
  setShowReviewPage,
  JobName,
  pipelineStages,
  updateStatus,
  setShowModal,
  setChatDetail,
  matchScoreThreshold,
  chatDetail,
  jobId,
  setHideCallBar,
  companydata,
  positionData,
  resources,
  setCandidates,
  setCandidateDetail,
  isPublic,
  currentPage,
  totalPage,
  onPageChange,
  totalactiveCandidates,
  isPageLoading,
  currentIndex,
  setCurrentIndex,
  isSourceCandidate,
  atsloading,
  setShowReviewProspects,
  candidateExperience,
  ReviewTabName,
  checkResource = false,
}) => {
  // const [currentIndex, setCurrentIndex] = useState(0);

  const [activeCandidates, setActiveCandidates] = useState(
    totalactiveCandidates ?? [],
  );

  const currentCandidate = candidates && candidates[currentIndex];
  const [activeTabName, setAvtiveTabName] = useState(ReviewTabName ?? "Resume");
  const [showStages, setShowStages] = useState(false);
  const [ShowReject, setShowReject] = useState("");
  const [CandidateExp, setCandidateExp] = useState({});
  const [loading, setLoading] = useState(false);
  const [contactLoading, setcontactLoading] = useState("");
  const [ShowReviewModal, setShowReviewModal] = useState("");
  if (!isPublic) {
    const { setContentReload } = useContext(JobPageContext);
  }
  const [pdfLoading, setPdfLoading] = useState(true);
  const [IsremoveCandidateLoading, setIsremoveCandidateLoading] =
    useState(false);

  async function getExperience() {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_APPLICANT_ANALYTICS +
          currentCandidate?._id +
          "/" +
          jobId,
        1,
      );
      const data = json?.data;
      setCandidateExp(data);
      setLoading(false);
      return data;
    } catch (error) {
      setLoading(false);
    }
  }

  async function profileViewActivity() {
    try {
      setLoading(true);
      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_PROFILE_VIEW +
          currentCandidate?._id +
          "/" +
          jobId,
        1,
      );
      console.log("profle actvity api ");
    } catch (error) {
      console.error(error);
    }
  }
  async function saveForLater(sts, id) {
    await core
      .API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_CANDIDATE_FEVORITE_STATUS,
        1,
        {
          matchProfileIds: [id],
          isFavorite: sts,
        },
      )
      .then((json) => {
        if (json.data) {
          setCandidates((prevData) =>
            prevData.map((item) => {
              if (item._id === id) {
                return { ...item, favourite: sts };
              }
              return item;
            }),
          );
        } else {
        }
      });
  }
  useEffect(() => {
    setPdfLoading(true);
    getExperience();
    profileViewActivity();
  }, [currentIndex]);
  const btnList = [
    { ButtonName: "Resume" },
    { ButtonName: "Notes" },
    { ButtonName: "Feedback" },
  ];
  const nextCandidate = (step = 1) => {
    if (currentIndex < candidates.length - step) {
      setCurrentIndex(currentIndex + step);
    }
  };
  const previousCandidate = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  console.log(currentIndex, currentPage);
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "ArrowLeft") {
        previousCandidate();
      } else if (event.key === "ArrowRight") {
        nextCandidate();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [currentIndex]);
  const RejectModalClose = () => {
    setShowReject("");
  };
  async function removeCandidate(cid) {
    setIsremoveCandidateLoading(true);
    await core
      .API(
        core.API_METHODS.DELETE,
        core.RECRUITER_API.DELETE_MATCH_PROFILE + currentCandidate?._id,
        1,
        {},
      )
      .then((json) => {
        if (json.data) {
          setShowReviewModal("");
          setCandidates((prevCandidates) =>
            prevCandidates.filter(
              (candidate) => candidate._id !== currentCandidate?._id,
            ),
          );
          if (currentIndex === candidates.length - 1) {
            if (currentIndex > 0) {
              setCurrentIndex(currentIndex - 1);
            } else {
              setShowReviewPage(false);
            }
          }
          setIsremoveCandidateLoading(false);
        } else {
          setIsremoveCandidateLoading(false);
          setMessage({
            type: "error",
            message: json?.error?.message,
          });
        }
      });
  }
  const colors = (score) => {
    let colorArr = [
      "#b80000",
      "#b80000",
      "#cc540a",
      "#de862b",
      "#efb353",
      "#ffdf82",
      "#d2cb5c",
      "#a0b83a",
      "#a0b83a",
      "#a0b83a",
      "#a0b83a",
    ];
    if (score >= 90) {
      return "#009107";
    }
    if (score >= matchScoreThreshold) {
      return "#67a51d";
    } else return colorArr[Math.floor(score / 10)];
  };
  function formatLink(link) {
    // Check if the link starts with "https://" or "http://"
    if (!link.startsWith("https://") && !link.startsWith("http://")) {
      // If it doesn't, add "https://"
      link = "https://" + link;
    }

    return link;
  }
  const handleGetContact = async (type) => {
    try {
      setcontactLoading(type);
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.GET_CANDIDATE_CONTACT,
        1,
        {
          matchProfileIds: [currentCandidate?._id],
          type,
        },
      );
      if (!json?.data?.length) {
        setcontactLoading("");
        return toast.error(json?.message);
      }
      if (type === "email") {
        if (json && json?.data?.length > 0) {
          const newData = {
            ...currentCandidate?.applicant, // Copy existing properties from iter?.applicant
            summary: currentCandidate?.matchSummary, // Add or update the 'summary' property
            email: json?.data,
          };

          setChatDetail(newData);
          setShowReviewModal("send-email-modal");
        }
      }
      if (type === "phone") {
        if (json && json?.data?.length > 0) {
          setHideCallBar(true);
          setChatDetail({ ...currentCandidate?.applicant, phone: json?.data });
        }
      }
    } catch (error) {
      toast.error(error?.message || error?.data?.message);
      console.log(error);
    }
    setcontactLoading("");
  };

  const [notes, setNotes] = useState([]);

  const getApplicantsDetails = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.ACTIVITY_API.GET_NOTES + currentCandidate?._id,
        1,
      );
      if (json?.data) {
        setNotes(json.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const [applyDropDown, setApplyDropDown] = useState(-1);
  const handleApplyClick = () => {
    // setJobId(profile?.matchProfile?.job); // Set the current job ID
    setApplyDropDown(1);
    // setCandidatejobmdal(profile?.applicant_id);
  };

  const handleModalDismiss = () => {
    setApplyDropDown(-1);
  };

  useEffect(() => {
    getApplicantsDetails();
  }, [currentCandidate]);

  const [loadingresume, setLoadingresume] = useState(false);

  useEffect(() => {
    // Show loader when value changes
    setLoadingresume(true);

    // Hide loader after 2 seconds
    const timer = setTimeout(() => {
      setLoadingresume(false);
    }, 2000);

    // Clean up the timer if the component unmounts or value changes
    return () => clearTimeout(timer);
  }, [currentCandidate]);

  return (
    <div className="fixed inset-0 z-50 bg-gray-100 p-4 ">
      {!isPublic && (
        <DraggableActions
          currentIndex={currentIndex}
          setCurrentIndex={setCurrentIndex}
          candidatesLength={candidates?.length}
          setShowReviewModal={setShowReviewModal}
          previousCandidate={previousCandidate}
          nextCandidate={nextCandidate}
          closeReview={() => {
            isSourceCandidate
              ? setShowReviewProspects(false)
              : setShowReviewPage(false);
            setContentReload((prev) => prev + 1);
          }}
          pipelineStages={pipelineStages}
          currentCandidate={currentCandidate}
          setShowReject={setShowReject}
          setChatDetail={setChatDetail}
          updateStatus={updateStatus}
          currentPage={currentPage}
          totalPages={totalPage}
          onPageChange={onPageChange}
          activeCandidates={activeCandidates}
          isPageLoading={isPageLoading}
          isSourceCandidate={isSourceCandidate}
          setCandidateDetail={setCandidateDetail}
          atsloading={atsloading}
          setShowModal={setShowModal}
          resources={resources}
        />
      )}
      <div className="relative">
        <button
          onClick={() => {
            if (isSourceCandidate) {
              setShowReviewProspects(false);
            } else {
              setShowReviewPage(false);
            }
          }}
          type="button"
          className="absolute top-0 right-0 bg-red-300 hover:bg-red-400 shadow-xl rounded-full p-3 text-gray-800 group focus:outline-none focus:ring-2 focus:ring-white"
        >
          <svg
            className="h-6 w-6 group-hover:stroke-blue"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="2.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
      </div>

      <section className="flex h-screen bg-gray-100">
        {!candidates ? (
          <div className="h-full w-full flex items-center justify-center">
            <ScaleLoader height={40} color="#1369e9" loading={true} />
          </div>
        ) : (
          <div className="w-full flex flex-col ">
            {/* Candidate details */}
            <div className="flex-grow overflow-y-auto p-2 bg-defaultBg rounded-lg ">
              <div className="flex flex-row gap-4">
                <div className="bg-white w-[45%] h-screen overflow-y-auto p-4 mb-4 rounded-lg shadow-md">
                  <div className="">
                    <div className="flex items-center mb-6 justify-between">
                      <div className="flex items-center">
                        <InitialsLogo
                          str={currentCandidate?.applicant?.first_name}
                          bgRounded={"50%"}
                        />
                        <div className="mx-2 p-2">
                          <div className="flex items-center gap-2">
                            <h2 className="text-xl font-semibold">
                              {currentCandidate?.applicant?.first_name}{" "}
                              {currentCandidate?.applicant?.last_name}{" "}
                            </h2>
                            <div className="flex items-center gap-2 ">
                              <div className="flex ">
                                {currentCandidate?.applicant?.linkedin?.length >
                                  0 && (
                                  <>
                                    {currentCandidate?.applicant?.linkedin?.toLowerCase() !=
                                      "not specified" && (
                                      <a
                                        className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                                        href={formatLink(
                                          currentCandidate?.applicant?.linkedin,
                                        )}
                                        target="_blank"
                                        rel="noreferrer"
                                        data-tooltip-id="my-tooltip-4"
                                        data-tooltip-content={"Linkedin"}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                        >
                                          <path
                                            d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                                            fill="#5F6989"
                                          />
                                        </svg>
                                      </a>
                                    )}
                                  </>
                                )}
                                {!isPublic && (
                                  <button
                                    className="candidate-email-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7 px-2 py-1 mr-2 relative"
                                    onClick={(e) => {
                                      if (
                                        contactLoading === "email" ||
                                        contactLoading === "phone"
                                      ) {
                                        return;
                                      }
                                      // Creating a new object to prevent mutation of the original data
                                      if (
                                        currentCandidate?.applicant?.email
                                          ?.length > 0
                                      ) {
                                        const newData = {
                                          ...currentCandidate?.applicant, // Copy existing properties from iter?.applicant
                                          summary:
                                            currentCandidate?.matchSummary, // Add or update the 'summary' property
                                          matchId:
                                            currentCandidate?.id ??
                                            currentCandidate?._id,
                                        };

                                        // Assuming 'setChatDetail' and other functions are defined and work correctly
                                        setChatDetail(newData);
                                        setShowReviewModal("send-email-modal");
                                      } else handleGetContact("email");
                                    }}
                                    data-tooltip-id="my-tooltip-4"
                                    data-tooltip-content={"Email"}
                                  >
                                    {currentCandidate?.applicant?.email
                                      ?.length > 0 ||
                                    currentCandidate?.applicant?.work_email
                                      ?.length > 0 ? (
                                      <div className="rounded-full h-3 w-3 flex items-center justify-center bg-green-900 | absolute -top-1 -right-1">
                                        <BiCheck
                                          size={9}
                                          className="text-white"
                                        />
                                      </div>
                                    ) : (
                                      <div className="rounded-full h-3 w-3 flex items-center justify-center bg-gray-600 | absolute -top-1 -right-1">
                                        <BiQuestionMark
                                          size={9}
                                          className="text-white"
                                        />
                                      </div>
                                    )}
                                    {contactLoading === "email" ? (
                                      <ClipLoader
                                        color="blue"
                                        loading={true}
                                        size={15}
                                      />
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="12"
                                        viewBox="0 0 14 12"
                                        fill="none"
                                      >
                                        <g clipPath="url(#clip0_2992_136007)">
                                          <rect
                                            x="1.5"
                                            y="1.15332"
                                            width="11"
                                            height="9.9"
                                            rx="2"
                                            fill="#5F6989"
                                            stroke="white"
                                          />
                                          <path
                                            d="M1.5 3.35352L4.50122 5.75449C5.96209 6.92319 8.03791 6.92319 9.49878 5.75449L12.5 3.35352"
                                            stroke="white"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </g>
                                        <defs>
                                          <clipPath id="clip0_2992_136007">
                                            <rect
                                              width="12.1"
                                              height="11"
                                              fill="white"
                                              transform="translate(0.949219 0.5)"
                                            />
                                          </clipPath>
                                        </defs>
                                      </svg>
                                    )}
                                  </button>
                                )}
                                {!isPublic && (
                                  <button
                                    className="candidate-phone-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7  px-2 py-1 mr-2 relative"
                                    onClick={(e) => {
                                      if (
                                        contactLoading === "email" ||
                                        contactLoading === "phone"
                                      ) {
                                        return;
                                      }

                                      if (
                                        currentCandidate?.applicant?.phone
                                          ?.length > 0
                                      ) {
                                        setHideCallBar(true);
                                        setChatDetail(
                                          currentCandidate?.applicant,
                                        );
                                      } else handleGetContact("phone");
                                    }}
                                    data-tooltip-id="my-tooltip-4"
                                    data-tooltip-content={"Call"}
                                  >
                                    {currentCandidate?.applicant?.phone
                                      ?.length > 0 ? (
                                      <div className="rounded-full h-3 w-3 flex items-center justify-center bg-green-900 | absolute -top-1 -right-1">
                                        <BiCheck
                                          size={9}
                                          className="text-white"
                                        />
                                      </div>
                                    ) : (
                                      <div className="rounded-full h-3 w-3 flex items-center justify-center bg-gray-600 | absolute -top-1 -right-1">
                                        <BiQuestionMark
                                          size={9}
                                          className="text-white"
                                        />
                                      </div>
                                    )}
                                    {contactLoading === "phone" ? (
                                      <ClipLoader
                                        color="blue"
                                        loading={true}
                                        size={15}
                                      />
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                      >
                                        <path
                                          d="M5.73926 2.48338C5.58613 2.04044 5.21536 1.75 4.80305 1.75H3.4162C2.88862 1.75 2.46094 2.24472 2.46094 2.85514C2.46094 8.04371 6.09637 12.25 10.5808 12.25C11.1084 12.25 11.5359 11.7551 11.5359 11.1447L11.5362 9.53981C11.5362 9.06275 11.2852 8.63383 10.9024 8.45666L9.57318 7.84171C9.2293 7.68257 8.83778 7.75419 8.55326 8.02852L8.21021 8.35956C7.80957 8.74585 7.22008 8.71513 6.85132 8.28846L5.88535 7.16978C5.51658 6.74311 5.48933 6.06161 5.8232 5.59807L6.10925 5.20117C6.34635 4.87197 6.40881 4.41884 6.27126 4.02097L5.73926 2.48338Z"
                                          fill="#5F6989"
                                        />
                                      </svg>
                                    )}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                          <p className="text-gray-600">
                            {currentCandidate?.applicant?.title || "No title"}
                          </p>
                        </div>
                      </div>
                      <div className="flex space-x-2 items-center">
                        {isSourceCandidate ? (
                          <>
                            <ProtectedComponent
                              showModal={true}
                              code={
                                checkResource ? "Candidate_update" : "no_code"
                              }
                            >
                              <SelectMatchScore
                                matchScore={
                                  currentCandidate?.humanMatch
                                    ? currentCandidate?.humanMatch
                                    : currentCandidate?.overallMatch * 100 >= 80
                                      ? "Strong Match"
                                      : currentCandidate?.overallMatch * 100 >=
                                          matchScoreThreshold
                                        ? "Good Match"
                                        : currentCandidate?.overallMatch * 100 <
                                              matchScoreThreshold &&
                                            currentCandidate?.overallMatch *
                                              100 >
                                              0
                                          ? "Weak Match"
                                          : ""
                                }
                                onClick={(vote, match) => {
                                  setCandidateDetail({
                                    name: currentCandidate?.notes,
                                    id: currentCandidate?._id,
                                    vote: vote,
                                    humanMatchScore: match,
                                  });
                                  setShowModal("add-notes-modal");
                                }}
                              />
                            </ProtectedComponent>
                          </>
                        ) : (
                          <div>
                            {currentCandidate?.humanMatch ||
                            currentCandidate?.overallMatch ? (
                              <span
                                className="border-gray-300 border px-2 py-1 rounded-md text-[14px] font-normal not-italic relative"
                                style={{
                                  color: colors(
                                    Math.round(
                                      currentCandidate?.overallMatch * 100,
                                    ),
                                  ),
                                }}
                              >
                                {currentCandidate?.humanMatch
                                  ? currentCandidate?.humanMatch
                                  : currentCandidate?.overallMatch * 100 >= 80
                                    ? "Strong Match"
                                    : currentCandidate?.overallMatch * 100 >=
                                        matchScoreThreshold
                                      ? "Good Match"
                                      : currentCandidate?.overallMatch * 100 <
                                            matchScoreThreshold &&
                                          currentCandidate?.overallMatch * 100 >
                                            0
                                        ? "Weak Match"
                                        : ""}
                              </span>
                            ) : null}
                          </div>
                        )}
                        {!isPublic && (
                          <Dropdown
                            dropdownRender={() => {
                              return (
                                <div className="bg-white p-1 rounded-2 w-[150px]  | flex flex-col | border shadow rounded-md | candidate-other-status-dropdown">
                                  <ProtectedComponent
                                    showModal={true}
                                    code={
                                      checkResource
                                        ? "Candidate_update"
                                        : "no_code"
                                    }
                                  >
                                    <div
                                      className=" px-3 w-full py-2 rounded-md | cursor-pointer | hover:bg-gray-200 | flex gap-2 items-center"
                                      onClick={() => {
                                        setShowReviewModal("update_can_data");
                                        setCandidateDetail({
                                          index: currentIndex,
                                          name: currentCandidate?.applicant
                                            ?.first_name,
                                          last_name:
                                            currentCandidate?.applicant
                                              ?.last_name,
                                          id: currentCandidate?.applicant?._id,
                                          title:
                                            currentCandidate?.applicant?.title,
                                          email:
                                            currentCandidate?.applicant?.email,
                                          work_email:
                                            currentCandidate?.applicant
                                              ?.work_email,
                                          phone:
                                            currentCandidate?.applicant?.phone,
                                          work_phone:
                                            currentCandidate?.applicant
                                              ?.work_phone,
                                          linkedln:
                                            currentCandidate?.applicant
                                              ?.linkedin,
                                          source:
                                            currentCandidate?.current_source,
                                          remote_source:
                                            currentCandidate?.remote_source,
                                          matchProfileId: currentCandidate?._id,
                                        });
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                      >
                                        <path
                                          d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z"
                                          stroke="#252E4B"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Edit
                                    </div>
                                  </ProtectedComponent>

                                  <ProtectedComponent
                                    showModal={true}
                                    code={
                                      checkResource
                                        ? "Candidate_update"
                                        : "no_code"
                                    }
                                  >
                                    <div
                                      className="px-3 w-full py-2 rounded-md | cursor-pointer | hover:bg-gray-200 | flex gap-2 items-center"
                                      onClick={handleApplyClick}
                                    >
                                      <VscGitStashApply
                                        className="text-gray-700"
                                        size={16}
                                      />
                                      Apply job
                                    </div>
                                  </ProtectedComponent>

                                  <ProtectedComponent
                                    showModal={true}
                                    code={
                                      checkResource
                                        ? "Candidate_update"
                                        : "no_code"
                                    }
                                  >
                                    <div
                                      className=" px-3 w-full py-2 rounded-md | cursor-pointer | hover:bg-gray-200 | flex gap-2 items-center"
                                      onClick={() => {
                                        setCandidateDetail({
                                          name: currentCandidate?.applicant
                                            ?.first_name,
                                          id: currentCandidate?._id,
                                        });
                                        setShowReviewModal(
                                          "delete-candidate-modal",
                                        );
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M3.33333 5.83398V12.5007C3.33333 13.9734 4.52724 15.1673 6 15.1673H10C11.4728 15.1673 12.6667 13.9734 12.6667 12.5007V5.83398M9.33333 7.83398V11.834M6.66667 7.83398L6.66667 11.834M10.6667 3.83398L9.72916 2.42772C9.48187 2.05679 9.06556 1.83398 8.61975 1.83398H7.38025C6.93444 1.83398 6.51813 2.05679 6.27084 2.42772L5.33333 3.83398M10.6667 3.83398H5.33333M10.6667 3.83398H14M5.33333 3.83398H2"
                                          stroke="#FF475D"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      Delete
                                    </div>
                                  </ProtectedComponent>

                                  {resources?.filter(
                                    (item) => item === "Candidate_create",
                                  )?.length > 0 && (
                                    <div
                                      className=" px-3 w-full py-2 rounded-md | cursor-pointer | hover:bg-gray-200 | flex gap-2 items-center"
                                      onClick={() => {
                                        saveForLater(
                                          !currentCandidate?.favourite,
                                          currentCandidate?._id,
                                        );
                                      }}
                                    >
                                      {!currentCandidate?.favourite ? (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="23"
                                          viewBox="0 0 20 23"
                                          fill="none"
                                        >
                                          <path
                                            d="M14.5 1.5H4.86C2.73 1.5 1 3.24 1 5.36V19.45C1 21.25 2.29 22.01 3.87 21.14L8.75 18.43C9.27 18.14 10.11 18.14 10.62 18.43L15.5 21.14C17.08 22.02 18.37 21.26 18.37 19.45V5.36C18.36 3.24 16.63 1.5 14.5 1.5Z"
                                            stroke="#5F6989"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                        >
                                          <path
                                            d="M16.8203 1.91016H7.18031C5.06031 1.91016 3.32031 3.65016 3.32031 5.77016V19.8602C3.32031 21.6602 4.61031 22.4202 6.19031 21.5502L11.0703 18.8402C11.5903 18.5502 12.4303 18.5502 12.9403 18.8402L17.8203 21.5502C19.4003 22.4302 20.6903 21.6702 20.6903 19.8602V5.77016C20.6803 3.65016 18.9503 1.91016 16.8203 1.91016ZM15.6203 9.03016L11.6203 13.0302C11.4703 13.1802 11.2803 13.2502 11.0903 13.2502C10.9003 13.2502 10.7103 13.1802 10.5603 13.0302L9.06031 11.5302C8.77031 11.2402 8.77031 10.7602 9.06031 10.4702C9.35031 10.1802 9.83031 10.1802 10.1203 10.4702L11.0903 11.4402L14.5603 7.97016C14.8503 7.68016 15.3303 7.68016 15.6203 7.97016C15.9103 8.26016 15.9103 8.74016 15.6203 9.03016Z"
                                            fill="#5F6989"
                                          />
                                        </svg>
                                      )}
                                      {currentCandidate?.favourite
                                        ? "Unsave"
                                        : "Save"}
                                    </div>
                                  )}
                                </div>
                              );
                            }}
                          >
                            <button
                              className="candidate-other-status-button border-button rounded-lg border h-6 aspect-square border-gray-500 bg-white py-2 px-2.5 ml-3 flex items-center justify-center"
                              style={{ color: "#FF475D" }}
                            >
                              <svg
                                width="4"
                                height="16"
                                viewBox="0 0 4 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1 14C1 14.5523 1.44772 15 2 15C2.55228 15 3 14.5523 3 14C3 13.4477 2.55228 13 2 13C1.44772 13 1 13.4477 1 14Z"
                                  stroke="#96969F"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M1 8C1 8.55228 1.44772 9 2 9C2.55228 9 3 8.55228 3 8C3 7.44772 2.55228 7 2 7C1.44772 7 1 7.44772 1 8Z"
                                  stroke="#96969F"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M1 2C1 2.55228 1.44772 3 2 3C2.55228 3 3 2.55228 3 2C3 1.44772 2.55228 1 2 1C1.44772 1 1 1.44772 1 2Z"
                                  stroke="#96969F"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </button>
                          </Dropdown>
                        )}
                      </div>
                    </div>
                    {/* <div className="flex">
                      {currentCandidate?.applicant?.linkedin?.length > 0 && (
                        <>
                          {currentCandidate?.applicant?.linkedin?.toLowerCase() !=
                            "not specified" && (
                              <a
                                className="candidate-linkedin-icon border-button  rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md px-2 py-1 mr-2"
                                href={formatLink(
                                  currentCandidate?.applicant?.linkedin,
                                )}
                                target="_blank"
                                rel="noreferrer"
                                data-tooltip-id="my-tooltip-4"
                                data-tooltip-content={"Linkedin"}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                >
                                  <path
                                    d="M4.35313 3.55556C4.35295 3.89233 4.2138 4.21523 3.96629 4.45324C3.71878 4.69125 3.38318 4.82487 3.03333 4.8247C2.68347 4.82453 2.34801 4.69059 2.10075 4.45234C1.85348 4.21409 1.71467 3.89106 1.71484 3.55429C1.71502 3.21753 1.85417 2.89462 2.10168 2.65661C2.34919 2.41861 2.68479 2.28499 3.03465 2.28516C3.3845 2.28532 3.71996 2.41927 3.96723 2.65751C4.21449 2.89576 4.3533 3.2188 4.35313 3.55556ZM4.3927 5.76496H1.75442V13.7137H4.3927V5.76496ZM8.56119 5.76496H5.9361V13.7137H8.53481V9.54253C8.53481 7.21885 11.681 7.00299 11.681 9.54253V13.7137H14.2863V8.67909C14.2863 4.76184 9.6297 4.90787 8.53481 6.83157L8.56119 5.76496Z"
                                    fill="#5F6989"
                                  />
                                </svg>
                              </a>
                            )}
                        </>
                      )}
                      {!isPublic && (
                        <button
                          className="candidate-email-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7 px-2 py-1 mr-2 relative"
                          onClick={(e) => {
                            if (
                              contactLoading === "email" ||
                              contactLoading === "phone"
                            ) {
                              return;
                            }
                            // Creating a new object to prevent mutation of the original data
                            if (
                              currentCandidate?.applicant?.email?.length > 0
                            ) {
                              const newData = {
                                ...currentCandidate?.applicant, // Copy existing properties from iter?.applicant
                                summary: currentCandidate?.matchSummary, // Add or update the 'summary' property
                                matchId:
                                  currentCandidate?.id ?? currentCandidate?._id,
                              };

                              // Assuming 'setChatDetail' and other functions are defined and work correctly
                              setChatDetail(newData);
                              setShowReviewModal("send-email-modal");
                            } else handleGetContact("email");
                          }}
                          data-tooltip-id="my-tooltip-4"
                          data-tooltip-content={"Email"}
                        >
                          {currentCandidate?.applicant?.email?.length >
                            0 ? null : (
                            <div className="rounded-full h-2 w-2 bg-orange-900 | absolute -top-1 -right-1"></div>
                          )}
                          {contactLoading === "email" ? (
                            <ClipLoader color="blue" loading={true} size={15} />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="12"
                              viewBox="0 0 14 12"
                              fill="none"
                            >
                              <g clipPath="url(#clip0_2992_136007)">
                                <rect
                                  x="1.5"
                                  y="1.15332"
                                  width="11"
                                  height="9.9"
                                  rx="2"
                                  fill="#5F6989"
                                  stroke="white"
                                />
                                <path
                                  d="M1.5 3.35352L4.50122 5.75449C5.96209 6.92319 8.03791 6.92319 9.49878 5.75449L12.5 3.35352"
                                  stroke="white"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_2992_136007">
                                  <rect
                                    width="12.1"
                                    height="11"
                                    fill="white"
                                    transform="translate(0.949219 0.5)"
                                  />
                                </clipPath>
                              </defs>
                            </svg>
                          )}
                        </button>
                      )}
                      {!isPublic && (
                        <button
                          className="candidate-phone-icon border-button rounded-md border border-solid border-gray-gray-4 bg-gray-gray-1 shadow-md max-h-7  px-2 py-1 mr-2 relative"
                          onClick={(e) => {
                            if (
                              contactLoading === "email" ||
                              contactLoading === "phone"
                            ) {
                              return;
                            }

                            if (
                              currentCandidate?.applicant?.phone?.length > 0
                            ) {
                              setHideCallBar(true);
                              setChatDetail(currentCandidate?.applicant);
                            } else handleGetContact("phone");
                          }}
                          data-tooltip-id="my-tooltip-4"
                          data-tooltip-content={"Call"}
                        >
                          {currentCandidate?.applicant?.phone?.length >
                            0 ? null : (
                            <div className="rounded-full h-2 w-2 bg-orange-900 | absolute -top-1 -right-1"></div>
                          )}
                          {contactLoading === "phone" ? (
                            <ClipLoader color="blue" loading={true} size={15} />
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                d="M5.73926 2.48338C5.58613 2.04044 5.21536 1.75 4.80305 1.75H3.4162C2.88862 1.75 2.46094 2.24472 2.46094 2.85514C2.46094 8.04371 6.09637 12.25 10.5808 12.25C11.1084 12.25 11.5359 11.7551 11.5359 11.1447L11.5362 9.53981C11.5362 9.06275 11.2852 8.63383 10.9024 8.45666L9.57318 7.84171C9.2293 7.68257 8.83778 7.75419 8.55326 8.02852L8.21021 8.35956C7.80957 8.74585 7.22008 8.71513 6.85132 8.28846L5.88535 7.16978C5.51658 6.74311 5.48933 6.06161 5.8232 5.59807L6.10925 5.20117C6.34635 4.87197 6.40881 4.41884 6.27126 4.02097L5.73926 2.48338Z"
                                fill="#5F6989"
                              />
                            </svg>
                          )}
                        </button>
                      )}
                    </div> */}

                    {/* <div className="flex space-x-2 items-center my-4 ">
                    <div className="relative">
                      <div className="flex items-center gap-2">
                        <button
                          onClick={() => setShowStages(!showStages)}
                          className="border-button flex items-center rounded-lg border h-8 border-gray-500 bg-white py-2 px-2.5 justify-center max-w-[160px] min-w-[140px]"
                        >
                          <span className="text-gray-800 text-sm font-normal not-italic hover:text-blue-900 focus:text-blue-900 max-w-[140px] truncate">
                            {currentCandidate.current_stage}
                          </span>
                          <span className="ml-8px">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                            >
                              <path
                                d="M6.71838 8.30751L6.71753 8.30657L5.18845 6.62649C5.17672 6.6136 5.17066 6.60014 5.16811 6.58407C5.1652 6.56574 5.16722 6.54403 5.17472 6.5241C5.17827 6.51464 5.18239 6.50742 5.18595 6.50247C5.18662 6.50154 5.18724 6.50072 5.18782 6.5L8.14833 6.5L10.8114 6.5C10.8151 6.50456 10.8205 6.51266 10.8253 6.52534C10.8331 6.54588 10.8351 6.56776 10.8323 6.58575C10.8299 6.60139 10.8241 6.61416 10.8129 6.62649L8.34541 9.33765C8.14851 9.55399 7.84854 9.55412 7.65152 9.33803C7.6514 9.33791 7.65128 9.33778 7.65116 9.33765L6.71838 8.30751Z"
                                fill="#252E4B"
                                stroke="#252E4B"
                              />
                            </svg>
                          </span>
                        </button>
                      </div>
                      {showStages && (
                        <div className="absolute w-[160px] top-[40px] right-0 bg-white rounded-lg p-1 shadow-menuOptionShadow z-10">
                          <ul>
                            {pipelineStages
                              .filter(
                                (item) =>
                                  item.stage !==
                                  currentCandidate.current_stage &&
                                  item.stage !== "Rejected",
                              )
                              .map((item, index) => (
                                <li
                                  key={index}
                                  className="p-2 text-gray-800 text-sm font-normal not-italic rounded-lg cursor-pointer hover:bg-bg1"
                                  onClick={() => {
                                    updateStatus(
                                      currentCandidate?._id,
                                      item.stage,
                                      "",
                                    );
                                    setShowStages(false);
                                  }}
                                >
                                  {item.stage}
                                </li>
                              ))}
                          </ul>
                        </div>
                      )}
                    </div>
                    {currentCandidate?.current_stage != "Rejected" && (
                      <button
                        className="px-6 py-2 bg-red-200 h-8 text-red-900 rounded hover:bg-red-300 flex items-center gap-2"
                        onClick={() => {
                          setChatDetail({
                            id: currentCandidate?._id,
                            email: currentCandidate?.applicant?.email,
                            name: currentCandidate?.applicant?.first_name,
                          });
                          setShowReject("Show_reject_modal");
                        }}
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 2048 2048"
                          >
                            <path
                              fill="currentColor"
                              d="m1115 1024l690 691l-90 90l-691-690l-691 690l-90-90l690-691l-690-691l90-90l691 690l691-690l90 90z"
                            />
                          </svg>
                        </span>
                        Reject
                      </button>
                    )}
                  </div> */}
                    <div>
                      <span className="inline cursor-pointer py-[2px] px-[6px] bg-blue-50 rounded-[4px]  text-[12px] not-italic font-normal text-blue-700">
                        {currentCandidate?.current_source
                          ? currentCandidate?.current_source
                          : null}
                      </span>
                    </div>
                    <div className="flex items-center flex-wrap gap-4 my-2">
                      <div>
                        <h3 className="text-sm font-medium">
                          Interview{" : "}
                          <span
                            className={`text-sm py-[2px] px-[6px] ${String(currentCandidate?.interview_status).toLowerCase() === "invited" || String(currentCandidate?.interview_status).toLowerCase() === "scheduled" ? " text-green-500" : " text-yellow-400"}`}
                          >
                            {currentCandidate?.interview_status}
                          </span>
                        </h3>
                      </div>
                      <div>
                        <h3 className="text-sm font-medium">
                          Availability{" : "}
                          <span
                            className={`text-sm py-[2px] px-[6px]  ${String(currentCandidate?.availablity_status).toLowerCase() === "completed" ? " text-green-500" : " text-yellow-400"}`}
                          >
                            {currentCandidate?.availablity_status}
                          </span>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col gap-6 mt-4">
                    <div>
                      <h3 className="text-base font-semibold mb-2">
                        Profile Highlights
                      </h3>
                      {currentCandidate?.profileHighlights?.length > 0 ? (
                        <div className="flex flex-wrap gap-2">
                          {currentCandidate?.profileHighlights
                            .slice(0, 5)
                            .map((highlight, index) => (
                              <span className="bg-gray-200 text-gray-800 text-sm px-3 py-1 rounded-lg">
                                {highlight}
                              </span>
                            ))}
                        </div>
                      ) : (
                        <div className="text-gray-500 text-base flex flex-col items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fill="currentColor"
                              d="M8 8.5a1.5 1.5 0 1 1-3 0a1.5 1.5 0 0 1 3 0m-4 3.199a.95.95 0 0 1 .949-.949H8.05a.95.95 0 0 1 .949.949c0 .847-.577 1.585-1.399 1.791l-.059.015c-.684.17-1.4.17-2.084 0l-.06-.015A1.846 1.846 0 0 1 4 11.699M11.5 8a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM2 5.75C2 4.784 2.784 4 3.75 4h12.5c.966 0 1.75.784 1.75 1.75v8.5A1.75 1.75 0 0 1 16.25 16H3.75A1.75 1.75 0 0 1 2 14.25zM3.75 5a.75.75 0 0 0-.75.75v8.5c0 .414.336.75.75.75h12.5a.75.75 0 0 0 .75-.75v-8.5a.75.75 0 0 0-.75-.75z"
                            />
                          </svg>
                          <p className="mt-2 text-sm font-medium text-gray-900">
                            No profile highlights
                          </p>
                        </div>
                      )}
                    </div>

                    <div className="mt-2">
                      <h3 className="text-base font-semibold ">
                        Match Summary
                      </h3>
                      {currentCandidate?.matchSummary?.length > 0 ? (
                        <p className="text-gray-700 my-2">
                          {currentCandidate?.matchSummary}
                        </p>
                      ) : (
                        <div className="text-gray-500 text-base flex flex-col items-center justify-center">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="currentColor"
                              d="M21 8h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2m0 4h-8a1 1 0 0 1 0-2h8a1 1 0 0 1 0 2"
                              opacity="0.5"
                            />
                            <rect
                              width="8"
                              height="8"
                              x="2"
                              y="4"
                              fill="currentColor"
                              rx="1"
                            />
                            <path
                              fill="currentColor"
                              d="M21 16H3a1 1 0 0 1 0-2h18a1 1 0 0 1 0 2m-8 4H3a1 1 0 0 1 0-2h10a1 1 0 0 1 0 2"
                              opacity="0.5"
                            />
                          </svg>
                          <p className="mt-2 text-sm font-medium text-gray-900">
                            No match summary
                          </p>
                        </div>
                      )}

                      <div className="flex flex-wrap gap-8 my-2">
                        {currentCandidate?.overallMatch && (
                          <MatchIndicator
                            label="Overall Match"
                            percentage={Math.round(
                              currentCandidate?.overallMatch * 100,
                            )}
                          />
                        )}
                        {currentCandidate?.skillsMatch && (
                          <MatchIndicator
                            label="Skills Match"
                            percentage={Math.round(
                              currentCandidate?.skillsMatch * 100,
                            )}
                          />
                        )}
                        {currentCandidate?.educationMatch && (
                          <MatchIndicator
                            label="Education Match"
                            percentage={Math.round(
                              currentCandidate?.educationMatch * 100,
                            )}
                          />
                        )}
                        {currentCandidate?.jobPositionMatch && (
                          <MatchIndicator
                            label="Job Position Match"
                            percentage={Math.round(
                              currentCandidate?.jobPositionMatch * 100,
                            )}
                          />
                        )}
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="w-1/3 text-sm font-medium">
                          Location Match
                        </span>
                        <span
                          className={`text-sm font-semibold ${currentCandidate?.location_match?.toLowerCase() === "yes" ? "text-[#4CAF50]" : "text-red-500"}`}
                        >
                          {currentCandidate?.location_match === "yes"
                            ? "Yes"
                            : "No"}
                        </span>
                      </div>
                      <div className="flex items-center mb-2">
                        <span className="w-1/3 text-sm font-medium">
                          Experience
                        </span>
                        <span className="text-sm font-medium">
                          {currentCandidate?.applicant.years_experience} years,{" "}
                          {currentCandidate?.applicant.months_experience} months
                        </span>
                      </div>
                    </div>
                    <div className="mt-4 bg-white">
                      <h3 className="text-base font-semibold mb-2 text-gray-800">
                        Work Experience
                      </h3>
                      {loading ? (
                        <div className="flex justify-center items-center h-40">
                          <ScaleLoader
                            height={35}
                            width={4}
                            radius={2}
                            margin={2}
                            color="#1369e9"
                            loading={true}
                          />
                        </div>
                      ) : (isPublic
                          ? candidateExperience?.work_experience
                          : CandidateExp?.work_experience
                        )?.length > 0 ? (
                        <div className="relative">
                          {(isPublic
                            ? candidateExperience.work_experience
                            : CandidateExp.work_experience
                          ).map((exp, index) => (
                            <div key={index} className="mb-8 flex">
                              <div className="flex flex-col items-center mr-6">
                                <div className="w-4 h-4 bg-gray-600 rounded-full shadow-sm"></div>
                                {index !==
                                  (isPublic
                                    ? candidateExperience?.work_experience
                                    : CandidateExp?.work_experience
                                  ).length -
                                    1 && (
                                  <div className="w-0.5 h-full bg-gray-300 transform translate-x-1/2"></div>
                                )}
                              </div>
                              <div className="bg-gray-50 p-5 rounded-lg shadow-md flex-grow transition-all duration-300 hover:shadow-lg">
                                <div className="flex justify-between items-start mb-3">
                                  <div>
                                    <h4 className="font-semibold text-base ">
                                      {exp?.position}
                                    </h4>
                                    <p className="text-gray-700 font-medium">
                                      {exp?.company}
                                    </p>
                                  </div>
                                  <p className="text-sm text-gray-600 bg-gray-200 px-3 py-1 rounded-full">
                                    {moment(exp?.start).format("MMM YYYY")} -
                                    {exp?.ongoing
                                      ? "Present"
                                      : moment(exp.end).format("MMM YYYY")}
                                  </p>
                                </div>
                                {exp?.description && (
                                  <TruncatedDescription
                                    description={exp?.description}
                                  />
                                )}
                                {exp?.skills && exp?.skills.length > 0 && (
                                  <div className="mt-4">
                                    <p className="font-semibold text-gray-700 mb-2">
                                      Skills:
                                    </p>
                                    <div className="flex flex-wrap gap-2">
                                      {exp.skills.map((skill, skillIndex) => (
                                        <span
                                          key={skillIndex}
                                          className="bg-gray-300 text-gray-800 text-sm px-3 py-1 rounded-lg"
                                        >
                                          {skill}
                                        </span>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div className="text-center py-10">
                          <svg
                            className="mx-auto h-12 w-12 text-gray-400"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              vectorEffect="non-scaling-stroke"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                            />
                          </svg>
                          <h3 className="mt-2 text-sm font-medium text-gray-900">
                            No work experience
                          </h3>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex-1 flex flex-col mb-4 rounded-lg shadow-md bg-white p-4 ">
                  <div className="flex gap-8">
                    {btnList?.map((btn) => (
                      <button
                        onClick={() => {
                          setAvtiveTabName(btn.ButtonName);
                        }}
                        className={`text-md font-medium pt-2 pb-3.5 border-t-0 border-b-2 border-x-0 ${
                          btn.ButtonName == activeTabName
                            ? "border-[#1369e9] text-[#16182f]"
                            : "border-transparent text-[#252e4b]"
                        } border-solid hover:border-blue-600`}
                      >
                        {btn?.ButtonName}
                      </button>
                    ))}
                  </div>
                  <div className="p-4  ">
                    {activeTabName === "Resume" && (
                      <div
                        key={currentCandidate?._id}
                        className="w-full  relative "
                        style={{ height: "calc(100vh - 130px)" }}
                      >
                        {/* {pdfLoading && (
                        <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-75">
                          <ScaleLoader
                            height={20}
                            color="#1369e9"
                            loading={true}
                          />
                        </div>
                      )} */}
                        {/* {currentCandidate?.applicant?.resume_link ? (
                          <iframe
                            className="w-full h-full"
                            title="PDF Viewer"
                            src={currentCandidate.applicant.resume_link}
                            onLoad={() => setPdfLoading(false)}
                          />
                        ) : (
                          <>
                            <div className="h-full flex justify-center">
                              <p>No resume found</p>
                            </div>
                          </>
                        )} */}
                        {loadingresume ? (
                          <div className="h-full w-full flex items-center justify-center">
                            <ScaleLoader
                              height={40}
                              color="#1369e9"
                              loading={true}
                            />
                          </div>
                        ) : (
                          <>
                            {currentCandidate?.applicant?.resume_link ? (
                              <DocViewer
                                className="scrollbar-candidate"
                                style={{
                                  borderColor: "lightgray",
                                  overflowY: "scroll",
                                  borderRadius: 10,
                                  borderWidth: 1,
                                  // minHeight: 500,
                                }}
                                config={{
                                  header: {
                                    disableHeader: true,
                                    disableFileName: true,
                                    retainURLParams: true,
                                  },
                                  noRenderer: {
                                    overrideComponent: () => (
                                      <div className="w-full h-full flex justify-center items-center">
                                        Failed to load
                                      </div>
                                    ),
                                  },
                                  pdfZoom: {
                                    defaultZoom: 0.8, // 1 as default,
                                  },
                                }}
                                documents={[
                                  {
                                    uri: currentCandidate?.applicant
                                      ?.resume_link,
                                  },
                                ]}
                                pluginRenderers={DocViewerRenderers}
                              />
                            ) : (
                              <>
                                <div className="flex flex-col items-center justify-center my-4 gap-2 h-full">
                                  <NotFoundDefaultIcon
                                    width="200"
                                    height="180"
                                  />
                                  <p className="text-base font-medium ">
                                    Resume not found
                                  </p>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    {activeTabName === "Notes" && (
                      <div className="h-full">
                        {notes?.length > 0 ? (
                          <>
                            <div className="grid grid-cols-1 gap-4">
                              {notes?.map((note, index) => (
                                <div
                                  key={index}
                                  className="bg-gray-50 p-4 rounded"
                                >
                                  <div className="flex gap-4">
                                    <InitialsLogo
                                      str={note?.user?.name}
                                      borderRadius="50%"
                                      width="30px"
                                      height="30px"
                                    />
                                    <div className="flex flex-col">
                                      <span className="flex items-center gap-4">
                                        {" "}
                                        <span className=" text-sm not-italic  text-gray-800 truncate font-semibold">
                                          {note?.user?.name}
                                        </span>{" "}
                                        -{" "}
                                        <span className=" text-sm not-italic  text-gray-800 truncate">
                                          {note?.createdAt}
                                        </span>
                                      </span>
                                      <p className="text-gray-700">
                                        {note?.body}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </>
                        ) : (
                          <div className="flex flex-col items-center justify-center my-24 gap-2 h-full">
                            <NotFoundDefaultIcon width="200" height="180" />
                            <p className="text-base font-medium ">No notes</p>
                          </div>
                        )}
                      </div>
                    )}
                    {activeTabName === "Feedback" && (
                      <div>
                        <FeedbackIndividual
                          appId={currentCandidate?._id}
                          candidateData={candidateData}
                          review={true}
                          jobId={jobId}
                          checkResource={checkResource}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
      {ShowReject == "Show_reject_modal" && (
        <RejectedReasonModal
          updateRejectedStatus={updateStatus}
          modalDismiss={RejectModalClose}
          chatDetail={chatDetail}
          candidateId={currentCandidate?._id}
          jobId={jobId}
        />
      )}
      {ShowReviewModal == "send-email-modal" && (
        <SendEmail
          chatDetail={chatDetail}
          companydata={companydata}
          positionData={positionData}
          candidateId={currentCandidate?.id}
          modalDismiss={() => {
            setShowReviewModal("");
          }}
        />
      )}

      {ShowReviewModal == "send-notes" && (
        <Notes
          modalDismiss={() => {
            setShowReviewModal("");
            getApplicantsDetails();
          }}
          id={currentCandidate?._id}
        />
      )}
      {ShowReviewModal == "update_can_data" && (
        <ChangeCandidatesDataModal
          candidateDetail={{
            index: currentIndex,
            name: currentCandidate?.applicant?.first_name,
            last_name: currentCandidate?.applicant?.last_name,
            id: currentCandidate?.applicant?._id,
            title: currentCandidate?.applicant?.title,
            email: currentCandidate?.applicant?.email,
            phone: currentCandidate?.applicant?.phone,
            work_phone: currentCandidate?.applicant?.work_phone,
            work_email: currentCandidate?.applicant?.work_email,
            linkedln: currentCandidate?.applicant?.linkedin,
            source: currentCandidate?.current_source,
            matchProfileId: currentCandidate?._id,
            remote_source: currentCandidate?.remote_source,
            resume_link: currentCandidate?.applicant?.resume_link,
          }}
          jobId={jobId}
          loadData={(newObj) =>
            setCandidates((prevData) =>
              prevData?.map((candidate, index) =>
                index === currentIndex
                  ? {
                      ...candidate,
                      applicant: newObj,
                      current_source: newObj?.current_source,
                      remote_source: newObj?.remote_source ?? "",
                    }
                  : candidate,
              ),
            )
          }
          modalDismiss={() => setShowReviewModal("")}
        />
      )}

      {applyDropDown > -1 && (
        <ApplyForJobModal
          jobId={jobId}
          id={applyDropDown}
          candidate={currentCandidate?.applicant?._id}
          modalDismiss={handleModalDismiss}
        />
      )}
      {ShowReviewModal == "delete-candidate-modal" && (
        <Modal
          modalDismiss={() => {
            setShowReviewModal("");
          }}
          heading={currentCandidate?.applicant?.first_name}
          subheading="Are you sure you want to remove this candidate."
          defaultButtons={{
            primaryClass: "hover:!bg-[#ad3333] !bg-red-900 !border-[#ad3333]",
            primaryLabel: "Remove",
          }}
          onSave={removeCandidate}
          isLoading={IsremoveCandidateLoading}
        />
      )}
    </div>
  );
};

export default ReviewCandidates;
