import { useEffect, useMemo, useState } from "react";
import * as d3 from "d3";
import ProgressBar from "../../../analytics/ProgressBar";
import DualAxisGanttChart from "../../../analytics/GanttChart";
import InlineGanttChart from "../../../analytics/InlineGanttChart";
import LineChart from "../../../analytics/LineChart";
import { Range, getTrackBackground } from "react-range";
import SkillsColumnChart from "../../../analytics/SkillsColumnChart";
import { getTypeColor, getTypeName } from "../../createJob2/TypesButton";

export default function JobAnalytics({
  job,
  classTxt,
  hide,
  onlyAnalytics,
  preferCriteria,
}) {
  console.log(job);
  console.log(preferCriteria);
  console.log(onlyAnalytics, classTxt);
  const [skills, setSkills] = useState(job?.skills);
  const [width, setWidth] = useState(500);
  useEffect(() => {
    window.addEventListener("resize", setChartWidth);
    setSkills(job?.skills);
    setChartWidth();
  }, [job, onlyAnalytics]);

  const checkLabel = (label1, label2) =>
    label1 ? label1 : label2 ? label2 : null;

  function setChartWidth() {
    try {
      if (onlyAnalytics) {
        setTimeout(() => {
          if (preferCriteria?.length > 0)
            setWidth(
              Math.floor(
                d3.select("#job-analytics").node().getBoundingClientRect()
                  .width,
              ) ?? 500,
            );
          else
            setWidth(
              Math.floor(
                d3.select("#job-analytics").node().getBoundingClientRect()
                  .width,
              ) ?? 500,
            );
        }, 500);
      } else {
        setTimeout(() => {
          setWidth(
            Math.floor(
              d3?.select("#job-analytics").node()?.getBoundingClientRect()
                ?.width,
            ) ?? 500,
          );
        }, 500);
      }
    } catch (err) {
      console.error(err);
      setChartWidth(500);
    }
  }
  let categories = [...new Set(preferCriteria?.map((d) => d?.category))];

  let preferences = useMemo(() => {
    const nestedData = {};
    // Iterate through the data array
    preferCriteria?.forEach((item) => {
      const { category, relationId } = item;

      // Create the category group if it doesn't exist
      if (!nestedData[category]) {
        nestedData[category] = {};
      }

      // Add the item to the category group
      if (!nestedData[category][relationId]) {
        nestedData[category][relationId] = [];
      }

      nestedData[category][relationId].push(item);
    });

    return nestedData;
  }, [preferCriteria]);

  return (
    // job != null && (
    <div className={classTxt} id="job-analytics">
      {skills?.length > 0 && (
        <div className={onlyAnalytics ? "w-full relative" : "w-full relative"}>
          {!hide && (
            <>
              <ul
                className={`w-full flex flex-row flex-wrap justify-between items-center mb-[18px] rounded-lg border-gray-300 border-2 list-none`}
              >
                {[2, 1, -1, -2].map((type, i) => (
                  <li key={i} className="py-1 px-2">
                    <span
                      style={{
                        background: getTypeColor(type),
                      }}
                      className={`inline-block w-2 h-2 rounded-full mr-2`}
                    />
                    {getTypeName(type)}
                  </li>
                ))}
              </ul>
              <h4 className="text-[#16182F] text-[16px] not-italic font-medium">
                Skills
              </h4>
              {/* <div className="bg-slate-600 "> */}
              <div className="max-w-full relative overflow-x-scroll ">
                <LineChart
                  id="job-skills"
                  data={skills?.filter((iter) => iter?.criteria != "Misc")}
                  chartHeight={240}
                  width={Math.max(width - 20, skills?.length * 5)}
                  color={"steelblue"}
                  clickFunction={false}
                />
              </div>
              {/* </div> */}
            </>
          )}
          <div>
            {hide && (
              <h4 className="text-[#16182F] text-[16px] not-italic font-medium">
                Skills
              </h4>
            )}

            {skills?.length > 0 &&
              skills.map(
                (data, it) =>
                  data?.skills?.length > 0 && (
                    <div className="w-full mb-4" key={it}>
                      <h4 className="text-gray-700 text-sm not-italic font-medium mb-3">
                        {data?.criteria}
                      </h4>
                      <div className="w-full border p-2 border-[#E1E1EE] rounded-xl relative">
                        {data?.skills.map((skill, i) => (
                          <div
                            className=" flex last-of-type:pb-0 pb-2 items-center ml-0"
                            key={i}
                          >
                            <span
                              className="w-[45%] text-[#252E4B] text-[14px] not-italic font-normal leading-7 truncate overflow-hidden  mr-[10px]"
                              onMouseOver={(e) => {
                                var overflowed =
                                  e.target.scrollWidth > e.target.clientWidth;
                                e.target.title = overflowed
                                  ? e.target.textContent
                                  : "";
                              }}
                              onMouseOut={(e) => (e.target.title = "")}
                            >
                              {skill?.label}
                            </span>
                            <span className="w-[45%]  mr-[10px]">
                              <ProgressBar
                                id={"skills-bar" + it + i}
                                style_classes="w-full"
                                data={Math.abs(skill?.value)}
                                height={8}
                                barColor={getTypeColor(skill?.type) ?? "teal"}
                                bgColor="#EFF0F2"
                                width={width * 0.45 - 10}
                                fromRight={skill?.value < 0}
                              />
                            </span>
                            <span style={{ width: 20 }}>
                              {Math.floor(skill?.value)}
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ),
              )}
          </div>
        </div>
      )}
      {Object.entries(preferences)?.length > 0 && (
        <div className={onlyAnalytics ? "w-full" : "w-full"}>
          <div className="flex flex-col justify-evenly items-center">
            {categories?.map((category, it) => (
              <div className=" mb-[16px] w-full" key={it}>
                <div className=" flex justify-between items-center ">
                  <h4 className="text-[#16182F] text-[16px] not-italic font-medium">
                    {category}
                  </h4>
                </div>

                <div className="flex flex-col mt-[11px]">
                  {Object.values(preferences[category])?.map((related, i) => (
                    <div
                      className={`relative flex flex-col float-right items-center ml-0  ${
                        related.length > 1
                          ? "border rounded-lg pr-4 pl-5 py-2"
                          : "px-2 mt-2"
                      } border-[#E1E1EE] bg-white`}
                      key={i}
                    >
                      {related?.length > 1 && (
                        <p
                          style={{ top: 16 * (related?.length - 1) + 2 }}
                          className={`absolute -left-3 bg-gray-200 p-px rounded align-middle border border-gray-300`}
                        >
                          OR
                        </p>
                      )}
                      {related.map((row) => (
                        <div className="w-full flex items-center justify-between">
                          <span
                            className="w-[45%] text-[#252E4B] text-[14px] not-italic font-normal leading-7 truncate overflow-hidden  capitalize  mr-[10px] "
                            onMouseOver={(e) => {
                              var overflowed =
                                e.target.scrollWidth > e.target.clientWidth;
                              e.target.title = overflowed
                                ? e.target.textContent
                                : "";
                            }}
                            onMouseOut={(e) => (e.target.title = "")}
                          >
                            {row?.label}
                          </span>
                          <span className="w-[45%]  mr-[10px]">
                            <ProgressBar
                              id={"skills-bar" + it + i}
                              style_classes="w-full"
                              data={Math.abs(row?.value)}
                              height={8}
                              barColor={getTypeColor(row?.type)}
                              bgColor="#EFF0F2"
                              width={width * 0.45 - 10}
                              fromRight={row?.value < 0}
                            />
                          </span>
                          <span style={{ width: 20 }}>
                            {Math.floor(row.value)}
                          </span>
                        </div>
                      ))}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* <div className={onlyAnalytics ? "row-span-1" : "w-full"}>
        <div className="w-full data-section experience">
          <div className="w-full section-header no-padding">
            <h4 className="col-xs-9 no-padding">Experience</h4>
          </div>
          <InlineGanttChart
            id="experienceChart"
            chartWidth={width}
            totalExperience={6}
            experienceRange={[0, 6]}
            dataList={[
              {
                name: "SDE",
                type: "Position",
                minvalue: 1,
                maxvalue: 4,
              },
              {
                name: "Sr. SDE",
                type: "Position",
                minvalue: 4,
                maxvalue: 6,
              },
              {
                type: "Industry",
                name: "Fintech",
                minvalue: 2,
                maxvalue: 4,
              },
              {
                type: "Company",
                name: "Startup",
                minvalue: 0,
                maxvalue: 2,
              },
            ]}
            yearWise={false}
            chartMargin={{ left: 90, bottom: 50, right: 90 }}
            barGap={2}
            barSize={30}
            dualAxis={true}
            colors={["#80BB01", "#1369E9"]}
          />
        </div>
      </div>
      <div className={onlyAnalytics ? "row-span-1" : "w-full"}>
        <div className="w-full data-section experience">
          <div className="w-full section-header no-padding">
            <h4 className="col-xs-9 no-padding">Education</h4>
          </div>
          <InlineGanttChart
            id="educationChart"
            chartWidth={width}
            colors={["#80BB01", "#1369E9"]}
            totalExperience={6}
            experienceRange={[2016, 2022]}
            yearWise={true}
            dataList={[
              {
                name: "",
                type: "Bachelors",
                minvalue: 2016,
                maxvalue: 2020,
              },
              {
                type: "Masters",
                name: "",
                minvalue: 2020,
                maxvalue: 2022,
              },
            ]}
            chartMargin={{ left: 100, bottom: 50 }}
            barGap={2}
            barSize={30}
            dualAxis={false}
          />
        </div>
      </div> */}
    </div>
    // )
  );
}
