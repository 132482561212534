import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import Modal from "../../../modal/Modal";
import InputBox from "../../../../layouts/InputBox";
import SelectBox from "../../../../layouts/SelectBox";
import { ScaleLoader } from "react-spinners";
import { JobPageContext } from "../../../../lib/context";
import { MdOutlineFileUpload } from "react-icons/md";
import { BiPlus } from "react-icons/bi";

import TabLayout from "../../../../layouts/TabLayout";
import UploadFiles from "./uploadCandidates/UploadFiles";
import { useStatusModal } from "../../../../App";
const core = require("../../../../lib/core");

// Add these validation helper functions at the top of your file, after imports
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// const isValidLinkedInUrl = (url) => {
//   const linkedinUrlPattern =
//     /^(https?:\/\/)?(www\.)?linkedin\.com\/in\/[a-zA-Z0-9_-]+(\/|\?.*)?$/;
//   return linkedinUrlPattern.test(url.trim());
// };

const isValidLinkedInUrl = (url) => {
  const linkedinUrlPattern =
    /^(https?:\/\/)?(www\.|[a-z]{2}\.)?linkedin\.com\/[\w\-\d\/]+(\?.*)?$/i;
  return linkedinUrlPattern.test(url.trim());
};

const isValidPhone = (phone) => {
  // Remove all characters except digits and +
  const cleanedPhone = phone.replace(/[^\d+]/g, "");
  // Check if the cleaned phone number matches our pattern
  const phoneRegex = /^\+?\d{8,15}$/;
  return phoneRegex.test(cleanedPhone);
};

const FileInput = ({ setFile, file, loading }) => {
  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  return (
    <div className="mb-6">
      <label className="block text-sm font-medium text-gray-700">
        Attach resume
      </label>
      <div className="flex items-center space-x-3">
        <label
          htmlFor="formFile"
          className="px-4 py-2 bg-gray-100 cursor-pointer text-gray-700 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 flex items-center"
          style={{ backgroundColor: "#F3F5F9" }}
        >
          <MdOutlineFileUpload size={18} className="mr-2" />
          Choose file
        </label>

        <input
          onChange={handleChange}
          className="hidden"
          type="file"
          id="formFile"
          accept="application/pdf"
        />

        {loading ? (
          <p className="text-sm mt-1 text-blue-700">Uploading...</p>
        ) : (
          <>
            {file ? (
              <p className="text-sm mt-1 text-blue-700">
                {file?.name}
                <span className="text-sm text-gray-500"> {file?.size} Kb</span>
              </p>
            ) : (
              <span className="text-sm text-gray-500">No file chosen</span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

function ChangeCandidatesDataModal({
  candidateDetail,
  jobId,
  modalDismiss,
  pipelines,
  loadData,
  from,
}) {
  const { setContentReload } = useContext(JobPageContext);
  const { showSuccess, showError } = useStatusModal();

  const [name, setName] = useState("");
  const [last_name, setLast_Name] = useState("");
  const [title, setTitle] = useState("");
  const [emails, setEmails] = useState([]);
  const [workEmails, setWorkEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [workPhones, setWorkPhones] = useState([]);
  const [source, setSource] = useState("");
  const [remoteSource, setRemoteSource] = useState("");
  const [prospect, setProspect] = useState("Yes");
  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState("");
  const [file, setFile] = useState(null);

  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("manual");
  const [linkedinUrl, setLinkedinUrl] = useState("");
  const [message, setMessage] = useState({});
  const [loadingFetch, setLoadingFetch] = useState(false);

  const [errors, setErrors] = useState({});
  const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    // Allow + and - characters, but store only the first + if present
    let formattedValue = value;
    if (value.includes("+")) {
      // Keep only the first + and remove others
      formattedValue = "+" + value.replace(/\+/g, "");
    }

    const newPhones = [...phones];
    newPhones[0] = formattedValue;
    setPhones(newPhones);

    // Only validate if user has attempted to submit
    if (hasAttemptedSubmit) {
      validateForm();
    }
  };

  const validateForm = () => {
    const newErrors = {};

    // Name validation
    if (!name.trim()) {
      newErrors.name = "First name is required";
    } else if (name.trim().length <= 1) {
      newErrors.name = "First name must be at least 2 characters";
    }

    // Email validation
    if (emails[0]?.length > 0 && !isValidEmail(emails[0])) {
      newErrors.email = "Please enter a valid email address";
    }

    // Work email validation
    if (workEmails[0]?.length > 0 && !isValidEmail(workEmails[0])) {
      newErrors.workEmail = "Please enter a valid work email address";
    }

    // Phone validation
    if (phones[0]?.length > 0) {
      if (!isValidPhone(phones[0])) {
        newErrors.phone = "Please enter a valid phone number";
      }
    }

    // Work Phone validation
    if (workPhones[0]?.length > 0) {
      if (!isValidPhone(workPhones[0])) {
        newErrors.workPhone = "Please enter a valid phone number";
      }
    }

    // LinkedIn URL validation
    if (linkedinUrl && !isValidLinkedInUrl(linkedinUrl)) {
      newErrors.linkedinUrl = "Please enter a valid LinkedIn profile URL";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const tabButtons = [
    // { id: "linkedin", name: "Import From LinkedIn" },
    { id: "manual", name: "Add Manually" },
    { id: "files", name: "Add files" },
    { id: "folder", name: "Add folder" },
  ];

  useEffect(() => {
    if (candidateDetail) {
      console.log("candidateDetail", candidateDetail);
      setName(candidateDetail?.name || "");
      setLast_Name(candidateDetail?.last_name || "");
      setTitle(candidateDetail?.title || "");
      setEmails(candidateDetail?.email || [""]);
      setWorkEmails(candidateDetail?.work_email || [""]);
      setPhones(candidateDetail?.phone || []);
      setWorkPhones(candidateDetail?.work_phone || []);
      setLinkedinUrl(candidateDetail?.linkedln || "");
      setSource(candidateDetail?.source || "");
      setRemoteSource(candidateDetail?.remote_source || "");
    }
  }, [candidateDetail]);
  console.log("remoteSource", remoteSource);

  const updateNotes = async () => {
    setHasAttemptedSubmit(true);
    if (!validateForm()) {
      return;
    }

    setLoading(true);

    try {
      let resumeUploaded = true;

      if (file) {
        resumeUploaded = await attachResume(file, "updateNotes");
      }

      if (!resumeUploaded) {
        modalDismiss();
        showError(
          "Resume Upload Failed",
          "Please check the file format and try again",
        );
        return;
      }

      const json = await core.API(
        core.API_METHODS.PUT,
        core.JOB_API.PUT_UPDATE_CANDIDATE_DETAILS +
          candidateDetail?.id +
          "/" +
          candidateDetail?.matchProfileId,
        1,
        {
          first_name: name,
          last_name,
          title,
          email: emails?.length > 0 && emails[0]?.length > 1 ? emails : [],
          work_email:
            workEmails?.length > 0 && workEmails[0]?.length > 1
              ? workEmails
              : [],
          phone: phones?.length > 0 && phones[0]?.length > 1 ? phones : [],
          work_phone:
            workPhones?.length > 0 && workPhones[0]?.length > 1
              ? workPhones
              : [],
          linkedin: linkedinUrl,
          source,
          remoteSource,
        },
      );

      if (json?.data) {
        setLoading(false);
        loadData(json?.data);
        modalDismiss();
        showSuccess(
          "Candidate updated successfully",
          "You can go through the updated details",
        );
      }
    } catch (error) {
      setLoading(false);
      modalDismiss();
      showError(
        "Failed to update Candidate details",
        "Please check the details again",
      );
    }
  };

  const createMatchprofileManually = async () => {
    setLoading(true);

    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.CREATE_MATCH_PROFILE_MANUALLY,
        1,
        {
          job: jobId,
          first_name: name,
          last_name,
          title,
          email: emails?.length > 0 && emails[0]?.length > 1 ? emails : [],
          work_email:
            workEmails?.length > 0 && workEmails[0]?.length > 1
              ? workEmails
              : [],
          phone: phones?.length > 0 && phones[0]?.length > 1 ? phones : [],
          work_phone:
            workPhones?.length > 0 && workPhones[0]?.length > 1
              ? workPhones
              : [],
          linkedin: linkedinUrl,
          remoteSource,
          source,
          stage,
          prospect,
        },
      );
      console.log("json", json);
      if (json?.data) {
        setLoading(false);
        modalDismiss();
        showSuccess(
          "Candidate Added successfully",
          "You can go through the updated candidate list",
        );
        setContentReload((prev) => prev + 1);
      } else {
        setLoading(false);
        modalDismiss();
        showError(
          "Candidate already exists",
          "The provided candidate details already exists for the job",
        );
      }
    } catch (error) {
      setLoading(false);
      modalDismiss();
      showError(
        "Failed to Create Candidate Profile",
        `Please check the candidate details and try again`,
      );
      console.log("error", error);
    }
  };

  const attachResume = async (file, from = "") => {
    let formData = new FormData();
    formData.append("files", file);
    formData.append("candidate_details_id", candidateDetail?.id);

    setLoading(true);
    try {
      const token = await core.getData(core.data.ACCESS_TOKEN);
      let res = await fetch(core.RECRUITER_API.ATTACH_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
        },
        body: formData,
      });

      if (res.status === 200) {
        setLoading(false);

        if (from !== "updateNotes") {
          modalDismiss();
          showSuccess(
            "Resume Uploaded Successfully",
            "Resume has been successfully attached to the candidate profile",
          );
        }
        return true; // Return success flag
      } else {
        throw new Error("Resume upload failed");
      }
    } catch (error) {
      setLoading(false);
      if (from !== "updateNotes") {
        modalDismiss();
        showError(
          "Resume Upload Failed",
          "Please check the file format and try again ",
        );
      }
      return false; // Return failure flag
    }
  };

  const add_linkedin_candidate = async () => {
    // Fix: Normalize URL with formatLinkedInUrl to ensure consistent formatting
    let linkedinUrlformat = formatLinkedInUrl(linkedinUrl);

    setLinkedinUrl(linkedinUrlformat);

    try {
      // Fix: Use the formatted URL for all validation and API calls
      let updatedLinkedInUrl = linkedinUrlformat;

      // Check if the URL is valid
      if (!updatedLinkedInUrl || updatedLinkedInUrl.length === 0) {
        setMessage({
          type: "error",
          msg: "Please enter a valid URL.",
        });
        return;
      }

      // Validate the LinkedIn URL
      // Fix: Allow query parameters in LinkedIn profile URLs using updated regex
      const linkedinUrlPattern =
        /^https?:\/\/(www\.|[a-z]{2}\.)?linkedin\.com\/in\/[\w-]+([?/#].*)?$/;
      if (!linkedinUrlPattern.test(updatedLinkedInUrl)) {
        setMessage({
          type: "error",
          msg: "Please provide a valid LinkedIn profile URL.",
        });
        return;
      }

      // Proceed with the API request
      setLoadingFetch(true); // Set loading before API call
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.FETCH_LINKEDIN_DATA,
        1,
        {
          linkedinUrls: [updatedLinkedInUrl],
          jobId: jobId,
          check: false,
        },
      );

      setLoadingFetch(false); // Reset loading after API call

      if (json?.data) {
        setMessage({
          type: "success",
          msg: "Candidate added successfully from LinkedIn.",
        });
        modalDismiss();
        showSuccess(
          "Candidate Added successfully",
          "You can go through the updated candidate list",
        );
      } else {
        setMessage({
          type: "error",
          msg: "Candidate not found in the database. Please enter the details manually.",
        });
      }
    } catch (error) {
      setLoadingFetch(false);
      console.log(error);
      setMessage({
        type: "error",
        msg: "Error in fetching. Please try again later",
      });
    }
  };

  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errMsg, setErrorMsg] = useState("");

  const { user } = useContext(core.UserContext);

  async function upload_resume() {
    setErrorMsg(null);
    try {
      setLoading(true);
      let formData = new FormData();
      for (let i = 0; i < uploadedFiles.length; i++) {
        formData.append("files", uploadedFiles[i]);
      }
      formData.append("prospect", String(prospect));
      formData.append("source", String(source));
      formData.append("stage", String(stage));
      formData.append("jobId", String(jobId));

      const token = await core.getData(core.data.ACCESS_TOKEN);
      await fetch(core.RECRUITER_API.POST_MULTIPLE_UPLOAD_RESUME, {
        method: "POST",
        headers: {
          Authorization: "Bearer " + token,
          uid: user?.id,
        },
        body: formData,
      })
        .then((res) => res.json())
        .then((data) => {
          if (data?.data?.error) {
            setLoading(false);
            return;
          } else {
            setLoading(false);

            modalDismiss();
            showSuccess(
              "Resume Uploaded Successfully",
              "You can go through the updated candidate list",
            );
          }
        })
        .catch((err) => {
          setErrorMsg("Failed to upload");
          setLoading(false);
          modalDismiss();
          showError(
            "Resume Upload Failed",
            "Please check the file format and try again",
          );
        });
    } catch (err) {
      setLoading(false);
    }
  }

  const saveDetails = async () => {
    setHasAttemptedSubmit(true);
    if (!validateForm() && activeTab === "manual") {
      return;
    }

    if (activeTab === "manual") {
      createMatchprofileManually();
    } else {
      upload_resume();
    }
  };

  function formatLinkedInUrl(url) {
    // Ensure the input is a string
    if (typeof url !== "string") {
      throw new Error("Invalid URL input");
    }

    // Trim spaces and normalize the input
    url = url.trim();

    // Check if the URL starts with "www." and prepend "https://"
    if (url.startsWith("www.")) {
      url = "https://" + url;
    }

    // Check if the URL starts with http or https
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      // Add https:// if the protocol is missing
      url = "https://" + url;
    }

    // Replace http with https for security
    url = url.replace(/^http:\/\//, "https://");

    // Define pattern for LinkedIn domains including country subdomains
    const linkedInPattern = /^https:\/\/(www\.|[a-z]{2}\.)?linkedin\.com\//;

    // If URL doesn't match LinkedIn pattern, reconstruct it
    if (!linkedInPattern.test(url)) {
      url =
        "https://www.linkedin.com/" +
        url.replace(/^(https?:\/\/)?(www\.|[a-z]{2}\.)?linkedin\.com\/?/, "");
    }

    return url;
  }

  return (
    <Modal
      heading={from ? "Add Candidate" : "Edit Candidate Detail"}
      onSave={from ? saveDetails : updateNotes}
      subheading={
        from
          ? ""
          : "Edit the candidate's details if any information was parsed incorrectly."
      }
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryLabel: "Save",
        primaryDisabled:
          loading ||
          ((activeTab === "files" || activeTab === "folder") &&
            uploadedFiles?.length === 0) ||
          (activeTab === "manual" && name?.trim()?.length === 0),
      }}
      className={"md:max-w-[60%]"}
      isLoading={loading}
    >
      {from && (
        <TabLayout
          btnList={tabButtons}
          state={activeTab}
          setState={setActiveTab}
          className="w-w/2 mb-4 "
        />
      )}
      {!candidateDetail?.resume_link && !from && (
        <FileInput
          attachResume={attachResume}
          file={file}
          setFile={setFile}
          loading={loading}
        />
      )}
      {(!from || (from && activeTab === "manual")) && (
        <>
          {from && (
            <>
              <label className="block text-sm font-medium text-gray-700">
                Enter linkedIn URL
              </label>
              <div className="flex gap-4 items-center">
                <InputBox
                  placeholder="Enter linkedIn URL"
                  value={linkedinUrl}
                  onChange={(e) => {
                    let value = e.target.value.trim();

                    // Clear error message if the field is empty
                    if (value.length === 0) {
                      setMessage({}); // Clear the message state
                    }

                    // Update the LinkedIn URL state
                    setLinkedinUrl(value);
                    setLoading(false);
                  }}
                  className="w-w/2 flex-1 "
                />

                <button
                  className="btn-md btn-primary flex items-center justify-center border-none cursor-pointer rounded-lg bg-blue-800 h-[48px]"
                  onClick={() => {
                    // Fix: Normalize and format the LinkedIn URL before calling the function
                    setLinkedinUrl(formatLinkedInUrl(linkedinUrl));
                    add_linkedin_candidate();
                  }}
                  disabled={loadingFetch}
                >
                  {loadingFetch ? "Fetching..." : "Fetch"}
                </button>
              </div>
              {Object?.entries(message)?.length > 0 && (
                <p
                  style={
                    message?.type === "error"
                      ? { color: "red" }
                      : { color: "green" }
                  }
                  className="block text-sm font-medium mt-2"
                >
                  {message?.msg}
                </p>
              )}
            </>
          )}
          <div className="p-1 mt-4  rounded-lg   grid grid-cols-2 gap-y-4 gap-x-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 required">
                First name
              </label>
              <InputBox
                placeholder="Enter first name"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                  if (hasAttemptedSubmit) validateForm();
                }}
              />
              {errors.name && (
                <p className="text-red-900 text-sm mt-1">{errors.name}</p>
              )}
            </div>
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                Last name
              </label>
              <InputBox
                placeholder={"Enter last name"}
                value={last_name}
                onChange={(e) => setLast_Name(e.target.value)}
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Phone
              </label>
              <InputBox
                placeholder="Enter phone number"
                value={phones[0] ?? ""}
                onChange={(e) => {
                  let value = e.target.value;

                  // Remove invalid characters, keeping only digits, '+', and '-'
                  value = value.replace(/[^0-9+-]/g, "");

                  const newPhones = [...phones];
                  newPhones[0] = value;
                  setPhones(newPhones);

                  // Only validate if the user has attempted to submit
                  if (hasAttemptedSubmit) {
                    validateForm();
                  }
                  if (!value) {
                    setErrors((prev) => ({ ...prev, phone: undefined }));
                  }
                }}
                type="tel"
              />
              {errors.phone && (
                <p className="text-red-900 text-sm mt-1">{errors.phone}</p>
              )}
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">
                Work Phone
              </label>
              <InputBox
                placeholder="Enter phone number"
                value={workPhones[0] ?? ""}
                onChange={(e) => {
                  let value = e.target.value;

                  // Remove invalid characters, keeping only digits, '+', and '-'
                  value = value.replace(/[^0-9+-]/g, "");

                  const newPhones = [...workPhones];
                  newPhones[0] = value;
                  setWorkPhones(newPhones);

                  // Only validate if the user has attempted to submit
                  if (hasAttemptedSubmit) {
                    validateForm();
                  }
                  if (!value) {
                    setErrors((prev) => ({ ...prev, workPhone: undefined }));
                  }
                }}
                type="tel"
              />
              {errors.workPhone && (
                <p className="text-red-900 text-sm mt-1">{errors.workPhone}</p>
              )}
            </div>
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                Email
              </label>

              <InputBox
                placeholder="Enter email"
                value={emails[0] ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const newEmails = [...emails];
                  newEmails[0] = e.target.value;
                  setEmails(newEmails);
                  if (hasAttemptedSubmit) validateForm();
                  if (!value) {
                    setErrors((prev) => ({ ...prev, email: undefined }));
                  }
                }}
              />
              {errors.email && (
                <p className="text-red-900 text-sm mt-1">{errors.email}</p>
              )}
            </div>
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                Work Email
              </label>

              <InputBox
                placeholder="Enter work email"
                value={workEmails[0] ?? ""}
                onChange={(e) => {
                  const value = e.target.value;
                  const newEmails = [...workEmails];
                  newEmails[0] = e.target.value;
                  setWorkEmails(newEmails);
                  if (hasAttemptedSubmit) validateForm();
                  if (!value) {
                    setErrors((prev) => ({ ...prev, workEmail: undefined }));
                  }
                }}
              />
              {errors.workEmail && (
                <p className="text-red-900 text-sm mt-1">{errors.workEmail}</p>
              )}
            </div>
            <div className="">
              <label className="block text-sm font-medium text-gray-700">
                Title
              </label>
              <InputBox
                placeholder={"Enter title"}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            {!from && (
              <div className="">
                <label className="block text-sm font-medium text-gray-700">
                  LinkedIn URL
                </label>
                <InputBox
                  placeholder={"Enter LinkedIn URL"}
                  value={linkedinUrl}
                  onChange={(e) => {
                    let value = e.target.value.trim();

                    // Replace 'http' with 'https' dynamically as the user types
                    // value = formatLinkedInUrl(value);
                    if (value.startsWith("www.")) {
                      value = "https://" + value;
                    }

                    setLinkedinUrl(value);

                    // Validate the form if an attempt was made
                    if (hasAttemptedSubmit) validateForm();

                    // Clear errors if the input is empty
                    if (!value) {
                      setErrors((prev) => ({
                        ...prev,
                        linkedinUrl: undefined,
                      }));
                    }
                  }}
                />
                {errors.linkedinUrl && (
                  <p className="text-red-900 text-sm mt-1">
                    {errors.linkedinUrl}
                  </p>
                )}
              </div>
            )}
            {from && (
              <div className="">
                <label className="block text-sm font-medium text-gray-700">
                  Prospect
                </label>
                <SelectBox
                  value={prospect}
                  options={["Yes", "No"]}
                  onChange={(e) => setProspect(e.target.value)}
                />
              </div>
            )}

            {from && prospect == "No" && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Initial stage
                </label>
                <SelectBox
                  className="mt-1"
                  value={stage}
                  options={pipelines?.filter((x) => x != "Rejected")}
                  onChange={(e) => setStage(e.target.value)}
                />
              </div>
            )}
            {
              <>
                {
                  <div className="">
                    <label className="block text-sm font-medium text-gray-700">
                      Source
                    </label>
                    <SelectBox
                      className="mt-1"
                      value={source}
                      options={[
                        "Greenhouse",
                        "Applied",
                        "Sprouts",
                        "Recruiter",
                        "SproutsAI Recruiter",
                        "None",
                      ]}
                      onChange={(e) => setSource(e.target.value)}
                    />
                  </div>
                }
                <div className="">
                  <label className="block text-sm font-medium text-gray-700">
                    Remote source
                  </label>
                  <SelectBox
                    className="mt-1"
                    value={remoteSource}
                    options={["Internal Applicant", "Referral", "None"]}
                    onChange={(e) => setRemoteSource(e.target.value)}
                  />
                </div>
              </>
            }
          </div>
        </>
      )}

      {(activeTab === "files" || activeTab === "folder") && (
        <div>
          <UploadFiles
            activeTab={activeTab}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
          />
          <div className="flex items-center relative w-full col-span-2  space-x-4">
            {/* Prospect Dropdown */}
            <div className="flex-1">
              <label
                htmlFor="prospect"
                className="block text-sm font-medium text-gray-700"
              >
                Prospect
              </label>
              <SelectBox
                id="prospect"
                value={prospect}
                options={["Yes", "No"]}
                onChange={(e) => setProspect(e.target.value)}
              />
            </div>

            {/* Source Dropdown */}
            <div className="flex-1">
              <label
                htmlFor="source"
                className="block text-sm font-medium text-gray-700"
              >
                Source
              </label>
              <SelectBox
                id="source"
                className="mt-1"
                value={source}
                options={[
                  "Greenhouse",
                  "Applied",
                  "Sprouts",
                  "Recruiter",
                  "SproutsAI Recruiter",
                  "None",
                ]}
                onChange={(e) => setSource(e.target.value)}
              />
            </div>
          </div>
          <div className="flex items-center relative w-1/2 mt-4 pr-2">
            {from && prospect == "No" && (
              <div className=" flex-1 ">
                <label className="block text-sm font-medium text-gray-700">
                  Initial stage
                </label>
                <SelectBox
                  className="mt-1"
                  value={stage}
                  options={pipelines?.filter((x) => x != "Rejected")}
                  onChange={(e) => setStage(e.target.value)}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </Modal>
  );
}

export default ChangeCandidatesDataModal;
