import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect, useState } from "react";
import { TypesButtons, getTypeName } from "./TypesButton";
import { minMaxValid } from "./ValidateValues";
import errorIcon from "../../assets/error.svg";
import { useContext } from "react";
import RichEditor from "../../all/RichEditor/RichEditor";
const core = require("../../../lib/core");

export default function CandidateRequirements({ form, reload }) {
  const { errorList, setErrorList } = useContext(core.JobFormContext);
  const [editorRoles, setEditorRoles] = useState(() =>
    EditorState.createEmpty(),
  );
  const [editorEdu, setEditorEdu] = useState(() => EditorState.createEmpty());
  // const preferences = ['Diversity hire', 'Recommendations', Near location / Willing to regular trave l/ commute / relocate etc]

  // useEffect(() => {
  //   if (typeof form?.data?.experienceEducation == "string") {
  //     setEditorEdu(
  //       EditorState?.createWithContent(
  //         ContentState?.createFromBlockArray(
  //           convertFromHTML(
  //             new String(
  //               form?.data?.experienceEducation.split("&lt;").join("<")
  //             ).toString()
  //           )
  //         )
  //       )
  //     );
  //   } else {
  //     setEditorEdu(
  //       EditorState?.createWithContent(
  //         ContentState?.createFromBlockArray(
  //           convertFromHTML(new String(form?.data?.experienceEducation).toString())
  //         )
  //       )
  //     );
  //   }
  // }, [reload]);

  useEffect(() => {
    if (typeof form?.data?.experienceEducation == "string") {
      setEditorEdu(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(
                form?.data?.experienceEducation.split("&lt;").join("<"),
              ).toString(),
            ),
          ),
        ),
      );
    } else {
      setEditorEdu(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(form?.data?.experienceEducation).toString(),
            ),
          ),
        ),
      );
    }

    if (typeof form?.data?.roles == "string") {
      setEditorRoles(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(form?.data?.roles.split("&lt;").join("<")).toString(),
            ),
          ),
        ),
      );
    } else {
      setEditorRoles(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(new String(form?.data?.roles).toString()),
          ),
        ),
      );
    }
  }, [reload]);

  // useEffect(() => {
  //   form.setFunction("roles", convertToHTML(editorRoles.getCurrentContent()));
  // }, [editorRoles]);

  // useEffect(() => {
  //   form.setFunction(
  //     "experienceEducation",
  //     convertToHTML(editorEdu.getCurrentContent()),
  //   );
  // }, [editorEdu]);

  const handleMinExpInput = (event) => {
    try {
      if (typeof form?.data?.experienceLevel == "string") {
        form.setFunction("experienceLevel", {
          min: event.target.value,
        });
      } else {
        form.setFunction("experienceLevel", {
          ...form?.data?.experienceLevel,
          min: event.target.value,
        });
      }
      if (
        !(
          event.target.value.trim() == "" &&
          Object.keys(errorList).includes("experience")
        )
      ) {
        minMaxValid(
          +event.target.value,
          form?.data?.experienceLevel?.max ?? 100,
        );
      }
      setErrorList((current) => {
        const { experience, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, experience: error });
    }
  };

  const handleMaxExpInput = (event) => {
    try {
      if (typeof form?.data?.experienceLevel == "string") {
        form.setFunction("experienceLevel", {
          max: event.target.value,
        });
      } else {
        form.setFunction("experienceLevel", {
          ...form?.data?.experienceLevel,
          max: event.target.value,
        });
      }
      if (
        !(
          event.target.value.trim() == "" &&
          Object.keys(errorList).includes("experience")
        )
      ) {
        minMaxValid(form?.data?.experienceLevel?.min ?? 0, +event.target.value);
      }
      setErrorList((current) => {
        const { experience, ...rest } = current;
        return rest;
      });
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, experience: error });
    }
  };

  return (
    <section className="w-full " id="candidate-requirement">
      {/* Years of experience */}
      <div className="w-full flex flex-wrap justify-between">
        <label
          id="experience"
          className="w-3/5 text-gray-800 text-sm font-normal not-italic mt-6 mb-1"
        >
          Years of experience <br />
          {Object.keys(errorList).includes("experience") ? (
            <>
              <img className="mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
              <span className="text-red-800 text-sm">
                {errorList?.experience}
              </span>
            </>
          ) : null}
        </label>
        <label className="w-1/4 text-gray-800 text-sm font-normal not-italic mt-6 mb-1 text-right">
          Add preference
        </label>
        <div className="w-3/5 flex justify-between experience-inputs ">
          <div className="col-xs-5 no-padding">
            <input
              step={0.5}
              type="number"
              className={
                Object.keys(errorList).includes("salary")
                  ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                  : "w-full"
              }
              placeholder="Min"
              min={0}
              value={form?.data?.experienceLevel?.min}
              onChange={handleMinExpInput}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "e") e.preventDefault();
              }}
            />
          </div>
          <span>
            <svg
              className="inline-block"
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="40"
              viewBox="0 0 15 40"
              fill="none"
            >
              <path
                d="M4 20H11"
                stroke="#252E4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="col-xs-5 no-padding">
            <input
              type="number"
              step={0.5}
              className={
                Object.keys(errorList).includes("salary")
                  ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                  : "w-full"
              }
              placeholder="Max"
              value={form?.data?.experienceLevel?.max}
              onChange={handleMaxExpInput}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "e") e.preventDefault();
              }}
            />
          </div>
        </div>
        <div className="w-1/4 flex justify-between h-11">
          <TypesButtons
            data={form?.data?.experienceLevel?.type}
            clickFunction={(e, type) => {
              if (typeof form?.data?.experienceLevel == "string") {
                form.setFunction("experienceLevel", {
                  type: type,
                });
              } else {
                form.setFunction("experienceLevel", {
                  ...form?.data?.experienceLevel,
                  type: type,
                });
              }
            }}
          />
        </div>
      </div>
      {/* Notice period */}
      <div className="w-full flex flex-row justify-between items-end">
        <div className="w-3/5">
          <label
            htmlFor="notice"
            className="w-full text-gray-800 text-sm font-normal not-italic mt-6 mb-1"
          >
            Notice period
          </label>
          <select
            name="notice"
            id="notice"
            className="w-full cursor-pointer"
            value={form?.data?.noticePeriod?.data}
            onChange={(event) => {
              form.setFunction("noticePeriod", {
                ...form?.data?.noticePeriod,
                data: event.target.value,
              });
            }}
          >
            <option hidden>Select</option>
            <option value="Immediate">Immediate</option>
            <option value="3 to 7 days">3 to 7 days</option>
            <option value="1 to 2 weeks">1 to 2 weeks</option>
            <option value="2 to 4 weeks">2 to 4 weeks</option>
            <option value="More than 4 weeks">More than 4 weeks</option>
          </select>
        </div>
        <div className="w-1/4 flex justify-between h-11">
          <TypesButtons
            data={form?.data?.noticePeriod?.type}
            clickFunction={(e, type) => {
              form.setFunction("noticePeriod", {
                ...form?.data?.noticePeriod,
                type: type,
              });
            }}
          />
        </div>
      </div>
      {/* Diversity */}
      {/* <div className="w-full flex justify-between items-center mt-6">
        <div className="w-3/5 flex-col">
          <label
            className="w-full text-gray-800 text-sm font-normal not-italic !mt-0 mb-1"
            htmlFor="roles"
          >
            Diversity hire
          </label>
          <span className="w-full text-sm text-gray-700">
            {`The candidate ${String(
              getTypeName(form?.data?.diversity ?? 1, [
                "",
                "",
                "can/cannot be from",
                "must be from",
              ])
            ).toLowerCase()} diverse background.
            ${
              Math.abs(form?.data?.diversity) == 1
                ? "But all candidates can apply."
                : ""
            }`}
          </span>
        </div>
        <div className="w-[calc(12.5%-12px)] flex justify-between h-11">
          <TypesButtons
            options={[2, 1]}
            data={form?.data?.diversity}
            clickFunction={(e, type) => {
              form.setFunction("diversity", type);
            }}
          />
        </div>
      </div> */}

      {/* Recommendation */}
      {/* <div className="w-full flex justify-between items-center mt-6">
        <div className="w-3/5 flex-col">
          <label
            className="w-full text-gray-800 text-sm font-normal not-italic !mt-0 mb-1"
            htmlFor="roles"
          >
            Recommendation
          </label>
          <span className="w-full text-sm text-gray-700">
            {` The candidate ${String(
              getTypeName(form?.data?.recommendation ?? 1)
            ).toLowerCase()} ${
              Math.abs(form?.data?.recommendation) == 1 ? "to have" : " "
            }
            recommendation from a collegue from similar background.
            ${
              Math.abs(form?.data?.recommendation) == 1
                ? "But all candidates can apply."
                : ""
            }`}
          </span>
        </div>
        <div className="w-1/4 flex justify-between h-11">
          <TypesButtons
            data={form?.data?.recommendation}
            clickFunction={(e, type) => {
              form.setFunction("recommendation", type);
            }}
          />
        </div>
      </div> */}
      {/* Location/Relocate */}
      {/* {form?.data?.workplace != "remote" && (
        <div className="w-full flex justify-between items-center mt-6">
          <div className="w-3/5 flex-col">
            <label
              className="w-full text-gray-800 text-sm font-normal not-italic !mt-0 mb-1"
              htmlFor="roles"
            >
              Relocation
            </label>
            <span className="w-full text-sm text-gray-700">
              {`The candidate ${String(
                getTypeName(form?.data?.relocation ?? 1, [
                  "",
                  "",
                  "must be willing to relocate to the work location",
                  "should be from one of the work locations only",
                ])
              ).toLowerCase()} 
               ${
                 form?.data?.location?.length > 0
                   ? " i.e. " + form?.data?.location.join(", ").trim() + ". "
                   : "."
               } 
               ${
                 Math.abs(form?.data?.relocation) == 1
                   ? "But all candidates can apply."
                   : ""
               }`}
            </span>
          </div>
          <div className="w-[calc(12.5%-12px)] flex justify-between h-11">
            <TypesButtons
              options={[2, 1]}
              data={form?.data?.relocation}
              clickFunction={(e, type) => {
                form.setFunction("relocation", type);
              }}
            />
          </div>
        </div>
      )} */}
      <hr className="border-t-gray-500 mt-6" />

      <div className="w-full mt-6">
        <label
          className="w-full text-gray-800 text-sm font-normal not-italic !mt-0 mb-1"
          htmlFor="roles"
          id="roles"
        >
          Roles and responsibilities
        </label>
        {/* <Editor
          editorState={editorRoles}
          onEditorStateChange={setEditorRoles}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        /> */}
        <RichEditor
          content={form?.data?.roles}
          onEditorChange={(content) => form.setFunction("roles", content)}
          withToolbar
        />
      </div>
      <div className="w-full mt-6">
        <label
          className="w-full text-gray-800 text-sm font-normal not-italic !mt-0 mb-1"
          htmlFor="experienceEducation"
          id="education"
        >
          Experience and education
        </label>
        {/* <Editor
          editorState={editorEdu}
          onEditorStateChange={setEditorEdu}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        /> */}
        <RichEditor
          content={form?.data?.experienceEducation}
          onEditorChange={(content) =>
            form.setFunction("experienceEducation", content)
          }
          withToolbar
        />
      </div>
    </section>
  );
}
