import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import InitialsLogo from "../../../all/InitialsLogo";
import InviteModal from "./InviteModal";
import { useParams } from "react-router";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { JobPageContext } from "../../../../lib/context";
import { useContext } from "react";
import ProtectedComponent from "../../../../../src/layouts/ProtectedComponent";

const core = require("../../../../lib/core");
export default function AssessmentInvites({ checkResource = false, ...props }) {
  const params = useParams();
  const { setContentReload } = useContext(JobPageContext);
  const [dataList, setDataList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [sortCol, setSortCol] = useState({ col: "invitedAt", asc: false });

  const jobDetails = useSelector((state) => state?.JobExpandSlice?.assessment);
  const [clickedItems, setClickedItems] = useState([]);

  function dateFormat(date) {
    return (
      new Date(date).toString().split(" ")[2] +
      " " +
      new Date(date).toString().split(" ")[1] +
      ", " +
      new Date(date).toString().split(" ")[3]
    );
  }

  async function resendInvite(email, invitedAt) {
    console.log("Clicked Items ", clickedItems);
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CANDIDATE_API.POST_REMINDER,
        1,
        {
          email: email,
        },
      );
      if (json?.message) {
        console.log("Success from reinvite");
        toast.success("Reminder mail sent successfully");
        setClickedItems((prev) => [...prev, invitedAt]);
      } else {
        toast.error("Something went wrong");
      }
    } catch (err) {
      toast.error("Something went wrong");
      console.error(err);
    }
  }

  useEffect(() => {
    sort(sortCol.col);
  }, []);

  useEffect(() => {
    if (jobDetails) setDataList(jobDetails?.invitedCandidates);
  }, [jobDetails]);

  function sort(label) {
    let sortedArray = [...dataList];
    switch (label) {
      case "email":
        sortedArray.sort((a, b) => {
          return String(a?.candidate?.email).localeCompare(
            String(b?.candidate?.email),
          );
        });
        break;
      case "assessment":
        sortedArray.sort((a, b) => {
          // Add your sorting logic for "assessment" here
        });
        break;
      case "invitedAt":
        sortedArray.sort((a, b) => {
          return new Date(a[label]) - new Date(b[label]);
        });
        break;
      case "testTaken":
        sortedArray.sort((a, b) => {
          return a[label] - b[label];
        });
        break;
    }
    if (!sortCol.asc) {
      sortedArray.reverse();
    }
    setSortCol({
      col: label,
      asc: !sortCol.asc,
    });
    setDataList(sortedArray);
  }

  return (
    <>
      <ProtectedComponent
        showModal={true}
        code={checkResource ? "Candidate_invite" : "no_code"}
      >
        <button
          onClick={() => setShowModal(true)}
          className="
        z-[12] mt-[-40px]
        absolute right-0 flex gap-2 items-center rounded-lg py-2 px-4 border border-[#DADAE4] bg-white shadow-jobPostButtonShadow "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="17"
            height="16"
            viewBox="0 0 17 16"
            fill="none"
          >
            <g clipPath="url(#clip0_1324_121084)">
              <path
                d="M4.15397 14.6666C5.39896 14.6666 6.44468 13.8135 6.73835 12.6599C6.82919 12.3031 7.11911 12 7.4873 12H12.154M4.15397 14.6666C2.68121 14.6666 1.4873 13.4727 1.4873 12V3.33331C1.4873 2.22874 2.38274 1.33331 3.4873 1.33331H10.154C11.2585 1.33331 12.154 2.22874 12.154 3.33331V12M4.15397 14.6666H12.154C13.399 14.6666 14.4447 13.8135 14.7384 12.6599C14.8292 12.3031 14.5222 12 14.154 12H12.154"
                stroke="#252E4B"
                strokeLinecap="round"
              />
              <path
                d="M8.96944 9.5C8.96944 8.78408 8.04084 8.2037 6.89536 8.2037C5.74988 8.2037 4.82129 8.78408 4.82129 9.5M6.89507 6.59259C6.17915 6.59259 5.59878 6.01222 5.59878 5.2963C5.59878 4.58037 6.17915 4 6.89507 4C7.611 4 8.19137 4.58037 8.19137 5.2963C8.19137 6.01222 7.611 6.59259 6.89507 6.59259Z"
                stroke="#252E4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1324_121084">
                <rect
                  width="16"
                  height="16"
                  fill="white"
                  transform="translate(0.154297)"
                />
              </clipPath>
            </defs>
          </svg>
          Invite
        </button>
      </ProtectedComponent>

      <section
        id=""
        className="col-xs-12 no-padding relative mt-[34px] h-[calc(100vh-408px)] scrollbar-candidate overflow-auto"
      >
        {/* <div className="col-xs-12 no-padding ">
        <h3>Candidates invited</h3>
       

        <div id="confirm-modal" className="modal fade" role={"dialog"}>
          <Modal
            form={<EvaluationPlanGraph />}
            hideButtons={true}
            hideCancel={true}
          />
          
        </div>
      </div> */}

        <div className="flex px-[18px] sticky top-0 bg-[#F3F5F9] z-10 pb-[8px]">
          <h5 className=" w-[40%] ">
            <span
              onClick={() => sort("email")}
              className="flex items-center text-[#252E4B] text-[14px] font-normal not-italic "
            >
              Candidate
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                {(sortCol.col == "email" && sortCol.asc) ||
                  (sortCol.col != "email" && (
                    <path
                      d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  ))}
                {(sortCol.col == "email" && !sortCol.asc) ||
                  (sortCol.col != "email" && (
                    <path
                      d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  ))}
              </svg>
            </span>
          </h5>
          <h5 className=" w-[30%]  ">
            <span
              onClick={() => sort("assessment")}
              className="flex items-center text-[#252E4B] text-[14px] font-normal not-italic"
            >
              Assessments
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                {(sortCol.col == "assessment" && sortCol.asc) ||
                  (sortCol.col != "assessment" && (
                    <path
                      d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  ))}
                {(sortCol.col == "assessment" && !sortCol.asc) ||
                  (sortCol.col != "assessment" && (
                    <path
                      d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  ))}
              </svg>
            </span>
          </h5>
          <h5 className=" w-[15%]  ">
            <span
              onClick={() => sort("invitedAt")}
              className="flex items-center text-[#252E4B] text-[14px] font-normal not-italic"
            >
              Invited date
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                {sortCol.col === "invitedAt" ? (
                  sortCol.asc ? (
                    <path
                      d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  ) : (
                    <path
                      d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  )
                ) : (
                  <>
                    <path
                      d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </>
                )}
              </svg>
            </span>
          </h5>
          <h5 className=" w-[15%] ">
            <span
              onClick={() => sort("testTaken")}
              className="flex items-center text-[#252E4B] text-[14px] font-normal not-italic"
            >
              Status
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="16"
                viewBox="0 0 17 16"
                fill="none"
              >
                {(sortCol.col == "testTaken" && sortCol.asc) ||
                  (sortCol.col != "testTaken" && (
                    <path
                      d="M10.6328 6.33301L8.23281 3.66634L5.83281 6.33301"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  ))}
                {(sortCol.col == "testTaken" && !sortCol.asc) ||
                  (sortCol.col != "testTaken" && (
                    <path
                      d="M10.6328 10.333L8.23281 12.9997L5.83281 10.333"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  ))}
              </svg>
            </span>
          </h5>
        </div>

        <div className="mt-[12px]">
          {dataList?.map((iter, idx) => (
            <div
              key={idx}
              className="px-[18px] py-[16px] items-center flex bg-[#FFF] first-of-type:rounded-t-[12px] last-of-type:rounded-b-[12px]"
            >
              <div className="w-[40%] flex">
                <InitialsLogo
                  // str={iter?.candidate?.name ?? iter?.email}
                  str={"Name"}
                  width={40}
                  height={40}
                  // bgRounded="50%"
                  // fontSize={14}
                />
                <div className="ml-[12px] flex flex-col">
                  <span className="text-[#252E4B] text-[16px] font-medium not-italic">
                    {iter?.name || "Not provided"}
                  </span>
                  <span className=" text-[#5F6989] text-[14px] font-normal not-italic">
                    {iter?.email || "Not provided"}
                  </span>
                </div>
              </div>
              <div className=" w-[30%]  ">{iter.assessment}</div>
              <div className="w-[15%]">{dateFormat(iter.invitedAt)}</div>
              <div className="w-[15%]">
                {iter?.testTaken ? (
                  <span className="accepted">Accepted</span>
                ) : iter?.isExpired ? (
                  <span className="expired">Expired</span>
                ) : (
                  <>
                    {clickedItems.includes(iter.invitedAt) ? (
                      <button className="btn-primary btn-md">Re-Invited</button>
                    ) : (
                      <button
                        className="btn-primary btn-md"
                        disabled={
                          (new Date() - new Date(iter.invitedAt)) /
                            (1000 * 60 * 60 * 24) >
                          2
                        }
                        onClick={() =>
                          resendInvite(iter?.email, iter.invitedAt)
                        }
                      >
                        Follow up
                      </button>
                    )}
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
        {showModal && (
          <InviteModal
            modalDismiss={() => {
              setShowModal(false);
              // setContentReload((prev) => prev + 1);
            }}
            isEmailSection={true}
            jobId={jobDetails?.id}
            assessmentDetails={
              props?.assessments?.length > 0
                ? props?.assessments?.filter((d) => d?.status == "active")
                : null
            }
          />
        )}
      </section>
    </>
  );
}
