import React, { useContext, useState } from "react";
import { minAlphaNumericVal, minMaxValid } from "./ValidateValues";
import errorIcon from "../../assets/error.svg";
const core = require("../../../lib/core");
import InputBox from "../../../layouts/InputBox";
function PaySection({ bonus, benefits, form, setBenefits, setBonus }) {
  const { errorList, setErrorList } = useContext(core.JobFormContext);
  const [benefitInputValue, setBenefitInputValue] = useState("");
  const addNewBenefit = (value) => {
    if (
      benefits
        .map((d) => String(d.benifits).toLowerCase())
        .includes(String(value).toLowerCase())
    )
      throw "Benefit already added";
    setBenefits([
      ...benefits,
      { benifits: String(value).trim(), status: true },
    ]);
  };

  const toggleBenefitStatus = (idx) => {
    let temp = [...benefits].map((d, i) => {
      if (idx != i) return d;
      let prev = d.status;
      return { ...d, status: !prev };
    });
    setBenefits(temp);
  };
  const toggleBonusStatus = (idx) => {
    let temp = [...bonus].map((d, i) => {
      if (idx != i) return d;
      let prev = d.status;
      return { ...d, status: !prev };
    });
    setBonus(temp);
  };

  const handleNewBenefit = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.target.value.trim() == "") return;
        minAlphaNumericVal(e.target.value);
        addNewBenefit(e.target.value);
        setBenefitInputValue("");
        setErrorList((current) => {
          const { benefits, ...rest } = current;
          return rest;
        });
      }
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, benefits: error });
    }
  };
  return (
    <div className="w-full">
      <div className="w-full header-section">
        <h4 className="w-full text-[#252e4b] text-md font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g id="Huge-icon/finance and payment/outline/money bag-coin">
              <path
                id="Vector"
                d="M20.0004 18H14.0004M20.0004 18C21.1049 18 22.0004 18.8954 22.0004 20C22.0004 21.1046 21.1049 22 20.0004 22H14.0004C12.8958 22 12.0004 21.1046 12.0004 20C12.0004 18.8954 12.8958 18 14.0004 18M20.0004 18C21.1049 18 22.0004 17.1046 22.0004 16C22.0004 14.8954 21.1049 14 20.0004 14H19.3337M14.0004 18C12.8958 18 12.0004 17.1046 12.0004 16C12.0004 14.8954 12.8958 14 14.0004 14H19.3337M13.6758 22H10.3249C6.22957 22 3.33775 17.9878 4.63281 14.1026L5.63281 11.1026C6.4495 8.65258 8.74233 7 11.3249 7H12.6758C15.2584 7 17.5512 8.65258 18.3679 11.1026L19.3337 14"
                stroke="#252E4B"
                strokeLinejoin="round"
              />
              <path
                id="Rectangle 855"
                d="M14.0859 7L9.91411 7L8.51303 5.39296C7.13959 3.81763 8.74185 1.46298 10.7471 2.10985L11.6748 2.40914C11.8861 2.47728 12.1139 2.47728 12.3252 2.40914L13.2529 2.10985C15.2582 1.46298 16.8604 3.81763 15.487 5.39296L14.0859 7Z"
                stroke="#252E4B"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          Pay details
        </h4>
      </div>
      <div className="w-full input-data-section">
        <div className="w-full flex flex-col">
          <div className="flex items-center space-x-2">
            <label htmlFor="" className="w-full text-label mr-5" id="salary">
              Expected salary range
              {Object.keys(errorList).includes("salary") ? (
                <>
                  <img
                    className="ml-6 mr-2 w-3.5 h-3.5 inline"
                    src={errorIcon}
                  />
                  <span className="text-red-800 text-sm">
                    {errorList?.salary}
                  </span>
                </>
              ) : null}
            </label>
            <div className="flex items-center space-x-2 mr-6">
              <span className="text-sm text-label ">Visibility </span>
              <button
                type="button"
                className={`${
                  form.data?.salary?.visibility !== "Hide"
                    ? "bg-blue-800"
                    : "bg-gray-400"
                } relative inline-flex items-center h-5 rounded-full w-11 transition-colors text-label duration-300`}
                onClick={() => {
                  const newVisibility =
                    form.data?.salary?.visibility === "Hide"
                      ? "Display"
                      : "Hide";
                  form.setFunction("salary", {
                    ...form?.data?.salary,
                    visibility: newVisibility,
                  });
                }}
              >
                <span
                  className={`${
                    form.data?.salary?.visibility !== "Hide"
                      ? "translate-x-7"
                      : "translate-x-1"
                  } inline-block w-3 h-3 transform bg-white rounded-full transition-transform duration-300`}
                />
              </button>
              {/* <span className="text-sm">
                {form.data?.salary?.visibility === "Display" ? "Display" : "Hide"}
              </span> */}
            </div>
          </div>

          <div className="w-full salary-inputs flex justify-between">
            <select
              className="min-w-min w-[10%] duration !bg-[right_10px_center] cursor-pointer"
              name="expectedSalaryCurrency"
              value={form?.data?.salary?.currency}
              defaultValue={"$"}
              onChange={(event) => {
                form.setFunction("salary", {
                  ...form?.data?.salary,
                  currency: event.target.value,
                });
              }}
            >
              <option value="$">$</option>
              <option value="₹">₹</option>
            </select>
            <input
              className={
                Object.keys(errorList).includes("salary")
                  ? "w-1/4 ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                  : "w-1/4"
              }
              name="expectedSalaryMin"
              type="number"
              placeholder="Type here"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "e") e.preventDefault();
              }}
              value={form?.data?.salary?.min}
              onChange={(event) => {
                try {
                  form.setFunction("salary", {
                    ...form?.data?.salary,
                    min: event.target.value,
                  });
                  if (
                    !(
                      event.target.value.trim() == "" &&
                      Object.keys(errorList).includes("salary")
                    )
                  ) {
                    minMaxValid(+event.target.value, form.data?.salary?.max, [
                      0,
                      Infinity,
                    ]);
                  }

                  setErrorList((current) => {
                    const { salary, ...rest } = current;
                    return rest;
                  });
                } catch (error) {
                  console.error(error);
                  setErrorList({ ...errorList, salary: error });
                }
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="40"
              viewBox="0 0 15 40"
              fill="none"
            >
              <path
                d="M4 20H11"
                stroke="#252E4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              className={
                Object.keys(errorList).includes("salary")
                  ? "w-1/4 ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                  : "w-1/4"
              }
              type="number"
              placeholder="Type here"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "e") e.preventDefault();
              }}
              name="expectedSalaryMax"
              value={form.data?.salary?.max}
              onChange={(event) => {
                try {
                  form.setFunction("salary", {
                    ...form?.data?.salary,
                    max: event.target.value,
                  });
                  if (
                    !(
                      event.target.value.trim() == "" &&
                      Object.keys(errorList).includes("salary")
                    )
                  ) {
                    minMaxValid(form.data?.salary?.min, +event.target.value, [
                      0,
                      Infinity,
                    ]);
                  }
                  setErrorList((current) => {
                    const { salary, ...rest } = current;
                    return rest;
                  });
                } catch (error) {
                  console.error(error);
                  setErrorList({ ...errorList, salary: error });
                }
              }}
            />
            <select
              className="w-1/4 duration cursor-pointer"
              name="expectedSalaryDuration"
              defaultValue={"Per year"}
              value={form.data?.salary?.duration}
              onChange={(event) => {
                form.setFunction("salary", {
                  ...form?.data?.salary,
                  duration: event.target.value,
                });
              }}
            >
              <option value="Per hour">Per hour</option>
              <option value="Per day">Per day</option>
              <option value="Per week">Per week</option>
              <option value="Per month">Per month</option>
              <option value="Per year">Per year</option>
            </select>
          </div>
          <div>
            <label className="w-full text-label">Salary notes</label>
            <InputBox
              id="salary"
              placeholder="Enter your salary note"
              value={form.data?.salarynotes}
              onChange={(event) => {
                form.setFunction("salarynotes", event.target.value);
              }}
            />
          </div>
        </div>

        <div className="w-full flex flex-col">
          <label htmlFor="" className="w-full text-label" id="equity">
            Expected equity (%)
            {Object.keys(errorList).includes("equity") ? (
              <>
                <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">
                  {errorList?.equity}
                </span>
              </>
            ) : null}
          </label>
          <div className="flex justify-start gap-4">
            <input
              className={
                Object.keys(errorList).includes("equity")
                  ? "w-1/3 ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                  : "w-1/3"
              }
              type="number"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "e") e.preventDefault();
              }}
              placeholder="Type here"
              name="expectdEquityMin"
              value={form.data?.equity?.min}
              onChange={(event) => {
                try {
                  form.setFunction("equity", {
                    ...form?.data?.equity,
                    min: event.target.value,
                  });
                  if (
                    !(
                      event.target.value.trim() == "" &&
                      Object.keys(errorList).includes("equity")
                    )
                  ) {
                    minMaxValid(+event.target.value, form.data?.equity?.max);
                  }
                  setErrorList((current) => {
                    const { equity, ...rest } = current;
                    return rest;
                  });
                } catch (error) {
                  console.error(error);
                  setErrorList({ ...errorList, equity: error });
                }
              }}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="40"
              viewBox="0 0 15 40"
              fill="none"
            >
              <path
                id="Vector"
                d="M4 20H11"
                stroke="#252E4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <input
              className={
                Object.keys(errorList).includes("equity")
                  ? "w-1/3 ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                  : "w-1/3"
              }
              type="number"
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === "e") e.preventDefault();
              }}
              placeholder="Type here"
              name="expectdEquityMax"
              value={form.data?.equity?.max}
              onChange={(event) => {
                try {
                  form.setFunction("equity", {
                    ...form?.data?.equity,
                    max: event.target.value,
                  });
                  if (
                    !(
                      event.target.value.trim() == "" &&
                      Object.keys(errorList).includes("equity")
                    )
                  ) {
                    minMaxValid(form.data?.equity?.min, +event.target.value);
                  }
                  setErrorList((current) => {
                    const { equity, ...rest } = current;
                    return rest;
                  });
                } catch (error) {
                  console.error(error);
                  setErrorList({ ...errorList, equity: error });
                }
              }}
            />
          </div>
        </div>
        <div className="w-full flex-flex-col">
          <h5 className="w-full other-compensation-text">
            Do you offer any other compensation pay?
          </h5>
          <div className="w-full compensation-tags">
            {bonus?.map((benefit, index) => (
              <div key={index}>
                <button
                  className={
                    benefit.status
                      ? "hover:!bg-[#699c8f]"
                      : "hover:!bg-[#00b98e34]"
                  }
                  type="button"
                  style={{
                    background: benefit.status
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                  }}
                  onClick={() => toggleBonusStatus(index)}
                >
                  <span
                    style={{ color: benefit.status ? "#FFFFFF" : "#252E4B" }}
                  >
                    {benefit.benifits}
                  </span>
                  {!benefit.status ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="add">
                        <path
                          d="M4 8H12"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="Right icon">
                        <path
                          d="M4.66797 4.66602L11.3346 11.3327"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66732 11.3327L11.334 4.66602"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full header-section header-border mt-5">
        <h4 className="w-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g id="Huge-icon/finance and payment/outline/donate">
              <path
                d="M2 8H4L10.2622 10.7397C11.2241 11.1605 11.6664 12.2784 11.2528 13.2434V13.2434C10.837 14.2136 9.71355 14.663 8.74342 14.2472L7 13.5"
                stroke="#252E4B"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.22361 16.5528C1.97662 16.4293 1.67628 16.5294 1.55279 16.7764C1.42929 17.0234 1.5294 17.3237 1.77639 17.4472L2.22361 16.5528ZM11.2236 13.5528L10.7764 13.3292L10.3292 14.2236L10.7764 14.4472L11.2236 13.5528ZM19.5 18C19.5 18.2761 19.7239 18.5 20 18.5C20.2761 18.5 20.5 18.2761 20.5 18H19.5ZM13.7331 15.3666L13.5095 15.8138L13.7331 15.3666ZM16.4164 16.5H18V15.5H16.4164V16.5ZM18 19.5H9.88854V20.5H18V19.5ZM6.53444 18.7082L2.22361 16.5528L1.77639 17.4472L6.08723 19.6026L6.53444 18.7082ZM13.9567 14.9193L11.2236 13.5528L10.7764 14.4472L13.5095 15.8138L13.9567 14.9193ZM9.88854 19.5C8.72421 19.5 7.57586 19.2289 6.53444 18.7082L6.08723 19.6026C7.2675 20.1928 8.56896 20.5 9.88854 20.5V19.5ZM19.5 18C19.5 18.8284 18.8284 19.5 18 19.5V20.5C19.3807 20.5 20.5 19.3807 20.5 18H19.5ZM18 16.5C18.8284 16.5 19.5 17.1716 19.5 18H20.5C20.5 16.6193 19.3807 15.5 18 15.5V16.5ZM16.4164 15.5C15.5626 15.5 14.7204 15.3012 13.9567 14.9193L13.5095 15.8138C14.4121 16.2651 15.4073 16.5 16.4164 16.5V15.5Z"
                fill="#252E4B"
              />
              <path
                d="M12.6083 8.51043L15.4383 12.0478C16.2389 13.0486 17.7611 13.0486 18.5617 12.0478L21.3917 8.51043C21.7855 8.01818 22 7.40656 22 6.77617V6.65165C22 5.18718 20.8128 4 19.3484 4H19.0509C18.5381 4 18.0463 4.20372 17.6836 4.56635C17.3061 4.94392 16.6939 4.94392 16.3164 4.56635C15.9537 4.20372 15.4619 4 14.9491 4H14.6517C13.1872 4 12 5.18718 12 6.65165V6.77617C12 7.40656 12.2145 8.01818 12.6083 8.51043Z"
                stroke="#252E4B"
                strokeLinejoin="round"
              />
            </g>
          </svg>
          Benefits offered
        </h4>
      </div>

      <div className="w-full input-data-section">
        <label className="w-full text-label">
          Add benefits
          {Object.keys(errorList).includes("benefits") ? (
            <>
              <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
              <span className="text-red-800 text-sm">
                {errorList?.benefits}
              </span>
            </>
          ) : null}
        </label>
        <input
          type="text"
          className={
            Object.keys(errorList).includes("benefits")
              ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
              : "w-full"
          }
          value={benefitInputValue}
          onChange={(e) => {
            if (
              e.target.value.trim() == "" &&
              Object.keys(errorList).includes("benefits")
            ) {
              setErrorList((current) => {
                const { benefits, ...rest } = current;
                return rest;
              });
            }
            setBenefitInputValue(e.target.value);
          }}
          placeholder="Type here"
          onKeyDown={handleNewBenefit}
        />
        <div className="w-full compensation-tags">
          {benefits?.map((benefit, index) => (
            <button
              key={index}
              type="button"
              className={
                benefit.status ? "hover:!bg-[#699c8f]" : "hover:!bg-[#00b98e34]"
              }
              style={{
                background: benefit.status
                  ? "#00B98D"
                  : "rgba(51, 52, 142, 0.03)",
                transition: "background-color 0.5s",
              }}
              onClick={() => toggleBenefitStatus(index)}
            >
              <span
                style={{
                  color: benefit.status ? "#FFFFFF" : "#252E4B",
                  transition: "color 0.5s",
                }}
              >
                {benefit.benifits}
              </span>
              {!benefit.status ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="add">
                    <path
                      id="Vector"
                      d="M4 8H12"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M8 12V4"
                      stroke="#252E4B"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <g id="Right icon">
                    <path
                      id="Vector"
                      d="M4.66797 4.66602L11.3346 11.3327"
                      stroke="white"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      id="Vector_2"
                      d="M4.66732 11.3327L11.334 4.66602"
                      stroke="white"
                      strokeWidth={1.5}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default PaySection;
