import React, { useContext, useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../../modal/Modal";
import { JobPageContext } from "../../../../lib/context";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");
import { EditorState, Modifier } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { convertToHTML } from "draft-convert";
import CustomSelect from "../../../../layouts/CustomSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import InputBox from "../../../../layouts/InputBox";
import SelectAndSearch from "../../../../layouts/SelectAndSearch";
import RichEditor from "../../../all/RichEditor/RichEditor";
const initialSubject =
  "Excited to Connect: First Interview for {{job_name}} at {{org_name}}";

const initialTemplate = `
<p>Dear {{first_name}},</p>
<p></p>
<p>We were impressed with your profile for the {{job_name}} role at {{org_name}} and would love to set up the first round of interviews with you.</p>
<p></p>
<p>To make scheduling easier, please use the link below to share your available time slots:</p>
<p></p>
<p>{{availability_link}}</p>
<p></p>
<p>Once we receive your preferences, we will confirm the interview details.</p>
<p></p>
<p>We are looking forward to speaking with you!</p>
<p></p>
<p>Best regards,</p>
<p>SproutsAi</p>
`;

const ToggleButton = ({ options, value, onChange }) => {
  return (
    <div className="flex border border-gray-400 w-fit rounded-lg shadow-[0px_2px_0px_rgba(0,0,0,0.04)]">
      {options.map((option, index) => (
        <div
          key={index}
          onClick={() => onChange(option.value)}
          className={`px-2 py-1 text-sm rounded-md ${
            value === option.value
              ? "bg-gray-300 text-gray-900"
              : "text-gray-800"
          } ${index === 0 ? "" : ""} ${index === options.length - 1 ? "" : ""}`}
        >
          {option.label}
        </div>
      ))}
    </div>
  );
};

function AvailabilityModal({
  modalDismiss,
  matchprofileIds,
  Timings,
  setTimings,
  InterviewertimeZone,
  selectedEmail,
  setSelectedEmail,
  setReload,
}) {
  const { setContentReload } = useContext(JobPageContext);
  const [Availability_subject, setAvailabilitySubject] =
    useState(initialSubject);
  const [editorStateDesc, setEditorStateDesc] = useState("");
  const [variables, setVariables] = useState([]);
  const [message, setMessage] = useState({});
  const [loading, setLoading] = useState(false);
  // const [Timings, setTimings] = useState({
  //   StartTime: "9:00 AM",
  //   endTime: "5:00 PM",
  //   StartDate: new Date(),
  //   EndDate: new Date(new Date().setDate(new Date().getDate() + 2)),
  // });
  const [customTimes, setCustomTimes] = useState({
    StartTime: "",
    endTime: "",
  });
  const setInput = (key, value) => {
    setTimings((prevstate) => ({
      ...prevstate,
      [key]: value,
    }));
  };
  const generateTimeOptions = (is24HourFormat) => {
    return Array.from({ length: 96 }, (_, index) => {
      const hour = Math.floor(index / 4);
      const minute = (index % 4) * 15;
      const formattedMinute = minute.toString().padStart(2, "0");

      if (is24HourFormat) {
        // 24-hour format: 00:00 to 23:45
        const formattedHour = hour.toString().padStart(2, "0");
        return {
          value: `${formattedHour}:${formattedMinute}`,
          label: `${formattedHour}:${formattedMinute}`,
        };
      } else {
        // 12-hour format: 12:00 AM to 11:45 PM
        const adjustedHour = hour % 12 || 12;
        const ampm = hour < 12 ? "AM" : "PM";
        return {
          value: `${adjustedHour}:${formattedMinute} ${ampm}`,
          label: `${adjustedHour}:${formattedMinute} ${ampm}`,
        };
      }
    });
  };

  const [isOfficehours, setIsOfficehours] = useState(true);
  const [is24HourFormat, setIs24HourFormat] = useState(false);
  const [timeOptions, setTimeOptions] = useState(
    generateTimeOptions(is24HourFormat),
  );

  const convertTo24HourFormat = (time12h) => {
    if (!time12h.includes("AM") && !time12h.includes("PM")) {
      return time12h; // Already in 24-hour format
    }

    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");

    if (hours === "12") {
      hours = "00";
    }

    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }

    return `${String(hours).padStart(2, "0")}:${minutes}`;
  };

  const convertTo12HourFormat = (time24h) => {
    if (time24h.includes("AM") || time24h.includes("PM")) {
      return time24h; // Already in 12-hour format
    }

    let [hours, minutes] = time24h.split(":");
    const suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert "00" to "12"
    return `${hours}:${minutes} ${suffix}`;
  };

  const getFilteredTimeOptions = (startTime, isStartTimeSelected) => {
    const formatTime = (time) => {
      return is24HourFormat
        ? convertTo24HourFormat(time)
        : convertTo12HourFormat(time);
    };

    const parseTime = (time) => {
      return moment(time, is24HourFormat ? "HH:mm" : "hh:mm A");
    };

    if (!isStartTimeSelected) {
      return timeOptions;
    } else {
      const formattedStartTime = formatTime(startTime);
      const startTimeMoment = parseTime(formattedStartTime);

      return timeOptions.filter((option) => {
        const optionTimeMoment = parseTime(option.value);
        return optionTimeMoment.isAfter(startTimeMoment);
      });
    }
  };

  const handleTimeFormatToggle = (value) => {
    setIs24HourFormat(value);

    const convertedStartTime = Timings?.StartTime
      ? value
        ? convertTo24HourFormat(Timings.StartTime)
        : convertTo12HourFormat(Timings.StartTime)
      : "";

    const convertedEndTime = Timings?.endTime
      ? value
        ? convertTo24HourFormat(Timings.endTime)
        : convertTo12HourFormat(Timings.endTime)
      : "";

    setInput("StartTime", convertedStartTime);
    setInput("endTime", convertedEndTime);
    setTimeOptions(generateTimeOptions(value));
  };

  const handleTimeChange = (time, isStartTime) => {
    const formattedTime = is24HourFormat
      ? convertTo24HourFormat(time)
      : convertTo12HourFormat(time);

    if (isStartTime) {
      setInput("StartTime", formattedTime);
      if (!isOfficehours) {
        setCustomTimes((prev) => ({ ...prev, StartTime: formattedTime }));
      }

      const endTimeMoment = moment(
        Timings.endTime,
        is24HourFormat ? "HH:mm" : "hh:mm A",
      );

      if (
        Timings.endTime &&
        (moment(formattedTime, is24HourFormat ? "HH:mm" : "hh:mm A").isAfter(
          endTimeMoment,
        ) ||
          (moment(formattedTime, "HH:mm").isSameOrAfter(
            "11:59 PM",
            "hh:mm A",
          ) &&
            endTimeMoment.isBefore("12:00 AM", "hh:mm A")))
      ) {
        setInput("endTime", "");
        setCustomTimes((prev) => ({ ...prev, endTime: "" }));
      } else {
        setMessage({});
      }
    } else {
      const startTimeMoment = moment(
        Timings.StartTime,
        is24HourFormat ? "HH:mm" : "hh:mm A",
      );
      const endTimeMoment = moment(
        formattedTime,
        is24HourFormat ? "HH:mm" : "hh:mm A",
      );
      if (startTimeMoment.isSame(endTimeMoment)) {
        setInput("endTime", "");
        return;
      }
      if (startTimeMoment.isSameOrAfter("11:45 PM", "hh:mm A")) {
        if (
          endTimeMoment.isBefore(startTimeMoment) ||
          endTimeMoment.isAfter("11:59 PM", "hh:mm A")
        ) {
          setInput("endTime", "");
          setCustomTimes((prev) => ({ ...prev, endTime: "" }));
          setMessage({ error: "End time cannot cross into the next day." });
        } else {
          setInput("endTime", formattedTime);
          setCustomTimes((prev) => ({ ...prev, endTime: formattedTime }));
          setMessage({});
        }
      } else {
        if (Timings.StartTime && endTimeMoment.isBefore(startTimeMoment)) {
          setInput("endTime", "");
          setCustomTimes((prev) => ({ ...prev, endTime: "" }));
        } else {
          setInput("endTime", formattedTime);
          if (!isOfficehours) {
            setCustomTimes((prev) => ({ ...prev, endTime: formattedTime }));
          }
          setMessage({});
        }
      }
    }
  };

  const handleOfficeHoursToggle = (isOfficeHours) => {
    setIsOfficehours(isOfficeHours);

    if (isOfficeHours) {
      setInput("StartTime", "9:00 AM");
      setInput("endTime", "5:00 PM");
    } else {
      let newStartTime = customTimes.StartTime || "9:00 AM";
      let newEndTime = customTimes.endTime || "5:00 PM";

      const startTimeMoment = moment(newStartTime, "hh:mm A");
      const endTimeMoment = moment(newEndTime, "hh:mm A");

      if (startTimeMoment.isAfter(endTimeMoment)) {
        newEndTime = "";
      }

      setInput("StartTime", newStartTime);
      setInput("endTime", newEndTime);
    }
  };

  const [interval, setInterval] = useState("15 mins");
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(0);
  const [isCustom, setIsCustom] = useState(false);
  const listRef = useRef(null);
  const editorRef = useRef(null);
  const [customInterval, setCustomInterval] = useState("");
  const options = [
    { value: "15 mins", label: "15 mins" },
    { value: "30 mins", label: "30 mins" },
    { value: "60 mins", label: "60 mins" },
    { value: "custom", label: "Custom..." },
  ];

  const handleSelectOption = (value) => {
    if (value === "custom") {
      setIsCustom(true);
    } else {
      setInterval(value);
    }
    setIsOpen(false);
  };

  const handleCustomIntervalChange = (e) => {
    setCustomInterval(e.target.value);
  };

  const handleCustomIntervalSubmit = () => {
    const numericValue = parseInt(customInterval);
    if (numericValue > 0 && numericValue <= 1440) {
      setInterval(`${customInterval} mins`);
      setIsCustom(false);
    } else {
      setInterval("15 mins");
      setCustomInterval("");
      setIsCustom(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleCustomIntervalSubmit();
    }
  };

  const handleClickOutside = (event) => {
    if (listRef.current && !listRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const dispatch = useDispatch();
  const { jobId, jobPosition, OrgName } = useSelector((state) => ({
    jobId: state?.JobExpandSlice?.profile?.id,
    jobPosition: state?.JobExpandSlice?.profile?.position,
    OrgName: state?.JobExpandSlice?.profile?.company?.name,
  }));
  const { user } = useContext(core.UserContext);
  useEffect(() => {
    getVariables();
    setInitialTemplate();
  }, []);

  const setInitialTemplate = () => {
    setEditorStateDesc(initialTemplate);
  };

  const getVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_VARIABLE_LIST,
      1,
    );
    if (json?.data) {
      setVariables([
        "{{job_name}}",
        "{{org_name}}",
        "{{first_name}}",
        "{{availability_link}}",
      ]);
    }
  };

  const addText = (text) => {
    editorRef.current.addText(text);
  };
  const formatDateTime = (date, time) => {
    // Combine date and time
    const formattedDate = moment(date).format("YYYY-MM-DD");
    const formattedTime = moment(time, ["h:mm A"]).format("HH:mm:ss");
    return moment(`${formattedDate} ${formattedTime}`).toISOString();
  };

  const AvailabilityInvite = async () => {
    const startDateTime = formatDateTime(
      Timings?.StartDate,
      Timings?.StartTime,
    );
    const endDateTime = formatDateTime(Timings?.EndDate, Timings?.endTime);
    const bodyContent = editorStateDesc;
    const requestData = {
      subject: Availability_subject,
      body: bodyContent,
      matchProfiles: matchprofileIds,
      job: jobId,
      org_name: OrgName ? OrgName : user.organization.name,
      job_name: jobPosition,
      interviewerAvailability: {
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        timezone: InterviewertimeZone,
      },
      from: selectedEmail,
    };

    setLoading(true);
    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.SCHEDULING_API.POST_AVAILABILITY_INVITE,
        1,
        requestData,
      );

      if (response.code == 201) {
        setLoading(false);
        toast.success("Invite Sent Successfully");
        modalDismiss();
        setReload((prev) => prev + 1);
        setMessage({
          type: "success",
          message: "Invitation sent to candidate.",
        });
      } else {
        toast.error("Error Sending Invite");
        setLoading(false);
        setMessage({
          type: "error",
          message: "Something went wrong. Please try again",
        });
      }
    } catch (error) {
      setLoading(false);
      setMessage({
        type: "error",
        message: error?.message ?? "Something went wrong. Please try again",
      });
    }
  };

  const formatText = (text) => {
    text = String(text).slice(2, -2);
    text = text.replace(/_/g, " ");
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  };

  const modalElements = {
    heading: "Invite candidates for Availability",
    subheading: "Send availability invite to candidates.",
    modalDismiss: modalDismiss,
    onSave: AvailabilityInvite,
    defaultButtons: {
      primaryDisabled:
        loading ||
        !(
          Availability_subject?.length > 0 &&
          user?.userIntegrationsList.length !== 0 &&
          selectedEmail.length > 0
        ),
      primaryLabel: "Invite",
    },
    isLoading: loading,
  };

  return (
    <Modal {...modalElements} className={"sm:!max-w-[60%] !pb-5 h-fit"}>
      <div className="my-4 flex flex-col gap-2">
        <div className="flex flex-col ">
          <h1 className="text-[14px] font-normal mb-2 ">Availability period</h1>
          <div className="flex items-center gap-4">
            <div className="w-fit my-2">
              <ToggleButton
                options={[
                  { value: true, label: "Office hours" },
                  { value: false, label: "Custom time" },
                ]}
                value={isOfficehours}
                onChange={handleOfficeHoursToggle}
              />
            </div>

            <div className="w-fit my-2">
              <ToggleButton
                options={[
                  { value: true, label: "24-hour " },
                  { value: false, label: "12-hour " },
                ]}
                value={is24HourFormat}
                onChange={handleTimeFormatToggle}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-4 ">
          <div className="w-1/3 flex flex-col">
            <label
              htmlFor="date"
              className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
            >
              Start Date
            </label>

            <DatePicker
              id="StartDate"
              autoComplete="off"
              selected={
                Timings?.StartDate ? new Date(Timings?.StartDate) : null
              }
              dateFormat="MMM d, yyyy"
              className="w-full h-[36px] border border-gray-400 p-2 rounded-lg text-center stext-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
              minDate={new Date()}
              required
              placeholder="Start Date"
              onChange={(date) => {
                setInput("StartDate", date);
              }}
            />
          </div>
          <div className="w-1/3 flex flex-col">
            <label
              htmlFor="date"
              className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5"
            >
              End Date
            </label>

            <DatePicker
              id="Endate"
              autoComplete="off"
              selected={Timings?.EndDate ? new Date(Timings?.EndDate) : null}
              dateFormat="MMM d, yyyy"
              className="w-full h-[36px] border border-gray-400 p-2 rounded-lg text-center stext-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] placeholder-gray-600"
              minDate={new Date()}
              required
              placeholder="End Date"
              onChange={(date) => {
                setInput("EndDate", date);
              }}
            />
          </div>
        </div>
        <div>
          <div className="flex flex-row gap-4">
            <div className="w-1/3">
              <label className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5">
                Start time
              </label>

              <div className="relative">
                <CustomSelect
                  options={timeOptions}
                  value={Timings?.StartTime}
                  onChange={(value) => handleTimeChange(value, true)}
                  placeholder="Start Time"
                  is24HourFormat={is24HourFormat}
                  isOfficeHours={isOfficehours}
                />
              </div>
            </div>
            <div className="w-1/3">
              <label className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5">
                End time
              </label>

              <div className="relative">
                <CustomSelect
                  options={getFilteredTimeOptions(
                    Timings?.StartTime,
                    !!Timings.StartTime,
                  )}
                  value={Timings?.endTime}
                  onChange={(value) => handleTimeChange(value, false)}
                  placeholder="End Time"
                  is24HourFormat={is24HourFormat}
                  isOfficeHours={isOfficehours}
                />
              </div>
            </div>
            {/* <div className="relative w-1/3">
                <label className="w-full text-gray-800 text-sm not-italic font-normal after:content-['*'] after:text-red-900 after:ml-0.5">
                  Interval
                </label>
                <div className="relative">

                  {isCustom ?
                    <div
                      className="w-full  p-2 border border-gray-400 rounded-lg cursor-pointer"

                    >
                      <input
                        type="text"
                        value={customInterval}
                        onChange={handleCustomIntervalChange}
                        onBlur={handleCustomIntervalSubmit}
                        onKeyDown={handleKeyDown}
                        className="w-full placeholder:text-gray-700"
                        placeholder="Enter minutes"
                      />
                    </div>
                    : <div
                      className="w-full  p-2 border border-gray-400 rounded-lg cursor-pointer"
                      onClick={() => setIsOpen(!isOpen)}
                    >
                      {interval}
                    </div>
                  }

                  {isOpen && (
                    <ul
                      ref={listRef}
                      className="absolute top-[40px] z-[5000] py-2 bg-white w-full overflow-y-auto max-h-[300px] border border-gray-400 px-2 text-sm rounded-lg shadow-xl"
                    >
                      {options.map((option, index) => (
                        <li
                          key={option.value}
                          className={`p-2 cursor-pointer ${highlightedIndex === index ? 'bg-defaultBg rounded-md' : ''}`}
                          onClick={() => handleSelectOption(option.value)}
                          onMouseEnter={() => setHighlightedIndex(index)}
                        >
                          {option.label}
                        </li>
                      ))}

                    </ul>
                  )}
                </div>
              </div> */}
          </div>
        </div>
      </div>
      <div className="mt-[2px]">
        <label
          className=" text-right text-[14px] font-normal not-italic required mt-[2px]"
          htmlFor=""
        >
          From
          {user?.userIntegrationsList.length == 0 && (
            <span className="text-red-500 text-xs font-normal ml-2">
              {`( Your email is not verified. Please verify your email before
                    sending the email.)`}
            </span>
          )}
        </label>
        <SelectAndSearch
          placeholder="Select email"
          defalultValue={selectedEmail}
          data={[
            ...user?.userIntegrationsList?.map((integration) => ({
              name: integration?.email,
              id: integration?.email,
            })),
            {
              name: "no-reply@sproutsai.com",
              id: "no-reply@sproutsai.com",
            },
          ]}
          setSelectedTemplate={setSelectedEmail}
        />
        <label
          className="text-[14px] mt-[15px] font-normal not-italic required"
          htmlFor=""
        >
          Subject
        </label>
        <InputBox
          className="border border-[#E1E1EE] py-[11px] bg-[#FFF] px-[20px] rounded-[8px] w-full"
          type="text"
          required={true}
          value={Availability_subject}
          onChange={(e) => setAvailabilitySubject(e?.target?.value)}
        />
      </div>

      <div className="mt-[20px]" id="description">
        <label
          className="text-right text-[14px] mt-[5px] font-normal not-italic"
          htmlFor=""
        >
          Body
        </label>
        {/* <Editor
          editorState={editorStateDesc}
          onEditorStateChange={setEditorStateDesc}
          wrapperClassName="wrapper-class"
          editorClassName="editor-class"
          toolbarClassName="toolbar-class"
        /> */}
        <RichEditor
          withToolbar={true}
          content={editorStateDesc}
          onEditorChange={(content) => {
            setEditorStateDesc(content);
          }}
          ref={editorRef}
        />
        <div className="w-[calc(100%-2px)] flex flex-row flex-wrap rounded-b-lg mb-2px ml-px mt-px z-50 flex h-auto bg-[#f7f7fb] py-1 px-2 h-9 items-center gap-2">
          <h4>Add variables :</h4>
          {variables?.map((item, index) => (
            <button
              key={"var" + (index + 1)}
              onClick={() => addText(item)}
              className="btn-sm btn-sec text-sm"
            >
              {formatText(item)}
            </button>
          ))}
        </div>
      </div>

      {Object.entries(message)?.length > 0 && (
        <span
          className={
            message.type == "success"
              ? "text-green-800 font-normal text-sm"
              : "text-red-800 font-normal text-sm"
          }
        >
          {message.message}
        </span>
      )}
    </Modal>
  );
}

export default AvailabilityModal;
