import React, { useContext, useEffect, useState, Suspense } from "react";
import Progressbar from "./interviewFeedback/Progressbar";
import { ScaleLoader } from "react-spinners";
import AddNewFeedback from "./interviewFeedback/AddNewFeedback";
import FeedbackInviteModal from "./FeedbackInviteModal";
import FeedbackInviteEmailModal from "./FeedbackInviteEmailModal";
import FeedbackSummary from "./interviewFeedback/FeedbackSummary";
import { MdDelete } from "react-icons/md";
import { Download, Share2 } from "lucide-react";
import FeedbackShareModal from "../../job/FeedbackShareModal";
import { toast, ToastContainer } from "react-toastify";
import { Loader } from "lucide-react";
import { SuccessModal, ErrorModal } from "../../../modal/StatusModals";
const core = require("../../../../lib/core");
// import { UserContext } from "../../../../lib/core";

import {
  UserRound,
  CircleX,
  CheckCheck,
  Check,
  CircleHelp,
} from "lucide-react";
import { useSelector } from "react-redux";
import ProtectedComponent from "../../../../layouts/ProtectedComponent";
import { useParams } from "react-router-dom";
import Modal from "../../../modal/Modal";
var parse = require("html-react-parser");
const ResumeTab = React.lazy(() => import("../Resume"));
const NoDecisionIcon = ({ size = 24, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}
  >
    <circle cx="12" cy="12" r="10" />
    <line x1="8" y1="12" x2="16" y2="12" />
  </svg>
);
const Decisioncard = ({ decision }) => {
  const getFeedbackStyle = () => {
    switch (decision) {
      case "Definitely Yes":
        return {
          icon: <CheckCheck />,
          bgColor: "#4CAF50", // Green
          text: "Definitely Yes",
        };
      case "Strong yes":
        return {
          icon: <CheckCheck />,
          bgColor: "#4CAF50", // Green
          text: "Definitely Yes",
        };
      case "Yes":
        return {
          icon: <Check />,
          bgColor: "#4CAF50",
          text: "Yes",
        };
      case "No":
        return {
          icon: <CircleX />,
          bgColor: "#FF0000",
          text: "No",
        };
      case "Definitely No":
        return {
          icon: <CircleX />,
          bgColor: "#FF0000", // Red
          text: "Definitely No",
        };
      case "No Decision":
        return {
          icon: <NoDecisionIcon />,
          bgColor: "#d9d93f", // Yellow
          text: "No Decision",
        };
      default:
        return {
          icon: <CircleHelp />,
          bgColor: "#BDBDBD",
          text: "Unknown",
        };
    }
  };

  const { icon, bgColor, text } = getFeedbackStyle();

  return (
    <div className={` bg-white flex items-center gap-2 rounded-md `}>
      <div style={{ color: bgColor, fontSize: "1.5rem" }}>{icon}</div>
      <div className="text-gray-800">{text}</div>
    </div>
  );
};

function FeedbackIndividual({
  appId,
  review,
  candidateData,
  jobId,
  checkResource = false,
  criterias,
  pipelineStages,
  invited = false,
  showSidebutton = false,
  fromInvite,
}) {
  const [showSection, setShowSection] = useState("list");
  const [feedbackList, setFeedbackList] = useState([]);
  const userContext = useContext(core.UserContext);
  const [statusModal, setStatusModal] = useState({
    show: false,
    type: null,
    message: "",
  });
  const [role, setRole] = useState();
  const { user } = userContext;
  console.log("User Data is:", user);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const { role } = await core.getResources();
      setRole(role);
    };

    fetchData();
  }, []);

  const AllData = useSelector((state) => state);
  console.log("All Data is : ", AllData);

  console.log("User Role is:", role);
  // const { hiring_managers, recruiters } = useSelector((state) => {
  //   return {
  //     hiring_managers:
  //       state?.JobExpandSlice?.description?.hiring_managers ?? [],
  //     recruiters: state?.JobExpandSlice?.description?.recruiters ?? [],
  //   };
  // });
  const [interviewStages, setInterviewStages] = useState([]);
  const [hiring_managers, setHiringManagers] = useState([]);
  const [recruiters, setRecruiters] = useState([]);
  const [inviteLoading, setInviteLoading] = useState(false);
  const [token, setToken] = useState("");
  const feedbackInvite = async () => {
    setInviteLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_INVITE_FOR_FEEDBACK,
        1,
        {
          jobId: jobId,
          matchProfileId: appId,
        },
      );
      if (json?.data) {
        setToken(json?.data);
      }
      console.log("Token is :", token);
    } catch (error) {
      console.log("Error is :", error);
    } finally {
      setInviteLoading(false);
    }
  };

  const JobAPIfetch = async (jobId) => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_DESCRIPTION + jobId,
        1,
      );

      if (json && json.data) {
        console.log("Data is:", json.data);
        setHiringManagers(json.data?.hiring_managers || []);
        setRecruiters(json.data?.recruiters || []);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const getAllfeedback = async () => {
    try {
      setLoading(true);

      const json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_INTERVIEW_FEEDBACK_LIST + "/" + appId,
        1,
        {
          matchprofile: appId,
        },
      );

      if (json?.data) {
        setFeedbackList(json?.data);
        setLoading(false);
        if (json?.data[0]?.job) {
          JobAPIfetch(json?.data[0]?.job);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  const GET_INTERVIEW_PLAN = async () => {
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_INTERVIEW_PLAN + jobId,
        1,
        {},
      );
      if (json?.data?.plan?.stages) {
        const stageData = json.data.plan.stages.map((stage) => ({
          name: stage.name,
          interviewers: (stage.interviewers || []).map((interviewer) => ({
            id: interviewer._id,
            email: interviewer.email,
          })),
        }));
        setInterviewStages(stageData);
      }
    } catch (error) {
      setMessage({ type: "error", message: error.message });
    }
  };
  console.log(feedbackList);
  useEffect(() => {
    getAllfeedback();
    GET_INTERVIEW_PLAN();
  }, []);

  const [feedbackIndex, setFeedbackIndex] = useState(null);
  const CheckTag = (id) => {
    if (hiring_managers && hiring_managers.some((hm) => hm.id === id)) {
      return "Hiring manager";
    } else if (recruiters && recruiters.some((r) => r.id === id)) {
      return "Recruiter";
    } else {
      return "External";
    }
  };

  function calculatScore(data) {
    let totalScore = 0;
    let totalRequired = 20;

    data.forEach((criteria) => {
      // Add the criteria value and required value if present

      criteria.value && (totalScore += criteria.value);
      criteria.required && (totalRequired += 10);

      // Add the scores of the skills within each criteria
      criteria.skills.forEach((skill) => {
        totalScore += skill.value;
        totalRequired += 10;
      });
    });

    // Calculate the percentage
    const percentage = (totalScore / totalRequired) * 10;

    return percentage.toFixed(1);
  }

  const [showModal, setShowModal] = useState("");

  function calculateWeightedScore(feedback) {
    let totalScore = 0;
    let validRatings = 0;
    feedback.forEach((item) => {
      if (item.rating > 0) {
        totalScore += item.rating;
        validRatings++;
      }
    });
    if (validRatings === 0) return 0;

    const averageScore = totalScore / validRatings;
    const normalizedScore = ((averageScore - 1) / 3) * 4 + 1; // For Normalizing the score to a 1-5 scale (Explnation: otherwise Exceptional would also be rated as 4 only)

    return Math.round(normalizedScore * 10) / 10;
  }

  const [currentTab, setCurrentTab] = useState("");
  const handleSideButtonClick = (tabName) => {
    if (currentTab === tabName) {
      setCurrentTab("");
    } else {
      setCurrentTab(tabName);
    }
  };

  const renderSplitComponent = () => {
    switch (currentTab) {
      case "Resume":
        return (
          <Suspense fallback={<div>Loading...</div>}>
            <ResumeTab />
          </Suspense>
        );

      default:
        return null;
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedFeedbackId, setSelectedFeedbackId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingStates, setLoadingStates] = useState({});

  const handleModalOpen = (feedbackId) => {
    setSelectedFeedbackId(feedbackId);
    setIsModalOpen(true);
  };

  const handleModalDismiss = () => {
    setIsModalOpen(false);
    setSelectedFeedbackId(null);
  };

  const downloadFeedbackReport = async (interview_id) => {
    setLoadingStates((prevState) => ({
      ...prevState,
      [interview_id]: true, // Set loading state for this specific card
    }));

    try {
      const response = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_DOWNLOAD_FEEDBACKREPORT,
        1,
        { interview_id },
      );

      if (response?.data) {
        // Extract base64 content from data URI (strip off the data URI prefix)
        const base64String = response.data.split(",")[1]; // Remove 'data:application/pdf;base64,'

        // Convert the base64 string into a byte array
        const byteArray = atob(base64String); // Decode base64 string
        const uint8Array = new Uint8Array(byteArray.length);

        for (let i = 0; i < byteArray.length; i++) {
          uint8Array[i] = byteArray.charCodeAt(i);
        }

        // Create a Blob from the byte array
        const blob = new Blob([uint8Array], { type: "application/pdf" });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element
        const link = document.createElement("a");
        link.href = url;
        link.download = `feedback-${interview_id}.pdf`;

        // Append to the document, click it, and remove it
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // Release the URL object
        window.URL.revokeObjectURL(url);

        toast.success("File downloaded successfully");
      } else {
        console.error("Failed to retrieve PDF data.");
        toast.error("Error during download. Please try again");
      }
    } catch (error) {
      console.error("Error during download:", error);
    } finally {
      setLoadingStates((prevState) => ({
        ...prevState,
        [interview_id]: false, // Reset loading state for this specific card
      }));
    }
  };

  const [deletingFeedback, setDeletingFeedback] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  // const handleDeleteFeedback = async (feedbackId) => {
  //   try {
  //     setIsDeleting(true);
  //     const response = await core.API(
  //       core.API_METHODS.DELETE,
  //       `${core.RECRUITER_API.DELETE_INTERVIEW_FEEDBACK}/${feedbackId}`,
  //       1
  //     );

  //     if (response) {
  //       toast.success("Feedback deleted successfully");
  //       getAllfeedback(); // Refresh the feedback list
  //     }
  //   } catch (error) {
  //     console.error("Error deleting feedback:", error);
  //     toast.error(error.message || "Failed to delete feedback");
  //   } finally {
  //     setIsDeleting(false);
  //     setIsDeleteModalOpen(false);
  //     setDeletingFeedback(null);
  //   }
  // };
  const handleDeleteFeedback = async (feedbackId) => {
    try {
      setIsDeleting(true);
      const response = await core.API(
        core.API_METHODS.DELETE,
        fromInvite
          ? `${core.RECRUITER_API.DELETE_INTERVIEW_FEEDBACK_FROM_INVITE}/${feedbackId}`
          : `${core.RECRUITER_API.DELETE_INTERVIEW_FEEDBACK}/${feedbackId}`,
        1,
      );

      if (response) {
        setStatusModal({
          show: true,
          type: "success",
          message: "Feedback deleted successfully",
        });
        getAllfeedback(); // Refresh the feedback list
      }
    } catch (error) {
      console.error("Error deleting feedback:", error);
      setStatusModal({
        show: true,
        type: "error",
        message: error.message || "Failed to delete feedback",
      });
    } finally {
      setIsDeleting(false);
      setIsDeleteModalOpen(false);
      setDeletingFeedback(null);
    }
  };

  const handleDeleteClick = (e, feedbackId) => {
    e.stopPropagation(); // Prevent card expansion
    setDeletingFeedback(feedbackId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteModalDismiss = () => {
    setIsDeleteModalOpen(false);
    setDeletingFeedback(null);
  };

  return (
    <div className="w-full p-4 mb-4 flex flex-col md:flex-row transition-all duration-300 ease-in-out ">
      {showSidebutton && (
        <div className="relative w-0 h-0 flex justify-end">
          <div className="fixed top-1/6 pb-[72px] -translate-y-1/2 z-[400] flex flex-col justify-start gap-4 mr-6">
            <button
              onClick={() => handleSideButtonClick("Resume")}
              type="button"
              className={`rounded-full p-4 bg-white hover:text-blue group focus:outline-none focus:ring-2 focus:ring-white shadow-lg ${currentTab !== "Resume" ? "text-gray-800" : "text-blue"} `}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  d="M13 9h5.5L13 3.5zM6 2h8l6 6v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V4c0-1.11.89-2 2-2m8 18v-1c0-1.33-2.67-2-4-2s-4 .67-4 2v1zm-4-8a2 2 0 0 0-2 2a2 2 0 0 0 2 2a2 2 0 0 0 2-2a2 2 0 0 0-2-2"
                />
              </svg>
            </button>
          </div>
        </div>
      )}
      <div
        className={`flex-1 flex flex-row justify-start transition-all duration-300 ease-in-out  ${
          currentTab ? "md:w-1/2" : "w-full"
        } `}
      >
        <div
          className={`p-4 w-full bg-bg1 h-screen overflow-y-auto transition-all duration-300 ease-in-out`}
        >
          {loading ? (
            <ScaleLoader height={14} color="#1563E9" loading={true} />
          ) : (
            <>
              {showSection === "list" && (
                <div className="transition-all duration-300 ease-in-out">
                  <div className="flex  items-center ">
                    <ProtectedComponent
                      showModal={true}
                      code={
                        checkResource
                          ? "Candidate_interview_feedback_write"
                          : "no_code"
                      }
                    >
                      <button
                        className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
                        onClick={() => {
                          setCurrentTab("");
                          setShowSection("addPipeline");
                        }}
                      >
                        <span>+ Add feedback</span>
                      </button>
                    </ProtectedComponent>
                    {!candidateData && !invited && (
                      <ProtectedComponent
                        showModal={true}
                        code={
                          checkResource
                            ? "Candidate_interview_feedback_write"
                            : "no_code"
                        }
                      >
                        <button
                          className="rounded-lg border border-[#dadae4] border-button bg-white h-[40px] text-[#252e4b] text-[16px] px-4  mr-4 flex items-center gap-2"
                          onClick={() => {
                            setShowModal("invite");
                            feedbackInvite();
                          }}
                        >
                          <span>Share</span>
                        </button>
                      </ProtectedComponent>
                    )}
                  </div>
                  <div className="my-2 transition-all duration-300 ease-in-out ">
                    {feedbackList.length > 0 ? (
                      <FeedbackSummary feedbackList={feedbackList} />
                    ) : null}
                  </div>
                  <div
                    className={`grid ${
                      fromInvite
                        ? "grid-cols-1 sm:grid-cols-2"
                        : review
                          ? "grid-cols-1 sm:grid-cols-2"
                          : "grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3"
                    } gap-4 my-5`}
                  >
                    {feedbackList?.map((item, index) => (
                      <div
                        onClick={() => {
                          if (feedbackIndex === index) {
                            setFeedbackIndex(null);
                          } else {
                            setFeedbackIndex(index);
                          }
                        }}
                        className={`relative flex flex-col rounded-lg p-4 bg-white shadow-md hover:shadow-lg transition-shadow duration-300 border-2 ${
                          feedbackIndex === index ? "ring-2 ring-blue-500" : ""
                        }`}
                      >
                        <span className="flex text-[#5F6989] text-[14px] font-normal leading-[22px] not-italic">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                            className="w-5 h-5 mr-2"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                            />
                          </svg>
                          {item?.interviewStage}
                        </span>
                        <div className="text-[#252E4B] my-2 text-md font-semibold leading-[32px] not-italic flex items-center">
                          <Decisioncard decision={item?.recomandation} />
                          {/* {item?.recomandation} */}
                          {/* <span
                        className={`ml-[5px] flex items-center rounded-[14px] py-[3px] px-[6px] ${["No", "Definitely Not"].includes(item?.recomandation) ? "text-red-800 bg-red-100" : "text-[#00B998] bg-[#E6F8F5]"} text-[12px]`}
                      >
                        {calculatScore(item?.feedback) > 0
                          ? calculatScore(item?.feedback)
                          : 0}
                        /10
                      </span> */}
                          <div className="absolute top-2 right-2 rounded-md p-2 flex items-center gap-2 text-sm bg-yellow-100 ">
                            <span>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="#eed42b"
                                stroke="#eed42b"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="lucide lucide-star"
                              >
                                <polygon
                                  fill="#eed42b"
                                  points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"
                                />
                              </svg>
                            </span>
                            {calculateWeightedScore(item?.QuickFeedback)}
                          </div>
                        </div>
                        <div className="flex flex-row items-center justify-between gap-2 mt-2 flex-wrap">
                          <div className="flex items-center gap-2">
                            <UserRound size={14} color="#000" />
                            <span className="text-[#5F6989] text-[14px] font-normal leading-[22px] not-italic break-all">
                              {item?.externalInterviewer}
                            </span>
                            <span className="px-2 py-1 bg-gray-300 rounded-md text-gray-800 text-xs">
                              {CheckTag(item?.recruiter)}
                            </span>
                          </div>
                          <div className="flex-grow"></div>
                          <div className="flex items-center gap-2">
                            {(user?.id === item?.recruiter ||
                              role?.includes("Admin")) && (
                              <button
                                data-tooltip-id="tooltip"
                                data-tooltip-content={"Delete"}
                                className="p-2 text-slate-800 hover:text-gray-800 hover:bg-gray-100 rounded-full transition-colors"
                                onClick={(e) => handleDeleteClick(e, item?._id)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  strokeWidth={1.5}
                                  stroke="currentColor"
                                  className="w-5 h-5 shrink-0 mt-px text-red-900"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                                  />
                                </svg>
                              </button>
                            )}
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content={
                                loadingStates[item?._id]
                                  ? "Downloading..."
                                  : "Download"
                              }
                              className="p-2 text-slate-800 hover:text-gray-800 hover:bg-gray-100 rounded-full transition-colors"
                              onClick={() => downloadFeedbackReport(item?._id)}
                              disabled={loadingStates[item?._id]}
                            >
                              {loadingStates[item?._id] ? (
                                <Loader className="w-4 h-4 animate-spin" />
                              ) : (
                                <Download className="w-4 h-4" />
                              )}
                            </button>
                            <button
                              data-tooltip-id="tooltip"
                              data-tooltip-content={"Share"}
                              className="p-2 text-slate-800 hover:text-gray-800 hover:bg-gray-100 rounded-full transition-colors"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleModalOpen(item._id);
                              }}
                            >
                              <Share2 className="w-4 h-4" />
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {feedbackIndex !== null && (
                    <div className="mt-10">
                      <div>
                        <div className="flex justify-between items-center ">
                          <h2 className="text-xl font-semibold mb-4">
                            Overall Conclusions
                          </h2>
                          {user?.id ===
                            feedbackList?.[feedbackIndex]?.recruiter && (
                            <button
                              onClick={(e) => setShowSection("edit")}
                              className="p-2 text-gray-800 text-sm font-normal not-italic cursor-pointer hover:bg-[#F3F4FD] rounded flex gap-2 items-start "
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 shrink-0 mt-px"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                                ></path>
                              </svg>
                              Edit
                            </button>
                          )}
                        </div>
                        <p className="p-4 bg-[#fff] rounded-lg mb-5 prose w-full max-w-full">
                          {feedbackList &&
                            feedbackList[feedbackIndex] &&
                            feedbackList[feedbackIndex].overallComment &&
                            parse(
                              feedbackList[feedbackIndex].overallComment
                                .split("&lt;")
                                .join("<"),
                            )}
                        </p>
                      </div>
                      <h2 className="text-xl font-semibold mb-4">
                        Evaluation Categories
                      </h2>
                      <Progressbar
                        skills={feedbackList?.[feedbackIndex]?.feedback}
                      />
                    </div>
                  )}
                </div>
              )}
              {showSection === "addPipeline" ? (
                <AddNewFeedback
                  setShowSection={setShowSection}
                  getAllfeedback={getAllfeedback}
                  candidateData={candidateData}
                  job_Id={jobId}
                  InterviewStages={interviewStages}
                  edit={false}
                  Criterias={criterias}
                  pipelineStages={pipelineStages}
                  feedbackList={feedbackList}
                  invited={invited}
                  app_Id={appId}
                  fromInvite={fromInvite}
                />
              ) : showSection === "edit" ? (
                <AddNewFeedback
                  setShowSection={setShowSection}
                  getAllfeedback={getAllfeedback}
                  candidateData={candidateData}
                  InterviewStages={interviewStages}
                  feedbackList={feedbackList}
                  job_Id={jobId}
                  edit={true}
                  feedback={feedbackList?.[feedbackIndex]}
                  invited={invited}
                  app_Id={appId}
                  fromInvite={fromInvite}
                />
              ) : null}

              {isModalOpen && (
                <FeedbackShareModal
                  modalDismiss={handleModalDismiss}
                  feedback_id={selectedFeedbackId}
                />
              )}

              {showModal === "invite" ? (
                <>
                  {inviteLoading ? (
                    <Modal>
                      {" "}
                      <ScaleLoader
                        height={20}
                        color="#1563E9"
                        loading={true}
                      />{" "}
                    </Modal>
                  ) : (
                    <FeedbackInviteModal
                      modalDismiss={(e) => setShowModal("")}
                      setShowModal={setShowModal}
                      token={token}
                    />
                  )}
                </>
              ) : showModal === "emailInvite" ? (
                <FeedbackInviteEmailModal
                  job_Id={jobId}
                  appId={appId}
                  token={token}
                  modalDismiss={(e) => setShowModal("")}
                />
              ) : null}
              {/* {isDeleteModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
                  <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
                    <div className="mb-4">
                      <h2 className="text-lg font-semibold">Delete Feedback</h2>
                      <p className="text-sm text-gray-500 mt-1">
                        Are you sure you want to delete this feedback? This
                        action cannot be undone.
                      </p>
                    </div>

                    <div className="flex justify-end gap-3">
                      <button
                        className="btn-sec btn-md"
                        onClick={() => setIsDeleteModalOpen(false)}
                        disabled={isDeleting}
                      >
                        Cancel
                      </button>
                      <button
                        className="btn-primary btn-md"
                        onClick={() => handleDeleteFeedback(deletingFeedback)}
                        disabled={isDeleting}
                      >
                        {isDeleting ? (
                          <ScaleLoader
                            height={14}
                            color="#ffffff"
                            loading={true}
                          />
                        ) : (
                          "Delete"
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              )} */}

              {isDeleteModalOpen && (
                <Modal
                  heading="Delete Feedback"
                  subheading="Are you sure you want to delete this feedback? This action cannot be undone."
                  modalDismiss={handleDeleteModalDismiss}
                  customButtons={[
                    {
                      label: isDeleting ? (
                        <ScaleLoader
                          height={14}
                          color="#ffffff"
                          loading={true}
                        />
                      ) : (
                        "Delete"
                      ),
                      className:
                        "flex flex-row rounded-md border border-transparent shadow-sm bg-blue-800 hover:bg-blue-900 text-white px-4 py-1.5 hover:cursor-pointer mr-2 active:border-[#145ecc] active:bg-[#145ecc] active:shadow-[0px_2px_0px_0px_rgba(0,0,0,0.04)] active:ring active:ring-[#3146f5] active:ring-opacity-20",
                      function: () => handleDeleteFeedback(deletingFeedback),
                      disabled: isDeleting,
                    },
                    {
                      label: "Cancel",
                      className: "btn-sec",
                      function: handleDeleteModalDismiss,
                      disabled: isDeleting,
                    },
                  ]}
                />
              )}
              {statusModal.show && statusModal.type === "success" && (
                <SuccessModal
                  heading="Feedback Successfully Deleted"
                  subheading="The selected feedback has been successfully deleted"
                  onDismiss={() =>
                    setStatusModal({ show: false, type: null, message: "" })
                  }
                />
              )}

              {statusModal.show && statusModal.type === "error" && (
                <ErrorModal
                  heading="Error"
                  subheading={statusModal.message}
                  onDismiss={() =>
                    setStatusModal({ show: false, type: null, message: "" })
                  }
                />
              )}
            </>
          )}
        </div>
      </div>
      {currentTab && (
        <div className="w-0 md:w-1/2 h-screen overflow-y-auto bg-white rounded-md border-l border-gray-200 transition-all duration-300 ease-in-out transform translate-x-full md:translate-x-0">
          {renderSplitComponent()}
        </div>
      )}
    </div>
  );
}

export default FeedbackIndividual;
