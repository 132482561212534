import React, { useState, useRef, useEffect } from "react";
import Modal from "../../../modal/Modal";
import { MessageList, Input } from "react-chat-elements";
import "react-chat-elements/dist/main.css";
import InitialsLogo from "../../../all/InitialsLogo";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
const core = require("../../../../lib/core");
const WhatsAppModal = ({ modalDismiss, BulkAction = false, candidates }) => {
  const [messages, setMessages] = useState([]);
  const [inputText, setInputText] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [sendingProgress, setSendingProgress] = useState(0);
  const [totalToSend, setTotalToSend] = useState(0);
  const [loadingMessages, setLoadingMessages] = useState(!BulkAction);
  const chatContainerRef = useRef(null);
  const [ErrorMessage, setErrorMessage] = useState("");
  const { role, company } = useSelector((state) => {
    return {
      company: state?.JobExpandSlice?.profile?.company?.name ?? "Sprouts",
      role: state?.JobExpandSlice?.profile?.position,
    };
  });
  const [isVisible, setIsVisible] = useState(false);
  const [messageStatus, setMessageStatus] = useState({});
  const activeCandidate = BulkAction ? null : candidates[0];

  const getCandidateMessages = async (matchId) => {
    setLoadingMessages(true);
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.GET_MESSAGE_LIST,
        1,
        {
          matchProfile: matchId,
        },
      );
      if (json?.data?.messages) {
        setMessages(
          json?.data?.messages
            .filter((message) => message.type === "whatsapp")
            .map((message) => ({
              position: message?.direction == "outbound-api" ? "right" : "left",
              type: "text",
              text: message.body,
              date: new Date(message.date_created),
              title:
                message?.direction == "outbound-api"
                  ? "You"
                  : activeCandidate?.applicant?.first_name,
              status: message.status,
            })),
        );
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingMessages(false);
    }
  };
  useEffect(() => {
    if (BulkAction) {
      setTotalToSend(candidates.length);
    } else {
      try {
        getCandidateMessages(activeCandidate?.matchId);
      } catch (error) {
        console.log(error);
      }
    }
  }, [candidates, BulkAction]);

  const formatPhoneNumber = (phone) => {
    if (!phone || !phone.length) return "";
    const phoneNumber = phone[0];
    return phoneNumber.startsWith("+") ? phoneNumber.slice(1) : phoneNumber;
  };

  const getTemplateMessage = (candidate) => ({
    position: "right",
    type: "text",
    text: `Hi ${candidate?.applicant?.first_name || "{{Candidate}}"},\n\nWe believe that your profile is a great fit for the job role ${role} at the company ${company}. If you are interested in this opportunity, please reach out to us and we can take this discussion further.`,
    date: new Date(),
    title: "You",
  });

  const sendWhatsAppMessage = async (candidateMatchIds, messageBody) => {
    try {
      setIsSending(true);

      const reqBody = {
        // to: phone,
        // name: candidate?.applicant?.first_name || "Candidate",
        matchProfileIds: candidateMatchIds || [],
        isFirstMessage: messages.length == 0 ? true : false,
        body: messages.length === 0 ? getTemplateMessage().text : messageBody,
        // authToken: "EAAZAVec77d3QBOZCxj6YY9EKVZBB3WLLvUoYl23KgFd2hvwDkjySuxGIbXEVasjHzpa7FKDUu5qzVQ47hibqZBDrq9TSPZAQR30tV6tfZA6TQlFHhQrHwiDAvTEi9VmzJwgpw0Laj5DtU3qxZCK90ZASRCCOlcN3CdaONvfILhbcbrHYvbbr1n23G05s54v11DQZCWyAOxSA0YKRKcZAPjOZAF9eCrLwrbhUdZBNhrrm",
        templateName: "outreach_v1",
        parameters: ["Candidate", role, company],
      };
      const json = await core.API(
        core.API_METHODS.POST,
        core.CHAT_API.SEND_WHATSAPP_MSG,
        1,
        reqBody,
      );

      console.log(json);
      return await json;
    } catch (error) {
      console.error("Error sending WhatsApp message:", error);
      throw error;
    } finally {
      setIsSending(false);
    }
  };

  const handleBulkSend = async () => {
    setIsSending(true);
    try {
      const candidateMatchIds = candidates.map(
        (candidate) => candidate.matchId,
      );
      // const templateMessages = candidates.map((candidate) =>
      //   getTemplateMessage(candidate),
      // );
      setIsVisible(false);
      const res = await sendWhatsAppMessage(candidateMatchIds);
      setMessageStatus(res);
      setIsVisible(true);
    } catch (error) {
      setErrorMessage("Failed to send bulk messages");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      console.error("Failed to send bulk messages:", error);
    } finally {
      setIsSending(false);
    }
  };

  const handleSingleSend = async () => {
    try {
      if (!messages.length) {
        const templateMessage = getTemplateMessage(activeCandidate);
        const res = await sendWhatsAppMessage([activeCandidate?.matchId]);
        if (res?.successful && res?.successful.length > 0) {
          setMessages([templateMessage]);
        } else {
          setErrorMessage("Failed to send message");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
      } else if (inputText.trim()) {
        const newMessage = {
          position: "right",
          type: "text",
          text: inputText.trim(),
          date: new Date(),
          title: "You",
        };
        const res = await sendWhatsAppMessage(
          [activeCandidate?.matchId],
          inputText.trim(),
        );
        if (res?.successful && res?.successful.length > 0) {
          setMessages([...messages, newMessage]);
        } else {
          setErrorMessage("Failed to send message");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
        }
        setInputText("");
      }
    } catch (error) {
      console.error("Failed to send message:", error);
    }
  };

  const handleSendMessage = async () => {
    setIsSending(true);
    try {
      if (BulkAction) {
        await handleBulkSend();
      } else {
        await handleSingleSend();
      }
    } finally {
      setIsSending(false);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  const getNameById = (profileId) => {
    const candidate = candidates.find((c) => c.matchId === profileId);
    return candidate?.applicant?.first_name || "Unknown";
  };
  const modalElements = {
    heading: BulkAction ? "Bulk WhatsApp reach out" : "",
    subheading: BulkAction
      ? `Send to ${totalToSend} selected candidate(s) `
      : "",
    // : `Chat with ${activeCandidate?.applicant?.first_name || "Candidate"}`,
    modalDismiss: modalDismiss,
    onSave: handleSendMessage,
    defaultButtons: {
      primaryDisabled:
        isSending ||
        (BulkAction
          ? totalToSend === 0
          : !activeCandidate?.applicant?.phone?.length),
      primaryLabel: isSending ? (
        <ScaleLoader color="#FFFFFF" height={20} />
      ) : (
        "Send message"
      ),
      primaryClass: !BulkAction && messages.length ? "hidden" : "",
    },
  };

  const messageListProps = {
    className: "flex flex-col w-full h-full overflow-y-auto px-4",
    lockable: true,
    toBottomHeight: "100%",
    dataSource: messages,
    customProps: {
      renderMessageText: (text, message) => (
        <div className="flex flex-col w-full">
          <div
            className={`
                  relative p-4 rounded-2xl shadow-sm
                  ${
                    message.position === "right"
                      ? "bg-blue-500 text-white ml-auto"
                      : "bg-gray-100 text-gray-800"
                  }
                  max-w-[60%] break-words
                `}
          >
            {/* Message Title */}
            {message.title && (
              <div
                className={`
                      text-xs font-medium mb-1
                      ${
                        message.position === "right"
                          ? "text-blue-100"
                          : "text-gray-600"
                      }
                    `}
              >
                {message.title}
              </div>
            )}

            {/* Message Content */}
            <div className="text-sm leading-relaxed">{text}</div>

            {/* Timestamp */}
            <div
              className={`
                    text-xs mt-2 text-right
                    ${
                      message.position === "right"
                        ? "text-blue-100"
                        : "text-gray-500"
                    }
                  `}
            >
              {message.date instanceof Date
                ? message.date.toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })
                : ""}
            </div>
          </div>
        </div>
      ),
    },
  };
  return (
    <Modal {...modalElements} className="sm:!max-w-[60%] !pb-5 h-[90vh]">
      <div className="flex flex-col h-full bg-gray-50 rounded-md">
        {!BulkAction && (
          <div className="bg-white px-4 py-2 border-b shadow-sm">
            <div className="flex items-center">
              <div className="w-10 h-10 rounded-full bg-blue-500 flex items-center justify-center text-white font-semibold">
                <InitialsLogo
                  str={activeCandidate?.applicant?.first_name || ""}
                  bgRounded={"50%"}
                />
              </div>
              <div className="flex-1 ml-3 justify-between flex items-center">
                <div className="">
                  <h3 className="font-medium text-gray-900">
                    {activeCandidate?.applicant?.first_name || "Candidate"}
                  </h3>
                  <div className="flex items-center gap-2">
                    <p className="text-sm text-gray-700">
                      {activeCandidate?.applicant?.title}
                    </p>
                    {!activeCandidate?.applicant?.phone?.length && (
                      <span className="text-xs px-2 py-1 bg-red-200 rounded-md text-red-800 font-medium">
                        No phone number available
                      </span>
                    )}
                  </div>
                </div>
                <div>
                  {ErrorMessage && (
                    <div className="flex items-center text-yellow-800 bg-yellow-100 border border-yellow-200 rounded-md px-2 py-1 my-2 gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 256 256"
                      >
                        <g fill="currentColor">
                          <path
                            d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91"
                            opacity="0.2"
                          />
                          <path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12" />
                        </g>
                      </svg>
                      <p className="text-yellow-800 text-sm m-0">
                        {ErrorMessage}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="flex-1 overflow-y-auto p-4" ref={chatContainerRef}>
          {BulkAction ? (
            <>
              <div className="bg-white rounded-lg shadow-sm p-6">
                <h3 className="font-medium text-gray-900 mb-3">
                  Bulk Message Preview
                </h3>
                <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
                  <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">
                    {
                      getTemplateMessage({
                        applicant: { first_name: "[Candidate Name]" },
                      }).text
                    }
                  </p>
                </div>
                <p className="mt-4 text-sm text-gray-600">
                  This message will be sent to {totalToSend} candidates
                </p>
                <div>
                  {ErrorMessage && (
                    <div className="flex w-fit items-center text-yellow-800 bg-yellow-100 border border-yellow-200 rounded-md px-2 py-1 my-2 gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="15"
                        viewBox="0 0 256 256"
                      >
                        <g fill="currentColor">
                          <path
                            d="M215.46 216H40.54c-12.62 0-20.54-13.21-14.41-23.91l87.46-151.87c6.3-11 22.52-11 28.82 0l87.46 151.87c6.13 10.7-1.79 23.91-14.41 23.91"
                            opacity="0.2"
                          />
                          <path d="M236.8 188.09L149.35 36.22a24.76 24.76 0 0 0-42.7 0L19.2 188.09a23.51 23.51 0 0 0 0 23.72A24.35 24.35 0 0 0 40.55 224h174.9a24.35 24.35 0 0 0 21.33-12.19a23.51 23.51 0 0 0 .02-23.72m-13.87 15.71a8.5 8.5 0 0 1-7.48 4.2H40.55a8.5 8.5 0 0 1-7.48-4.2a7.59 7.59 0 0 1 0-7.72l87.45-151.87a8.75 8.75 0 0 1 15 0l87.45 151.87a7.59 7.59 0 0 1-.04 7.72M120 144v-40a8 8 0 0 1 16 0v40a8 8 0 0 1-16 0m20 36a12 12 0 1 1-12-12a12 12 0 0 1 12 12" />
                        </g>
                      </svg>
                      <p className="text-yellow-800 text-sm m-0">
                        {ErrorMessage}
                      </p>
                    </div>
                  )}
                </div>
              </div>
              {isVisible ? (
                <>
                  <div className="bg-white rounded-lg shadow-sm p-6 mb-2 relative my-1">
                    <button
                      onClick={handleClose}
                      className="absolute top-4 right-4 text-gray-800 hover:text-gray-900"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="1.5"
                          d="m5 19l7-7m0 0l7-7m-7 7L5 5m7 7l7 7"
                        />
                      </svg>
                    </button>

                    <div className="">
                      <div className="">
                        <h3 className="text-sm font-semibold text-gray-900 mb-2">
                          Bulk Message Status{" "}
                        </h3>
                        <div className="flex gap-4 text-sm">
                          {messageStatus?.successful?.length > 0 && (
                            <div className="bg-green-50 text-green-700 px-3 py-1 rounded-md">
                              <span className="font-medium">
                                {messageStatus?.successful?.length}
                              </span>{" "}
                              Successful
                            </div>
                          )}
                          {messageStatus?.failed?.length > 0 && (
                            <div className="bg-red-50 text-red-700 px-3 py-1 rounded-md">
                              <span className="font-medium">
                                {messageStatus?.failed?.length}
                              </span>{" "}
                              Failed
                            </div>
                          )}
                          {messageStatus?.contactsFetched?.length > 0 && (
                            <div className="bg-blue-50 text-blue-700 px-3 py-1 rounded-md">
                              <span className="font-medium">
                                {messageStatus?.contactsFetched?.length}
                              </span>{" "}
                              Contacts Fetched
                            </div>
                          )}
                        </div>
                      </div>

                      {messageStatus?.successful?.length > 0 && (
                        <div className="p-2">
                          <h4 className="font-medium text-sm text-green-800">
                            Successfully Sent To:
                          </h4>
                          <div className="flex flex-row flex-wrap rounded-md">
                            {messageStatus?.successful?.map((success) => (
                              <div
                                key={success.profileId}
                                className="flex items-center bg-[#F3F5F9] rounded-full gap-2 pr-2 m-1 border-2 border-green-300 w-fit bg-green-200"
                              >
                                <InitialsLogo
                                  str={getNameById(success.profileId)}
                                  fontSize="12px"
                                  width="20px"
                                  height="20px"
                                  borderRadius="50%"
                                />
                                <span className="text-sm ">
                                  {getNameById(success.profileId)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}

                      {messageStatus?.failed?.length > 0 && (
                        <div className="p-2">
                          <h4 className="font-medium text-sm text-red-800">
                            Failed to Send To:
                          </h4>
                          <div className="flex flex-row flex-wrap rounded-md">
                            {messageStatus?.failed?.map((failure) => (
                              <div
                                key={failure.profileId}
                                className="flex items-center bg-[#F3F5F9] rounded-full gap-2 pr-2 m-1 border-2 border-red-300 w-fit bg-red-200"
                              >
                                <InitialsLogo
                                  str={getNameById(failure.profileId)}
                                  fontSize="12px"
                                  width="20px"
                                  height="20px"
                                  borderRadius="50%"
                                />
                                <span className="text-sm ">
                                  {getNameById(failure.profileId)}
                                </span>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : null}
            </>
          ) : loadingMessages ? (
            <div className="flex items-center h-64 justify-center">
              <ScaleLoader height={30} color="#1369E9" loading={true} />
            </div>
          ) : (
            <>
              {messages.length === 0 ? (
                <div className="bg-white rounded-lg shadow-sm p-6">
                  <h3 className="font-medium text-gray-900 mb-3">
                    Template Message Preview
                  </h3>
                  <div className="bg-blue-50 p-4 rounded-lg border border-blue-100">
                    <p className="whitespace-pre-wrap text-gray-700 leading-relaxed">
                      {getTemplateMessage(activeCandidate).text}
                    </p>
                  </div>
                </div>
              ) : (
                <MessageList {...messageListProps} />
              )}
            </>
          )}
        </div>

        {!BulkAction && messages.length > 0 && (
          <div className="bg-gray-200 rounded-md m-2">
            <div className="flex items-center gap-2">
              <div className="flex-1">
                <Input
                  placeholder="Type a message..."
                  value={inputText}
                  onChange={(e) => setInputText(e.target.value)}
                  className="!border-0 !bg-gray-100 !rounded-full !px-4 !py-2"
                  onKeyPress={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      e.preventDefault();
                      handleSendMessage();
                    }
                  }}
                  disabled={isSending}
                />
              </div>
              <button
                onClick={handleSendMessage}
                disabled={
                  (!inputText.trim() && messages.length > 0) || isSending
                }
                className="p-2 bg-white border-2 border-blue-700 text-blue-700 hover:text-blue-800 rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="currentColor"
                    d="M4.4 19.425q-.5.2-.95-.088T3 18.5V14l8-2l-8-2V5.5q0-.55.45-.837t.95-.088l15.4 6.5q.625.275.625.925t-.625.925z"
                  />
                </svg>
              </button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default WhatsAppModal;
