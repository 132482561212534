import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useContext, useEffect, useRef, useState } from "react";
import FormAutocomplete from "../createJob/Autocomplete";
import { minAlphaNumericVal } from "./ValidateValues";
import errorIcon from "../../assets/error.svg";
import InputBox from "../../../layouts/InputBox";
import RichEditor from "../../all/RichEditor/RichEditor";

const core = require("../../../lib/core");

function JobDetailsSection({
  autofillParsedData,
  form,
  isCompanyNew,
  formJobdetails,
  selected,
  reload,
  JobTypeArray,
  setJobTypeArray,
  jobLocation,
  setJobLocation,
  departments,
  setDepartments,
  locationInputValue,
  setLocationInputValue,
}) {
  const { errorList, setErrorList } = useContext(core.JobFormContext);
  const [jobList, setJobList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [jobTypeInputValue, setJobTypeInputValue] = useState("");
  const [locationValue, setLocationValue] = useState("");
  const [departmentValue, setDepartmentValue] = useState("");

  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );

  useEffect(() => {
    setLocationInputValue(locationValue);
  }, [locationValue]);

  const select_data = {
    jobType: [
      "full time",
      "part time",
      "contract",
      "internship",
      "volunteer",
      "temporary",
      "other",
    ],
    workplace: ["On-site", "Remote", "Hybrid"],
  };
  useEffect(() => {
    if (typeof form?.data?.description == "string") {
      setEditorStateDesc(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(
              new String(
                form?.data?.description.split("&lt;").join("<"),
              ).toString(),
            ),
          ),
        ),
      );
    } else {
      setEditorStateDesc(
        EditorState?.createWithContent(
          ContentState?.createFromBlockArray(
            convertFromHTML(new String(form?.data?.description).toString()),
          ),
        ),
      );
    }
  }, [reload]);

  // useEffect(() => {
  //   form.setFunction(
  //     "description",
  //     convertToHTML(editorStateDesc?.getCurrentContent()),
  //   );
  // }, [editorStateDesc]);

  // Get all the jobtitles added in the database
  function searchListJobs(term) {
    console.log(term);
    try {
      if (term.trim() == "") return;
      if (term?.length >= 1) {
        setErrorList((current) => {
          const { position, ...rest } = current;
          return rest;
        });
        setJobList(["loader"]);
        setTimeout(async () => {
          let response = await core.API(
            core.API_METHODS.GET,
            core.TEMPLATE_API.GET_JOB_TEMPLATE_LIST + "?name=" + term,
            1,
            {},
          );
          console.log(response);
          if (response?.data?.content.length > 0) {
            if (typeof response?.data?.content[0] === "string") {
              setJobList(response?.data?.content);
            } else {
              setJobList(
                response?.data?.content?.map((d) => ({
                  name: d?.template_name,
                  id: d?._id,
                })),
              );
            }
          } else {
            setJobList([]);
          }
        }, 300);
      }
    } catch (err) {
      setErrorList({ ...errorList, position: err });
    }
  }

  const addNewJobtype = (value) => {
    if (
      JobTypeArray.map((d) => String(d.type).toLowerCase()).includes(
        String(value).toLowerCase(),
      )
    )
      throw "Job type already added";
    setJobTypeArray([
      ...JobTypeArray,
      { type: String(value).trim(), status: true },
    ]);
  };

  const handleNewJobType = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.target.value.trim() == "") return;
        minAlphaNumericVal(e.target.value);
        addNewJobtype(e.target.value);
        setJobTypeInputValue("");
        setErrorList((current) => {
          const { type, ...rest } = current;
          return rest;
        });
      }
    } catch (error) {
      setErrorList({ ...errorList, type: error });
    }
  };

  const togglejobTypeStatus = (idx) => {
    let temp = [...JobTypeArray].map((d, i) => {
      if (idx != i) return d;
      let prev = d.status;
      return { ...d, status: !prev };
    });
    setJobTypeArray(temp);
  };

  const addNewLocationtype = (value) => {
    if (
      jobLocation
        .map((d) => String(d.name).toLowerCase())
        .includes(String(value).toLowerCase())
    )
      throw "Location already added";
    setJobLocation([
      ...jobLocation,
      { name: String(value).trim(), status: true },
    ]);
  };

  // const handleNewLocation = (e) => {
  //   try {
  //     if (e.key === "Enter") {
  //       e.preventDefault();
  //       if (e.target.value.trim() == "") return;
  //       minAlphaNumericVal(e.target.value);
  //       addNewLocationtype(e.target.value);
  //       setLocationValue("");
  //       setErrorList((current) => {
  //         const { location, ...rest } = current;
  //         return rest;
  //       });
  //     }
  //   } catch (error) {
  //     console.error(error);
  //     setErrorList({ ...errorList, location: error });
  //   }
  // };

  const handleNewLocation = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        saveLocation(e.target.value);
      }
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, location: error });
    }
  };

  const handleBlurLocation = (e) => {
    try {
      if (e.target.value.trim()) {
        saveLocation(e.target.value);
      }
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, location: error });
    }
  };

  const saveLocation = (value) => {
    if (value.trim() === "") return;
    minAlphaNumericVal(value);
    addNewLocationtype(value);
    setLocationValue("");
    setErrorList((current) => {
      const { location, ...rest } = current;
      return rest;
    });
  };

  const togglelocationStatus = (idx) => {
    let temp = [...jobLocation].map((d, i) => {
      if (idx != i) return d;
      let prev = d.status;
      return { ...d, status: !prev };
    });
    setJobLocation(temp);
  };

  const removeLocation = (index) => {
    setJobLocation((prev) => prev.filter((_, i) => i !== index));
  };
  const addNewDepartmenttype = (value) => {
    if (
      departments
        .map((d) => String(d.name).toLowerCase())
        .includes(String(value).toLowerCase())
    )
      throw "Department already added";
    setDepartments([
      ...departments,
      { name: String(value).trim(), status: true },
    ]);
  };

  const handleNewDepartment = (e) => {
    try {
      if (e.key === "Enter") {
        e.preventDefault();
        if (e.target.value.trim() == "") return;
        minAlphaNumericVal(e.target.value);
        addNewDepartmenttype(e.target.value);
        setDepartmentValue("");
        setErrorList((current) => {
          const { department, ...rest } = current;
          return rest;
        });
      }
    } catch (error) {
      console.error(error);
      setErrorList({ ...errorList, department: error });
    }
  };

  const toggledepartmentStatus = (idx) => {
    let temp = [...departments].map((d, i) => {
      if (idx != i) return d;
      let prev = d.status;
      return { ...d, status: !prev };
    });
    setDepartments(temp);
  };

  const [generateStatus, setGenerateStatus] = useState(false);

  async function generateDescription() {
    setGenerateStatus(true);
    try {
      let api_resp = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_JD_GENERATE,
        1,
        {
          // templateId: selectTemp,
          templateId: "662f6682f7cea0ac44593a92",
        },
      );
      if (api_resp?.data) {
        autofillParsedData(api_resp?.data);
        setGenerateStatus(false);
      } else setGenerateStatus(false);
    } catch (error) {
      setGenerateStatus(false);
    }
  }

  return (
    <div id="jobDetailSection2">
      <div className="w-full  header-section">
        <h4 className="w-full text-[#252e4b] text-md font-medium">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g id="Huge-icon/business/outline/briefcase 01">
              <path
                id="Vector"
                d="M22 10V18C22 20.2091 20.2091 22 18 22H6C3.79086 22 2 20.2091 2 18V10M22 10C22 7.79086 20.2091 6 18 6H6C3.79086 6 2 7.79086 2 10M22 10C22 10 18.8374 12.7647 12 12.5811C5.16262 12.3975 2 10 2 10M8 6V5C8 3.34315 9.34315 2 11 2H13C14.6569 2 16 3.34315 16 5V6M8 10.6767V13.6767M16 10.6767V13.6767"
                stroke="#252E4B"
                strokeLinecap="round"
              />
            </g>
          </svg>
          Title details
        </h4>
      </div>
      <div className="w-full  input-data-section">
        <div className="w-full flex flex-col" id="position">
          <label htmlFor="position" className={`w-full required text-label`}>
            Job title
            {Object.keys(errorList).includes("position") ? (
              <>
                <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">
                  {errorList?.position}
                </span>
              </>
            ) : null}
          </label>
          <FormAutocomplete
            type="text"
            value={form?.data?.position ?? "Type to search..."}
            idName="position"
            name="position"
            data={jobList}
            required={true}
            searchFunction={searchListJobs}
            selectionFunction={(name, value) => {
              form.setFunction(name, value);
            }}
            inputClassName={
              Object.keys(errorList).includes("position")
                ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                : ""
            }
          />
        </div>
        <div className="w-full flex flex-col" id="description">
          <label
            className=" required text-label"
            htmlFor="description"
            id="description"
          >
            Job description
          </label>
          {/* <Editor
            editorState={editorStateDesc}
            onEditorStateChange={setEditorStateDesc}
            wrapperClassName="wrapper-class"
            editorClassName="editor-class max-h-[400px]"
            toolbarClassName="toolbar-class"
          /> */}
          <RichEditor
            content={form.data?.description}
            onEditorChange={(content) =>
              form.setFunction("description", content)
            }
            withToolbar
          />
        </div>

        <div className="w-full flex flex-col" id="Job-type">
          <label htmlFor="" className="w-full  text-label" id="jobType">
            Add job type
            {Object.keys(errorList).includes("type") ? (
              <>
                <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">{errorList?.type}</span>
              </>
            ) : null}
          </label>
          <input
            type="text"
            className={
              Object.keys(errorList)?.includes("type")
                ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900 mb-1"
                : "w-full mb-1"
            }
            value={jobTypeInputValue}
            onChange={(e) => {
              if (
                e.target.value.trim() == "" &&
                Object.keys(errorList).includes("type")
              ) {
                setErrorList((current) => {
                  const { type, ...rest } = current;
                  return rest;
                });
              }
              setJobTypeInputValue(e.target.value);
            }}
            placeholder="Type here"
            onKeyDown={handleNewJobType}
          />

          <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
            {JobTypeArray?.map((item, index) => (
              <div key={index} className="flex">
                <button
                  className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 
                    ${
                      item?.status
                        ? "hover:!bg-[#699c8f]"
                        : "hover:!bg-[#00b98e34]"
                    }
                 `}
                  type="button"
                  style={{
                    background: item?.status
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                  }}
                  onClick={() => togglejobTypeStatus(index)}
                >
                  <span style={{ color: item?.status ? "#FFFFFF" : "#252E4B" }}>
                    {item?.type}
                  </span>
                  {!item?.status ? (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="add">
                        <path
                          d="M4 8H12"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="Right icon">
                        <path
                          d="M4.66797 4.66602L11.3346 11.3327"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66732 11.3327L11.334 4.66602"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>

        <div className="w-full left-input">
          <label htmlFor="departments" className="w-full   text-label">
            Department
            {Object.keys(errorList).includes("department") ? (
              <>
                <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">
                  {errorList?.department}
                </span>
              </>
            ) : null}
          </label>

          <input
            type="text"
            id="departments"
            name="departments"
            className={
              Object.keys(errorList).includes("department")
                ? "w-full ![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900 mb-1"
                : "w-full mb-1"
            }
            value={departmentValue}
            onChange={(e) => {
              if (
                e.target.value.trim() == "" &&
                Object.keys(errorList).includes("department")
              ) {
                setErrorList((current) => {
                  const { department, ...rest } = current;
                  return rest;
                });
              }
              setDepartmentValue(e.target.value);
            }}
            placeholder="Type here"
            onKeyDown={handleNewDepartment}
          />
          <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
            {departments?.map((item, index) => (
              <div key={index} className="flex">
                <button
                  className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 
                    ${
                      item.status
                        ? "hover:!bg-[#699c8f]"
                        : "hover:!bg-[#00b98e34]"
                    }
                 `}
                  type="button"
                  style={{
                    background: item.status
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                  }}
                  onClick={() => toggledepartmentStatus(index)}
                >
                  <span style={{ color: item.status ? "#FFFFFF" : "#252E4B" }}>
                    {item?.name}
                  </span>
                  {!item.status ? (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="add">
                        <path
                          d="M4 8H12"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="Right icon">
                        <path
                          d="M4.66797 4.66602L11.3346 11.3327"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66732 11.3327L11.334 4.66602"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="w-full mt-5 header-section header-border">
        <h4>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <g id="Huge-icon/interface/outline/location 01">
              <path
                id="Vector"
                d="M6 21.5C5.72386 21.5 5.5 21.7239 5.5 22C5.5 22.2761 5.72386 22.5 6 22.5V21.5ZM18 22.5C18.2761 22.5 18.5 22.2761 18.5 22C18.5 21.7239 18.2761 21.5 18 21.5V22.5ZM19 9.5C19 11.2922 17.867 13.5553 16.3479 15.4055C15.5968 16.3203 14.7733 17.1066 13.9908 17.6596C13.1927 18.2235 12.5005 18.5 12 18.5V19.5C12.812 19.5 13.7136 19.0799 14.5678 18.4762C15.4377 17.8616 16.3251 17.0093 17.1208 16.04C18.6955 14.1221 20 11.6352 20 9.5H19ZM12 18.5C11.5096 18.5 10.8219 18.2087 10.0209 17.6073C9.23751 17.0192 8.41257 16.1849 7.65974 15.2206C6.13742 13.2708 5 10.9082 5 9.11111H4C4 11.2413 5.30008 13.8233 6.87151 15.836C7.66556 16.8531 8.55155 17.7546 9.42051 18.407C10.2718 19.0461 11.1779 19.5 12 19.5V18.5ZM5 9.11111C5 5.32039 8.36372 2.5 12 2.5V1.5C7.9043 1.5 4 4.67961 4 9.11111H5ZM12 2.5C15.6179 2.5 19 5.30336 19 9.5H20C20 4.69664 16.1141 1.5 12 1.5V2.5ZM14.5 9C14.5 10.3807 13.3807 11.5 12 11.5V12.5C13.933 12.5 15.5 10.933 15.5 9H14.5ZM12 11.5C10.6193 11.5 9.5 10.3807 9.5 9H8.5C8.5 10.933 10.067 12.5 12 12.5V11.5ZM9.5 9C9.5 7.61929 10.6193 6.5 12 6.5V5.5C10.067 5.5 8.5 7.067 8.5 9H9.5ZM12 6.5C13.3807 6.5 14.5 7.61929 14.5 9H15.5C15.5 7.067 13.933 5.5 12 5.5V6.5ZM6 22.5H18V21.5H6V22.5Z"
                fill="#252E4B"
              />
            </g>
          </svg>
          Location details
        </h4>
      </div>
      <div className="w-full px-4 input-data-section" id="workplace">
        <label htmlFor="workplace" className="w-full   text-label">
          Workplace type
        </label>

        <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
          {select_data?.workplace?.map((item, index) => (
            <div key={index} className="flex">
              <button
                className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 
                    ${
                      form.data?.workplace?.toLowerCase() ===
                      item?.toLowerCase()
                        ? "hover:!bg-[#699c8f]"
                        : "hover:!bg-[#00b98e34]"
                    }
                 `}
                type="button"
                style={{
                  background:
                    form.data?.workplace?.toLowerCase() === item?.toLowerCase()
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                }}
                onClick={() => form.setFunction("workplace", item)}
              >
                <span
                  style={{
                    color:
                      form.data?.workplace?.toLowerCase() ===
                      item?.toLowerCase()
                        ? "#FFFFFF"
                        : "#252E4B",
                  }}
                >
                  {item}
                </span>
              </button>
            </div>
          ))}
        </div>
        <>
          <label htmlFor="" className="w-full required  text-label">
            Location
            {Object.keys(errorList).includes("location") ? (
              <>
                <img className="ml-6 mr-2 w-3.5 h-3.5 inline" src={errorIcon} />
                <span className="text-red-800 text-sm">
                  {errorList?.location}
                </span>
              </>
            ) : null}
          </label>
          <InputBox
            required={true}
            className={
              Object.keys(errorList).includes("location")
                ? "![box-shadow:0px_0px_0px_3px_rgba(149,7,7,0.35)] !border-red-900"
                : ""
            }
            name={`location`}
            value={locationValue}
            onChange={(e) => setLocationValue(e.target.value)}
            placeholder={"Press Enter to insert"}
            onKeyDown={handleNewLocation}
            onBlur={handleBlurLocation}
          />

          <div className="w-full compensation-tags flex flex-wrap gap-[6px]">
            {jobLocation?.map((item, index) => (
              <div key={index} className="flex">
                <button
                  className={` mt-[8px] rounded-[68px]  border border-[#E1E1EE] flex items-center
                  py-[7px] px-[12px] 
                    ${
                      item.status
                        ? "hover:!bg-[#699c8f]"
                        : "hover:!bg-[#00b98e34]"
                    }
                 `}
                  type="button"
                  style={{
                    background: item.status
                      ? "#00B98D"
                      : "rgba(51, 52, 142, 0.03)",
                  }}
                  // onClick={() => togglelocationStatus(index)}
                  onClick={() => removeLocation(index)}
                >
                  <span style={{ color: item.status ? "#FFFFFF" : "#252E4B" }}>
                    {item?.name}
                  </span>
                  {!item.status ? (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="add">
                        <path
                          d="M4 8H12"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M8 12V4"
                          stroke="#252E4B"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  ) : (
                    <svg
                      className="ml-[8px]"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g id="Right icon">
                        <path
                          d="M4.66797 4.66602L11.3346 11.3327"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M4.66732 11.3327L11.334 4.66602"
                          stroke="white"
                          strokeWidth={1.5}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </g>
                    </svg>
                  )}
                </button>
              </div>
            ))}
          </div>
        </>
      </div>
    </div>
  );
}

export default JobDetailsSection;
