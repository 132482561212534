import React, { useState } from "react";
import Modal from "../../modal/Modal";
import axios from "axios";
import InputBox from "../../../layouts/InputBox";
const core = require("../../../lib/core");

const UploadImageModal = ({ handleDismiss, handleSubmit }) => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [externalLink, setExternalLink] = useState(null);
  const [width, setWidth] = useState("auto");
  const [height, setHeight] = useState("auto");

  const handleImageChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    setLoading(true);
    try {
      // Get presigned URL from backend
      const response = await new Promise((resolve, reject) => {
        const formData = new FormData();
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = async () => {
          // make axios request
          formData.append("files", file);
          const token = await core.getData(core.data.ACCESS_TOKEN);
          await fetch(core.S3_API.POST_UPLOAD_FILE, {
            method: "POST",
            headers: {
              Authorization: "Bearer " + token,
            },
            body: formData,
          })
            .then((res) => res.json())
            .then((data) => {
              const responseData = {
                link: data?.data?.[0]?.url,
                name: data?.data?.[0]?.name,
              };
              console.log(data, responseData);
              resolve({
                data: responseData,
              });
            })
            .catch((err) => {
              reject(err);
            });
        };
        reader.onerror = (error) => reject(error);
      });

      setImageUrl(response.data.link);
      setImage(file);
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSave = () => {
    if (!imageUrl) return;
    // Return image URL and dimensions
    handleSubmit({
      url: imageUrl,
      width,
      height,
      link: externalLink,
    });
    handleDismiss();
  };

  return (
    <Modal
      heading={"Upload Image"}
      subheading={"Upload Image and set size"}
      onSave={handleSave}
      modalDismiss={handleDismiss}
      defaultButtons={{
        primaryDisabled: loading || !imageUrl,
        primaryLabel: "Save",
      }}
      isLoading={loading}
    >
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col">
            <label>Image</label>
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="border p-2 rounded"
            />
          </div>
          <p className="text-center">OR</p>
          <div className="flex flex-col">
            <label>Image URL</label>
            <InputBox
              type="text"
              value={imageUrl}
              onChange={(e) => setImageUrl(e.target.value)}
              className="border p-2 rounded"
            />
          </div>
        </div>

        {imageUrl && (
          <div className="flex flex-col gap-2">
            <img
              src={imageUrl}
              alt="Preview"
              className="max-w-[200px] max-h-[200px] object-contain"
            />

            <div className="flex gap-4 mt-2">
              <div className="flex flex-col">
                <label>Width</label>
                <InputBox
                  type="text"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  className="border p-1 rounded"
                />
              </div>
              <div className="flex flex-col">
                <label>Height</label>
                <InputBox
                  type="text"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  className="border p-1 rounded"
                />
              </div>
            </div>
            <div className="flex flex-col mt-3">
              <label>External Link</label>
              <InputBox
                type="text"
                value={externalLink}
                onChange={(e) => setExternalLink(e.target.value)}
                className="border p-1 rounded"
                placeholder="When image is clicked, it will open this link"
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default UploadImageModal;
