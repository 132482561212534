import React, { useEffect, useMemo, useState } from "react";
import Modal from "../../modal/Modal";
import Badge from "../../partial/Badge";
import { ICONS } from "../../assets/componentAssets";
import { Switch } from "antd";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiPlusCircle } from "react-icons/fi";
import InputBox from "../../../layouts/InputBox";
import SelectBox from "../../../layouts/SelectBox";
const core = require("../../../lib/core");

const TemplateVaribleModal = ({
  showModal,
  setShowModal,
  getVariables,
  variables,
}) => {
  const modalDismiss = () => {
    setShowModal(false);
  };

  const [allVariables, setAllVariables] = useState([]);

  const myVariables = useMemo(() => {
    return variables;
  }, [variables]);

  const getAllVariables = async () => {
    const json = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_ALL_VARIABLES,
      1,
    );
    if (json?.data) setAllVariables(json?.data);
  };

  const [selectedVariable, setSelectedVariable] = useState(null);
  const [displayName, setDisplayName] = useState("");

  const handleSubmit = async () => {
    if (!selectedVariable || !displayName) return;
    const varibaleData = allVariables.find(
      (variable) => variable._id === selectedVariable,
    );
    const obj = {
      ...varibaleData,
      label: displayName,
    };
    const json = await core.API(
      core.API_METHODS.POST,
      core.TEMPLATE_API.CREATE_TEMPLATE_VARIABLES,
      1,
      { variable: obj },
    );
    if (json?.data) {
      getVariables();
      // modalDismiss();
    }
    setDisplayName("");
    setSelectedVariable("");
  };

  const handleDelete = (id) => async () => {
    const json = await core.API(
      core.API_METHODS.DELETE,
      core.TEMPLATE_API.DELETE_TEMPLATE_VARIABLES + "/" + id,
      1,
    );
    if (json?.data) {
      getVariables();
    }
  };
  // const handleSubmit = async () => {
  //   const json = await core.API(
  //     core.API_METHODS.POST,
  //     core.TEMPLATE_API.UPDATE_TEMPLATE_VARIABLES,
  //     1,
  //     {
  //       variables: myVariables,
  //     },
  //   );
  //   if (json?.data) {
  //     getVariables();
  //     modalDismiss();
  //   }
  // };

  useEffect(() => {
    getAllVariables();
  }, []);
  return (
    <Modal
      modalDismiss={modalDismiss}
      heading={"Manage Template Variables"}
      className={"md:max-w-[750px] scrollbar-hide"}
      noBtns={true}
    >
      <div className="flex gap-4 items-end">
        <div className="grid grid-cols-2 gap-2 grow">
          <div>
            <label
              className="text-sm font-normal not-italic required"
              htmlFor=""
            >
              Collection
            </label>
            <select
              name=""
              id=""
              className={` h-[40px] w-full border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989] disabled:bg-gray-200 disabled:opacity-50 disabled:cursor-not-allowed`}
              value={selectedVariable}
              onChange={(e) => setSelectedVariable(e.target.value)}
              disabled={
                allVariables?.filter(
                  (variable) =>
                    !myVariables?.some(
                      (v) => v?.variable?.variable === variable?.variable,
                    ),
                )?.length === 0
              }
            >
              <option value="">Select variable</option>
              {allVariables
                .filter(
                  (variable) =>
                    !myVariables.some(
                      (v) => v.variable.variable === variable.variable,
                    ),
                )
                .map((variable, index) => (
                  <option key={index} value={variable._id}>
                    {variable.label}
                  </option>
                ))}
            </select>
          </div>
          <div>
            <label
              className="text-sm font-normal not-italic required"
              htmlFor=""
            >
              Display name
            </label>
            <InputBox
              disabled={
                allVariables?.filter(
                  (variable) =>
                    !myVariables?.some(
                      (v) => v?.variable?.variable === variable?.variable,
                    ),
                )?.length === 0
              }
              type="text"
              className="w-full border border-gray-300 rounded-md p-2 disabled:opacity-50 disabled:cursor-not-allowed"
              placeholder="Enter display name"
              size={"medium"}
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="mt-4 mb-6">
        <button
          disabled={
            !selectedVariable ||
            !displayName ||
            allVariables?.filter(
              (variable) =>
                !myVariables?.some(
                  (v) => v?.variable?.variable === variable?.variable,
                ),
            )?.length === 0
          }
          className=" w-full solid-button-blue h-[40px] rounded-[8px] bg-[#1369E9] shadow-jobPostButtonShadow flex items-center justify-center text-white text-base not-italic font-normal disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => {
            handleSubmit();
          }}
        >
          <FiPlusCircle size={20} />
          <span className="ml-2">Add variable</span>
        </button>
        {allVariables?.filter(
          (variable) =>
            !myVariables?.some(
              (v) => v?.variable?.variable === variable?.variable,
            ),
        )?.length === 0 ? (
          <div className="text-sm text-gray-600">
            *All variables are already added
          </div>
        ) : null}
      </div>
      <div className="mt-3">
        <div className="space-y-4">
          {myVariables.map((variable, index) => (
            <div
              key={index}
              className="flex justify-between items-center bg-gray-50 p-3 rounded-md"
            >
              <div>
                <h3 className="font-medium text-gray-800">
                  {variable.variable.label}
                </h3>
                <p className="text-sm text-gray-600">
                  {variable.variable.variable}
                </p>
              </div>
              <button
                className="text-red-600 hover:text-red-700 transition-colors"
                onClick={handleDelete(variable._id)}
              >
                <RiDeleteBin6Line size={20} />
              </button>
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
};

export default TemplateVaribleModal;
