import React, { useState, useEffect } from "react";
import Modal from "../../modal/Modal";
import { PlusCircle, Trash2 } from "lucide-react";
import TextAreaBox from "../../../layouts/TextAreaBox";
import InputBox from "../../../layouts/InputBox";
import { ScaleLoader } from "react-spinners";
import SearchUser from "../user/SearchUser";
import InitialsLogo from "../../all/InitialsLogo";
import { useStatusModal } from "../../../App";
const core = require("../../../lib/core");

function InterviewPlanModal({ jobId, modalDismiss }) {
  const [plan, setPlan] = useState({
    description: "",
    stages: [],
  });
  const [message, setMessage] = useState({});
  const [editingStageIndices, setEditingStageIndices] = useState(new Set());
  const [isPlanLoading, setisPlanLoading] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [errors, setErrors] = useState({});
  const [LocalErrors, setLocalErrors] = useState({});
  const [originalStages, setOriginalStages] = useState({});
  const { showSuccess, showError } = useStatusModal();

  const validateForm = () => {
    const newErrors = {};

    if (!plan.description.trim()) {
      newErrors.description = "Description is required";
    }

    plan.stages.forEach((stage, index) => {
      const stageErrors = {};
      let hasError = false;

      if (!stage.name.trim()) {
        stageErrors.name = "Stage name is required";
        hasError = true;
      }

      if (!stage.description.trim()) {
        stageErrors.description = "Stage description is required";
        hasError = true;
      }

      if (
        stage.duration === null ||
        stage.duration === undefined ||
        stage.duration === "" ||
        stage.duration <= 0
      ) {
        stageErrors.duration = "Duration must be greater than 0";
        hasError = true;
      }

      const validSkills = stage.skills.filter((skill) => skill.trim() !== "");
      if (validSkills.length === 0) {
        stageErrors.skills = "At least one skill is required";
        hasError = true;
      }

      if (hasError) {
        newErrors[`stage_${index}`] = stageErrors;
      }
    });

    setErrors(newErrors);

    if (Object.keys(newErrors).length > 0) {
      setMessage({
        type: "error",
        message: "Please fill out all required fields",
      });
      return false;
    }

    setMessage({});
    return true;
  };

  const stageValidate = (index) => {
    let isValid = true;
    const stageErrors = {};

    const stage = plan.stages[index];
    if (!stage) return false;

    if (!stage.name.trim()) {
      stageErrors.name = "Stage name is required";
      isValid = false;
    }

    if (!stage.description.trim()) {
      stageErrors.description = "Stage description is required";
      isValid = false;
    }

    if (
      stage.duration === null ||
      stage.duration === undefined ||
      stage.duration === "" ||
      stage.duration <= 0
    ) {
      stageErrors.duration = "Duration must be greater than 0";
      isValid = false;
    }

    const validSkills = stage.skills.filter((skill) => skill.trim() !== "");
    if (validSkills.length === 0) {
      stageErrors.skills = "At least one skill is required";
      isValid = false;
    }

    if (validSkills.length !== stage.skills.length) {
      const updatedStages = [...plan.stages];
      updatedStages[index] = {
        ...stage,
        skills: validSkills,
      };
      setPlan((prevPlan) => ({
        ...prevPlan,
        stages: updatedStages,
      }));
    }

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      if (isValid) {
        delete newErrors[`stage_${index}`];
      } else {
        newErrors[`stage_${index}`] = stageErrors;
      }
      return newErrors;
    });

    if (!isValid) {
      setMessage({
        type: "error",
        message: "Please fill out all required fields",
      });
    } else {
      setMessage({});
    }

    return isValid;
  };

  const toggleEditMode = (index) => {
    setEditingStageIndices((prevIndices) => {
      const newIndices = new Set(prevIndices);
      if (newIndices.has(index)) {
        newIndices.delete(index);
      } else {
        newIndices.add(index);
      }
      return newIndices;
    });
  };

  const handleSave = (index) => {
    const isValid = stageValidate(index);
    const isduplicates = checkForDuplicateStageNames(index);
    if (isValid && !isduplicates) {
      setOriginalStages((prevOriginalStages) => {
        const updatedOriginalStages = [...prevOriginalStages];
        updatedOriginalStages[index] = { ...plan.stages[index] }; // Update only the specific index
        return updatedOriginalStages;
      });

      // Remove the specific index from the editing set only if validation is successful
      setEditingStageIndices((prevIndices) => {
        const newIndices = new Set(prevIndices);
        newIndices.delete(index); // Remove only the specified index
        return newIndices;
      });
      UPDATE_STAGE_PLAN();
    }
  };

  const handleCancel = (index) => {
    setPlan((prevPlan) => {
      const updatedStages = [...prevPlan.stages];
      updatedStages[index] = {
        ...originalStages[index],
        skills: originalStages[index].skills.filter(
          (skill) => skill.trim() !== "",
        ),
      };
      return {
        ...prevPlan,
        stages: updatedStages,
      };
    });

    setEditingStageIndices((prevIndices) => {
      const newIndices = new Set(prevIndices);
      newIndices.delete(index);
      return newIndices;
    });

    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`stage_${index}`];
      return newErrors;
    });

    setLocalErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[`stage_${index}`];
      return newErrors;
    });
  };

  const validateAndSave = () => {
    const duplicateNames = checkForDuplicateNames();

    if (duplicateNames) {
      setLocalErrors(duplicateNames);
    } else {
      setLocalErrors({});
      // Call the original save function if validation passes
      UPDATE_INTERVIEW_PLAN();
    }
  };

  const checkForDuplicateNames = () => {
    const stageNames = plan.stages.map((stage) =>
      stage.name.trim().toLowerCase(),
    );
    const nameToIndicesMap = new Map();
    const duplicateDetails = {};

    stageNames.forEach((name, index) => {
      if (nameToIndicesMap.has(name)) {
        nameToIndicesMap.get(name).push(index);
      } else {
        nameToIndicesMap.set(name, [index]);
      }
    });

    nameToIndicesMap.forEach((indices, name) => {
      if (indices.length > 1) {
        indices.forEach((index) => {
          duplicateDetails[`stage_${index}`] = {
            name: `Duplicate stage name detected: "${name}"`,
          };
        });
      }
    });

    return Object.keys(duplicateDetails).length > 0 ? duplicateDetails : null;
  };

  const checkForDuplicateStageNames = (index) => {
    const stageNameToCheck = plan.stages[index].name.trim().toLowerCase();
    let isDuplicate = false; // To track if the current name is a duplicate

    // Loop through all stages to check if the name at the given index is duplicated
    plan.stages.forEach((stage, i) => {
      if (i !== index && stage.name.trim().toLowerCase() === stageNameToCheck) {
        isDuplicate = true; // Mark as duplicate if any other stage has the same name
      }
    });

    // Update LocalErrors state for this particular index
    setLocalErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };

      if (isDuplicate) {
        // If duplicate, set the error for the current index
        updatedErrors[`stage_${index}`] = {
          name: `Duplicate stage name detected: "${stageNameToCheck}"`,
        };
      } else {
        // Otherwise, remove the duplicate name error if it exists
        if (
          updatedErrors[`stage_${index}`] &&
          updatedErrors[`stage_${index}`].name
        ) {
          delete updatedErrors[`stage_${index}`].name;
          // If there are no other errors for this stage, remove the whole object
          if (Object.keys(updatedErrors[`stage_${index}`]).length === 0) {
            delete updatedErrors[`stage_${index}`];
          }
        }
      }

      return updatedErrors; // Return the updated errors object
    });
    if (isDuplicate) {
      setMessage({
        type: "error",
        message: "Please fill out all required fields",
      }); // Set error message if not valid
    }

    // Return true if there's a duplicate, false otherwise (if needed for validation)
    return isDuplicate;
  };

  const GET_INTERVIEW_PLAN = async () => {
    setisPlanLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.GET,
        core.JOB_API.GET_INTERVIEW_PLAN + jobId,
        1,
        {},
      );
      if (json?.data) {
        setPlan(json?.data?.plan);
        setOriginalStages(JSON.parse(JSON.stringify(json.data.plan.stages)));
        setisPlanLoading(false);
      }
    } catch (error) {
      setMessage({ type: "error", message: error.message });
      setisPlanLoading(false);
    } finally {
      setisPlanLoading(false);
    }
  };

  const UPDATE_INTERVIEW_PLAN = async () => {
    setIsUpdating(true);
    if (validateForm()) {
      try {
        const json = await core.API(
          core.API_METHODS.PUT,
          core.JOB_API.GET_INTERVIEW_PLAN + jobId,
          1,
          { interviewPlan: plan },
        );
        if (json?.data) {
          setIsUpdating(false);
          modalDismiss();
          showSuccess(
            "Interview plan updated",
            "The interview plan has been successfully updated",
          );
        }
      } catch (error) {
        setIsUpdating(false);
        modalDismiss();
        showError(
          "Update failed",
          "An error occurred while updating the interview plan",
        );
        setMessage({ type: "error", message: error.message });
      }
    } else {
      setMessage({
        type: "error",
        message: "Please fill out all required fields",
      });
      setIsUpdating(false);
    }
  };

  const UPDATE_STAGE_PLAN = async () => {
    if (validateForm()) {
      try {
        const json = await core.API(
          core.API_METHODS.PUT,
          core.JOB_API.GET_INTERVIEW_PLAN + jobId,
          1,
          { interviewPlan: plan },
        );
      } catch (error) {
        setMessage({ type: "error", message: error.message });
      }
    } else {
      setMessage({
        type: "error",
        message: "Please fill out all required fields",
      });
    }
  };

  const Create_InterviewPlan = async () => {
    setisPlanLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.POST,
        core.JOB_API.POST_GEN_INTERVIEW_PLAN,
        1,
        { job_id: jobId },
      );
      if (json?.data) {
        GET_INTERVIEW_PLAN();
      }
    } catch (error) {
      setMessage({ type: "error", message: error.message });
      setisPlanLoading(false);
    }
  };

  useEffect(() => {
    GET_INTERVIEW_PLAN();
  }, []);

  const modalElements = {
    heading: "Interview Plan",
    subheading: "Create and edit interview stages",
    modalDismiss: () => modalDismiss(),
    onSave: validateAndSave,
    defaultButtons: {
      primaryLabel: "Save",
      primaryDisabled: !(plan?.stages?.length > 0),
      primaryClass: plan?.stages?.length > 0 ? "" : "hidden",
    },
    className: "sm:!max-w-[60%]",
    isLoading: isUpdating,
  };

  const handleDescriptionChange = (e) => {
    setPlan({ ...plan, description: e.target.value });
  };

  const handleStageChange = (index, field, value) => {
    setPlan((prevPlan) => {
      const newStages = prevPlan.stages.map((stage, i) =>
        i === index ? { ...stage, [field]: value } : stage,
      );
      return { ...prevPlan, stages: newStages };
    });
  };

  const addStage = () => {
    setPlan({
      ...plan,
      stages: [
        ...plan.stages,
        { name: "", description: "", duration: 0, skills: [] },
      ],
    });
  };

  const removeStage = (index) => {
    const newErrors = Object.keys(errors).reduce((acc, key) => {
      const stageIndex = parseInt(key.split("_")[1]);

      if (stageIndex === index) {
        return acc;
      } else if (stageIndex > index) {
        const newKey = `stage_${stageIndex - 1}`;
        acc[newKey] = errors[key];
      } else {
        acc[key] = errors[key];
      }
      return acc;
    }, {});

    const newLocalErrors = Object.keys(LocalErrors).reduce((acc, key) => {
      const stageIndex = parseInt(key.split("_")[1]);

      if (stageIndex === index) {
        return acc;
      } else if (stageIndex > index) {
        const newKey = `stage_${stageIndex - 1}`;
        acc[newKey] = LocalErrors[key];
      } else {
        acc[key] = LocalErrors[key];
      }
      return acc;
    }, {});

    setErrors(newErrors);
    setLocalErrors(newLocalErrors);

    const newStages = plan.stages.filter((_, i) => i !== index);
    setPlan({ ...plan, stages: newStages });

    const newOriginalStages = originalStages.filter((_, i) => i !== index);
    setOriginalStages(newOriginalStages);

    if (
      Object.keys(newErrors).length === 0 &&
      Object.keys(newLocalErrors).length === 0
    ) {
      setMessage({});
    }
  };

  const addSkill = (stageIndex) => {
    const newStages = [...plan.stages];
    newStages[stageIndex].skills.push("");
    setPlan({ ...plan, stages: newStages });
  };

  const removeSkill = (stageIndex, skillIndex) => {
    const newStages = [...plan.stages];
    newStages[stageIndex].skills = newStages[stageIndex].skills.filter(
      (_, i) => i !== skillIndex,
    );
    setPlan({ ...plan, stages: newStages });
  };

  const EditIcon = () => (
    <svg
      fill="none"
      viewBox="0 0 33 33"
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
    >
      <g id="button_2">
        <rect height="32" width="32" fill="white" rx="8" x="0.5" y="0.5" />
        <rect height="31" width="31" rx="7.5" stroke="#E1E1EE" x="1" y="1" />
        <path
          d="M14.3865 5.83114L18.169 9.61364M3 21L6.67278 20.3008C7.45152 20.1526 8.16769 19.7736 8.72823 19.213L20.1837 7.75754C21.2721 6.66918 21.2721 4.90462 20.1837 3.81626C19.0954 2.72791 17.3308 2.72791 16.2425 3.81627L4.78696 15.2718C4.22642 15.8323 3.8474 16.5485 3.69916 17.3272L3 21Z"
          fill="#5F6989"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          transform="translate(5, 5) scale(0.85)"
        />
      </g>
    </svg>
  );

  const SaveIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 20 20"
    >
      <path
        fill="currentColor"
        d="m15.3 5.3l-6.8 6.8l-2.8-2.8l-1.4 1.4l4.2 4.2l8.2-8.2z"
      />
    </svg>
  );

  const CancelIcon = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 2048 2048"
    >
      <path
        fill="currentColor"
        d="m1115 1024l690 691l-90 90l-691-690l-691 690l-90-90l690-691l-690-691l90-90l691 690l691-690l90 90z"
      />
    </svg>
  );

  const addInterviewer = (stageIndex, interviewer) => {
    setPlan((prevData) => {
      const newStages = [...prevData.stages];
      newStages[stageIndex] = {
        ...newStages[stageIndex],
        interviewers: [
          ...(newStages[stageIndex]?.interviewers ?? []),
          interviewer,
        ],
      };
      return {
        ...prevData,
        stages: newStages,
      };
    });
  };

  const removeInterviewer = (stageIndex, interviewerIndex) => {
    const newStages = [...plan.stages];
    newStages[stageIndex].interviewers = newStages[
      stageIndex
    ].interviewers.filter((_, i) => i !== interviewerIndex);
    setPlan({ ...plan, stages: newStages });
  };

  return (
    <Modal {...modalElements}>
      {isPlanLoading ? (
        <div className="flex justify-center items-center">
          <ScaleLoader height={20} color="#007aff" loading={true} />
        </div>
      ) : (
        <div className="w-full flex flex-col gap-4">
          {plan?.description ? (
            <div>
              <label className="mt-2 text-base font-semibold">
                Description<span className="text-red-900 ml-1">*</span>
              </label>
              <p className="text-sm text-justify">{plan?.description}</p>
              {errors.description && (
                <p className="text-red-600">{errors.description}</p>
              )}
            </div>
          ) : null}

          <div>
            {Array.isArray(plan?.stages) && plan?.stages?.length > 0 ? (
              <h2 className="text-base font-semibold mb-2">Stages</h2>
            ) : null}
            {Array.isArray(plan?.stages) && plan?.stages?.length > 0 ? (
              <>
                {plan?.stages?.map((stage, index) => (
                  <>
                    <div
                      key={index}
                      className="relative mb-4 p-4 z-10 border shadow-sm rounded-lg"
                    >
                      <div className="flex justify-between items-center mb-2">
                        <div className="w-[70%]">
                          {editingStageIndices.has(index) ? (
                            <div>
                              <label className="block text-base font-medium text-gray-700">
                                Stage name
                                <span className="text-red-900 ml-1">*</span>
                              </label>
                              <InputBox
                                className="w-full mb-2 p-2 border rounded"
                                value={stage.name}
                                onChange={(e) =>
                                  handleStageChange(
                                    index,
                                    "name",
                                    e.target.value,
                                  )
                                }
                                placeholder="Stage Name"
                              />
                            </div>
                          ) : (
                            <div>
                              <span className="font-medium text-gray-700">
                                Stage name
                              </span>
                              <h3 className="text-base font-medium truncate">
                                {stage.name}
                              </h3>
                            </div>
                          )}
                          {errors[`stage_${index}`]?.name && (
                            <p className="text-red-600">
                              {errors[`stage_${index}`].name}
                            </p>
                          )}
                          {LocalErrors[`stage_${index}`]?.name && (
                            <p className="text-red-600">
                              {LocalErrors[`stage_${index}`].name}
                            </p>
                          )}
                        </div>
                        <div className="flex flex-shrink-0">
                          {editingStageIndices.has(index) ? (
                            <>
                              <button
                                onClick={() => handleSave(index)}
                                className="mr-2 text-green-700"
                              >
                                <SaveIcon />
                              </button>
                              <button
                                onClick={() => handleCancel(index)}
                                className="mr-2 text-red-700"
                              >
                                <CancelIcon />
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => toggleEditMode(index)}
                                className="mr-2 text-blue-600"
                              >
                                <EditIcon />
                              </button>
                              <button
                                onClick={() => {
                                  console.log("Button clicked, index:", index);
                                  removeStage(index);
                                }}
                                className="ml-2 text-red-700"
                              >
                                <Trash2 size={16} />
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      {editingStageIndices.has(index) ? (
                        <>
                          <label className="block text-base font-medium text-gray-700">
                            Description
                            <span className="text-red-900 ml-1">*</span>
                          </label>
                          <TextAreaBox
                            className="w-full mb-2 p-2 border rounded"
                            value={stage.description}
                            onChange={(e) =>
                              handleStageChange(
                                index,
                                "description",
                                e.target.value,
                              )
                            }
                            placeholder="Stage Description"
                            size="auto"
                          />
                          {errors[`stage_${index}`]?.description && (
                            <p className="text-red-600">
                              {errors[`stage_${index}`].description}
                            </p>
                          )}

                          <div className="mb-2">
                            <label className="block text-base font-medium text-gray-700">
                              Duration (minutes)
                              <span className="text-red-900 ml-1">*</span>
                            </label>
                            <InputBox
                              type="number"
                              value={stage.duration}
                              min="0"
                              onChange={(e) =>
                                handleStageChange(
                                  index,
                                  "duration",
                                  parseInt(e.target.value),
                                )
                              }
                              size="medium"
                            />
                            {errors[`stage_${index}`]?.duration && (
                              <p className="text-red-600">
                                {errors[`stage_${index}`].duration}
                              </p>
                            )}
                          </div>
                          <div>
                            <label className="block text-base font-medium text-gray-700">
                              Skills<span className="text-red-900 ml-1">*</span>
                            </label>
                            {stage.skills.map((skill, skillIndex) => (
                              <div
                                key={skillIndex}
                                className="flex items-center mt-1"
                              >
                                <InputBox
                                  value={skill}
                                  onChange={(e) => {
                                    const newSkills = [...stage.skills];
                                    newSkills[skillIndex] = e.target.value;
                                    handleStageChange(
                                      index,
                                      "skills",
                                      newSkills,
                                    );
                                  }}
                                  placeholder="Skill"
                                />
                                <button
                                  onClick={() => removeSkill(index, skillIndex)}
                                  className="ml-2 text-red-700"
                                >
                                  <Trash2 size={16} />
                                </button>
                              </div>
                            ))}
                            {errors[`stage_${index}`]?.skills && (
                              <p className="text-red-600">
                                {errors[`stage_${index}`].skills}
                              </p>
                            )}
                            <button
                              onClick={() => addSkill(index)}
                              className="mt-2 text-blue-800 hover:text-blue-900 flex items-center"
                            >
                              <PlusCircle size={16} className="mr-1" /> Add
                              Skill
                            </button>
                          </div>
                          <div className="mt-2">
                            <SearchUser
                              label={"Interviewers"}
                              emails={stage.interviewers ?? []}
                              removerEmail={(e) => removeInterviewer(index, e)}
                              setEmails={(e) => {
                                addInterviewer(index, e);
                              }}
                            />
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="mb-2">
                            <label className="text-sm font-medium text-gray-700">
                              Description
                            </label>
                            <p className="mt-1">{stage.description}</p>
                            {errors[`stage_${index}`]?.description && (
                              <p className="text-red-600">
                                {errors[`stage_${index}`].description}
                              </p>
                            )}
                          </div>
                          <p className="mb-2">
                            <span className="font-medium text-gray-700">
                              Duration:
                            </span>{" "}
                            {stage.duration} minutes
                            {errors[`stage_${index}`]?.duration && (
                              <p className="text-red-600">
                                {errors[`stage_${index}`].duration}
                              </p>
                            )}
                          </p>
                          <div>
                            <p className="font-medium mb-1 text-gray-700">
                              Skills
                            </p>
                            <div className="flex flex-wrap gap-2">
                              {stage.skills.map((skill, skillIndex) => (
                                <span
                                  key={skillIndex}
                                  className="bg-gray-200 text-gray-800 text-sm px-3 py-1 rounded-lg"
                                >
                                  {skill}
                                </span>
                              ))}
                            </div>
                            {errors[`stage_${index}`]?.skills && (
                              <p className="text-red-600">
                                {errors[`stage_${index}`].skills}
                              </p>
                            )}
                          </div>
                          <div className="mt-2">
                            <p className="font-medium mb-1 text-gray-700">
                              Interviewers
                            </p>
                            <div className="flex flex-wrap gap-2">
                              {stage?.interviewers?.map((int, intIndex) => (
                                <span
                                  key={intIndex}
                                  className="border-gray-300 border-[2px] gap-2 flex items-center  text-gray-800 text-sm px-3 py-1 rounded-lg"
                                >
                                  <InitialsLogo
                                    str={int?.name ?? int?.email}
                                    width="20px"
                                    height="20px"
                                    fontSize="12px"
                                    borderRadius="50%"
                                  />
                                  {int?.name ?? int?.email}
                                </span>
                              ))}
                            </div>
                          </div>
                        </>
                      )}
                      <div className="absolute left-1/2 bottom-0 -translate-x-1/2 translate-y-1/2 flex justify-center">
                        <button
                          onClick={() => {
                            const newStages = [...plan.stages];
                            const newStage = {
                              name: `Round ${newStages.length + 1}`,
                              description: "",
                              duration: 0,
                              skills: [],
                            };

                            // Add the new stage to the stages array
                            newStages.splice(index + 1, 0, newStage);

                            // Update the state of plan.stages
                            setPlan({ ...plan, stages: newStages });

                            // Also, update originalStages to include this new stage
                            setOriginalStages((prevOriginalStages) => {
                              const updatedOriginalStages = [
                                ...prevOriginalStages,
                              ];
                              updatedOriginalStages.splice(
                                index + 1,
                                0,
                                newStage,
                              );
                              return updatedOriginalStages;
                            });

                            // Add the index of the new stage to the editing set
                            setEditingStageIndices((prevIndices) => {
                              const newIndices = new Set(prevIndices);
                              newIndices.add(index + 1); // Index of the new stage
                              return newIndices;
                            });
                          }}
                          className="bg-gray-200 rounded-full z-50 p-4 shadow-md hover:bg-gray-400 hover:z-50 "
                          title="Add new stage"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#00008B"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="12" y1="5" x2="12" y2="19"></line>
                            <line x1="5" y1="12" x2="19" y2="12"></line>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </>
                ))}
              </>
            ) : (
              <div className="flex justify-center py-4 mb-4">
                <button
                  onClick={Create_InterviewPlan}
                  className="btn-primary btn-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    className="w-5 h-5 mr-2"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                    />
                  </svg>
                  {isPlanLoading ? (
                    <>
                      <ScaleLoader height={14} color="#FFF" loading={true} />
                    </>
                  ) : (
                    "Generate interview plan"
                  )}
                </button>
                {/* <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className="w-5 h-5 mr-2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                />
              </svg>

              <p>No Interview Plan found</p> */}
              </div>
            )}
            {/* <button
            onClick={addStage}
            className="mt-4 px-3 py-2 text-white rounded-md btn-primary"
          >
            Add Stage
          </button> */}
          </div>
        </div>
      )}
      {Object.entries(message)?.length > 0 && (
        <p
          style={
            message?.type == "error" ? { color: "red" } : { color: "green" }
          }
          className={`block text-sm flex items-center text-red-600 gap-2 mt-5 w-fit font-medium px-2 py-1 bg-red-200 rounded-md`}
        >
          {message.type == "error" ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.25rem"
              height="1.25rem"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                d="M13 13h-2V7h2m0 10h-2v-2h2M12 2A10 10 0 0 0 2 12a10 10 0 0 0 10 10a10 10 0 0 0 10-10A10 10 0 0 0 12 2"
              />
            </svg>
          ) : null}
          {message?.message}
        </p>
      )}
    </Modal>
  );
}
export default InterviewPlanModal;
