import React, { useEffect, useRef, useState } from "react";
import Modal from "../../../modal/Modal";
import { ScaleLoader } from "react-spinners";
import { IoCopy } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
// import { generateFeedbackToken } from "../../../../utils/tokenUtil";

function FeedbackInviteModal({ modalDismiss, setShowModal, token }) {
  const loc = window.location;

  // const generateFeedbackLink = (jobId, appId) => {
  //   const token = generateFeedbackToken(jobId, appId);
  //   return `/feedback/${token}`;
  // };

  const [url, setUrl] = useState(`${loc.origin}/feedback/${token}`);
  const [message, setMessage] = useState("");

  const copyToClipboard = () => {
    navigator.clipboard.writeText(url).then(
      () => {
        showMessage("URL copied to clipboard!");
      },
      (err) => {
        showMessage("Failed to copy URL");
      },
    );
  };

  const sendEmail = () => {
    const subject = encodeURIComponent("Check out this URL");
    const body = encodeURIComponent(
      `I wanted to share this URL with you: ${url}`,
    );
    window.location.href = `mailto:?subject=${subject}&body=${body}`;
    showMessage("Email client opened!");
  };

  const showMessage = (text) => {
    setMessage(text);
    setTimeout(() => setMessage(""), 3000);
  };

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      heading={"Share for feedback"}
      noBtns={true}
      subheading={"Invite another recruiter or share this URL for feedback."}
      //   onSave={updateNotes}
      modalDismiss={modalDismiss}
      defaultButtons={{
        primaryDisabled: loading,
        primaryLabel: "Save",
      }}
      isLoading={loading}
    >
      <div className="">
        <div className="flex items-center mb-4">
          <span className="flex-grow mr-2 truncate">{url}</span>
          <button
            onClick={copyToClipboard}
            className="p-2 bg-blue-600 text-white rounded hover:bg-blue-800 transition-colors"
            aria-label="Copy URL"
          >
            <IoCopy size={15} />
          </button>
        </div>
        <button
          onClick={(e) => setShowModal("emailInvite")}
          className="mb-4 w-full p-2 bg-blue-600 text-white rounded hover:bg-blue-800 transition-colors flex items-center justify-center"
        >
          <IoMdMail size={20} className="mr-2" />
          Send URL via Email
        </button>
        {message && (
          <div className="mt-4 text-center font-semibold text-gray-700">
            {message}
          </div>
        )}
      </div>
    </Modal>
  );
}

export default FeedbackInviteModal;
