import React, { useContext, useEffect, useState } from "react";
import Modal from "../../modal/Modal";
import TextAreaBox from "../../../layouts/TextAreaBox";
import { convertToHTML } from "draft-convert";
import { convertFromHTML, ContentState, EditorState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { ScaleLoader } from "react-spinners";
import InputBox from "../../../layouts/InputBox";
import { Link } from "react-router-dom";
import { useStatusModal } from "../../../App";
const core = require("../../../lib/core");
import SelectAndSearch from "../../../layouts/SelectAndSearch";
import ApplyForJobModal from "../../../screens/recruiter/candidate/ApplyForJobModal";
var parse = require("html-react-parser");
import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";
import { LuThumbsUp, LuThumbsDown } from "react-icons/lu";

function BulkActionModal({
  changeModal,
  isSourceCandidate,
  selectedSequence,
  updateBulkStatus,
  pipeline,
  bulkupdateCandidatesFavourite,
  Push_candidate_greenhouse,
  modalDismiss,
  cancel,
  sendText,
  candidates,
  checkedItems,
  setCheckedItems,
  checkResource = false,
  setShowWhatsAppModal,
  setBulkActionFlag,
}) {
  const [pipelineData, setPipelineData] = useState(pipeline);
  const [sequenceData, setSequenceData] = useState([]);
  const [message, setMessage] = useState([]);
  const { showSuccess, showError } = useStatusModal();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [candidateIdsToDelete, setCandidateIdsToDelete] = useState([]);
  useEffect(() => {
    setPipelineData(pipeline);
  }, [pipeline]);

  const [selectPipeline, setSelectPipeline] = useState("");
  const [reason, setReason] = useState("");
  const [formview, setFormview] = useState(false);
  const [addFavourite, setAddFavourite] = useState("");
  const [addAts, setAddAts] = useState("false");

  const [loading, setLoading] = useState(false);
  const { user } = useContext(core.UserContext);
  console.log("BulkAction", candidates);

  const [thumbsAction, setThumbsAction] = useState("");
  const [thumbsNotes, setThumbsNotes] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);

  const handleThumbsAction = async (action) => {
    try {
      setLoading(true);
      const response = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_BULK_THUMBS_UP_DOWN,
        1,
        {
          jobId: jobId,
          matchprofileIds: checkedItems,
          thumbs_up: action === "up" ? 1 : 2,
          notes: thumbsNotes,
          latestnote: thumbsNotes,
        },
      );

      if (response?.data) {
        showSuccess(`Thumbs ${action} updated successfully`);
        setThumbsAction("");
        setThumbsNotes("");
        setShowFeedbackModal(false);
        modalDismiss();
      } else {
        setMessage({
          type: "error",
          message: "Failed to update thumbs status",
        });
      }
    } catch (err) {
      setMessage({
        type: "error",
        message: "An error occurred while updating thumbs status",
      });
    } finally {
      setLoading(false);
    }
  };

  const SavePipeline = (send_email) => {
    setLoading(true);
    if (thumbsAction) {
      handleThumbsAction(thumbsAction);
      return;
    }
    if (
      addFavourite === true ||
      addFavourite === false ||
      addFavourite === "rematch" ||
      addFavourite === "reparse"
    ) {
      if (addFavourite === "rematch") {
        reparseMatchProfile();
      } else if (addFavourite === "reparse") {
        reparseMatchProfile(true);
      } else {
        bulkupdateCandidatesFavourite(addFavourite);
        setTimeout(() => {
          modalDismiss();
        }, 3000);
      }
      setLoading(false);
    } else if (addAts === true) {
      Push_candidate_greenhouse();
      setLoading(false);
      setTimeout(() => {
        modalDismiss();
      }, 3000);
    } else {
      if (selectPipeline === "Rejected") {
        console.log("rejectedReason", rejectedReason);
        if (formview === true) {
          updateBulkStatus(
            false,
            selectPipeline,
            reason,
            rejectionTemplate,
            rejection_reason_id,
            onBehalfId,
            scheduleDate !== "now"
              ? new Date(date + "T" + time + ":00").toISOString()
              : null,
            send_email,
            subject,
          );
          setLoading(false);
          setTimeout(() => {
            modalDismiss();
          }, 3000);
        } else {
          setFormview(true);
          setLoading(false);
        }
      } else {
        updateBulkStatus(false, selectPipeline, reason);
        setLoading(false);
        setTimeout(() => {
          modalDismiss();
        }, 3000);
      }
    }
  };

  const [rejectedReason, setRejectedReason] = useState([]);
  const [rejectedTemplates, setRejectedTemplates] = useState([]);
  const [rejectionTemplate, setRejectionTemplate] = useState("");
  const [rejection_reason_id, setRejection_reason_id] = useState("");
  const [onBehalfId, setOnBehalfId] = useState("");

  const { source, jobId, jobName } = useSelector((state) => {
    return {
      source: "Greenhouse",
      jobId: state?.JobExpandSlice?.profile?.id,
      jobName: state?.JobExpandSlice?.profile?.position,
    };
  });

  const reparseMatchProfile = async (onlyResume) => {
    setLoading(true);
    try {
      let json = await core.API(
        core.API_METHODS.POST,
        core.RECRUITER_API.POST_RE_MATCH_CANDIDATES,
        1,
        {
          jobid: jobId,
          matchprofileIds: checkedItems,
          onlyResume: onlyResume,
        },
      );
      if (json?.data) {
        setLoading(false);
        modalDismiss();
      } else {
        setMessage({
          type: "error",
          message:
            json?.error?.message ??
            "Unable to re-parse the profile. Please try again",
        });
      }
    } catch (err) {}
  };

  // const SavePipeline = (send_email) => {
  //   updateRejectedStatus(
  //     candidateId,
  //     "Rejected",
  //     reason,
  //     rejectionTemplate,
  //     rejection_reason_id,
  //     onBehalfId,
  //     scheduleDate !== "now"
  //       ? new Date(date + "T" + time + ":00").toISOString()
  //       : null,
  //     send_email,
  //   );
  // };

  const [jobData, setJobData] = useState([]);
  async function getHiringTeam() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_HIRING_TEAM + `/${jobId}`,
        1,
      );
      if (json?.data) {
        setJobData(json?.data);
      } else {
      }
    } catch (err) {}
  }

  const [editorStateDesc, setEditorStateDesc] = useState(() =>
    EditorState.createEmpty(),
  );

  useEffect(() => {
    if (jobData?.length > 0) {
      setOnBehalfId(jobData?.id);
    }
  }, [jobData]);

  useEffect(() => {
    getHiringTeam();
  }, []);

  const [editorValue, setEditorValue] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [scheduleDate, setScheduleDate] = useState("now");
  const [subject, setSubject] = useState("");
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [to, setTo] = useState([]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleOptionClick = (option) => {
    setSelectedOptions([...selectedOptions, option]);
    setInputValue(""); // Clear input after selecting an option
  };

  const handleRemoveOption = (index) => {
    const updatedOptions = [...selectedOptions];
    updatedOptions.splice(index, 1);
    setSelectedOptions(updatedOptions);
  };
  useEffect(() => {
    setEditorValue(convertToHTML(editorStateDesc?.getCurrentContent()));
  }, [editorStateDesc]);

  async function getRejectedReason() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_REJECTION_REASON,
        1,
      );
      if (json?.data) {
        setRejectedReason(json?.data);
        setRejection_reason_id(json?.data[0]?.id);
      } else {
      }
    } catch (err) {}
  }

  async function getRejectedTemplate() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.RECRUITER_API.GET_REJECTION_TEMPLATE,
        1,
      );
      if (json?.data) {
        setRejectedTemplates(json?.data);
        // setRejectionTemplate(json?.data[0]?.id);
        setSubject(json?.data[0]?.subject);
        setEditorStateDesc(
          EditorState?.createWithContent(
            ContentState?.createFromBlockArray(
              convertFromHTML(
                new String(
                  json?.data[0]?.html_body?.split("&lt;").join("<"),
                ).toString(),
              ),
            ),
          ),
        );
      } else {
      }
    } catch (err) {}
  }

  const [templates, setTemplates] = useState([]);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [bodyText, setBodyText] = useState("");

  useEffect(() => {
    getAllData();
  }, []);

  const getAllData = async () => {
    const list = await core.API(
      core.API_METHODS.GET,
      core.TEMPLATE_API.GET_ALL_TEMPLATE + user?.id,
      1,
    );
    if (list?.data) {
      setTemplates(list.data);
      const firstTemplate = list.data[0];
      if (firstTemplate) {
        setSelectedTemplateId(firstTemplate._id);
        setRejectionTemplate(firstTemplate._id || "");
        setSubject(firstTemplate.subject || "");
        setBodyText(firstTemplate.body || "");
        if (firstTemplate.body) {
          setEditorStateDesc(
            EditorState.createWithContent(
              ContentState.createFromBlockArray(
                convertFromHTML(firstTemplate.body.split("&lt;").join("<")),
              ),
            ),
          );
        }
      }
    }
  };

  const handleTemplateChange = (templateId) => {
    setRejectionTemplate(templateId);

    const selectedTemplate = templates.find(
      (template) => template._id === templateId,
    );

    if (selectedTemplate?.body) {
      setSubject(selectedTemplate.subject || "");
      setBodyText(selectedTemplate.body || "");
      setEditorStateDesc(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(selectedTemplate.body.split("&lt;").join("<")),
          ),
        ),
      );
    }
  };

  const getAllSequence = async () => {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.SEQUENCE_API.GET_ALL_SEQUENCES,
        1,
      );
      if (json?.response) {
        console.log("json?.data", json?.response);
        setSequenceData(json?.response);
      } else {
      }
    } catch (err) {}
  };

  const deleteBulkCandidates = async () => {
    setLoading(true);
    try {
      const json = await core.API(
        core.API_METHODS.PUT,
        core.RECRUITER_API.PUT_DELETE_BULK_CANDIDATE,
        1,
        {
          jobid: jobId,
          matchprofileIds: checkedItems,
        },
      );
      if (json?.data) {
        setLoading(false);
        const deletedIds = checkedItems || [];
        const updatedCheckedItems = (checkedItems || []).filter(
          (item) => !deletedIds.includes(item),
        );
        setCheckedItems(updatedCheckedItems);
        modalDismiss();
        setCandidateIdsToDelete([]);
        showSuccess("Candidates deleted successfully");
      } else {
        setMessage({
          type: "error",
          message:
            json?.error?.message ??
            "Unable to delete candidates. Please try again",
        });
      }
    } catch (err) {
      console.error("Error deleting candidates:", err);
      setMessage({
        type: "error",
        message:
          "An error occurred while deleting candidates. Please try again.",
      });
    } finally {
      setShowDeleteConfirmation(false);
      setLoading(false);
    }
  };

  const [applyDropDown, setApplyDropDown] = useState(-1);
  const handleApplyOtherJob = () => {
    setApplyDropDown(2); // Custom identifier for "apply for another job"
  };
  const handleModalDismiss = () => {
    setApplyDropDown(-1);
    modalDismiss();
  };

  useEffect(() => {
    // getRejectedTemplate();
    getRejectedReason();
    getAllSequence();
  }, []);

  return (
    <Modal
      modalDismiss={cancel}
      heading={"Edit candidate(s)"}
      onSave={SavePipeline}
      className={"md:max-w-[60%] p-[16px]"}
      noBtns={source === "Greenhouse" && formview ? true : false}
      defaultButtons={{
        primaryDisabled: !selectPipeline.length > 1,
        primaryLabel: formview ? "Reject" : "Save",
      }}
      isLoading={loading}
    >
      {!formview ? (
        <>
          {source === "Greenhouse" && (
            <div className="w-full border-b-2 pb-[20px] border-[#E1E1EE]">
              <h5 className="w-full text-[#252E4B] text-[16px] font-medium not-italic mb-2">
                Push to ATS
              </h5>
              <ul className="flex gap-4">
                <li
                  className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addAts === true ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                  onClick={() => {
                    if (addAts === true) {
                      setAddAts(false);
                    } else {
                      setAddAts(true);
                    }
                    setSelectPipeline("");
                    setAddFavourite("");
                  }}
                >
                  Greenhouse
                </li>
              </ul>
              <div></div>
            </div>
          )}
          <div className="w-full border-b-2 pb-[20px] border-[#E1E1EE]">
            <h5 className="text-[#252E4B] text-base font-medium not-italic">
              Candidate Actions
            </h5>
            <ul className="flex flex-wrap gap-x-4 mt-2 gap-y-2">
              <li
                className={`bg-opacity-80 text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${thumbsAction === "up" ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (thumbsAction !== "up") {
                    setThumbsAction("up");
                    setShowFeedbackModal(true);
                  } else {
                    setThumbsAction("");
                  }
                  setSelectPipeline("");
                  setAddAts(false);
                }}
              >
                <div className="flex items-center gap-2">
                  {thumbsAction === "up" ? (
                    <IoMdThumbsUp className="w-5 h-5" />
                  ) : (
                    <LuThumbsUp className="w-4 h-4" />
                  )}
                  Thumbs Up
                </div>
              </li>

              <li
                className={`bg-opacity-80 text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${thumbsAction === "down" ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (thumbsAction !== "down") {
                    setThumbsAction("down");
                    setShowFeedbackModal(true);
                  } else {
                    setThumbsAction("");
                  }
                  setSelectPipeline("");
                  setAddAts(false);
                }}
              >
                <div className="flex items-center gap-2">
                  {thumbsAction === "down" ? (
                    <IoMdThumbsDown className="w-5 h-5" />
                  ) : (
                    <LuThumbsDown className="w-4 h-4" />
                  )}
                  Thumbs Down
                </div>
              </li>

              <li
                className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === "reparse" ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite !== "reparse") {
                    setAddFavourite("reparse");
                  } else {
                    setAddFavourite("");
                  }
                  setSelectPipeline("");
                  setAddAts("false");
                }}
              >
                Re parse (resume)
              </li>
              <li
                className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === "rematch" ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite !== "rematch") {
                    setAddFavourite("rematch");
                  } else {
                    setAddFavourite("");
                  }
                  setSelectPipeline("");
                  setAddAts("false");
                }}
              >
                Re score (match)
              </li>
              <li
                className={`bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === true ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite === true) {
                    setAddFavourite("");
                  } else {
                    setAddFavourite(true);
                  }
                  setSelectPipeline("");
                }}
              >
                Add to Favorites
              </li>
              <li
                className={`bg-opacity-80 text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === false ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  if (addFavourite === false) {
                    setAddFavourite("");
                  } else {
                    setAddFavourite(false);
                  }
                  setSelectPipeline("");
                }}
              >
                Remove from Favorites
              </li>

              <li
                className={`bg-opacity-80 text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer ${addFavourite === "Delete" ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 bg-opacity-80 text-black"}`}
                onClick={() => {
                  setCandidateIdsToDelete(checkedItems);
                  setShowDeleteConfirmation(true);
                }}
              >
                Delete candidates
              </li>

              <li
                className="bg-opacity-80 text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer bg-gray-300 bg-opacity-80 text-black"
                onClick={() => {
                  handleApplyOtherJob();
                }}
              >
                Apply jobs
              </li>
            </ul>
            <div></div>
          </div>

          {showFeedbackModal && (
            <Modal
              heading="Feedback Notes"
              modalDismiss={() => {
                setShowFeedbackModal(false);
                setThumbsAction("");
                setThumbsNotes("");
              }}
              onSave={() => {
                handleThumbsAction(thumbsAction);
                setShowFeedbackModal(false);
              }}
              dismissOnSave={false}
              defaultButtons={{
                primaryLabel: "Save",
                secondaryLabel: "Cancel",
              }}
            >
              <div className="w-full">
                <TextAreaBox
                  onChange={(e) => setThumbsNotes(e.target.value)}
                  value={thumbsNotes}
                  size={"150px"}
                  placeholder="Enter feedback notes..."
                />
              </div>
            </Modal>
          )}

          {applyDropDown > -1 && (
            <ApplyForJobModal
              jobId={jobId} // Use a dynamic jobId for applying to other jobs
              id={applyDropDown}
              candidate={candidates}
              modalDismiss={handleModalDismiss}
            />
          )}
          {showDeleteConfirmation && (
            <Modal
              heading="Are you sure you want to delete these candidates?"
              modalDismiss={() => {
                setShowDeleteConfirmation(false);
                setCandidateIdsToDelete([]);
              }}
              subheading={`You are about to delete ${candidateIdsToDelete.length} candidates. This action cannot be undone.`}
              dismissOnSave={false}
              onSave={() => deleteBulkCandidates()} // Call delete function
              defaultButtons={{
                primaryLabel: "Confirm",
                secondaryLabel: "Cancel",
              }}
            />
          )}

          {!isSourceCandidate && (
            <div className="w-full border-b-2 pb-[20px] border-[#E1E1EE]">
              <h5 className="text-[#252E4B] text-base font-medium not-italic">
                Move to another stage
              </h5>
              <ul className="flex flex-wrap gap-x-4 mt-2 gap-y-2">
                {pipelineData.map((item) => (
                  <li
                    className={` bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer  ${selectPipeline === item ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 text-black"}`}
                    onClick={() => {
                      if (selectPipeline === item) {
                        setSelectPipeline("");
                        setAddFavourite("");
                        setAddAts("false");
                      } else {
                        setSelectPipeline(item);
                        setAddFavourite("");
                        setAddAts("false");
                      }
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          )}
          <div className="w-full border-b-2 pb-[20px] border-[#E1E1EE]">
            <h5 className="text-[#252E4B] text-base font-medium not-italic">
              Reachout to candidates
            </h5>
            <ul className="flex flex-wrap gap-x-4 mt-2 gap-y-2">
              <li
                className="flex items-center gap-2 bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic bg-gray-300 text-black hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer "
                onClick={() => {
                  setShowWhatsAppModal(true);
                  setBulkActionFlag(true);
                  cancel();
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                >
                  <g fill="currentColor">
                    <path
                      fillRule="evenodd"
                      d="M12 4a8 8 0 0 0-6.895 12.06l.569.718l-.697 2.359l2.32-.648l.379.243A8 8 0 1 0 12 4M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382l1.426-4.829l-.006-.007l-.033-.055A9.96 9.96 0 0 1 2 12"
                      clipRule="evenodd"
                    />
                    <path d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1 1 0 0 0-.34-.075c-.196 0-.362.098-.49.291c-.146.217-.587.732-.723.886c-.018.02-.042.045-.057.045c-.013 0-.239-.093-.307-.123c-1.564-.68-2.751-2.313-2.914-2.589c-.023-.04-.024-.057-.024-.057c.005-.021.058-.074.085-.101c.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711c-.158-.377-.366-.552-.655-.552c-.027 0 0 0-.112.005c-.137.005-.883.104-1.213.311c-.35.22-.94.924-.94 2.16c0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537c1.412.564 2.081.63 2.461.63c.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276c.192-.534.243-1.117.115-1.329c-.088-.144-.239-.216-.43-.308" />
                  </g>
                </svg>
                WhatsApp
              </li>
            </ul>
          </div>
          {sequenceData?.length > 0 && (
            <div className="w-full">
              <h5 className="text-[#252E4B] text-base font-medium not-italic">
                Add email sequence
              </h5>
              <ul className="flex flex-wrap gap-x-4 mt-2 gap-y-2">
                {sequenceData.map((item) => (
                  <li
                    key={item?._id}
                    className={` bg-opacity-80  text-sm px-2 py-1 rounded font-normal not-italic hover:bg-blue-200 hover:text-[#145ECC] cursor-pointer  ${selectedSequence[0] === item?._id ? "bg-blue-200 text-[#145ECC]" : "bg-gray-300 text-black"}`}
                    onClick={() => {
                      setSelectPipeline("");
                      setAddFavourite("");
                      setAddAts("false");
                      selectedSequence[0] == item
                        ? selectedSequence[1]("")
                        : selectedSequence[1](item);
                      changeModal("email-sequencing");
                    }}
                  >
                    {item?.name}
                  </li>
                ))}
              </ul>
            </div>
          )}
          {Object?.entries(message)?.length > 0 && (
            <p
              style={
                message?.type == "error" ? { color: "red" } : { color: "green" }
              }
              className="block text-sm font-medium mt-2"
            >
              {message?.message}
            </p>
          )}
        </>
      ) : (
        <div>
          {source === "Greenhouse" && (
            <div>
              <label className="text-sm font-normal  text-gray-800 not-italic required">
                Reason of rejection
              </label>
              <SelectAndSearch
                placeholder="Select"
                data={rejectedReason?.map((reason) => ({
                  name: reason.name,
                  id: reason.id,
                }))}
                setSelectedTemplate={setRejection_reason_id}
                defalultValue={
                  rejectedReason?.find(
                    (reason) => reason.id === rejection_reason_id,
                  )?.name || ""
                }
              />
            </div>
          )}
          <div>
            <label className="text-sm font-normal pt-2 text-gray-800 not-italic">
              Rejection notes
            </label>
            <TextAreaBox
              onChange={(e) => setReason(e.target.value)}
              size={"150px"}
            />
          </div>
          {source === "Greenhouse" && (
            <>
              <div className="pt-2">
                <h5 className="text-black text-base font-medium w-[calc(100%-32px)] not-italic">
                  Rejection Email
                </h5>
              </div>

              <div className="">
                <div>
                  <label className="text-[14px] font-normal not-italic required">
                    Template
                  </label>
                  <SelectAndSearch
                    placeholder="Select Template"
                    data={templates.map((template) => ({
                      name: template.template_name,
                      id: template._id,
                    }))}
                    setSelectedTemplate={(templateId) => {
                      handleTemplateChange(templateId);
                      setSelectedTemplateId(templateId);
                    }}
                    defalultValue={
                      templates.find(
                        (template) => template._id === selectedTemplateId,
                      )?.template_name || ""
                    }
                  />
                </div>
                {/* {jobData?.length > 0 && (
                  <div>
                    <label className="text-sm font-normal  text-gray-800 not-italic  mt-[20px]">
                      From
                    </label>
                    <select
                      className={`w-[100%]  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
                      name="from"
                      placeholder={"Select"}
                      onChange={(e) => setOnBehalfId(e.target.value)}
                      defaultValue={jobData.length > 0 && jobData[0]?.id}
                      // value={defaultValue}
                    >
                      {jobData?.map((option) => (
                        <option key={option?.id} value={option?.id}>
                          {option?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                )} */}
                <div className="pt-2">
                  <label
                    className="  text-[14px] font-normal not-italic required"
                    htmlFor=""
                  >
                    Subject
                  </label>
                  <InputBox
                    size={"small"}
                    type="text"
                    value={subject}
                    onChange={(e) => setSubject(e?.target?.value)}
                  />
                </div>

                {/* <div className=" mt-[20px]">
                  <label
                    className=" text-[14px] font-normal not-italic"
                    htmlFor=""
                  >
                    Cc
                  </label>
                  <div className="relative border flex flex-wrap py-[5px]  border-[#E1E1EE] bg-[#FFF]  rounded-[8px] w-full px-[20px] ">
                    {selectedOptions.map((option, index) => (
                      <span
                        className="h-[20px] flex items-center mr-[10px] rounded-[4px] bg-[#F3F5F9] px-[10px]"
                        key={index}
                      >
                        {option}
                        <svg
                          className="cursor-pointer"
                          onClick={(e) => handleRemoveOption(index)}
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 7L17 17"
                            stroke="#252E4B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 17L17 7"
                            stroke="#252E4B"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </span>
                    ))}
                    <div className="relative">
                      <input
                        type="email"
                        className="w-auto"
                        value={inputValue}
                        onChange={handleInputChange}
                      />

                      {inputValue?.length > 2 && (
                        <ul className="absolute bg-[#F3F5F9] px-[10px] py-[5px] rounded-[5px] top-[24px]">
                          <li onClick={() => handleOptionClick(inputValue)}>
                            {inputValue}
                          </li>
                        </ul>
                      )}
                    </div>
                  </div>
                </div> */}
                {/* <div className="mt-[20px]" id="description">
                  <label
                    className=" text-right text-[14px] mt-[15px] font-normal not-italic"
                    htmlFor=""
                  >
                    Body (View only)
                  </label>
                  <Editor
                    editorState={editorStateDesc}
                    // onEditorStateChange={handleEditorStateChange}
                    // onEditorStateChange={setEditorStateDesc}
                    wrapperClassName="wrapper-class"
                    editorClassName="editor-class"
                    toolbarClassName="toolbar-class"
                    // handleReturn={handleReturn}
                  />
                </div> */}

                <div className="pt-2" id="description">
                  <label
                    className="text-right text-[14px] font-normal not-italic"
                    htmlFor=""
                  >
                    Body
                  </label>
                  <div
                    className="w-full mt-1 p-4 bg-gray-100 border border-gray-300 rounded-md"
                    style={{
                      whiteSpace: "pre-wrap",
                      overflow: "auto",
                      maxHeight: "500px",
                    }}
                  >
                    {bodyText ? (
                      <div
                        className="prose prose-base max-w-none"
                        dangerouslySetInnerHTML={{
                          __html: bodyText.split("&lt;").join("<"),
                        }}
                      />
                    ) : (
                      "No Content"
                    )}
                  </div>
                </div>

                <div>
                  <label className="text-sm font-normal  text-gray-800 not-italic pt-2">
                    Send email when
                  </label>
                  <select
                    className={`w-[100%]  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]`}
                    name="from"
                    value={scheduleDate}
                    onChange={(e) => setScheduleDate(e.target.value)}
                    style={{
                      appearance: "none", // Remove default arrow icon
                      paddingRight: "24px", // Adjust padding to make space for the placeholder
                      backgroundImage:
                        "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' fill='%235F6989' viewBox='0 0 24 24' width='24' height='24'><path d='M0 0h24v24H0z' fill='none'/><path d='M7 10l5 5 5-5H7z'/></svg>\")", // Add custom arrow icon (downward arrow)
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "calc(100% - 8px) center", // Adjust position of the arrow icon
                      backgroundSize: "16px", // Adjust size of the arrow icon
                    }}
                    // value={defaultValue}
                  >
                    <option value={"now"}>Now</option>
                    <option value={"custom"}>Pick a custom time...</option>
                  </select>
                </div>
                {scheduleDate === "custom" && (
                  <div>
                    <label className="text-sm font-normal  text-gray-800 not-italic pt-2">
                      Send email on
                    </label>
                    <div>
                      <input
                        type="date"
                        className="  h-[32px]  border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]"
                        name="from"
                        min={new Date().toISOString().split("T")[0]}
                        placeholder={"Select"}
                        onChange={(e) => setDate(e.target.value)}
                        // value={defaultValue}
                      />
                      <input
                        type="time"
                        placeholder="Select time"
                        className="  h-[32px] ml-[20px] border border-gray-400 px-2 text-base rounded-lg  shadow-[0px_2px_0px_rgba(0,0,0,0.04)] active:border-[#1369E9] focus:border-[#1369E9] focus:shadow-inputShadow placeholder:text-[16px] placeholder:font-normal placeholder:text-[#5F6989]"
                        onChange={(e) => setTime(e.target.value)}
                        style={{
                          appearance: "none", // Remove default arrow icon
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex justify-end mt-[20px] gap-4">
                <button className={"btn-md btn-sec "} onClick={modalDismiss}>
                  {"Cancel"}
                </button>
                <button
                  onClick={() => SavePipeline(false)}
                  className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9]  shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
                >
                  {"Reject and don't send email"}
                </button>
                <button
                  onClick={() => {
                    SavePipeline(true); // Always send email when this button is clicked
                  }}
                  className="solid-button-blue px-[20px] h-[40px] rounded-[8px] bg-[#1369E9] shadow-jobPostButtonShadow flex items-center justify-center text-[#FFF] text-[16px] not-italic font-normal"
                >
                  {`Reject and ${scheduleDate === "now" ? "send" : "schedule"} email`}
                </button>
              </div>
            </>
          )}
        </div>
      )}
    </Modal>
  );
}

export default BulkActionModal;
