import React from "react";
import Modal from "../../../modal/Modal";

function ReparseModal({ modalDismiss, jobId, handleSubmit, loading }) {
  const onCancel = () => {
    modalDismiss();
  };
  const modalElements = {
    heading: "Re-parsing Job Details",
    modalDismiss: () => onCancel(),
    defaultButtons: {
      primaryLabel: "Save",
      secLabel: "Cancel",
    },
    onSave: () => {
      handleSubmit();
    },
  };
  return (
    <Modal {...modalElements}>
      {/* Warning message about changes on re-parsing the job */}
      <div className="border border-orange-400 bg-orange-100 p-4 rounded-md text-orange-800 font-sans">
        <p className="">
          Re-parsing the job will overwrite the current details with updated
          information based on the document parser's response.
        </p>
        <p className="font-bold mb-2">
          This action cannot be undone, and you may lose the existing job
          details.
        </p>
        <p>Are you sure you want to continue?</p>
      </div>
    </Modal>
  );
}

export default ReparseModal;
