import { useEffect, useMemo, useState, useContext } from "react";
import React, { Suspense, lazy } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";

// Lazy loads routes
const Login = lazy(() => import("./screens/auth/Login"));
const NewUserRegister = lazy(() => import("./screens/auth/NewUserRegister"));
const CreateProfile = lazy(() => import("./screens/user/CreateProfile"));
const Dashboard = lazy(() => import("./screens/user/Dashboard"));
const ProfilePage = lazy(() => import("./screens/candidate/ProfilePage"));
const AppliedJobs = lazy(
  () => import("./screens/candidate/dashboard/AppliedJobs"),
);
const SavedJobs = lazy(() => import("./screens/candidate/dashboard/SavedJobs"));
// import JobDescription from "./screens/jobBoard/Description";

// Recruiter
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import { toast, ToastContainer } from "react-toastify";
import { Tooltip } from "react-tooltip";

const Zoom = lazy(() => import("./components/candidate/tempFolder/Zoom"));
const GoogleCal = lazy(() => import("./components/recruiter/GoogleCal"));
const CandidateDrawer = lazy(
  () => import("./components/recruiter/candidate/CandidateDrawer"),
);
const CompanyDetails = lazy(
  () => import("./components/recruiter/company/CompanyDetails"),
);
const CreateSequence = lazy(
  () => import("./components/recruiter/sequencing/CreateSequence"),
);
const SequencePageContent = lazy(
  () => import("./components/recruiter/sequencing/SequencePageContent"),
);
const Status = lazy(
  () => import("./components/recruiter/sequencing/tabs/Status"),
);

const Structure = lazy(
  () => import("./components/recruiter/sequencing/tabs/Structure"),
);
const CreateTemplate = lazy(
  () => import("./components/recruiter/recruiterTemplates/CreateTemplate"),
);
const EditJobTemplate = lazy(
  () => import("./components/recruiter/recruiterTemplates/EditJobTemplate"),
);
const TemplatePageContent = lazy(
  () => import("./components/recruiter/recruiterTemplates/TemplatePageContent"),
);
const TemplateStructure = lazy(
  () => import("./components/recruiter/recruiterTemplates/tabs/Structure"),
);
const TemplateStructure2 = lazy(
  () =>
    import("./components/recruiter/recruiterTemplates/tabs/jobTempStructure"),
);
const LockedPage = lazy(() => import("./layouts/LockedPage"));

import { SocketProvider } from "./lib/ChatContext";

const Admin = lazy(() => import("./screens/admin/Admin"));
const AdminDashboard = lazy(() => import("./screens/admin/Dashboard"));
const NewUser = lazy(() => import("./screens/admin/NewUser"));
const Role = lazy(() => import("./screens/admin/Role"));
const TemplateManager = lazy(() => import("./screens/admin/TemplateManager"));
const User = lazy(() => import("./screens/admin/User"));
const ForgotPassword = lazy(() => import("./screens/auth/ForgotPassword"));
const InviteLogin = lazy(() => import("./screens/auth/InviteLogin"));
const InviteAuth = lazy(() => import("./screens/auth/inviteAuth/InviteAuth"));
const InvitePassword = lazy(
  () => import("./screens/auth/inviteAuth/InvitePassword"),
);
const InviteSignUp = lazy(
  () => import("./screens/auth/inviteAuth/InviteSignUp"),
);
const InviteVerifyCode = lazy(
  () => import("./screens/auth/inviteAuth/InviteVerifyCode"),
);
const Assessment = lazy(
  () => import("./screens/candidate/assessment/Assessment"),
);
const AssessmentDashboard = lazy(
  () => import("./screens/candidate/assessment/Assessmentdashboard"),
);

// import JobListing from "./screens/jobBoard/JobListing";
const AssessmentEvalReport = lazy(
  () => import("./screens/recruiter/AssessmentEvalReport"),
);
const ChatRecruiter = lazy(() => import("./screens/recruiter/ChatRecruiter"));
const CreateCompany = lazy(() => import("./screens/recruiter/CreateCompany"));

import CreateJob from "./screens/recruiter/CreateJob";
const EditCompany = lazy(() => import("./screens/recruiter/EditCompany"));
const GmailVerificationComplete = lazy(
  () => import("./screens/recruiter/GmailVerificationComplete"),
);
const HandPickedCandidate = lazy(
  () => import("./screens/recruiter/HandPickedCandidate"),
);
const JobTemplateScreen = lazy(
  () => import("./screens/recruiter/JobTemplateScreen"),
);
const RecruiterProfile = lazy(
  () => import("./screens/recruiter/RecruiterProfile"),
);
const EvaluationDoc = lazy(() => import("./screens/recruiter/SubmitCandidate"));
const TemplatePage = lazy(() => import("./screens/recruiter/TemplatePage"));
const Bot = lazy(() => import("./screens/recruiter/botComponent/Bot"));
const BrowseCandidates = lazy(
  () => import("./screens/recruiter/candidate/BrowseCandidates"),
);
const CandidateEvaluation = lazy(
  () => import("./screens/recruiter/candidate/CandidateEvaluation"),
);
const CompanyList = lazy(
  () => import("./screens/recruiter/companyProfile/CompanyList"),
);
const LiveMeeting = lazy(
  () => import("./screens/recruiter/instantMeeting/LiveMeeting"),
);
const MeetingNotes = lazy(
  () => import("./screens/recruiter/instantMeeting/MeetingNotes"),
);
import JobDetailPage from "./screens/recruiter/jobs/JobExpandedNew";

const SequencePage = lazy(
  () => import("./screens/recruiter/sequence/SequencePage"),
);
const ChartsTemplate = lazy(() => import("./screens/temp/Chart"));
const FormBuilder = lazy(() => import("./screens/temp/FormBuilder"));
const Sms = lazy(() => import("./screens/twilio/Sms"));
const Twilio = lazy(() => import("./screens/twilio/Twilio"));
const Message = lazy(() => import("./screens/user/Message"));
const NewSetting = lazy(() => import("./screens/user/setting/NewSetting"));
const VoiceCall = lazy(() => import("./screens/voice/VoiceCall"));

import "./style/all/style.scss";
import { PropagateLoader } from "react-spinners";
import { ErrorModal, SuccessModal } from "./components/modal/StatusModals";
import usePreventIframeEmbedding from "./hooks/user-prevent-iframe-embedding";

const AdminActivity = lazy(() => import("./screens/admin/AdminActivity"));

const CandidatePool = lazy(
  () => import("./screens/recruiter/candidate/CandidatePool"),
);
const GoogleMeet = lazy(() =>
  import("./components/recruiter/candidate/icons/Icons").then((module) => ({
    default: module.GoogleMeet,
  })),
);

const Jobs = lazy(() => import("./screens/jobPost/Jobs"));
const JobForm = lazy(() => import("./screens/jobPost/JobForm"));
const JobDesc = lazy(() => import("./screens/jobPost/jobDescription/JobDesc"));

const JobSubmitted = lazy(() => import("./screens/jobPost/JobSubmitted"));
const Guidelines = lazy(() => import("./screens/user/setting/Guidelines"));
const ThemeProvider = lazy(() =>
  import("./context/ThemeContext").then((module) => ({
    default: module.ThemeProvider,
  })),
);
const Avavilabiltycheck = lazy(
  () => import("./screens/recruiter/Avavilabiltycheck"),
);

const CreateNewProfile = lazy(
  () => import("./screens/candidate/CreateNewProfile"),
);
const CandidatePage = lazy(
  () => import("./screens/recruiter/candidate/CandidatePage"),
);
const PublicReviewCandidatepage = lazy(
  () =>
    import("./components/recruiter/job/candidates/PublicReviewCandidatepage"),
);
const LeadList = lazy(() => import("./screens/leadOnboard/LeadList"));
const LeadsCandidatesList = lazy(
  () => import("./screens/leadOnboard/LeadsCandidatesList"),
);
const ProtectedRouteComponent = lazy(
  () => import("./layouts/ProtectedRouteComponent"),
);

const General = lazy(() => import("./screens/user/setting/General"));
const Password = lazy(() => import("./screens/user/setting/Password"));
const JobIntegrationCard = lazy(
  () => import("./screens/user/setting/JobIntegrationCard"),
);
const Messaging = lazy(() => import("./screens/user/setting/Messaging"));
const BrowsingAgent = lazy(
  () => import("./screens/user/setting/BrowsingAgent"),
);
const Notification = lazy(() => import("./screens/user/setting/Notification"));
import InvitedFeedback from "./components/recruiter/candidate/interview/interviewFeedback/InvitedFeedback";

const core = require("./lib/core");

// Custom hook for easy access to status modals
export const useStatusModal = () => useContext(core.StatusModalContext);

export default function App() {
  const [user, setUser] = useState();
  const nav = useNavigate();
  const [profile, setProfile] = useState();

  const [resources, setResources] = useState([]);
  const [role, setRole] = useState();

  const userMemo = useMemo(
    () => ({ user, setUser, profile, resources, role }),
    [user, profile, resources, role],
  );

  usePreventIframeEmbedding();
  const loc = useLocation();
  useEffect(() => {
    if (
      loc.pathname.includes("job-post") ||
      loc?.pathname?.includes("availability") ||
      loc?.pathname?.includes("outsource") ||
      loc?.pathname?.includes("feedback")
    ) {
    } else {
      if (core.checkAuth()) {
        getUser();
      } else {
        core.logout();
        console.log(loc);
        // nav("/login", { state: loc.pathname });
        nav("/login");
      }
    }
  }, []);
  // Get user details

  console.log("calling");
  useEffect(() => {
    const params = new URLSearchParams(loc.search);
    const auth = params.get("auth");
    const service = params.get("service");
    const message = params.get("message");

    const isAuthTrue = auth === "true";
    const isAuthFalse = auth === "false";

    if ((isAuthTrue || isAuthFalse) && service && message) {
      if (isAuthTrue) {
        toast.success(message);
      } else if (isAuthFalse) {
        toast.error(message);
      }

      // Clear the URL parameters without adding to the history stack
      nav("/settings", { replace: true });
      window.history.replaceState({}, document.title, loc.pathname);
    }
  }, [loc, nav]);

  async function getUser() {
    try {
      let json = await core.API(
        core.API_METHODS.GET,
        core.USER_API.GET_USER,
        1,
      );
      // If user exists
      if (json?.data?.user) {
        setUser(json?.data?.user);
      } else {
        console.error("There is no user with these credentials");
        core.logout();
      }
    } catch (err) {
      console.error(err);
      core.logout();
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      const { resources, role } = await core?.getResources();
      setResources(resources);
      setRole(role);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (user?.id && user?.stage != "COMPLETED") {
      nav("/register", { replace: true });
      return;
    }
    // if (user?.account == 1 && user?.active != 1) {
    //   nav("/unactive");
    // }
  }, [user]);
  // const ProtectedRoute = ({ children }) => {
  //   if (core.checkAuth()) {
  //     return children;
  //   } else {
  //     return <Navigate to="/login" state={loc?.pathname + loc.search} />;
  //   }
  // };

  console.log("userMemo", userMemo);

  const [statusModal, setStatusModal] = useState({
    type: null, // 'success' or 'error'
    heading: "",
    subheading: "",
    isOpen: false,
  });

  // Status modal functions
  const showSuccess = (heading, subheading = "", className = "") => {
    console.log("show", heading, subheading);
    setStatusModal({
      type: "success",
      heading,
      subheading,
      isOpen: true,
      className: className,
    });
  };

  const showError = (heading, subheading = "", className = "") => {
    setStatusModal({
      type: "error",
      heading,
      subheading,
      isOpen: true,
      className: className,
    });
  };

  const closeStatusModal = () => {
    setStatusModal((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <core.UserContext.Provider value={{ ...userMemo, getUser }}>
      <core.StatusModalContext.Provider value={{ showSuccess, showError }}>
        <SocketProvider>
          <Suspense
            fallback={
              <div>
                <PropagateLoader className="loader" color="#0b53dd" />
              </div>
            }
          >
            <Routes>
              <Route
                path="/reviewcandidate/:jobId/:matchProfileId"
                element={<PublicReviewCandidatepage />}
              />
              {/* Login */}
              <Route path="/linkedin" element={<LinkedInCallback />} />
              <Route path="/register" element={<NewUserRegister />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              {/* <Route path="/unactive" element={<UnactiveAccount />} /> */}
              <Route path="/invite" element={<InviteAuth />} />
              <Route path="/invitelogin" element={<InviteLogin />} />
              {/* Invite Pages */}
              <Route path="/invite-signup" element={<InviteSignUp />} />
              <Route path="/invite-enter-code" element={<InviteVerifyCode />} />
              <Route path="/invite-set-password" element={<InvitePassword />} />
            </Routes>
            {/* <ThemeContext> */}
            <ThemeProvider>
              <Routes>
                {/* job post */}
                <Route path="/feedback/:token" element={<InvitedFeedback />} />
                <Route path="/job-post/:id" element={<Jobs />} />
                <Route path="/job-post/form/:id" element={<JobForm />} />
                <Route path="/job-post/details/:id" element={<JobDesc />} />
                <Route path="/job-post/submit" element={<JobSubmitted />} />
                <Route path="/settings/guidelines" element={<Guidelines />} />
                <Route
                  path="/availability/:userid"
                  element={<Avavilabiltycheck />}
                />
                <Route
                  path="/outsource/candidates/id/:id"
                  element={<LeadsCandidatesList />}
                >
                  {/* Nested routes */}
                  {/* Add more nested routes here */}
                </Route>

                {/* Login */}

                <Route path="/linkedin" element={<LinkedInCallback />} />
                <Route path="/register" element={<NewUserRegister />} />
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                {/* <Route path="/unactive" element={<UnactiveAccount />} /> */}
                <Route path="/invite" element={<InviteAuth />} />
                <Route path="/invitelogin" element={<InviteLogin />} />
                {/* Invite Pages */}
                <Route path="/invite-signup" element={<InviteSignUp />} />
                <Route
                  path="/invite-enter-code"
                  element={<InviteVerifyCode />}
                />
                <Route
                  path="/invite-set-password"
                  element={<InvitePassword />}
                />

                {/* Public Pages */}
                <Route path="/submit/:jobId" element={<EvaluationDoc />} />
                <Route
                  path="/evaluation/:id/:interview_id"
                  element={<CandidateEvaluation />}
                />
                {/* <Route path="/careers" element={<JobListing />} /> */}
                {/* <Route path="/jobpost/:id" element={<JobDescription />} /> */}
                <Route
                  path="/assessment-report/:id"
                  element={<AssessmentEvalReport />}
                />
                {/* interviewFeedback */}

                {/* Recruiter */}
                <Route
                  path="/"
                  exact
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:id"
                  exact
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-profile"
                  exact
                  element={
                    <ProtectedRoute>
                      {/* <CreateProfile /> */}
                      <CreateNewProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      <NewSetting />
                    </ProtectedRoute>
                  }
                >
                  <Route index element={<General />} />
                  <Route path="password" element={<Password />} />
                  <Route path="notification" element={<Notification />} />
                  <Route path="integration" element={<JobIntegrationCard />} />
                  <Route path="message" element={<Messaging />} />
                  <Route path="browsingAgent" element={<BrowsingAgent />} />
                </Route>
                {/* cadidate-pool */}

                <Route
                  path="/candidate-pool"
                  exact
                  element={
                    <ProtectedRoute>
                      <CandidatePool />
                    </ProtectedRoute>
                  }
                >
                  {/* <Route
                      path="show-candidate/:candidateId"
                      element={
                        <ProtectedRoute>
                          <CandidateDrawer source="candidate" />
                        </ProtectedRoute>
                      }
                    /> */}
                </Route>
                <Route
                  path="/googleCal"
                  element={
                    <ProtectedRoute>
                      <GoogleCal />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/verification-complete"
                  element={<GmailVerificationComplete />}
                />

                {/* Temporary/Practice files */}
                <Route path="/chart-temp" element={<ChartsTemplate />} />
                <Route path="/form-builder" element={<FormBuilder />} />
                {/* Recruiter */}
                <Route path="/voice-call" element={<VoiceCall />} />
                <Route path="/twilioapp" element={<Twilio />} />
                <Route
                  path="/sms"
                  element={
                    <LockedPage page={"Communications_read"}>
                      <Sms />
                    </LockedPage>
                  }
                />
                <Route path="/zoom" element={<Zoom />} />

                {/* Recruiter */}
                <Route
                  path="/"
                  exact
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/:id"
                  exact
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/create-profiles"
                  exact
                  element={
                    <ProtectedRoute>
                      <CreateProfile />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/settings"
                  element={
                    <ProtectedRoute>
                      {/* <Setting /> */}
                      <NewSetting />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/googleCal"
                  element={
                    <ProtectedRoute>
                      <GoogleCal />
                    </ProtectedRoute>
                  }
                />

                {user && (
                  <>
                    <Route
                      path="/dashboard"
                      element={
                        <ProtectedRoute>
                          <Dashboard />
                          {/* <AssessmentDashboard /> */}
                        </ProtectedRoute>
                      }
                    />
                    {user?.account === 0 ? (
                      <>
                        <Route
                          path="/saved"
                          element={
                            <ProtectedRoute>
                              <SavedJobs />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/applied"
                          element={
                            <ProtectedRoute>
                              <AppliedJobs />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/profile/:id/edit"
                          element={
                            <ProtectedRoute>
                              <ProfilePage />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          path="/profile/:id"
                          element={
                            <ProtectedRoute>
                              <ProfilePage />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/assessment/:id"
                          element={
                            <ProtectedRoute>
                              <Assessment />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/assessment"
                          element={
                            <ProtectedRoute>
                              <Assessment />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/assessment-dashboard"
                          element={
                            <ProtectedRoute>
                              <AssessmentDashboard />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/create-profile"
                          element={
                            <ProtectedRoute>
                              <CreateNewProfile />
                            </ProtectedRoute>
                          }
                        />
                      </>
                    ) : user?.account === 1 ? (
                      <>
                        <Route
                          path="/admin/dashboard"
                          element={
                            <LockedPage page={"Analytics_read"}>
                              <AdminDashboard />
                            </LockedPage>
                          }
                        />
                        <Route
                          path="/edit-company"
                          element={
                            <ProtectedRoute>
                              <EditCompany />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/create-job"
                          element={
                            // <LockedPage page={"Job_create"}>
                            //   <CreateJob />
                            // </LockedPage>
                            <ProtectedRouteComponent code="Job_create">
                              <CreateJob />
                            </ProtectedRouteComponent>
                          }
                        />
                        <Route
                          path="/edit-job/:id"
                          element={
                            <ProtectedRoute>
                              <CreateJob />
                            </ProtectedRoute>
                          }
                        />

                        <Route path="/job">
                          <Route
                            path=":jobType"
                            element={
                              <ProtectedRoute>
                                <ProtectedRouteComponent code="Job_access_page">
                                  <JobDetailPage />
                                </ProtectedRouteComponent>
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path=":jobType/:jobId/:tab"
                            element={
                              <ProtectedRoute>
                                <ProtectedRouteComponent code="Job_access_page">
                                  <JobDetailPage />
                                </ProtectedRouteComponent>
                              </ProtectedRoute>
                            }
                          ></Route>
                        </Route>

                        <Route
                          path="candidate/:candidateId"
                          element={
                            <ProtectedRoute>
                              <CandidatePage />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/browse-candidates"
                          exact
                          element={
                            <ProtectedRoute>
                              <BrowseCandidates />
                            </ProtectedRoute>
                          }
                        >
                          <Route
                            path="show-candidate/:candidateId"
                            element={
                              <ProtectedRoute>
                                <CandidateDrawer source="candidate" />
                              </ProtectedRoute>
                            }
                          />
                        </Route>
                        <Route
                          path="/create-company"
                          element={
                            <ProtectedRoute>
                              <CreateCompany />
                            </ProtectedRoute>
                          }
                        />

                        <Route
                          path="/create-company"
                          element={
                            <ProtectedRoute>
                              <CreateCompany />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/company-profile"
                          element={
                            <LockedPage>
                              <CompanyList />
                            </LockedPage>
                          }
                        ></Route>
                        <Route
                          path="/company-profile/:companyId"
                          element={
                            <LockedPage>
                              <CompanyList />
                            </LockedPage>
                          }
                        ></Route>
                        <Route
                          path="/hand-picked"
                          element={
                            <ProtectedRoute>
                              <HandPickedCandidate />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/company-details/:id"
                          element={
                            <ProtectedRoute>
                              <CompanyDetails />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/sequences" element={<SequencePage />}>
                          <Route path="new" element={<CreateSequence />} />
                          <Route path=":id" element={<SequencePageContent />}>
                            <Route index element={<Structure />} />
                            <Route path="structure" element={<Structure />} />
                            <Route path="status" element={<Status />} />
                          </Route>
                        </Route>

                        <Route
                          path="/templates/email"
                          element={<TemplatePage />}
                        >
                          <Route path="new" element={<CreateTemplate />} />
                          <Route path=":id" element={<TemplatePageContent />}>
                            <Route index element={<TemplateStructure />} />
                            {/* <Route
                          path="structure"
                          element={<TemplateStructure />}
                        /> */}
                            {/* <Route path="status" element={<Status />} /> */}
                          </Route>
                        </Route>

                        <Route
                          path="/templates/job"
                          element={<JobTemplateScreen />}
                        >
                          <Route path="new" element={<EditJobTemplate />} />
                          <Route path=":id" element={<TemplateStructure2 />} />
                          <Route
                            path=":id/edit"
                            element={<TemplateStructure2 editTemp={true} />}
                          />
                        </Route>

                        <Route
                          path="/recruiter-profile"
                          element={
                            <ProtectedRoute>
                              <RecruiterProfile />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/chat"
                          element={
                            <ProtectedRoute>
                              <Message />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/chats"
                          element={
                            <ProtectedRoute>
                              <ChatRecruiter />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/interview/:id"
                          element={
                            <ProtectedRoute>
                              <Bot />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/instant-meet/:id"
                          element={
                            <ProtectedRoute>
                              <MeetingNotes />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/instant-meet"
                          element={
                            <ProtectedRoute>
                              <MeetingNotes />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/instant-meet/live/:id"
                          element={
                            <ProtectedRoute>
                              <LiveMeeting />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/systemadmin"
                          element={<Admin user={user} />}
                        >
                          {/* Nested routes */}
                          <Route path="new-appearence" element={<NewUser />} />
                          <Route path="role/:teamId" element={<Role />} />
                          <Route path="users" element={<User />} />
                          <Route
                            path="activities"
                            element={<AdminActivity />}
                          />
                        </Route>
                        <Route
                          path="/company-profile"
                          element={
                            <LockedPage>
                              <CompanyList />
                            </LockedPage>
                          }
                        ></Route>
                        <Route
                          path="/company-profile/:companyId"
                          element={
                            <LockedPage>
                              <CompanyList />
                            </LockedPage>
                          }
                        ></Route>
                        <Route
                          path="/hand-picked"
                          element={
                            <ProtectedRoute>
                              <HandPickedCandidate />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/company-details/:id"
                          element={
                            <ProtectedRoute>
                              <CompanyDetails />
                            </ProtectedRoute>
                          }
                        />
                        <Route path="/sequences" element={<SequencePage />}>
                          <Route path="new" element={<CreateSequence />} />
                          <Route path=":id" element={<SequencePageContent />}>
                            <Route index element={<Structure />} />
                            <Route path="structure" element={<Structure />} />
                            <Route path="status" element={<Status />} />
                          </Route>
                        </Route>
                        <Route path="/templates" element={<TemplatePage />}>
                          <Route path="new" element={<CreateTemplate />} />
                          <Route path=":id" element={<TemplatePageContent />}>
                            <Route index element={<TemplateStructure />} />
                            <Route
                              path="structure"
                              element={<TemplateStructure />}
                            />
                            {/* <Route path="status" element={<Status />} /> */}
                          </Route>
                        </Route>
                        <Route
                          path="/recruiter-profile"
                          element={
                            <ProtectedRoute>
                              <RecruiterProfile />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/chat"
                          element={
                            <ProtectedRoute>
                              <Message />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/chats"
                          element={
                            <ProtectedRoute>
                              <ChatRecruiter />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/interview/:id"
                          element={
                            <ProtectedRoute>
                              <Bot />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/instant-meet/:id"
                          element={
                            <ProtectedRoute>
                              <MeetingNotes />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/instant-meet"
                          element={
                            <ProtectedRoute>
                              <MeetingNotes />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/instant-meet/live/:id"
                          element={
                            <ProtectedRoute>
                              <LiveMeeting />
                            </ProtectedRoute>
                          }
                        />
                        <Route
                          path="/systemadmin"
                          element={<Admin user={user} />}
                        >
                          {/* Nested routes */}
                          <Route path="new-appearence" element={<NewUser />} />
                          <Route path="role/:teamId" element={<Role />} />
                          <Route path="users" element={<User />} />
                          <Route
                            path="template-manager"
                            element={<TemplateManager />}
                          />
                          {/* Add more nested routes here */}
                        </Route>

                        <Route path="/leads-list" element={<LeadList />}>
                          {/* Nested routes */}
                          {/* Add more nested routes here */}
                        </Route>
                      </>
                    ) : null}
                  </>
                )}
              </Routes>
            </ThemeProvider>
            {/* </ThemeContext> */}
          </Suspense>
        </SocketProvider>
        {statusModal.isOpen && statusModal.type === "success" && (
          <SuccessModal
            heading={statusModal.heading}
            subheading={statusModal.subheading}
            onDismiss={closeStatusModal}
          />
        )}
        {statusModal.isOpen && statusModal.type === "error" && (
          <ErrorModal
            heading={statusModal.heading}
            subheading={statusModal.subheading}
            onDismiss={closeStatusModal}
          />
        )}
        <ToastContainer />
        <Tooltip
          id="tooltip"
          noArrow={false}
          style={{
            zIndex: 9999,
            background: "#5F6989",
            fontSize: "12px",
            padding: "4px 8px",
            fontWeight: "400",
            color: "white",
          }}
          place="bottom"
        />
      </core.StatusModalContext.Provider>
    </core.UserContext.Provider>
  );
}

const ProtectedRoute = React.memo(
  ({ children }) => {
    const location = useLocation();

    // Memoize auth check
    const isAuthenticated = useMemo(
      () => core.checkAuth(),
      [], // Only on mount
    );

    // Memoize path construction
    const redirectPath = useMemo(
      () => ({
        pathname: "/login",
        state: location.pathname + location.search,
      }),
      [location.pathname, location.search],
    );

    // Memoize navigation element
    const navigationElement = useMemo(
      () => <Navigate to={redirectPath} />,
      [redirectPath],
    );

    return isAuthenticated ? children : navigationElement;
  },
  (prevProps, nextProps) => {
    // Custom comparison for children
    return prevProps.children === nextProps.children;
  },
);
