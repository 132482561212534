import { useState, useRef, useEffect } from "react";

const CustomSelect = ({
  options,
  value,
  onChange,
  placeholder,
  className,
  dropdownheight,
  is24HourFormat,
  isOfficeHours = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const listRef = useRef(null);
  const containerRef = useRef(null);
  const [isEditing, setIsEditing] = useState(false);
  const [customInput, setCustomInput] = useState(value || "");
  const inputref = useRef(null);
  const [invalid, setInvalid] = useState(false);
  const selectedOptionIndex = options
    ? options.findIndex((option) => option.value === value)
    : -1;

  const handleDoubleClick = () => {
    setIsEditing(true);
    setCustomInput(value || "");
    setIsOpen(false);
  };
  const validateTime = (time) => {
    const time24HourRegex = /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/;
    const time12HourRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9]\s*(AM|PM|am|pm)$/;

    if (is24HourFormat) {
      return time24HourRegex.test(time);
    } else {
      return time12HourRegex.test(time);
    }
  };

  const convertTo24HourFormat = (time12h) => {
    const match = time12h.match(
      /^(0?[1-9]|1[0-2]):([0-5][0-9])\s*(AM|PM|am|pm)$/,
    );
    if (!match) return null;

    let [_, hours, minutes, modifier] = match;
    hours = parseInt(hours, 10);
    modifier = modifier.toLowerCase();

    if (hours === 12) {
      hours = modifier === "am" ? 0 : 12;
    } else if (modifier === "pm") {
      hours += 12;
    }

    return `${hours.toString().padStart(2, "0")}:${minutes}`;
  };

  const convertTo12HourFormat = (time24h) => {
    const match = time24h.match(/^([01]?[0-9]|2[0-3]):([0-5][0-9])$/);
    if (!match) return null;

    let [_, hours, minutes] = match;
    hours = parseInt(hours, 10);

    let period = "AM";
    if (hours >= 12) {
      period = "PM";
      if (hours > 12) hours -= 12;
    }
    if (hours === 0) hours = 12;

    return `${hours}:${minutes} ${period}`;
  };

  const formatTime = (time) => {
    if (is24HourFormat) {
      return time.match(/^([01]?[0-9]|2[0-3]):[0-5][0-9]$/)
        ? time
        : convertTo24HourFormat(time);
    } else {
      const match = time.match(
        /^(0?[1-9]|1[0-2]):([0-5][0-9])\s*(AM|PM|am|pm)$/,
      );
      if (match) {
        const [_, hours, minutes, period] = match;
        return `${hours}:${minutes} ${period.toUpperCase()}`;
      }
      return convertTo12HourFormat(time);
    }
  };

  const handleCustomInput = () => {
    let validatedTime = customInput.trim();

    if (!validateTime(validatedTime)) {
      setCustomInput(value);
      setInvalid(true);
    } else {
      validatedTime = formatTime(validatedTime);
      onChange(validatedTime);
    }
    setIsEditing(false);
  };

  useEffect(() => {
    setInvalid(!validateTime(customInput.trim()));
  }, [customInput]);

  useEffect(() => {
    if (isOpen && selectedOptionIndex !== -1 && listRef.current) {
      const listItems = listRef.current.querySelectorAll("li");
      const selectedItem = listItems[selectedOptionIndex];

      if (selectedItem) {
        listRef.current.scrollTop = selectedItem.offsetTop;
        setHighlightedIndex(selectedOptionIndex);
      }
    }
  }, [isOpen, selectedOptionIndex]);

  const handleSelectOption = (selectedValue) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  const handleKeyDown = (event) => {
    if (event.key === "ArrowDown" && highlightedIndex < options.length - 1) {
      setHighlightedIndex(highlightedIndex + 1);
    } else if (event.key === "ArrowUp" && highlightedIndex > 0) {
      setHighlightedIndex(highlightedIndex - 1);
    } else if (event.key === "Enter" && highlightedIndex >= 0) {
      handleSelectOption(options[highlightedIndex].value);
    }
  };

  const handleClickOutside = (event) => {
    if (containerRef.current && !containerRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className="relative w-full "
      onDoubleClick={handleDoubleClick}
    >
      {isEditing && !isOfficeHours ? (
        <input
          type="text"
          ref={inputref}
          value={customInput}
          onChange={(e) => setCustomInput(e.target.value)}
          onBlur={handleCustomInput}
          placeholder={is24HourFormat ? "HH:MM" : "HH:MM XM"}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleCustomInput();
            }
          }}
          className={`${className} w-full placeholder:text-gray-700  px-4 py-2 rounded-lg h-[36px] text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] ${invalid ? "border border-red-500 bg-red-200" : "border border-gray-400"}`}
          autoFocus
        />
      ) : (
        <div
          className={`border border-gray-400 px-4 z-[100] py-2 cursor-pointer flex items-center justify-between h-[36px] rounded-lg text-sm shadow-[0px_2px_0px_rgba(0,0,0,0.04)] ${isOfficeHours ? "bg-gray-200 cursor-not-allowed opacity-75" : "bg-white"} ${className}`}
          onClick={() => !isOfficeHours && setIsOpen(!isOpen)}
          onKeyDown={(e) => !isOfficeHours && handleKeyDown(e)}
          tabIndex={isOfficeHours ? -1 : 0} // Disable focus if office hours
        >
          {value ? value : placeholder}
          {!isOfficeHours && (
            <svg
              className="absolute right-2 top-1/2 transform -translate-y-1/2 w-4 h-4 pointer-events-none"
              fill="#4A5568"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M7 10l5 5 5-5H7z"></path>
            </svg>
          )}
        </div>
      )}

      {isOpen && (
        <ul
          ref={listRef}
          className={`absolute top-[40px] z-[5000] py-2 bg-white w-full overflow-y-auto max-h-[300px] border border-gray-400 px-2 text-xs lg:text-sm rounded-lg  shadow-xl`}
        >
          {options && options.length > 0 ? (
            options.map((option, index) => (
              <li
                key={option.value}
                className={`p-2 cursor-pointer ${
                  highlightedIndex === index || value == option.value
                    ? "bg-defaultBg rounded-md"
                    : ""
                }`}
                onClick={() => handleSelectOption(option.value)}
                onMouseEnter={() => setHighlightedIndex(index)}
              >
                {option.label}
              </li>
            ))
          ) : (
            <li
              className="p-2 cursor-pointer bg-defaultBg rounded-md"
              onClick={handleDoubleClick}
            >
              Custom...
            </li>
          )}
        </ul>
      )}
    </div>
  );
};

export default CustomSelect;
