import React, { useContext, useMemo, useState } from "react";
import {
  LikertScale,
  MCQAnswer,
  OneChoiceAnswer,
  ParagraghAnswer,
} from "./Answer";
import { ScreeningQuestionContext } from "../../../../lib/context";
import { ANSWERTYPES } from "../../../../lib/constants";

const capitalize = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);

const Question = ({ data, index, isExpanded, toggleExpand, ...props }) => {
  const { setData } = useContext(ScreeningQuestionContext);

  const answerComp = useMemo(() => {
    switch (data?.answerType) {
      case ANSWERTYPES?.RADIO:
        return <OneChoiceAnswer index={index} />;
      case ANSWERTYPES?.YESNO:
        return <OneChoiceAnswer index={index} options={["Yes", "No"]} />;
      case ANSWERTYPES?.MCQ:
        return <MCQAnswer index={index} />;
      case ANSWERTYPES?.LIKERT:
        return <LikertScale index={index} />;
      default:
        return <></>;
      // return <ParagraghAnswer index={index} type={data?.answerType} />;
    }
  }, [data?.answerType]);

  return (
    <div
      className="w-full py-4 last-of-type:border-b-0 border-b border-gray-500 flex flex-col gap-4 items-start"
      {...props}
    >
      {isExpanded ? (
        <>
          <div className="w-full">
            <label className="text-sm font-semibold text-gray-700 w-full">
              Title
            </label>
            <input
              type="text"
              value={data?.name}
              onChange={(e) => setData(index, "name", e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>{" "}
          <div className="w-full">
            <label className="text-sm font-semibold text-gray-700 w-full">
              Question
            </label>
            <input
              type="text"
              value={data?.question}
              onChange={(e) => setData(index, "question", e.target.value)}
              className="w-full border border-gray-300 rounded-md p-2"
            />
          </div>
          <div className="w-full flex flex-col gap-1 items-start">
            <label
              htmlFor={"answerType" + index}
              className="text-sm font-semibold text-gray-700 mb-0"
            >
              Type
            </label>
            <select
              value={data?.answerType}
              onChange={(e) => setData(index, "answerType", e.target.value)}
              id={"answerType" + index}
              className="w-full border border-gray-300 rounded-md p-2"
            >
              {Object.values(ANSWERTYPES).map((option) => (
                <option key={option} value={option}>
                  {capitalize(option)}
                </option>
              ))}
            </select>
          </div>
          {answerComp}
          <div className="w-full flex gap-1 items-center">
            <input
              checked={data?.required}
              onClick={() => setData(index, "required", !data?.required)}
              id={"required" + index}
              name={"required" + index}
              type="radio"
              className="w-4 h-4 p-1"
            />
            <label
              htmlFor={"required" + index}
              className="text-sm text-red-800 font-medium mb-0 mt-1 pl-1"
            >
              Required
            </label>
          </div>
        </>
      ) : (
        <div className="w-full">
          <span className="font-semibold text-gray-700">{data?.name}</span>:{" "}
          {data?.question}
        </div>
      )}
    </div>
  );
};

export default Question;
